import { ButtonHTMLAttributes, useState , useEffect} from 'react';
import { Button } from '../Button';
import { formatBrMoney } from '../../shared/utils';
import { ButtonDownload, Select } from '../../pages/DashboardUsers/styles';
import { SiMicrosoftexcel } from 'react-icons/si';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { Collapse } from 'react-collapse';
import './styles.css';
import { api } from '../../services/api';

type MarketListProps = {
  id: string | null;
  name: string | null;
  type: string | null;
  label: string | null;
  value: string | null;
};

export function MarketAnalyses({
  title,
  marketFullData,
  statusColapse,
  callback,
  colapse,
  showAverage,
}: any) {
  function ShowMarket({ marketData }: any) {
    const [isTeste, setIsTeste] = useState<any>(0);
    const [markets, setMarkets] = useState<MarketListProps[]>([]);

    async function listMarkets() {
      try {
        const { data } = await api.get('/markets/statistics/titles');
        const intermediaris = data.map((intermediari) => ({
          label: intermediari.name,
          value: intermediari.id,
        }));
        setMarkets(intermediaris);

      } catch (error) {

      }
    }

    function getColorTda(campo: string, i: number, colapse: number) {
      if (colapse !== 0) {
        if (campo === 'p10_interpolated') {
          return marketFullData.tda[i].p10_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p25_interpolated') {
          return marketFullData.tda[i].p25_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p50_interpolated') {
          return marketFullData.tda[i].p50_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p75_interpolated') {
          return marketFullData.tda[i].p75_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p90_interpolated') {
          return marketFullData.tda[i].p90_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
      }
    }

    function getColorRda(campo: string, i: number, colapse: number) {
      if (colapse !== 0) {
        if (campo === 'p10_interpolated') {
          return marketFullData.rda[i].p10_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p25_interpolated') {
          return marketFullData.rda[i].p25_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p50_interpolated') {
          return marketFullData.rda[i].p50_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p75_interpolated') {
          return marketFullData.rda[i].p75_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p90_interpolated') {
          return marketFullData.rda[i].p90_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
      }
    }

    function getColorTd(campo: string, i: number, colapse: number) {
      if (colapse !== 0) {
        if (campo === 'p10_interpolated') {
          return marketFullData.td[i].p10_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p25_interpolated') {
          return marketFullData.td[i].p25_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p50_interpolated') {
          return marketFullData.td[i].p50_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p75_interpolated') {
          return marketFullData.td[i].p75_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p90_interpolated') {
          return marketFullData.td[i].p90_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
      }
    }

    function getColorRd(campo: string, i: number, colapse: number) {
      if (colapse !== 0) {
        if (campo === 'p10_interpolated') {
          return marketFullData.rd[i].p10_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p25_interpolated') {
          return marketFullData.rd[i].p25_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p50_interpolated') {
          return marketFullData.rd[i].p50_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p75_interpolated') {
          return marketFullData.rd[i].p75_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p90_interpolated') {
          return marketFullData.rd[i].p90_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
      }
    }

    function getColorSb(campo: string, i: number, colapse: number) {
      if (colapse !== 0) {
        if (campo === 'p10_interpolated') {
          return marketFullData.sb[i].p10_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p25_interpolated') {
          return marketFullData.sb[i].p25_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
        if (campo === 'p50_interpolated') {
          return marketFullData.sb[i].p50_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p75_interpolated') {
          return marketFullData.sb[i].p75_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }

        if (campo === 'p90_interpolated') {
          return marketFullData.sb[i].p90_interpolated_is_discrepant
            ? 'text-error'
            : '';
        }
      }
    }

    useEffect(() => {
      listMarkets();
    }, []);

    return (
      <div
        tabIndex={colapse}
        style={{ cursor: 'pointer', marginBottom: '25px' }}
      >
        <div
          className="collapse-title text-xl font-medium bg-slate-200 rounded-lg"
          onClick={() => callback()}
        >
          <div className="flex">
            <div className="flex items-center w-14">
              {statusColapse ? <FiChevronDown /> : <FiChevronRight />}
            </div>
            <div className="flex-auto w-64">
              <h1>{title}</h1>
            </div>
          </div>
        </div>
        <div>
          <Collapse isOpened={statusColapse}>
            <div className="card bg-base-100 shadow-xl mb-8 p-3">
              <div className="col-end-4 col-span-2 flex justify-start"></div>
              <div className="col-end-8 col-span-2 flex justify-end"></div>
              <div className="card bg-base-100 shadow-xl mb-8">
                <div className="overflow-x-auto flex pb-3">
                  {marketData &&
                  marketData.sb &&
                  title !==
                    'Análise de Crescimento Entre Linhas de Remuneração' ? (
                    <table className="table table-compact table-zebra w-full min-w-[400px] mr-3">
                      <thead>
                        <tr>
                          <th colSpan={7}>SB</th>
                        </tr>
                        <tr>
                          <th>Grade</th>
                          <th>P10</th>
                          <th>P25</th>
                          <th>P50</th>
                          <th>P75</th>
                          <th>P90</th>
                          <th>{showAverage ? 'Média' : ''}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketFullData.sb.map((i: any, index) => (
                          <tr
                            key={
                              i.id +
                              '-' +
                              Math.random().toString().substring(3, 8)
                            }
                          >
                            <td>
                              <strong>{i.grade}</strong>
                            </td>
                            <td
                              className={getColorSb(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p10_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p10_interpolated)
                                  : i.p10_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorSb(
                                'p25_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p25_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p25_interpolated)
                                  : i.p25_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorSb(
                                'p50_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p50_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p50_interpolated)
                                  : i.p50_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorSb(
                                'p75_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p75_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p75_interpolated)
                                  : i.p75_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorSb(
                                'p90_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p90_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p90_interpolated)
                                  : i.p90_interpolated + '%'
                                : null}
                            </td>
                            <td>
                              {showAverage ? formatBrMoney(i.average) : ''}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}

                  {marketData && marketData.tda ? (
                    <table className="table table-compact table-zebra w-full min-w-[400px] mr-3">
                      <thead>
                        <tr>
                          <th colSpan={7}>TDA</th>
                        </tr>
                        <tr>
                          <th>Grade</th>
                          <th>P10</th>
                          <th>P25</th>
                          <th>P50</th>
                          <th>P75</th>
                          <th>P90</th>
                          <th>{showAverage ? 'Média' : ''}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketFullData.tda.map((i: any, index) => (
                          <tr
                            key={
                              i.id +
                              '-' +
                              Math.random().toString().substring(3, 8)
                            }
                          >
                            <td>
                              <strong>{i.grade}</strong>
                            </td>
                            <td
                              className={getColorTda(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p10_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p10_interpolated)
                                  : i.p10_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTda(
                                'p25_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p25_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p25_interpolated)
                                  : i.p25_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTda(
                                'p50_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p50_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p50_interpolated)
                                  : i.p50_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTda(
                                'p75_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p75_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p75_interpolated)
                                  : i.p75_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTda(
                                'p90_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p90_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p90_interpolated)
                                  : i.p90_interpolated + '%'
                                : null}
                            </td>
                            <td>
                              {showAverage ? formatBrMoney(i.average) : ''}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}

                  {marketData && marketData.rda ? (
                    <table className="table table-compact table-zebra w-full min-w-[400px] mr-3">
                      <thead>
                        <tr>
                          <th colSpan={7}>RDA</th>
                        </tr>
                        <tr>
                          <th>Grade</th>
                          <th>P10</th>
                          <th>P25</th>
                          <th>P50</th>
                          <th>P75</th>
                          <th>P90</th>
                          <th>{showAverage ? 'Média' : ''}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketFullData.rda.map((i: any, index) => (
                          <tr
                            key={
                              i.id +
                              '-' +
                              Math.random().toString().substring(3, 8)
                            }
                          >
                            <td
                              className={getColorRda(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              <strong>{i.grade}</strong>
                            </td>
                            <td
                              className={getColorRda(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p10_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p10_interpolated)
                                  : i.p10_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRda(
                                'p25_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p25_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p25_interpolated)
                                  : i.p25_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRda(
                                'p50_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p50_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p50_interpolated)
                                  : i.p50_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRda(
                                'p75_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p75_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p75_interpolated)
                                  : i.p75_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRda(
                                'p90_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p90_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p90_interpolated)
                                  : i.p90_interpolated + '%'
                                : null}
                            </td>
                            <td>
                              {showAverage ? formatBrMoney(i.average) : ''}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}

                  {marketData && marketData.td ? (
                    <table className="table table-compact table-zebra w-full min-w-[400px] mr-3">
                      <thead>
                        <tr>
                          <th colSpan={7}>TD</th>
                        </tr>
                        <tr>
                          <th>Grade</th>
                          <th>P10</th>
                          <th>P25</th>
                          <th>P50</th>
                          <th>P75</th>
                          <th>P90</th>
                          <th>{showAverage ? 'Média' : ''}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketFullData.td.map((i: any, index) => (
                          <tr
                            key={
                              i.id +
                              '-' +
                              Math.random().toString().substring(3, 8)
                            }
                          >
                            <td>
                              <strong>{i.grade}</strong>
                            </td>
                            <td
                              className={getColorTd(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p10_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p10_interpolated)
                                  : i.p10_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTd(
                                'p25_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p25_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p25_interpolated)
                                  : i.p25_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTd(
                                'p50_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p50_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p50_interpolated)
                                  : i.p50_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTd(
                                'p75_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p75_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p75_interpolated)
                                  : i.p75_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorTd(
                                'p90_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p90_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p90_interpolated)
                                  : i.p90_interpolated + '%'
                                : null}
                            </td>
                            <td>
                              {showAverage ? formatBrMoney(i.average) : ''}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}

                  {marketData && marketData.rd ? (
                    <table className="table table-compact table-zebra w-full min-w-[400px] mr-3">
                      <thead>
                        <tr>
                          <th colSpan={7}>RD</th>
                        </tr>
                        <tr>
                          <th>Grade</th>
                          <th>P10</th>
                          <th>P25</th>
                          <th>P50</th>
                          <th>P75</th>
                          <th>P90</th>
                          <th>{showAverage ? 'Média' : ''}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {marketFullData.rd.map((i: any, index) => (
                          <tr
                            key={
                              i.id +
                              '-' +
                              Math.random().toString().substring(3, 8)
                            }
                          >
                            <td>
                              <strong>{i.grade}</strong>
                            </td>
                            <td
                              className={getColorRd(
                                'p10_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p10_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p10_interpolated)
                                  : i.p10_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRd(
                                'p25_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p25_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p25_interpolated)
                                  : i.p25_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRd(
                                'p50_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p50_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p50_interpolated)
                                  : i.p50_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRd(
                                'p75_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p75_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p75_interpolated)
                                  : i.p75_interpolated + '%'
                                : null}
                            </td>
                            <td
                              className={getColorRd(
                                'p90_interpolated',
                                index,
                                colapse,
                              )}
                            >
                              {i.p90_interpolated !== null
                                ? colapse === 0
                                  ? formatBrMoney(i.p90_interpolated)
                                  : i.p90_interpolated + '%'
                                : null}
                            </td>
                            <td>
                              {showAverage ? formatBrMoney(i.average) : ''}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }

  return (
    <>
      <ShowMarket marketData={marketFullData} />
    </>
  );
}
