import styled, { css } from 'styled-components';

interface InputProps {
  isErrored: boolean;
}

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--black-light);

  > p {
    padding: 1rem;
    font-weight: 700;
  }

  > svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  padding: 1rem;
  max-height: 70vh;
  overflow-y: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    padding: 0 1rem;
    width: 50%;
    margin-top: 1rem;

    > div {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    > div {
      padding: 0 1rem;
      width: 100%;

      > div {
        /* width: 100%; */
        max-width: 100%;
      }
    }
  };
`;

export const ContainerButton = styled.div`
  padding: 1rem 1rem 0;
  width: 100%;
  max-width: 210px;
`;

export const InputWithMask = styled.div<InputProps>`
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--text-title);
  color: var(--text-body);

  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--error);
    `}

  input {
    color: var(--text-title);
    flex: 1;
    background: transparent;

    &::placeholder {
      color: var(--text-body);
    }
  }
`;

export const SectionInputMask = styled.div`
  p {
    height: 20px;
    margin: 10px 0 0;
    font-size: 0.75rem;
    color: var(--error);
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;
