import { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { Button } from '../Button';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  OptionSelect,
  PositionFamily,
  PositionSubFamily,
} from '../../interfaces';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  FormContent,
  Select,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../Input';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface SubmitForm {
  id?: string;
  title: string;
  description: string;
  position_family_id: string;
}

interface Props {
  closeModal: () => void;
  callback: (data: SubmitForm) => void;
  data: PositionSubFamily | null;
  familyOptions: OptionSelect[];
}

const ModalSubFamily = ({
  data,
  closeModal,
  callback,
  familyOptions,
}: Props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitForm>();

  const title = !!data?.id ? 'Alterar' : 'Criar';

  const submit = (data: SubmitForm) => {
    callback(data);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>{title}</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>

        <ContentModal>
          <Form autoComplete="off" onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div className="flex-auto w-full mt-4">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Título </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('title', {
                      required: true,
                      value: data?.title,
                    })}
                    // error={!!errors.title}
                    // helperText={!!errors.title ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.title ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto w-full mt-0">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Descrição </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('description', {
                      required: false,
                      value: data?.description || '',
                    })}
                    // error={!!errors.description}
                    // helperText={!!errors.description ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.description ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
              <Controller
                name="position_family_id"
                control={control}
                rules={{ required: true }}
                defaultValue={
                  data?.position_family_id || familyOptions[0].value
                }
                render={({ field: { onChange, name, ref } }) => (
                  <Select
                    ref={ref}
                    name={name}
                    defaultValue={
                      data?.position_family_id
                        ? {
                            label: familyOptions.find(
                              (f) => f.value === data.position_family_id,
                            )?.label,
                            value: data.position_family_id,
                          }
                        : familyOptions[0]
                    }
                    onChange={(val: any) => onChange(val.value)}
                    options={familyOptions}
                    isSearchable={true}
                    isClearable={false}
                    maxMenuHeight={150}
                    placeholder="Escolha um tipo"
                  />
                )}
              />
            </FormContent>

            <ContainerButton>
              <Button type="submit">{title}</Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalSubFamily };
