import { Menu } from '../../components/Menu';
import ClickAwayListener from 'react-click-away-listener';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '../../contexts/AuthContext';
import {
  Container,
  DashboardTemplateHeader,
  DashboardTemplateHeaderContainer,
  DashboardTemplateHeaderLeft,
  DashboardTemplateHeaderRight,
  DashboardTemplateBody,
  DashboardTemplateContent,
  BasicContainer,
  Logout,
} from './styles';
import logo from '../../assets/png/logo.png';

import { useState } from 'react';
const Template = ({ children }) => {
  const { signOut } = useAuth();
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Container>
      <DashboardTemplateHeader>
        <DashboardTemplateHeaderContainer>
          <DashboardTemplateHeaderLeft>
            <img src={logo} alt="SMRTComp" style={{ height: '60px' }} />
          </DashboardTemplateHeaderLeft>

          <DashboardTemplateHeaderRight>
            <Logout onClick={signOut}>
              <FiLogOut />
            </Logout>
          </DashboardTemplateHeaderRight>
        </DashboardTemplateHeaderContainer>
      </DashboardTemplateHeader>

      <DashboardTemplateBody>
        <ClickAwayListener
          onClickAway={() => isOpenMobileMenu && setIsOpenMobileMenu(false)}
        >
          <Menu />
        </ClickAwayListener>
        <DashboardTemplateContent state={isCollapsed ? 'collapsed' : 'full'}>
          <BasicContainer>{children}</BasicContainer>
        </DashboardTemplateContent>
      </DashboardTemplateBody>
    </Container>
  );
};

export default Template;
