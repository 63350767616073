import { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiSearch, FiXCircle } from 'react-icons/fi';
import { Button } from '../../components/Button';
import { useForm } from 'react-hook-form';
import { Container, ContainerButton, Form } from './styles';
import { api } from '../../services/api';
import { ModalGeneric } from '../../components/ModalGeneric';
import { toast } from 'react-toastify';
import { ButtonPrimary } from '../DashboardUsers/styles';
import './styles.css';
import { Content } from '../../pages/MarketConfiguration/styles';
import { AppError } from '../../error/AppError';
import { ETenantRolesTag } from '../../enums/roles';

export default function Groups() {
  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [handleEdit, setHandleEdit] = useState(false);
  const [handleShow, setHandleShow] = useState(false);
  const [handleStartChecked, setHandleStartChecked] = useState(false);
  const [groupSelected, setGroupSelected] = useState<any>([]);
  const [itemAddPermission, setItemAddPermission] = useState<string>('');
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [itemDelete, setItemDelete] = useState<string>('');
  const [permissionItems, setPermissionItems] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const submit = async (data) => {
    try {
      const permissions = permissionItems.map((item: any) => {
        item.permission_id = item.id;
        return item;
      });

      let retorno;
      if (handleEdit) {
        const parseData = {
          name: data.nomePerfil,
          description: data.descricao,
          tag: data.tag,
          permissions,
        };
        retorno = await api.put(`/roles/${groupSelected.id}`, parseData);
      } else {
        const parseData = {
          name: data.nomePerfil,
          description: data.descricao,
          tag: data.tag,
          permissions,
        };
        retorno = await api.post('/roles', parseData);
      }

      if (retorno.status === 201 || retorno.status === 200) {
        toast.success(
          handleEdit
            ? 'Perfil editado com sucesso.'
            : 'Perfil criado com sucesso.',
        );
        getGroups();
        reset();
        setIsModalOpenPermission(false);
      }
    } catch (error) {
      AppError(error, `Problemas ao criar perfil!`);
    }
  };

  async function handleDeletePerfil() {
    try {
      const id = itemDelete;
      const retorno = await api.delete(`/roles/${id}`);
      toast.success('Perfil excluido com sucesso.');
      getGroups();
    } catch (error) {
      AppError(error, `Problemas ao excluir perfil!`);
    }
  }

  async function getGroups() {
    const { data } = await api.get('/roles');
    setGroups(data);
  }

  async function getPermissions() {
    const { data } = await api.get('/permissions');
    setPermissions(data);
  }

  function handleAddPermission() {
    if (itemAddPermission) {
      const isExists = permissionItems.filter(
        (item: any) => item.id === itemAddPermission,
      );
      if (!isExists.length) {
        const newPermission = {
          id: itemAddPermission,
          show: false,
          create: false,
          update: false,
          delete: false,
          disable: false,
          value: null,
        };
        setPermissionItems((prev: any) => [...prev, newPermission]);
      }
    }
  }

  function handleDeletePermission(id: string) {
    const permissionItemsFilter = permissionItems.filter(
      (item: any) => item.id !== id,
    );
    setPermissionItems(permissionItemsFilter);
  }

  function findPermissionTitle(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].title;
  }

  function findPermissionName(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].name;
  }

  function handleDelete(item: any) {
    setItemDelete(item['id']);
    setIsModalOpenDelete(true);
  }

  function handleCheckboxVizualizar(
    id: any,
    type: any,
    numItem: any,
    event: any,
  ) {
    permissionItems.map((item: any) => {
      if (String(item.id) === String(id)) {
        if (type === 'show') {
          item.show = event.target.checked;
        }
        if (type === 'create') {
          item.create = event.target.checked;
        }
        if (type === 'update') {
          item.update = event.target.checked;
        }
        if (type === 'delete') {
          item.delete = event.target.checked;
        }
        if (type === 'disable') {
          item.disable = event.target.checked;
        }
      }
      return item;
    });
  }

  function handlePermission(permission: any) {
    setPermissionItems(permission);
  }

  function findChecked(id: string, type: string) {
    if (handleEdit && handleStartChecked === false) {
      const permission: any = permissionItems.filter((e: any) => e.id === id);

      if (permission.length) {
        if (type === 'show') {
          return permission[0].show;
        }
        if (type === 'create') {
          return permission[0].create;
        }
        if (type === 'delete') {
          return permission[0].delete;
        }
        if (type === 'update') {
          return permission[0].update;
        }
        if (type === 'disable') {
          return permission[0].disable;
        }
      }
    }
  }

  const handleChangeAction = useCallback(
    (type: string, group) => {
      switch (type) {
        case 'edit':
          setIsModalOpenPermission(true);
          setGroupSelected(group);
          setHandleEdit(true);
          setHandleShow(false);
          reset();
          handlePermission(group.permissions);
          setHandleStartChecked(false);
          break;
        case 'delete':
          handleDelete(group);
          break;
        default:
          break;
      }
    },
    [
      setIsModalOpenPermission,
      setGroupSelected,
      setHandleEdit,
      setHandleShow,
      reset,
      handlePermission,
      setHandleStartChecked,
      handleDelete,
    ],
  );

  useEffect(() => {
    getGroups();
    getPermissions();
  }, []);

  return (
    <Container>
      <h2 className="text-2xl font-bold">Perfis</h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 shadow-xl card bg-base-100">
          <div className="flex justify-end">
            <ButtonPrimary
              onClick={() => {
                setIsModalOpenPermission(true);
                reset();
                setHandleEdit(false);
                setHandleShow(false);
                setHandleStartChecked(false);
              }}
              className="mb-5"
            >
              Criar novo perfil
            </ButtonPrimary>
          </div>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th></th>
                  <th>Nome perfil</th>
                  <th>Tag</th>
                  <th>Descrição</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {groups.map((group: any) => (
                  <tr key={group.id}>
                    <td className="w-[50px]">
                      <FiSearch
                        style={{ cursor: 'pointer' }}
                        onClick={(e: any) => {
                          setIsModalOpenPermission(true);
                          setGroupSelected(group);
                          setHandleEdit(true);
                          setHandleShow(true);
                          handlePermission(group.permissions);
                          reset();
                          setHandleStartChecked(false);
                        }}
                      />
                    </td>
                    <td className="w-[350px]">{group.name}</td>
                    <td>{group.tag}</td>
                    <td>{group.description}</td>
                    <td>
                      <select
                        value={
                          group?.id === groupSelected?.id ? selectedAction : ''
                        }
                        className="w-[150px] select select-bordered"
                        onChange={(e) => {
                          handleChangeAction(e.target.value, group);
                          setSelectedAction(e.target.value);
                        }}
                      >
                        <option>Ações</option>
                        <option value="edit" key="edit">
                          Editar
                        </option>
                        <option value="delete" key="delete">
                          Excluir
                        </option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ModalGeneric
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={(e) => {
          setIsModalOpenDelete(e);
          setSelectedAction('Ações');
        }}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
          Tem certeza que deseja Excluir ?
          <br />
          <Button
            type="button"
            onClick={(e: any) => {
              setIsModalOpenDelete(false);
              handleDeletePerfil();
            }}
          >
            Confirmar
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => {
              setIsModalOpenDelete(false);
              setSelectedAction('Ações');
            }}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenPermission}
        setIsModalOpen={(e) => {
          setIsModalOpenPermission(e);
          setSelectedAction('Ações');
        }}
        full={true}
      >
        <Form onSubmit={handleSubmit(submit)}>
          <div className="grid gap-4 min-w-[800px] mb-'8">
            <div className="flex-auto w-64">
              <div className="w-full">
                <label className="label">
                  <strong className="label-text">
                    Nome do perfil
                    <label className="text-error">*</label>
                  </strong>
                </label>

                <input
                  type="text"
                  {...register('nomePerfil', {
                    required: true,
                  })}
                  defaultValue={handleEdit ? groupSelected['name'] : null}
                  className="w-full max-w-xs input input-bordered"
                  disabled={handleShow}
                />
              </div>
              <Content>
                {!!errors.nomePerfil ? 'Campo Obrigatório' : null}
              </Content>
            </div>

            <div className="flex-auto w-64">
              <div className="w-full">
                <label className="label">
                  <strong className="label-text">
                    Tag
                    <label className="text-error">*</label>
                  </strong>
                </label>

                <select
                  className="w-[350px] select select-bordered"
                  disabled={handleShow}
                  {...register('tag', {
                    required: true,
                  })}
                  defaultValue={handleEdit ? groupSelected['tag'] : null}
                >
                  <option></option>
                  {[
                    ETenantRolesTag.MASTER.valueOf(),
                    ETenantRolesTag.DEFAULT.valueOf(),
                  ].map((item: any) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>

                {/* <input
                  type="text"
                  {...register('tag', {
                    required: true,
                  })}
                  disabled={handleShow}
                  defaultValue={handleEdit ? groupSelected['tag'] : null}
                  className="w-full max-w-xs input input-bordered"
                /> */}
              </div>
              <Content>{!!errors.tag ? 'Campo Obrigatório' : null}</Content>
            </div>

            <div className="w-full">
              <label className="label">
                <strong className="label-text">Descrição</strong>
              </label>

              <textarea
                className="input input-bordered w-[750px] h-[100px]"
                {...register('descricao')}
                disabled={handleShow}
                defaultValue={handleEdit ? groupSelected['description'] : null}
              ></textarea>
            </div>

            <div className="flex-auto w-64">
              <div className="flex w-full">
                <label className="label">
                  <strong className="label-text">Permissão</strong>
                </label>

                <select
                  className="w-[350px] select select-bordered"
                  disabled={handleShow}
                  onChange={(e: any) => {
                    setItemAddPermission(e.target.value);
                  }}
                >
                  <option></option>
                  {permissions.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                <div
                  className="btn btn-sm btn-success w-[50px] h-[50px] ml-3"
                  onClick={handleAddPermission}
                >
                  <FiPlus />
                </div>
              </div>
            </div>
          </div>

          {permissionItems.length ? (
            <div className="flex mb-8">
              <table className="table w-full table-compact table-zebra">
                <tbody>
                  <tr className="border-b dark:border-neutral-200">
                    <td>Nome</td>
                    <td>Título</td>
                    <td>Visualizar</td>
                    <td>Adicionar</td>
                    <td>Alterar</td>
                    <td>Excluir</td>
                    <td>Desabilitar</td>
                    <td></td>
                  </tr>
                  {permissionItems.map((item: any, index) => (
                    <tr
                      className="border-b dark:border-neutral-200"
                      key={index}
                    >
                      <td>
                        <strong>{findPermissionName(item.id)}</strong>
                      </td>
                      <td>
                        <strong>{findPermissionTitle(item.id)}</strong>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'show',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'show')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'create',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'create')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'update',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'update')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'delete',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'delete')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'disable',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'disable')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        {!handleShow && (
                          <div
                            onClick={() => handleDeletePermission(item.id)}
                            className="btn btn-sm btn-error w-[50px] h-[50px]"
                          >
                            <FiXCircle />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}

          {permissionItems.length == 0 && (
            <div
              className="alert hadow-lg alert-warning alertSalvar"
              style={{ marginTop: '20px', marginBottom: '30px' }}
            >
              <div>
                <span>
                  Atenção: Adicione pelo menos uma permissão para cadastrar o
                  perfil !
                </span>
              </div>
            </div>
          )}

          {!handleShow && (
            <ContainerButton>
              <Button type="submit">
                {handleEdit ? 'Editar Perfil' : 'Criar Perfil'}
              </Button>
            </ContainerButton>
          )}

          {handleShow && (
            <ContainerButton>
              <Button onClick={() => setIsModalOpenPermission(false)}>
                Fechar
              </Button>
            </ContainerButton>
          )}
        </Form>
      </ModalGeneric>
    </Container>
  );
}
