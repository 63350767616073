import React, {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { ContainerInput, Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  error?: boolean;
  helperText?: string;
  callback?: () => void;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { containerStyle = {}, icon: Icon, error, helperText, callback, ...rest },
  ref,
) => {
  return (
    <ContainerInput>
      <Container style={containerStyle} isErrored={!!error}>
        <input ref={ref} {...rest} />
        {Icon && <Icon size={20} onClick={callback} />}
        {error && <Error>{helperText}</Error>}
      </Container>
    </ContainerInput>
  );
};

export const Input = forwardRef(InputBase);
