import { useEffect, useState } from 'react';
import { FiEdit, FiPlus, FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Input } from '../../components/Input';
import { ModalFamily } from '../../components/ModalFamily';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { PositionFamily } from '../../interfaces';
import { api } from '../../services/api';
import { ButtonPrimary } from '../DashboardUsers/styles';
import * as s from './styles';

interface RequestFamiliesListProps {
  page?: number;
  limit?: number;
  title?: string;
}

export default function Families() {
  const [familiesList, setFamiliesList] = useState<PositionFamily[]>([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [titleSearch, setTitleSearch] = useState('');
  const [currentFamilyEdit, setCurrentFamilyEdit] =
    useState<PositionFamily | null>(null);
  const [showModal, setShowModal] = useState(false);

  const list = async ({
    page = 1,
    limit = 10,
    title = '',
  }: RequestFamiliesListProps) => {
    handleSetIsLoading(true);

    let query = `?page=${page}&limit=${limit}`;

    if (title) {
      query = query.concat(`&title=${title}`);
    }

    try {
      const { data } = await api.get<PositionFamily[]>(
        `smartpay/positions/families${query}`,
      );
      setFamiliesList(data);
    } catch (err) {
      toast.error('Erro ao buscar dados!');
    }

    handleSetIsLoading(false);
  };

  const handleCreateUpdateFamily = async (data: PositionFamily) => {
    handleSetIsLoading(true);
    const dataParse = {
      title: data.title,
      description: data.description,
    };

    const url = 'smartpay/positions/families';

    try {
      if (currentFamilyEdit?.id) {
        await api.put(`${url}/${currentFamilyEdit.id}`, dataParse);

        toast.success('Alterado com sucesso!');
      } else {
        await api.post(url, dataParse);

        toast.success('Criado com sucesso!');
      }
      await list({});
      setShowModal(false);
    } catch (err: any) {
      console.log(err.message);
      toast.error('Erro ao efetuar operação!');
    }

    handleSetIsLoading(false);
  };

  useEffect(() => {
    const loadData = list;
    loadData({ title: titleSearch });
  }, []);

  return (
    <s.Container>
      {showModal && (
        <ModalFamily
          closeModal={() => setShowModal(false)}
          callback={(data: any) => handleCreateUpdateFamily(data)}
          data={currentFamilyEdit}
        />
      )}
      <s.Content>
        <h2 className="text-2xl font-bold">Famílias</h2>
        <div className="divider"></div>

        <s.MainContent>
          <s.ControlContainer>
            <input
              type="text"
              className="input input-bordered w-full max-w-xs m-2 mt-0"
              onChange={(e) => setTitleSearch(e.currentTarget.value)}
              value={titleSearch}
            />
            <ButtonPrimary
              onClick={(e) => {
                list({
                  title: titleSearch,
                });
              }}
            >
              <FiSearch />
              Buscar
            </ButtonPrimary>
            <ButtonPrimary
              onClick={(e) => {
                setCurrentFamilyEdit(null);
                setShowModal(true);
              }}
            >
              <FiPlus />
              Criar
            </ButtonPrimary>
          </s.ControlContainer>
          <div className="grid grid-cols-1 gap-4 mt-6">
            <div className="card bg-base-100 shadow-xl">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <td>Título</td>
                      <td>Descrição</td>
                      <td>Ações</td>
                    </tr>
                  </thead>
                  <tbody>
                    {familiesList && (
                      <>
                        {familiesList.map((family, index) => (
                          <tr key={index}>
                            <td>{family.title}</td>
                            <td>{family.description}</td>
                            <td>
                              <div
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <FiEdit
                                  onClick={() => {
                                    setCurrentFamilyEdit(family);
                                    setShowModal(true);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </s.MainContent>
      </s.Content>
    </s.Container>
  );
}
