import DashboardIconGraySvg from '../../../src/assets/svg/gray/dashboard-icon.svg';
import DashboardIconWhiteSvg from '../../../src/assets/svg/white/dashboard-icon.svg';
import DashboardIconPrimarySvg from '../../../src/assets/svg/primary/dashboard-icon.svg';
import FileTrayFullIconGraySvg from '../../../src/assets/svg/gray/file-tray-full.svg';
import FileTrayFullIconWhiteSvg from '../../../src/assets/svg/white/file-tray-full.svg';
import FileTrayFullIconPrimarySvg from '../../../src/assets/svg/primary/file-tray-full.svg';
import HomeIconGraySvg from '../../../src/assets/svg/gray/home.svg';
import HomeIconWhiteSvg from '../../../src/assets/svg/white/home.svg';
import HomeIconPrimarySvg from '../../../src/assets/svg/primary/home.svg';
import StrategyIconGraySvg from '../../../src/assets/svg/gray/strategy.svg';
import StrategyIconWhiteSvg from '../../../src/assets/svg/white/strategy.svg';
import StrategyIconPrimarySvg from '../../../src/assets/svg/primary/strategy.svg';
import StructureIconGraySvg from '../../../src/assets/svg/gray/structure.svg';
import StructureIconWhiteSvg from '../../../src/assets/svg/white/structure.svg';
import StructureIconPrimarySvg from '../../../src/assets/svg/primary/structure.svg';
import BellIconGraySvg from '../../../src/assets/svg/gray/bell.svg';
import MailIconGraySvg from '../../../src/assets/svg/gray/mail.svg';
import UserStartIconSvg from '../../../src/assets/svg/user-start.svg';
import DescriptionIconPrimarySvg from '../../../src/assets/svg/primary/description.svg';
import CalendarIconPrimarySvg from '../../../src/assets/svg/primary/calendar.svg';
import AnalyticsIconGraySvg from '../../../src/assets/svg/gray/analytics.svg';
import CashIconGraySvg from '../../../src/assets/svg/gray/cash.svg';
import SmartpayIconGraySvg from '../../../src/assets/svg/gray/smartpay.svg';
import UserCheckIconSvg from '../../../src/assets/svg/user-check.svg';
import CloudDownloadIconSvg from '../../../src/assets/svg/cloud-download.svg';
import CloudDownloadOutlinedIconSvg from '../../../src/assets/svg/cloud-download-outlined.svg';
import AvatarIconSvg from '../../../src/assets/svg/avatar.svg';
import CloudUploadIconGraySvg from '../../../src/assets/svg/gray/cloud-upload.svg';
import CloudUploadIconWhiteSvg from '../../../src/assets/svg/white/cloud-upload.svg';
import CloudUploadIconPrimarySvg from '../../../src/assets/svg/primary/cloud-upload.svg';
import TrophyIconGraySvg from '../../../src/assets/svg/gray/trophy.svg';
import TrophyIconWhiteSvg from '../../../src/assets/svg/white/trophy.svg';
import TrophyIconPrimarySvg from '../../../src/assets/svg/primary/trophy.svg';
import BarsIconSvg from '../../../src/assets/svg/bars.svg';
import SalaryScaleIconSvg from '../../../src/assets/svg/salary-scale.svg';
import BoyTodoListIconSvg from '../../../src/assets/svg/boy-todolist.svg';
import GirlTodoListIconSvg from '../../../src/assets/svg/girl-todolist.svg';
import Check01IconSvg from '../../../src/assets/svg/checks/check-01.svg';
import Check02IconSvg from '../../../src/assets/svg/checks/check-02.svg';
import Check03IconSvg from '../../../src/assets/svg/checks/check-03.svg';
import Check04IconSvg from '../../../src/assets/svg/checks/check-04.svg';
import Check05IconSvg from '../../../src/assets/svg/checks/check-05.svg';
import Check06IconSvg from '../../../src/assets/svg/checks/check-06.svg';
import SalaryTablesIconSvg from '../../../src/assets/svg/salary-tables.svg';
import SalaryTables2IconSvg from '../../../src/assets/svg/salary-tables2.svg';

export const IconsSvgSrc = {
  DashboardIconGraySvg,
  DashboardIconWhiteSvg,
  DashboardIconPrimarySvg,
  FileTrayFullIconGraySvg,
  FileTrayFullIconWhiteSvg,
  FileTrayFullIconPrimarySvg,
  HomeIconGraySvg,
  HomeIconWhiteSvg,
  HomeIconPrimarySvg,
  StrategyIconGraySvg,
  StrategyIconWhiteSvg,
  StrategyIconPrimarySvg,
  StructureIconGraySvg,
  StructureIconWhiteSvg,
  StructureIconPrimarySvg,
  BellIconGraySvg,
  MailIconGraySvg,
  UserStartIconSvg,
  DescriptionIconPrimarySvg,
  CalendarIconPrimarySvg,
  AnalyticsIconGraySvg,
  CashIconGraySvg,
  SmartpayIconGraySvg,
  UserCheckIconSvg,
  CloudDownloadIconSvg,
  AvatarIconSvg,
  CloudUploadIconGraySvg,
  CloudUploadIconWhiteSvg,
  CloudUploadIconPrimarySvg,
  TrophyIconGraySvg,
  TrophyIconWhiteSvg,
  TrophyIconPrimarySvg,
  CloudDownloadOutlinedIconSvg,
  BarsIconSvg,
  SalaryScaleIconSvg,
  BoyTodoListIconSvg,
  GirlTodoListIconSvg,
  Check01IconSvg,
  Check02IconSvg,
  Check03IconSvg,
  Check04IconSvg,
  Check05IconSvg,
  Check06IconSvg,
  SalaryTablesIconSvg,
  SalaryTables2IconSvg,
};
