export const removeMask = (value: string) => {
  return value.replace(/\D/g, '');
};

export function removeDoubleQuotes(s: string) {
  return s.replace(/^"(.*)"$/, '$1');
}

type DownloadFileFromServerParams = {
  contentType: string;
  filename: string;
  data: any;
};

export function downloadFileFromServer({
  contentType,
  data,
  filename,
}: DownloadFileFromServerParams) {
  const blob = new Blob([data], { type: contentType });
  const fileURL = URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = fileURL;
  link.setAttribute('download', removeDoubleQuotes(filename));

  document.body.appendChild(link);

  link.click();
  link.parentNode?.removeChild(link);
}
