import { ModalGeneric } from '../../../components/ModalGeneric';
import { ModalMarketSmoothing } from '../../../components/ModalMarketSmoothing';
import { MarketTypes } from '../../../shared/types';

interface PublishMarketModalProps {
  isModalOpenPublicar: boolean;
  setIsModalOpenPublicar: (v: boolean) => void;
  marketId: string;
  marketType: MarketTypes | null;
  afterPublish: () => void;
}

export function PublishMarketModal({
  isModalOpenPublicar,
  setIsModalOpenPublicar,
  marketId,
  marketType,
  afterPublish
}: PublishMarketModalProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalOpenPublicar}
      setIsModalOpen={setIsModalOpenPublicar}
    >
      <div
        className="grid grid-cols-1 gap-4"
        style={{ width: '1800px !important' }}
      >
        <div className="overflow-x-auto w-[1450px]">
          <h3 className="text-lg font-bold">Publicar</h3>
          <br />
          <div className="flex">
            <ModalMarketSmoothing
              closeModal={() => setIsModalOpenPublicar(false)}
              marketId={marketId}
              marketType={marketType as any}
              afterPublish={() => afterPublish()}
            />
          </div>
        </div>
      </div>
    </ModalGeneric>
  );
}
