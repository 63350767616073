import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { Container, Content, Form, ContainerButton, Logo } from './styles';
import logo from '../../assets/png/logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { AppError } from '../../error/AppError';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

interface SubmitForm {
  email: string;
}

const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);

  const { recoverPassword } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitForm>();

  const submit = async ({ email }) => {
    try {
      setLoading(true);
      await recoverPassword(email);
      toast.success(
        'Enviamos para seu e-mail as informações para recuperação da senha.',
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      AppError(error, 'Email não encontrado!');
    }
  };

  return (
    <Container>
      <Content>
        <Logo src={logo} alt="Logo SmartPay" />

        <Form onSubmit={handleSubmit(submit)}>
          <Input
            type="email"
            placeholder="E-mail"
            {...register('email', { required: true })}
            error={!!errors.email}
            helperText={!!errors.email ? 'Campo Obrigatório' : ''}
          />
          <ContainerButton>
            <Button type="submit">{loading ? 'Enviando...' : 'Enviar'}</Button>
          </ContainerButton>
          <div>
            <Link to="/">Voltar para login</Link>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
