import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`;

export const MainContent = styled.div`
  padding: 1rem;
`;

export const MainTitle = styled.p`
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--text-body);
  border-bottom: 1px solid var(--black-light);
`;

export const SectionFilter = styled.div`
  margin-top: 0.75rem;
  display: flex;
`;

export const SectionListUsers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  max-height: 580px;
  overflow-y: auto;
`;

export const HeaderListUsers = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--text-body-clean);

  p {
    text-align: center;
    max-width: 230px;
    width: 100%;
  }

  span {
    text-align: center;
    max-width: 80px;
    width: 100%;
  }

  div {
    margin-left: auto;
    width: 60px;
  }
`;

export const ItemUser = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--text-body-clean);
  padding: 0.5rem;

  & + & {
    margin-top: 1rem;
  }

  p {
    text-align: center;
    max-width: 230px;
    width: 100%;
  }

  div {
    margin-left: auto;
    width: 60px;
    display: flex;
    justify-content: space-between;

    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  span {
    background-color: var(--black-light);
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    margin: 0 0.125rem;
  }
`;

export const ArrowLeft = styled.button`
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;

  svg {
    font-size: 17px;
  }

  &:disabled {
    background-color: var(--background-modal);
  }
`;

export const ArrowRight = styled.button`
  background-color: var(--primary);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  svg {
    font-size: 17px;
  }

  &:disabled {
    background-color: var(--background-modal);
  }
`;

export const Select = styled(ReactSelect)`
  /* margin: 1rem 0; */
  max-width: 250px;
  width: 100%;
`;

export const NoUsers = styled.p`
  font-size: 1.5rem;
  text-align: center;
  color: var(--text-body);
  position: absolute;
  top: 50%;
  left: 50%;
`;
