import { FiAlertTriangle } from 'react-icons/fi';
import { ModalGeneric } from '../../../components/ModalGeneric';
import { useForm } from 'react-hook-form';
import {
  Container,
  ContainerButton,
  Content,
  DatabaseData,
  Form,
} from '../styles';
import { Input } from '../../../components/Input';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Button } from '../../../components/Button';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';
import { AppError } from '../../../error/AppError';
import { CreateMarketModalProps, SubmitCreateMarketForm } from './types';
import { useEffect, useState } from 'react';
import { SingleCheckbox } from '../../../components/checkboxs';
import { MarketStatistic } from '../../../interfaces';

const animatedComponents = makeAnimated();

export function CreateMarketModal({
  isModalOpen,
  setIsModalOpen,
  statusProcess,
  disableInput,
  marketData,
  monthData,
  setIsType,
  setVisibility,
  visibility,
  companies,
  selectedOptionsCompanies,
  selectCompanies,
  selectedOptionsNiveis,
  levels,
  selectNiveis,
  family,
  selectedOptionsFamilia,
  selectFamily,
  exclusaoInclusao,
  setExclusaoInclusao,
  position,
  selectedOptionsCargos,
  selectCargos,
  selectedOptions,
  handleChange,
  clearTodos,
  isType,
  setores,
  selectedOptionsSetores,
  handleChangeSetores,
  isCreate,
  clearTodosSetores,
  familyFilter,
  clearTodosNiveis,
  clearTodosCompanies,
  setSelectedOptionsSetores,
  setSelectedOptionsCompanies,
  setSelectedOptionsFamilia,
  setSelectedOptionsCargos,
  setSelectedOptions,
  setSelectedOptionsNiveis,
  listMarket,
  currentPage,
  setMarketData,
}: CreateMarketModalProps) {
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<SubmitCreateMarketForm>();
  const [
    normalizeMarketToClt,
    setNormalizeMarketToClt,
  ] = useState(false);

  useEffect(() => {
    handleSelect(marketData);
  }, [marketData]);

  const handleSelect = async (data: MarketStatistic | null) => {
    reset();
    setMarketData('');
    setSelectedOptionsFamilia([]);
    setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
    setMarketData(data);
    setNormalizeMarketToClt(data?.normalize_employees_contract || false);
    if (data?.filters?.position_levels) {
      const niveis: any = await data.filters.position_levels.map((nivel) => {
        return {
          label: nivel,
          value: nivel,
        };
      });
      setSelectedOptionsNiveis(niveis);
    }
    if (data?.filters?.families) {
      const familyList = family
        .filter((e) => data.filters.families.indexOf(e.label) !== -1)
        .map((pos) => {
          return pos;
        });
      selectFamily(familyList);
    }
    if (data?.filters?.companies) {
      const companiesList = companies
        .filter((e) => data.filters.companies.indexOf(e.value) !== -1)
        .map((pos) => {
          return pos;
        });
      handleChange(companiesList);
    }
    if (data?.filters?.positions_to_exclude) {
      const cargosExclusList = position
        .filter(
          (e) => data.filters.positions_to_exclude.indexOf(e.label) !== -1,
        )
        .map((pos) => {
          return pos;
        });
      selectCargos(cargosExclusList);
    }
    if (data?.filters?.positions_to_include) {
      const cargosExclusList = position
        .filter(
          (e) => data.filters.positions_to_include.indexOf(e.label) !== -1,
        )
        .map((pos) => {
          return pos;
        });
      selectCargos(cargosExclusList);
    }
    if (data?.filters?.sectors) {
      const sectorsList = setores
        .filter((e) => data.filters.sectors.indexOf(e.value) !== -1)
        .map((pos) => {
          return pos;
        });
      handleChangeSetores(sectorsList);
    }
  };

  const submit = async (data: SubmitCreateMarketForm) => {
    const {
      mercado,
      database,
      tipo,
      permissao,
      mesesRetroativos,
      metodologia,
      elegiveisComissao,
      cargaHoraria,
      incentivosCurto,
      incentivosLongo,
      genero,
      raca,
      contratacao,
      month,
    } = data;

    try {
      const dateG = new Date();
      const yearCurrent = new Date().getFullYear();
      const allfamily = selectedOptionsFamilia
        .filter((e) => e.label === 'Todas')
        .map((pos) => {
          return true;
        });
      const familia = familyFilter.split(',').map((pos) => {
        return pos;
      });

      const mercadoItem =
        disableInput || statusProcess !== 'not_processed'
          ? marketData?.name
          : mercado;
      const tipoItem =
        disableInput || statusProcess !== 'not_processed'
          ? marketData?.type
          : tipo;
      let cargos;
      if (typeof selectedOptionsCargos[0].label === 'undefined') {
        cargos = selectedOptionsCargos.map((pos) => {
          if (typeof pos.label !== 'undefined') {
            return pos.label;
          } else {
            return '';
          }
        });
      } else if (selectedOptionsCargos[0].label === 'N/A') {
        cargos = [];
      } else {
        cargos = selectedOptionsCargos.map((pos) => {
          return pos.label;
        });
      }

      let empresas;
      if (clearTodos) {
        empresas = [];
      } else {
        empresas = selectedOptions
          .filter((e) => typeof e != 'undefined')
          .map((pos) => {
            return pos.value;
          });
      }

      let setores;
      if (clearTodosSetores) {
        setores = [];
      } else {
        setores = selectedOptionsSetores
          .filter((e) => typeof e != 'undefined')
          .map((pos) => {
            return pos.value;
          });
      }

      let niveis;
      if (clearTodosNiveis) {
        niveis = [];
      } else {
        niveis = selectedOptionsNiveis
          .filter((e) => e.value !== 'Todos')
          .map((pos) => {
            return pos.value;
          });
      }

      niveis = niveis
        .filter((e) => e !== '')
        .map((pos) => {
          return pos;
        });

      let companiesProcess;
      if (clearTodosCompanies) {
        companiesProcess = [];
      } else {
        companiesProcess = selectedOptionsCompanies
          .filter((e) => e.value !== 'Todas')
          .map((pos) => {
            return pos.value;
          });
      }
      companiesProcess = companiesProcess
        .filter((e) => e !== '')
        .map((pos) => {
          return pos;
        });

      const dadosDate = database;
      const parseData = {
        name: mercadoItem,
        type: tipoItem,
        calculation_method: metodologia,
        visibility: permissao,
        retroactive_months: mesesRetroativos,
        companies_to_process: companiesProcess,
        normalize_employees_contract: normalizeMarketToClt,
        month_reference:
          monthData[parseInt(String(dadosDate).split('-')[1]) - 1].split(
            '|',
          )[0],
        year_reference: String(dadosDate).split('-')[0],
        filters: {
          position_levels: niveis,
          monthly_workload: cargaHoraria ? cargaHoraria : 0,
          families: allfamily.length === 0 ? familia : [],
          positions_to_exclude: exclusaoInclusao === 0 ? cargos : [],
          positions_to_include: exclusaoInclusao === 1 ? cargos : [],
          sales_commission_eligibility: elegiveisComissao
            ? elegiveisComissao
            : null,
          icp_eligibility: incentivosCurto ? incentivosCurto : null,
          ilp_eligibility: incentivosLongo ? incentivosLongo : null,
          gender: genero,
          ethnicity: raca,
          contract_type: contratacao,
          companies: empresas,
          sectors: setores,
        },
      };

      if (isCreate) {
        const retorno = await api.post('/markets/statistics', parseData);
        toast.success('Mercado criado com sucesso.');
        setIsModalOpen(false);
        reset();
        setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
        setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
        setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
        setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
        setSelectedOptions([{ label: 'Todas', value: '' }]);
        setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
        listMarket(currentPage);
      } else {
        const id = marketData?.id;
        const retorno = await api.put(`/markets/statistics/${id}`, parseData);
        toast.success('Mercado criado com sucesso.');
        setIsModalOpen(false);
        reset();
        setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
        setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
        setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
        setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
        setSelectedOptions([{ label: 'Todas', value: '' }]);
        setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
        listMarket(currentPage);
      }
    } catch (err: any) {
      AppError(err);
    }
  };

  return (
    <ModalGeneric
      isModalOpen={isModalOpen as boolean}
      setIsModalOpen={setIsModalOpen}
      full={true}
    >
      <div
        className="grid grid-cols-1 gap-4"
        style={{ width: '1800px !important' }}
      >
        <div className="pb-28 basis-4/5">
          {statusProcess !== 'not_processed' && (
            <div className="mt-5 mb-8 shadow-lg alert alert-warning">
              <div>
                <FiAlertTriangle />
                <span>
                  Atenção, todos os dados do mercado serão zerados e novos dados
                  serão gerados baseados nos parâmetros atualizados.
                </span>
              </div>
            </div>
          )}

          <div className="overflow-x-auto w-[1300px]">
            <div className="flex">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Título do mercado
                      <label className="text-error">*</label>
                    </strong>
                  </label>
                  <input
                    className={
                      !!errors.mercado
                        ? 'input input-bordered input-error w-full max-w-xs'
                        : 'input input-bordered w-full max-w-xs'
                    }
                    type="text"
                    placeholder="Mercado"
                    {...register('mercado', {
                      required: true,
                      disabled:
                        disableInput || statusProcess !== 'not_processed'
                          ? true
                          : false,
                    })}
                    defaultValue={marketData ? marketData.name : ''}
                    // error={!!errors.mercado}
                    // helperText={!!errors.mercado ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.mercado ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Data base <label className="text-error">*</label>
                    </strong>
                  </label>
                  <DatabaseData>
                    <Input
                      type="month"
                      min={2018}
                      placeholder="Ano"
                      disabled={disableInput}
                      defaultValue={
                        marketData
                          ? marketData.year_reference +
                            '-' +
                            monthData
                              .filter(
                                (e) =>
                                  e.split('|')[0] == marketData.month_reference,
                              )
                              .map((monthLis) => {
                                return monthLis.split('|')[1];
                              })
                          : ''
                      }
                      {...register('database', { required: true })}
                      error={!!errors.database}
                      // helperText={!!errors.database ? 'Campo Obrigatório' : ''}
                    />
                  </DatabaseData>
                  <Content>
                    {!!errors.database ? 'Campo Obrigatório' : ''}
                  </Content>
                </div>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Tipo
                      <label className="text-error">*</label>
                    </strong>
                  </label>
                  <select
                    {...register('tipo', {
                      required: true,
                      disabled:
                        disableInput || statusProcess !== 'not_processed'
                          ? true
                          : false,
                    })}
                    // disabled={
                    //   disableInput || statusProcess !== 'not_processed'
                    // }
                    onChange={(e: any) => {
                      setIsType(e.target.value);
                    }}
                    className={
                      !!errors.tipo
                        ? 'select select-error w-full max-w-xs'
                        : 'w-full max-w-xs select select-bordered'
                    }
                  >
                    <option selected></option>
                    <option
                      value="grade"
                      selected={
                        marketData ? 'grade' === marketData.type : false
                      }
                    >
                      SmrtGrade
                    </option>
                    <option
                      value="position"
                      selected={
                        marketData ? 'position' === marketData.type : false
                      }
                    >
                      SmrtCargo
                    </option>
                    <option
                      value="position-grade"
                      selected={
                        marketData
                          ? 'position-grade' === marketData.type
                          : false
                      }
                    >
                      SmrtCargo/Grade
                    </option>
                  </select>
                </div>
                <Content>{!!errors.tipo ? 'Campo Obrigatório' : ''}</Content>
              </div>
            </div>

            <div className="flex">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Permissão de visibilidade
                      <label className="text-error">*</label>
                    </strong>
                  </label>

                  <select
                    {...register('permissao', { required: true })}
                    className={
                      !!errors.permissao
                        ? 'select select-error w-full max-w-xs'
                        : 'w-full max-w-xs select select-bordered'
                    }
                    disabled={disableInput}
                    onChange={(e: any) => {
                      setVisibility(e.target.value);
                    }}
                  >
                    <option selected></option>
                    <option
                      value="internal"
                      selected={
                        marketData
                          ? 'internal' === marketData.visibility
                          : false
                      }
                    >
                      Uso interno
                    </option>
                    <option
                      value="community"
                      selected={
                        marketData
                          ? 'community' === marketData.visibility
                          : false
                      }
                    >
                      Comunidade
                    </option>
                    <option
                      value="sector"
                      selected={
                        marketData ? 'sector' === marketData.visibility : false
                      }
                    >
                      Setor
                    </option>
                  </select>
                </div>
                <Content>
                  {!!errors.permissao ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Meses retroativos </strong>
                  </label>

                  <select
                    {...register('mesesRetroativos', { required: true })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected></option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i: any) => (
                      <option key={i} selected={i === 6}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
                <Content>
                  {!!errors.mesesRetroativos ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Metodologia de cálculo
                      <label className="text-error">*</label>
                    </strong>
                  </label>

                  <select
                    {...register('metodologia', { required: true })}
                    disabled={disableInput}
                    className={
                      !!errors.permissao
                        ? 'select select-error w-full max-w-xs'
                        : 'w-full max-w-xs select select-bordered'
                    }
                  >
                    <option selected></option>
                    <option
                      value="percentile"
                      selected={
                        marketData
                          ? 'percentile' === marketData.calculation_method
                          : false
                      }
                    >
                      Percentis
                    </option>
                    <option
                      value="average"
                      selected={
                        marketData
                          ? 'average' === marketData.calculation_method
                          : false
                      }
                    >
                      Média
                    </option>
                  </select>
                </div>
                <Content>
                  {!!errors.metodologia ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
            </div>
            <div className="flex">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <div className="flex">
                    <div className="label">
                      <strong className="label-text">
                        Normalizar para CLT
                      </strong>
                    </div>
                    <SingleCheckbox
                      disabled={disableInput}
                      onChange={(v) => setNormalizeMarketToClt(v)}
                      value={normalizeMarketToClt}
                    />
                  </div>
                </div>
              </div>
            </div>
            {(visibility && visibility === 'community') ||
            (marketData &&
              marketData.visibility === 'community' &&
              visibility === '') ? (
              <div className="flex">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Empresas</strong>
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      isDisabled={disableInput}
                      components={animatedComponents}
                      isMulti
                      options={companies}
                      value={selectedOptionsCompanies}
                      onChange={selectCompanies}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 650,
                        }),
                      }}
                    />
                  </div>
                  <Content>
                    {selectedOptionsCompanies.length === 0
                      ? 'Campo Obrigatório'
                      : ''}
                  </Content>
                </div>
              </div>
            ) : null}

            <div className="divider">Filtros</div>

            <div className="flex">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Elegíveis a comissão</strong>
                  </label>

                  <select
                    {...register('elegiveisComissao', { required: false })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      value="not-eligibles"
                      selected={
                        marketData
                          ? 'not-eligibles' ===
                            marketData.filters.sales_commission_eligibility
                          : false
                      }
                    >
                      Não elegíveis
                    </option>
                    <option
                      value="eligibles"
                      selected={
                        marketData
                          ? 'eligibles' ===
                            marketData.filters.sales_commission_eligibility
                          : false
                      }
                    >
                      Elegíveis
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.elegiveisComissao ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Níveis</strong>
                  </label>

                  <div className="form-control">
                    <Select
                      closeMenuOnSelect={false}
                      isDisabled={disableInput}
                      components={animatedComponents}
                      isMulti
                      value={selectedOptionsNiveis}
                      options={levels}
                      onChange={selectNiveis}
                    />
                  </div>
                  {/* <Content>
                      {selectedOptionsNiveis.length === 0
                        ? 'Campo Obrigatório'
                        : ''}
                    </Content> */}
                </div>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Carga horária </strong>
                  </label>

                  <select
                    {...register('cargaHoraria', { required: false })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      selected={
                        marketData
                          ? '220' == marketData.filters.monthly_workload
                          : false
                      }
                    >
                      220
                    </option>
                    <option
                      selected={
                        marketData
                          ? '180' == marketData.filters.monthly_workload
                          : false
                      }
                    >
                      180
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.cargaHoraria ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
            </div>

            <div className="flex pt-3">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Família</strong>
                  </label>

                  <Select
                    closeMenuOnSelect={false}
                    isDisabled={disableInput}
                    components={animatedComponents}
                    isMulti
                    options={family}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 600,
                      }),
                    }}
                    value={selectedOptionsFamilia}
                    onChange={selectFamily}
                  />
                </div>
                {/* <Content>
                    {selectedOptionsFamilia.length === 0
                      ? 'Campo Obrigatório'
                      : ''}
                  </Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <div className="flex gap-2">
                    <label className="flex gap-2 label">
                      <input
                        type="radio"
                        name="exclusao_inclusao"
                        checked={exclusaoInclusao === 0}
                        onChange={() => setExclusaoInclusao(0)}
                      />
                      <strong className="label-text">Exclusão de cargos</strong>
                    </label>

                    <label className="flex gap-2 label">
                      <input
                        type="radio"
                        name="exclusao_inclusao"
                        checked={exclusaoInclusao === 1}
                        onChange={() => setExclusaoInclusao(1)}
                      />
                      <strong className="label-text">Inclusão de cargos</strong>
                    </label>
                  </div>

                  <div className="form-control">
                    <Select
                      closeMenuOnSelect={false}
                      isDisabled={disableInput}
                      components={animatedComponents}
                      isMulti
                      options={position}
                      value={selectedOptionsCargos}
                      onChange={selectCargos}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 530,
                        }),
                      }}
                    />
                  </div>
                  <Content>
                    {selectedOptionsCargos.length === 0
                      ? 'Campo Obrigatório'
                      : ''}
                  </Content>
                </div>
              </div>
            </div>

            <div className="flex pt-3">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Incentivos de curto prazo
                    </strong>
                  </label>

                  <select
                    {...register('incentivosCurto', { required: false })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      value="not-eligibles"
                      selected={
                        marketData
                          ? 'not-eligibles' ==
                            marketData.filters.icp_eligibility
                          : false
                      }
                    >
                      Não elegíveis
                    </option>
                    <option
                      value="eligibles"
                      selected={
                        marketData
                          ? 'eligibles' == marketData.filters.icp_eligibility
                          : false
                      }
                    >
                      Elegíveis
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.incentivosCurto ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">
                      Incentivos de longo prazo
                    </strong>
                  </label>

                  <select
                    {...register('incentivosLongo', { required: false })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      value="not-eligibles"
                      selected={
                        marketData
                          ? 'not-eligibles' ==
                            marketData.filters.ilp_eligibility
                          : false
                      }
                    >
                      Não elegíveis
                    </option>
                    <option
                      value="eligibles"
                      selected={
                        marketData
                          ? 'eligibles' == marketData.filters.ilp_eligibility
                          : false
                      }
                    >
                      Elegíveis
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.incentivosLongo ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Gênero</strong>
                  </label>

                  <select
                    {...register('genero', { required: false })}
                    disabled={disableInput}
                    className="w-[full] max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'masculino' == marketData.filters.gender
                          : false
                      }
                      value="masculino"
                    >
                      Masculino
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'feminino' == marketData.filters.gender
                          : false
                      }
                      value="feminino"
                    >
                      Feminino
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'outros' == marketData.filters.gender
                          : false
                      }
                      value="outros"
                    >
                      Outros
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.genero ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
            </div>

            <div className="flex pt-3">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Raça</strong>
                  </label>

                  <input
                    className="w-full max-w-xs input input-bordered"
                    type="text"
                    placeholder="Raça"
                    {...register('raca', {
                      required: false,
                      disabled: disableInput ? true : false,
                    })}
                    defaultValue={
                      marketData ? marketData.filters.ethnicity : ''
                    }
                  />
                </div>
                {/* <Content>{!!errors.raca ? 'Campo Obrigatório' : ''}</Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Contratação</strong>
                  </label>

                  <select
                    {...register('contratacao', { required: false })}
                    disabled={disableInput}
                    className="w-full max-w-xs select select-bordered"
                  >
                    <option selected value="">
                      Todos
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'CLT' == marketData.filters.contract_type
                          : false
                      }
                    >
                      CLT
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'PJ' == marketData.filters.contract_type
                          : false
                      }
                    >
                      PJ
                    </option>
                    <option
                      selected={
                        marketData
                          ? 'Outro' == marketData.filters.contract_type
                          : false
                      }
                    >
                      Outro
                    </option>
                  </select>
                </div>
                {/* <Content>
                    {!!errors.contratacao ? 'Campo Obrigatório' : ''}
                  </Content> */}
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Empresas</strong>
                  </label>

                  <Select
                    closeMenuOnSelect={false}
                    {...register('companies', {
                      required:
                        selectedOptions.length <= 5 && !clearTodos
                          ? true
                          : false,
                    })}
                    isDisabled={disableInput}
                    name="companies"
                    components={animatedComponents}
                    isMulti
                    options={companies}
                    value={selectedOptions}
                    onChange={handleChange}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor:
                          selectedOptions.length <= 5 &&
                          !clearTodos &&
                          isType === 'grade'
                            ? 'red'
                            : selectedOptions.length <= 3 &&
                              !clearTodos &&
                              (isType === 'position-grade' ||
                                isType === 'position')
                            ? 'red'
                            : '',
                      }),
                    }}
                  />
                </div>
                <Content>
                  {selectedOptions.length <= 5 &&
                  !clearTodos &&
                  isType === 'grade'
                    ? 'Campo Obrigatório com no minímo 6 empresas'
                    : ''}
                  {selectedOptions.length <= 3 &&
                  !clearTodos &&
                  (isType === 'position-grade' || isType === 'position')
                    ? 'Campo Obrigatório com no minímo 4 empresas'
                    : ''}
                </Content>
              </div>
            </div>

            <div className="flex">
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Setor</strong>
                  </label>

                  <Select
                    closeMenuOnSelect={false}
                    {...register('setores', {
                      required:
                        selectedOptions.length <= 5 && !clearTodos
                          ? true
                          : false,
                    })}
                    isDisabled={disableInput}
                    name="setores"
                    components={animatedComponents}
                    isMulti
                    options={setores}
                    value={selectedOptionsSetores}
                    onChange={handleChangeSetores}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 1185,
                        borderColor:
                          selectedOptions.length <= 5 &&
                          !clearTodos &&
                          isType === 'grade'
                            ? 'red'
                            : selectedOptions.length <= 3 &&
                              !clearTodos &&
                              (isType === 'position-grade' ||
                                isType === 'position')
                            ? 'red'
                            : '',
                      }),
                    }}
                  />
                </div>
                <Content>
                  {selectedOptions.length <= 5 &&
                  !clearTodos &&
                  isType === 'grade'
                    ? 'Campo Obrigatório com no minímo 6 empresas'
                    : ''}
                  {selectedOptions.length <= 3 &&
                  !clearTodos &&
                  (isType === 'position-grade' || isType === 'position')
                    ? 'Campo Obrigatório com no minímo 4 empresas'
                    : ''}
                </Content>
              </div>
            </div>

            <div className="flex">
              <div className="flex-auto w-64 pt-7">
                {/* <Button type="submit" onClick={saveConfiguration}>
                      Salvar parâmetros
                    </Button> */}
                <Container>
                  <Form onSubmit={handleSubmit(submit)}>
                    {!disableInput && (
                      <ContainerButton>
                        <Button type="submit">
                          {isCreate
                            ? 'Salvar Parâmetros'
                            : 'Atualizar Parâmetros'}
                        </Button>
                      </ContainerButton>
                    )}
                  </Form>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalGeneric>
  );
}
