import { shade, transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --primary: #F56A00;
    --secondary: #44546A;
    --primary-600: #F56A00;
    --secondary-600: #44546A;
    --text-title: #000;
    --white: #fff;
    --text-clear: rgb(221 221 221);
    --text-sub: #f4f4f4;
    --text-body: #666360;
    --text-body-clean: rgb(249, 249, 249);
    --form-clean: #f9f9f9;
    --text-primary: #44546A;
    --text-primary-clean: #667d9d;
    --error: #c53030;
    --primary-hover: ${shade(0.1, '#F56A00')};
    --primary-light: ${transparentize(0.4, '#F56A00')};
    --black-light: ${transparentize(0.9, '#000')};
    --background-modal: ${transparentize(0.4, '#000')};
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: "Roboto", serif !important;
    font-size: 16px;
    box-sizing: border-box;
    -webkit-smoothing: antialiased;
  }

  #root {
    height: 100vh;
  }

  html, body {
    -webkit-smoothing: antialiased;
  }

  h1,h2,h3,h4,h5,h6, strong {
    font-weight: 500;
  }

  a {
    text-decoration: none;
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  input, button {
    /* border: 0; */
  }
`;
