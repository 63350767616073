import { Stitches } from '../../../../src/styles/stitches';

export const MenuButtonWrapper = Stitches.styled('div', {
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  gap: '$1',
  position: 'relative',
});

export const MenuButtonItem = Stitches.styled('button', {
  minHeight: '$4',
  transition: 'width 0.3s ease-in-out',
  position: 'relative',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$2',
  whiteSpace: 'nowrap',

  borderTopLeftRadius: '30px',
  borderBottomLeftRadius: '30px',

  '& > img': {
    width: '$iconMedium',
    height: '$iconMedium',
    objectFit: 'center',
  },

  '& > span': {
    fontSize: '$small',
    transition: 'width 0.3s ease-in-out',
    fontWeight: '500',
  },

  '& > label': {
    fontSize: '$small',
    transition: 'width 0.3s ease-in-out',
    fontWeight: '500',
  },

  '& > span.disabled-true': {
    color: '$gray-600',
    cursor: 'not-allowed',
  },

  variants: {
    color: {
      selected: {
        backgroundColor: '$primary',
        width: 'calc(100% - 25px)',
        paddingLeft: '$3',

        '&::before': {
          content: '""',
          position: 'absolute',
          left: '-15px',
          width: 5,
          height: '100%',
          borderRadius: '10px',
          backgroundColor: '$primary',
          transition: 'width 0.3s ease-in-out',
        },

        '& > span': {
          fontWeight: '$bold',
          color: '$white',
        },
      },
      notSelected: {
        width: '100%',
        backgroundColor: 'transparent',
        paddingLeft: 0,

        '&:hover > span': {
          color: '$primary',
        },

        '&::before': {
          content: '""',
          width: 0,
          backgroundColor: 'transparent',
        },

        '& > span': {
          color: '$gray400',
        },
      },
    },
    state: {
      collapsed: {
        backgroundColor: 'transparent',
        paddingLeft: 0,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',

        '&::before': {
          content: '""',
          display: 'none',
        },
      },
    },
  },
});

export const MenuButtonSubButtonsWrapper = Stitches.styled('nav', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '$1',
  padding: '0 $6',

  variants: {
    state: {
      collapsed: {
        position: 'absolute',
        top: 10,
        left: 100,
        width: 'fit-content',
        height: 'fit-content',
        padding: '$2',
        backgroundColor: '$white',
        zIndex: '$6',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

        borderRadius: '$default',

        '& > button': {
          whiteSpace: 'nowrap',
        },

        '& > button.close-subbuttons': {
          position: 'absolute',
          top: -7,
          right: -7,

          backgroundColor: '$gray200',
          width: '$iconLarge',
          height: '$iconLarge',
          borderRadius: '50%',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& > svg': {
            width: '$iconMedium',
            height: '$iconMedium',
            color: '$white',
          },
        },
      },
      inactive: {
        display: 'none',
      },
    },
  },
});

export const MenuButtonSubButton = Stitches.styled('button', {
  width: '100%',
  height: '$1',
  backgroundColor: 'transparent',
  fontSize: '$small',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$1',

  '&:before': {
    content: '""',
    width: 5,
    height: 5,
    borderRadius: '50%',
    display: 'inline-block',
  },

  variants: {
    state: {
      selected: {
        color: '$primary',
        fontWeight: '$medium',

        '&:before': {
          backgroundColor: '$primary',
        },
      },
      notSelected: {
        color: '$gray400',
        fontWeight: '$body',

        '&:hover': {
          color: '$primary',
          '&:before': {
            backgroundColor: '$primary',
          },
        },

        '&:before': {
          backgroundColor: '$gray400',
        },
      },

      disabled: {
        color: '$gray200',
        cursor: 'not-allowed',

        '&:before': {
          backgroundColor: '$gray200',
        },
      },
    },
  },
});
