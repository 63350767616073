import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FiX } from 'react-icons/fi';

import { Button } from '../../components/Button';
import { UpdatePositionDescription } from '../../interfaces';
import { PositionDescriptions } from '../../pages/PositionDescriptions';
import { Input } from '../Input';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  FormContent,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: (data: UpdatePositionDescription) => void;
  currentData: PositionDescriptions;
}

interface SubmitForm {
  broad_position: string;
  cbo: string;
  cbo_description: string;
  typical_formation: string;
  obs: string;
  main_activities: string;
}

const ModalEditPositionDescription = ({
  closeModal,
  callback,
  currentData,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();
  const [formData, setFormData] = useState({} as SubmitForm);

  const submit = (data: SubmitForm) => {
    const { broad_position, cbo, cbo_description, typical_formation, obs, main_activities } =
      data;

    callback({
      id: currentData.id,
      broad_position,
      cbo,
      cbo_description,
      typical_formation,
      obs,
      main_activities,
    });
    closeModal();
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>Descrições de cargos</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>
        <ContentModal>
          <Form onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div>
                <span>Cargo amplo</span>
                <Input
                  type="text"
                  placeholder="cargo amplo..."
                  {...register('broad_position', {
                    required: false,
                    value: currentData?.broad_position || '',
                  })}
                />
              </div>
              <div>
                <span>Cbo</span>
                <Input
                  type="text"
                  placeholder="cbo..."
                  {...register('cbo', {
                    required: false,
                    value: currentData?.cbo || '',
                  })}
                />
              </div>
              <div>
                <span>Descrição cbo</span>
                <Input
                  type="text"
                  placeholder="descrição cbo..."
                  {...register('cbo_description', {
                    required: false,
                    value: currentData?.cbo_description || '',
                  })}
                />
              </div>
              <div>
                <span>Formação típica</span>
                <Input
                  type="text"
                  placeholder="formação típica..."
                  {...register('typical_formation', {
                    required: false,
                    value: currentData?.typical_formation || '',
                  })}
                  />
              </div>
              <div>
                <span>Obs</span>
                <Input
                  type="text"
                  placeholder="observação..."
                  {...register('obs', {
                    required: false,
                    value: currentData?.obs || '',
                  })}
                />
              </div>
              <div>
                <span>Principais Atividades</span>
                <Input
                  type="text"
                  placeholder="principais atividades..."
                  {...register('main_activities', {
                    required: false,
                    value: currentData?.main_activities || '',
                  })}
                />
              </div>
            </FormContent>
            <ContainerButton>
              <Button type="submit">Alterar</Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalEditPositionDescription };
