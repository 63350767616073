export const DASHBOARD_CONSTANTS = {
  dataSubmenuMarket: [
    {
      label: 'Mercados',
      to: '/market-configuration',
      disabled: false,
    },
    {
      label: 'QA',
      to: '/market-intermiate-data',
      disabled: false,
    },
    {
      label: 'Mercados Publicados',
      to: '/market-result-data',
      disabled: false,
    },
    {
      label: 'Suavização',
      to: '/market-smoothing',
      disabled: false,
    },
    // {
    //   label: 'Mercados Publicados',
    //   to: '/market-published',
    //   disabled: false,
    // },
  ],
  jobStructureMenuRoutes: [
    {
      label: 'Criação de matriz',
      to: '/job-structure/matrix-creation',
      disabled: false,
    },
    {
      label: 'Visualizar matriz',
      to: '/job-structure/matrix-view',
      disabled: false,
    },
    {
      label: 'De-para cargo',
      to: '/job-structure/load-from-to',
      disabled: false,
    },
    {
      label: 'Descrição de cargo',
      to: '/job-structure/job-description',
      disabled: false,
    },
  ],
  compensationStrategyMenuRoutes: [
    {
      label: 'Tabelas salariais',
      to: '/compensation-strategy/salary-tables',
      disabled: false,
    },
    {
      label: 'Custo de enquadramento',
      to: '/framing-cost',
      disabled: false,
    },
    {
      label: 'Dados de mercado',
      to: '/market-data',
      disabled: false,
    },
    {
      label: 'Análise de competitividade',
      to: '/competitiveness-analysis',
      disabled: false,
    },
    {
      label: 'Alvos de incentivo',
      to: '/incentive-targets',
      disabled: false,
    },
    // {
    //   label: 'RBZ',
    //   to: '/compensation-strategy/rbz',
    //   disabled: true,
    // },
    // {
    //   label: 'Smart salary',
    //   to: '/compensation-strategy/smart-salary',
    //   disabled: true,
    // },
  ],
  payDashboardMenuRoutes: [
    {
      label: 'Funcionários',
      to: '/dashboard/employees',
      disabled: true,
    },
    {
      label: 'Empresa',
      to: '/dashboard/company',
      disabled: true,
    },
  ],
  dataMenuSector: [
    {
      label: 'Macro Setores',
      to: '/position-sector-group',
      disabled: false,
    },
    {
      label: 'Setores',
      to: '/position-sector',
      disabled: false,
    },
  ],
  dataSubmenuManagerAccess: [
    {
      label: 'Perfis',
      to: '/groups',
      disabled: false,
    },
    {
      label: 'Planos',
      to: '/plans',
      disabled: false,
    },
  ],
};