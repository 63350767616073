import { toast } from 'react-toastify';
import { HttpStatus } from '../enums/http-status';

export const AppError = (error: any, message?: string) => {
  const codes = [
    HttpStatus.BAD_REQUEST.valueOf(),
    HttpStatus.UNPROCESSABLE_ENTITY.valueOf(),
  ];

  console.log('error', error);

  if (codes.includes(error?.response?.status)) {
    toast.error(error.response.data.message);
    return;
  }

  toast.error(`Tivemos problema em nosso servidor. Tente novamente!`);
};
