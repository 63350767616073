import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Header, Select, NoQuestions } from './styles';
import { formatBrMoney, formatBrMoneyAndNull } from '../../shared/utils';
import { OptionSelect } from '../../interfaces';
import { useParams } from 'react-router-dom';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { api } from '../../services/api';
import { AppError } from '../../error/AppError';
import { ButtonDownload, ButtonPrimary } from '../DashboardUsers/styles';
import { MarketAnalyses } from '../../components/MarketAnalyses';
import { MarketAnalysesMeG } from '../../components/MarketAnalysesMeG';
import './styles.css';
import { downloadFileFromServer } from '../../utils/removeMask';
import ReactLoading from 'react-loading';
import { SiMicrosoftexcel } from 'react-icons/si';

type MarketListProps = {
  id: string | null;
  name: string | null;
  type: string | null;
  label: string | null;
  value: string | null;
  status?: string | null;
};

export default function MarketSmoothing() {
  const [isOpen0, setIsOpen0] = useState<any>(false);
  const [isOpen1, setIsOpen1] = useState<any>(false);
  const [isOpen2, setIsOpen2] = useState<any>(false);
  const [isOpen3, setIsOpen3] = useState<any>(false);
  const [marketData, setMarketData] = useState<any>([]);
  const [firstLoadData, setFirstLoadData] = useState<any>(false);
  const [isCheckManipulated, setIsCheckManipulated] = useState<any>([]);
  const [marketFullData, setMarketFullData] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [markets, setMarkets] = useState<MarketListProps[]>([]);
  const [idFilter, setIdFilter] = useState<string>('');
  const [marketStatus, setMarketStatus] = useState<string>('');
  const [selectedIntermedOption, setSelectedIntermedOption] =
    useState<OptionSelect>();
  const [selectedIntermedOptionLine, setSelectedIntermedOptionLine] =
    useState<any>({
      id: 'sb',
      label: 'SB',
    });
  const { handleSetIsLoading, isLoading } = useGlobalLoading();
  const [hasExcel, setHasExcel] = useState<boolean>(false);
  const [gradesChecked, setGradesChecked] = useState<any>([]);
  const [updateSlope, setUpdateSlope] = useState<any>(false);
  const [breakPointChecked, setBreakPointChecked] = useState<any>([]);
  const [gradesCheckedOnStart, setGradesCheckedOnStart] = useState<any>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [hasNegativeSlope, setHasNegativeSlope] = useState<boolean>(false);
  const checksGrades: any = useRef([]);
  const btnAutoBreak: any = useRef(null);

  const { id } = useParams();

  const linhas = [
    {
      id: 'sb',
      value: 'sb',
      label: 'SB',
    },
    {
      id: 'tda',
      value: 'tda',
      label: 'TDA',
    },
    {
      id: 'td',
      value: 'td',
      label: 'TD',
    },
    {
      id: 'icp',
      value: 'icp',
      label: 'ICP',
    },
    {
      id: 'ilp',
      value: 'ilp',
      label: 'ILP',
    },
    {
      id: 'rd',
      value: 'rd',
      label: 'RD',
    },
    {
      id: 'rda',
      value: 'rda',
      label: 'RDA',
    },
  ];

  async function listLinesMarkets() {
    try {
      if (selectedIntermedOption || id) {
        const marketId = selectedIntermedOption
          ? selectedIntermedOption.value
          : id;
        const { data } = await api.get(
          `/markets/statistics/${marketId}/breakpoints`,
        );
        setBreakPointChecked(data.lines);
      }
    } catch (error) {
      AppError(error, `Problemas ao carregar a lista!`);
    }
  }

  function isCheckedBreakpoint(line, grade) {
    const data = breakPointChecked.filter(
      (item) => item.line.toUpperCase() === line.toUpperCase(),
    );
    if (data.length) {
      if (data[0].breakpoints && data[0].breakpoints.length) {
        const existGrade = data[0].breakpoints.filter(
          (item) => Number(item.grade) === Number(grade),
        );
        if (existGrade.length) return true;
      }
    }
    return false;
  }

  async function listMarkets() {
    try {
      handleSetIsLoading(true);
      const { data } = await api.get('/markets/statistics/titles');
      const intermediaris = data
        .filter((item: any) => item.type === 'grade')
        .map((intermediari) => ({
          label: intermediari.name,
          value: intermediari.id,
          status: intermediari.status,
        }));
      setMarkets(intermediaris);
      handleSetIsLoading(false);
      if (id) {
        const marketItem = intermediaris.filter((item) => item.value === id);
        if (marketItem.length) {
          setMarketStatus(marketItem[0].status);
        }

        setIdFilter(id);
        setSelectedIntermedOption({ label: id, value: id });
      }
    } catch (error) {
      AppError(error, `Problemas ao carregar a lista!`);
    }
  }

  async function listMarketData() {
    try {
      if (selectedIntermedOption) {
        const { data } = await api.get(`/markets/statistics/items/`, {
          params: {
            market_statistic_id: selectedIntermedOption
              ? selectedIntermedOption.value
              : id,
            statistic_name: selectedIntermedOptionLine.id,
            limit: 300,
          },
        });

        if (data && data.data.length > 0) {
          let verifyCheck = false;
          const gradesCheckedStart: any = [];

          const marketListData = data.data.map((item: any, index: number) => {
            if (item.is_break_point) {
              verifyCheck = true;
            }
            if (
              (Number(item.grade) === 7 ||
                Number(item.grade) === 13 ||
                Number(item.grade) === 19) &&
              !verifyCheck &&
              !data.extra.has_p50_interpolated_personalized_empty_values
            ) {
              item.checked = true;
              item.is_break_point = true;
            } else {
              item.checked = item.is_break_point === true ? true : false;
            }

            if (item.checked) {
              gradesCheckedStart.push({
                grade: Number(item.grade),
                p50: item.p50_interpolated_personalized
                  ? item.p50_interpolated_personalized
                  : Number(item.p50),
              });
            }

            //-------remover estebloco---------
            // item.checked = false;
            // item.is_break_point = false;
            //-------remover estebloco---------

            if (data.data[index + 1]) {
              let slopeUpdated = '';

              if (item.p50 !== 0 && item.p50 !== '0' && item.p50 !== null) {
                if (isFinite((item.p50 / data.data[index + 1].p50 - 1) * 100)) {
                  slopeUpdated = (
                    (item.p50 / data.data[index + 1].p50 - 1) *
                    100
                  )?.toFixed(2);
                }
              }

              item.slopeP50 = slopeUpdated;
            }

            item.slope = '';
            return item;
          });

          setMarketData(marketListData);
          setIdFilter(selectedIntermedOption.value);
          setFirstLoadData(true);
          listLinesMarkets();

          setTimeout(() => {
            setGradesCheckedOnStart(gradesCheckedStart);
          }, 1000);
        }
      }
      handleSetIsLoading(false);
    } catch (error) {
      AppError(error, `Problemas ao carregar mercado!`);
    }
  }

  async function listMarketFullData() {
    try {
      if (selectedIntermedOption) {
        const market_statistic_id = selectedIntermedOption
          ? selectedIntermedOption.value
          : id;
        const { data } = await api.get(
          `/markets/statistics/${market_statistic_id}/items/reports`,
          {
            params: {
              market_statistic_id,
              limit: 1000,
            },
          },
        );

        if (data && data.statistics) {
          setMarketFullData(data.statistics);
        }
      }
    } catch (error) {
      AppError(error, `Problemas ao carregar dados do mercado!`);
    }
  }

  function emptySmoothedValues() {
    marketData.map((item: any) => {
      item.smoothed = 0;
      item.smoothed_p10 = 0;
      item.smoothed_p25 = 0;
      item.smoothed_p50 = 0;
      item.smoothed_p75 = 0;
      item.smoothed_p90 = 0;
      item.slope = '';
      if (item.p50 === 0 || item.p50 === '' || item.p50 === null) {
        item.checked = false;
      }
      return item;
    });

    setUpdateSlope(!updateSlope);
  }

  function putCheckedMarket(checked, grade) {
    marketData.map((item: any) => {
      if (Number(item.grade) === Number(grade)) {
        item.checked = checked;
      }
      return item;
    });

    calculateDataMarket(grade, checked, true);
  }

  function findNumberIntervals(arr) {
    const intervals: any = [];
    let start: any = arr[0];
    let end: any = arr[0];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] == end + 1) {
        end = arr[i];
      } else {
        intervals.push(start == end ? start : start);
        if (start !== end) {
          intervals.push(end);
        }
        start = arr[i];
        end = arr[i];
      }
    }

    intervals.push(start == end ? start : start);
    if (start !== end) {
      intervals.push(end);
    }

    return intervals;
  }

  function clearDataByGradeUp(grade) {
    let gradeStoped = 0;
    for (let i = 0; i < marketData.length; i++) {
      if (marketData[i].grade <= grade) {
        if (marketData[i].checked) {
          gradeStoped = marketData[i].grade;
          break;
        }
      }
    }

    if (gradeStoped !== 0 && gradeStoped !== null) {
      const newMarketData = marketData.map((item) => {
        if (Number(item.grade) > Number(gradeStoped)) {
          item.smoothed = '';
          item.smoothed_p10 = '';
          item.smoothed_p25 = '';
          item.smoothed_p50 = '';
          item.smoothed_p75 = '';
          item.smoothed_p90 = '';
          item.slope = '';
        }
        return item;
      });
      setMarketData(newMarketData);
    }
  }

  function calculateDataMarketByZero(itemsChecked, checked, grade) {
    const allItemsChecked = marketData
      .filter((item: any) => item.checked)
      .map((item) => Number(item.grade));
    const maxValueUp = Math.max(...allItemsChecked);
    const minValueUp = Math.min(...allItemsChecked);

    const valuesGrade = itemsChecked.map((item) => Number(item.grade));
    const minValue = Math.min(...valuesGrade);
    const maxValue = Math.max(...valuesGrade);

    if (grade > maxValue) {
      const gradeUp = itemsChecked.filter(
        (item) => Number(item.grade) === maxValue,
      );
      const gradeDown = itemsChecked.filter(
        (item) => Number(item.grade) === minValue,
      );

      if (grade <= maxValueUp) {
        let cont = 1;
        let calculateSmoothedIfIsZero = 0;
        const newItemsCalculate: any = [];
        for (let i = maxValue + 1; i <= grade; i++) {
          let calculateSmoothed = 0;
          const valueGradeUpOrNew =
            cont === 1 ? Number(gradeUp[0].p50) : calculateSmoothedIfIsZero;

          calculateSmoothed =
            valueGradeUpOrNew +
            (valueGradeUpOrNew / 100) * Number(gradeUp[0].slope);

          calculateSmoothedIfIsZero = calculateSmoothed;

          newItemsCalculate.push({ grade: i, smoothed: calculateSmoothed });
          cont++;
        }

        marketData.map((item) => {
          const verifyValuesSmooothed = newItemsCalculate.filter(
            (i) => Number(i.grade) === Number(item.grade),
          );
          if (verifyValuesSmooothed.length) {
            if (Number(item.grade) === grade) {
              item.checked = true;
            }
            item.smoothed = Number(verifyValuesSmooothed[0].smoothed);
            item.smoothed_p10 = Number(verifyValuesSmooothed[0].smoothed_p10);
            item.smoothed_p25 = Number(verifyValuesSmooothed[0].smoothed_p25);
            item.smoothed_p50 = Number(verifyValuesSmooothed[0].smoothed_p50);
            item.smoothed_p75 = Number(verifyValuesSmooothed[0].smoothed_p75);
            item.smoothed_p90 = Number(verifyValuesSmooothed[0].smoothed_p90);
            item.slope = Number(gradeUp[0].slope);
          }
          return item;
        });
        setUpdateSlope(!updateSlope);
      }
    } else {
      const gradeUp = itemsChecked.filter(
        (item) => Number(item.grade) === maxValue,
      );
      const gradeDown = itemsChecked.filter(
        (item) => Number(item.grade) === minValue,
      );

      let cont = 1;
      let calculateSmoothedIfIsZero = 0;
      const newItemsCalculate: any = [];
      for (let i = minValue; i >= grade; i--) {
        let calculateSmoothed = 0;

        const valueGradeUpOrNew =
          cont === 1 ? Number(gradeDown[0].p50) : calculateSmoothedIfIsZero;

        calculateSmoothed =
          valueGradeUpOrNew -
          (valueGradeUpOrNew / 100) * Number(gradeUp[0].slope);

        calculateSmoothedIfIsZero = calculateSmoothed;

        newItemsCalculate.push({ grade: i, smoothed: calculateSmoothed });
        cont++;
      }

      marketData.map((item) => {
        const verifyValuesSmooothed = newItemsCalculate.filter(
          (i) => Number(i.grade) === Number(item.grade),
        );
        if (verifyValuesSmooothed.length) {
          if (Number(item.grade) === grade) {
            item.checked = true;
          }
          item.smoothed = Number(verifyValuesSmooothed[0].smoothed);
          item.smoothed_p10 = Number(verifyValuesSmooothed[0].smoothed_p10);
          item.smoothed_p25 = Number(verifyValuesSmooothed[0].smoothed_p25);
          item.smoothed_p50 = Number(verifyValuesSmooothed[0].smoothed_p50);
          item.smoothed_p75 = Number(verifyValuesSmooothed[0].smoothed_p75);
          item.smoothed_p90 = Number(verifyValuesSmooothed[0].smoothed_p90);
          item.slope = Number(gradeUp[0].slope);
        }
        return item;
      });
      setUpdateSlope(!updateSlope);
    }

    if (!checked && Number(grade) > Number(maxValueUp)) {
      clearDataByGradeUp(grade);
    }

    if (!checked && Number(grade) < Number(minValueUp)) {
      const newMarketData = marketData.map((item) => {
        if (Number(item.grade) < Number(minValueUp)) {
          item.smoothed = '';
          item.smoothed_p10 = '';
          item.smoothed_p25 = '';
          item.smoothed_p50 = '';
          item.smoothed_p75 = '';
          item.smoothed_p90 = '';
          item.slope = '';
        }
        if (Number(item.grade) === Number(minValueUp)) {
          item.slope = '';
        }
        return item;
      });
      setMarketData(newMarketData);
    }

  }

  function calculateDataMarket(grade, checked, clicked = false) {
    const itemsChecked = marketData.filter((item: any) => item.checked && item.p50 !== 0 && item.p50 !== null);
    const itemsCheckedWithZero = marketData.filter((item: any) => Number(item.grade) > Number(grade) && item.checked && item.p50 === 0 && item.p50 === null);
    const verifyIsZero = marketData.filter((item: any) => Number(item.grade) === Number(grade));
    const allItemsChecked = marketData.filter((item: any) => item.checked).map((item) => Number(item.grade));
    const minValueUp = Math.min(...allItemsChecked);
    const maxValueUp = Math.max(...allItemsChecked);

    // calcula valores que não estão zerados
    if (itemsChecked.length > 1) {
      if (clicked === true) {
        emptySmoothedValues();
      }

      let updatedGrades: any = [];
      if (
        verifyIsZero.length &&
        verifyIsZero[0].p50 !== 0 &&
        verifyIsZero[0].p50 !== '' &&
        verifyIsZero[0].p50 !== null
      ) {
        const gradesChecked = itemsChecked.map((item: any) =>
          Number(item.grade),
        );
        gradesChecked.sort((a, b) => a - b);

        const intervals = findNumberIntervals(gradesChecked);

        let dataGradesUpdated: any = [];
        for (let i = 0; i < intervals.length; i++) {
          if (intervals[i + 1]) {
            const minValue = intervals[i];
            const maxValue = intervals[i + 1];

            const minGrade = marketData.filter(
              (item: any) => Number(item.grade) === minValue,
            );
            const maxGrade = marketData.filter(
              (item: any) => Number(item.grade) === maxValue,
            );

            const gradeHasSmoothed = marketData.filter(
              (item: any) =>
                Number(item.grade) >= Number(minValue) &&
                Number(item.grade) <= Number(maxValue),
            );

            let indexPosition = gradeHasSmoothed.length - 2;
            const updatedData = gradeHasSmoothed.map(
              (item: any, index: number) => {
                if (item.checked) {
                  item.smoothed = item.p50?.toFixed(2);
                  item.smoothed_p10 = item.p10?.toFixed(2);
                  item.smoothed_p25 = item.p25?.toFixed(2);
                  item.smoothed_p50 = item.p50?.toFixed(2);
                  item.smoothed_p75 = item.p75?.toFixed(2);
                  item.smoothed_p90 = item.p90?.toFixed(2);
                } else {

                  //se valor do check selecionado não tiver valor deve pegar valor da linha anterior que tenha valor
                  let maxGrade_p10 = maxGrade[0].p10;
                  if (!maxGrade_p10) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) < maxValue && item.p10,
                    );
                    if (gradeValue.length && gradeValue[0].grade > item.grade) {
                      maxGrade_p10 = gradeValue[0].p10;
                    }
                  }
                  let minGrade_p10 = minGrade[0].p10;
                  if (!minGrade_p10) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) > minValue && item.p10,
                    );
                    if (gradeValue.length) {
                      minGrade_p10 = gradeValue[gradeValue.length - 1].p10;
                    }
                  }

                  let maxGrade_p25 = maxGrade[0].p25;
                  if (!maxGrade_p25) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) < maxValue && item.p25,
                    );
                    if (gradeValue.length && gradeValue[0].grade > item.grade) {
                      maxGrade_p25 = gradeValue[0].p25;
                    }
                  }
                  let minGrade_p25 = minGrade[0].p25;
                  if (!minGrade_p25) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) > minValue && item.p25,
                    );
                    if (gradeValue.length) {
                      minGrade_p25 = gradeValue[gradeValue.length - 1].p25;
                    }
                  }

                  let maxGrade_p50 = maxGrade[0].p50;
                  if (!maxGrade_p50) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) < maxValue && item.p50,
                    );
                    if (gradeValue.length && gradeValue[0].grade > item.grade) {
                      maxGrade_p50 = gradeValue[0].p50;
                    }
                  }
                  let minGrade_p50 = minGrade[0].p50;
                  if (!minGrade_p50) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) > minValue && item.p50,
                    );
                    if (gradeValue.length) {
                      minGrade_p50 = gradeValue[gradeValue.length - 1].p50;
                    }
                  }

                  let maxGrade_p75 = maxGrade[0].p75;
                  if (!maxGrade_p75) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) < maxValue && item.p75,
                    );
                    if (gradeValue.length && gradeValue[0].grade > item.grade) {
                      maxGrade_p75 = gradeValue[0].p75;
                    }
                  }
                  let minGrade_p75 = minGrade[0].p75;
                  if (!minGrade_p75) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) > minValue && item.p75,
                    );
                    if (gradeValue.length) {
                      minGrade_p75 = gradeValue[gradeValue.length - 1].p75;
                    }
                  }

                  let maxGrade_p90 = maxGrade[0].p90;
                  if (!maxGrade_p90) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) < maxValue && item.p90,
                    );
                    if (gradeValue.length && gradeValue[0].grade > item.grade) {
                      maxGrade_p90 = gradeValue[0].p90;
                    }
                  }
                  let minGrade_p90 = minGrade[0].p90;
                  if (!minGrade_p90) {
                    const gradeValue = marketData.filter(
                      (item: any) => Number(item.grade) > minValue && item.p90,
                    );
                    if (gradeValue.length) {
                      minGrade_p90 = gradeValue[gradeValue.length - 1].p90;
                    }
                  }

                  const calcSmoothed =
                    minGrade[0].p50 *
                    (maxGrade[0].p50 / minGrade[0].p50) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  const calcSmoothed_p10 =
                    minGrade_p10 *
                    (maxGrade_p10 / minGrade_p10) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  const calcSmoothed_p25 =
                    minGrade_p25 *
                    (maxGrade_p25 / minGrade_p25) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  const calcSmoothed_p50 =
                    minGrade_p50 *
                    (maxGrade_p50 / minGrade_p50) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  const calcSmoothed_p75 =
                    minGrade_p75 *
                    (maxGrade_p75 / minGrade_p75) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  const calcSmoothed_p90 =
                    minGrade_p90 *
                    // (maxGrade_p90 / minGrade[0].p90) **
                    (maxGrade_p90 / minGrade_p90) **
                    (indexPosition / (gradeHasSmoothed.length - 1));

                  item.smoothed = calcSmoothed?.toFixed(2);
                  item.smoothed_p10 = calcSmoothed_p10?.toFixed(2);
                  item.smoothed_p25 = calcSmoothed_p25?.toFixed(2);
                  item.smoothed_p50 = calcSmoothed_p50?.toFixed(2);
                  item.smoothed_p75 = calcSmoothed_p75?.toFixed(2);
                  item.smoothed_p90 = calcSmoothed_p90?.toFixed(2);
                  indexPosition--;
                }
                return item;
              },
            );

            dataGradesUpdated = [...dataGradesUpdated, ...updatedData];
          }
        }

        updatedGrades = marketData.map((item) => {
          const verifyValuesSmooothed = dataGradesUpdated.filter(
            (i) => Number(i.grade) === Number(item.grade),
          );
          if (verifyValuesSmooothed.length) {
            item.checked = verifyValuesSmooothed[0].checked;
            item.smoothed = Number(verifyValuesSmooothed[0].smoothed);
            item.smoothed_p10 = Number(verifyValuesSmooothed[0].smoothed_p10);
            item.smoothed_p25 = Number(verifyValuesSmooothed[0].smoothed_p25);
            item.smoothed_p50 = Number(verifyValuesSmooothed[0].smoothed_p50);
            item.smoothed_p75 = Number(verifyValuesSmooothed[0].smoothed_p75);
            item.smoothed_p90 = Number(verifyValuesSmooothed[0].smoothed_p90);
          }
          return item;
        });
        setUpdateSlope(!updateSlope);

        const updatedDataSlope: any = [];
        for (let i = 0; i < updatedGrades.length; i++) {
          if (i + 1 < updatedGrades.length) {
            if (updatedGrades[i + 1].smoothed) {
              const slopeCalc = updatedGrades[i + 1].smoothed
                ? (
                  (updatedGrades[i].smoothed / updatedGrades[i + 1].smoothed -
                    1) *
                  100
                )?.toFixed(2)
                : '';
              if (slopeCalc !== '') {
                updatedDataSlope.push({
                  grade: updatedGrades[i].grade,
                  slope: Number(slopeCalc),
                });
              }
            }
          }
        }
        marketData.map((data) => {
          const grade = updatedDataSlope.filter(
            (slopeData) => Number(slopeData.grade) === Number(data.grade),
          );
          if (grade.length && grade[0].slope !== 0) {
            data.slope = grade[0].slope;
          }
          return data;
        });

        if (itemsCheckedWithZero.length && grade >= minValueUp) {
          calculateDataMarketByZero(itemsChecked, true, itemsCheckedWithZero[0].grade);
        }

        if (grade < minValueUp) {
          calculateDataMarketByZero(itemsChecked, checked, grade);
        }

      } else {
        calculateDataMarketByZero(itemsChecked, checked, grade);
      }
    } else {
      if (!itemsChecked.length) {
        toast.warning('Selecione pelo menos dois grades com valor bruto.');
      }
      emptySmoothedValues();
    }
    verifyHasNegativeSlope(maxValueUp);
  }

  function verifyHasNegativeSlope(maxValueUp) {
    const itemsMustUpdate = marketData
      .filter((item: any) => item.slope && Number(item.grade) <= maxValueUp && item.slope < 0)

    if (itemsMustUpdate.length) {
      setHasNegativeSlope(true);
    } else {
      setHasNegativeSlope(false);
    }
  }

  async function saveDataSmoothed() {
    const itemsMustUpdate = marketData
      // .filter((item: any) => item.smoothed)
      .map((item: any) => {
        return {
          id: item.id,
          p10_interpolated: (item.smoothed_p10) ? Number(item.smoothed_p10) : null,
          p25_interpolated: (item.smoothed_p25) ? Number(item.smoothed_p25) : null,
          p50_interpolated: (item.smoothed) ? Number(item.smoothed) : 0,
          p75_interpolated: (item.smoothed_p75) ? Number(item.smoothed_p75) : null,
          p90_interpolated: (item.smoothed_p90) ? Number(item.smoothed_p90) : null,
          is_break_point: (item.checked) ? item.checked : false,
        };
      });

    if (itemsMustUpdate.length > 0) {
      const idMarket = id
        ? id
        : selectedIntermedOption
          ? selectedIntermedOption.value
          : '';
      if (idMarket) {
        setLoad(true);

        await api.put(`/markets/statistics/${idMarket}/items/interpolations`, {
          interpolations: itemsMustUpdate,
          line: selectedIntermedOptionLine.id,
        });
        setIsEdited(false);
        toast.success('Dados suavizados gravados com sucesso.');
        listMarketData();
        listMarketFullData();
        setLoad(false);
      }
    }
  }

  function defaultValueChecked(market: any) {
    const verifyManipulated = isCheckManipulated.filter(
      (item: any) => Number(item) === Number(market.grade),
    );

    if (market.checked && !verifyManipulated.length) {
      return true;
    } else if (market.checked && verifyManipulated.length) {
      return true;
    } else if (
      !market.checked &&
      market.is_break_point &&
      !verifyManipulated.length
    ) {
      return true;
    }
  }

  function handleOpen(colapse: any) {
    colapse === 0 ? setIsOpen0(isOpen0 ? false : true) : '';
    colapse === 1 ? setIsOpen1(isOpen1 ? false : true) : '';
    colapse === 2 ? setIsOpen2(isOpen2 ? false : true) : '';
    colapse === 3 ? setIsOpen3(isOpen3 ? false : true) : '';
  }

  async function handleDownload() {
    try {
      setHasExcel(true);
      const market_statistic_id = selectedIntermedOption
        ? selectedIntermedOption.value
        : '';
      const dataProcess = await api.get(
        `/markets/statistics/${market_statistic_id}/items/reports/download`,
        { responseType: 'blob' },
      );
      const fileName = dataProcess.headers['content-disposition'].split('=')[1];
      downloadFileFromServer({
        contentType: dataProcess.headers['content-type'],
        data: dataProcess.data,
        filename: fileName,
      });
      toast.success('Download da mercado suavização realizado com sucesso!');
      setHasExcel(false);
    } catch (err) {
      toast.error('Erro ao realizar o download mercado suavização!');
      setHasExcel(false);
    }
  }

  function formatSmooth(value) {
    let valueUpdated = '';
    if (value) {
      if (
        selectedIntermedOptionLine.id != 'icp' &&
        selectedIntermedOptionLine.id != 'ilp'
      ) {
        valueUpdated = formatBrMoney(value);
      } else {
        valueUpdated = `${value?.toFixed(2)}`;
      }
    }
    return valueUpdated;
  }

  useEffect(() => {
    listMarkets();
  }, []);

  useEffect(() => {
    handleSetIsLoading(true);
    listMarketData();
    listMarketFullData();
  }, [selectedIntermedOption]);

  useEffect(() => {
    handleSetIsLoading(true);
    listMarketData();
  }, [selectedIntermedOptionLine]);

  useEffect(() => {
    const grades = gradesCheckedOnStart.map((item) => item.grade);
    const gradesInORder = grades.sort((a, b) => a - b);

    for (let i = 0; i < gradesInORder.length; i++) {
      const itemGrade = gradesCheckedOnStart.filter(
        (item) => item.grade === gradesInORder[i],
      );

      if (itemGrade.length && itemGrade[0].p50 > 0) {
        calculateDataMarket(gradesInORder[i], true);
      }
    }
  }, [gradesCheckedOnStart]);

  function autoCheckBreakpoints() {
    const grades = gradesCheckedOnStart.map((item) => item.grade);
    const gradesInORder = grades.sort((a, b) => a - b);

    for (let i = 0; i < gradesInORder.length; i++) {
      const itemGrade = gradesCheckedOnStart.filter(
        (item) => item.grade === gradesInORder[i],
      );
      if (itemGrade.length && itemGrade[0].p50 > 0) {
        checksGrades.current[gradesInORder[i]].checked = true;
        console.log(gradesInORder[i]);
        // código a ser executado após 2 segundos
        // calculateDataMarket(gradesInORder[i], true);
      }
    }
  }

  const addToRef = (el: any, grade) => {
    checksGrades.current[grade] = el;
  };

  return (
    <Container>
      <Header>
        <h2 className="text-2xl font-bold">Mercado - Suavização</h2>
      </Header>

      <div className="grid grid-cols-6 gap-4">
        <div className="col-start-1 col-end-3">
          <Select
            onChange={(option) =>
              setSelectedIntermedOption(option as OptionSelect)
            }
            options={markets}
            isSearchable={false}
            maxMenuHeight={250}
            value={markets.filter(({ value }) => value === idFilter)}
            placeholder="Escolha um mercado"
          />
          {/* {selectedIntermedOption && selectedIntermedOption.id ? selectedIntermedOption.id : 'aa'} */}
        </div>

        {/* {id || selectedIntermedOption ? ( */}
        <div className="flex justify-end col-end-8">
          <ButtonDownload
            onClick={() => handleDownload()}
            disabled={
              hasExcel || (!id && !selectedIntermedOption) ? true : false
            }
          >
            {!hasExcel ? (
              <>
                <SiMicrosoftexcel
                  size={'25px'}
                  style={{ marginRight: '10px' }}
                />
                Exportar para XLS{' '}
              </>
            ) : (
              <>
                <div style={{ paddingRight: '6px' }}>
                  <ReactLoading
                    type="spin"
                    color={'#F56A00'}
                    height={20}
                    width={20}
                  />
                </div>
                Aguarde exportação
              </>
            )}
          </ButtonDownload>

          {marketStatus !== 'published' ?
            <ButtonPrimary
              disabled={hasNegativeSlope || load || (!id && !selectedIntermedOption)}
              onClick={saveDataSmoothed}
            >
              Salvar
            </ButtonPrimary>
            : null}

        </div>
        {/* ) : null} */}
      </div>

      {id || selectedIntermedOption ? (
        <>
          <div className="flex gap-4">
            <div className="flex items-center gap-4">
              <strong>Linha</strong>

              <Select
                onChange={(option) => {
                  setSelectedIntermedOptionLine(option as OptionSelect);
                  setIsEdited(false);
                }}
                options={linhas}
                isSearchable={false}
                maxMenuHeight={350}
                defaultValue={linhas.filter(({ value }) => value === 'sb')}
                placeholder="Linha"
              />

            </div>

            {isEdited || hasNegativeSlope ?
              (<div className="mb-4 shadow-sm alert alert-warning alertSalvar">
                <div>

                  {isEdited ?
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-shrink-0 w-6 h-6 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>
                        Existem valores alterados não salvos, clique
                        em <b>Salvar</b>!
                      </span>
                    </>
                    : ''}

                  {hasNegativeSlope ?
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-shrink-0 w-6 h-6 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                      <span>
                        Existem valores com SLOPE negativo, favor corrigir antes de salvar
                      </span>
                    </>
                    : ''}
                </div>
              </div>
              ) : null}
          </div>

          <div className={`grid grid-cols-1 gap-4 ${isLoading ? 'opacity-30' : ''}`}>
            {/* <div className="div1"> */}
            <table className="table w-full table-compact">
              <thead>
                <tr>
                  <th colSpan={8}></th>
                  <th colSpan={5} className='text-center'><strong>Suavizado</strong></th>
                  <th colSpan={8}></th>
                </tr>
                <tr>
                  <th style={{ display: 'none' }}></th>
                  {marketData.length && !marketData[0].role ? (
                    <th>
                      <strong>Grade</strong>
                    </th>
                  ) : (
                    null
                  )}
                  {marketData.length && marketData[0].role ? (
                    <th>
                      <strong>Cargo</strong>
                    </th>
                  ) : (
                    null
                  )}
                  <th>
                    <strong>P10</strong>
                  </th>
                  <th>
                    <strong>P25</strong>
                  </th>
                  <th>
                    <strong>P50</strong>
                  </th>
                  <th>
                    <strong>P75</strong>
                  </th>
                  <th>
                    <strong>P90</strong>
                  </th>
                  <th>
                    <strong>SLOPE BRUTO</strong>
                  </th>
                  <th>
                    {marketStatus != 'published' ? <strong>Breakpoint</strong> : null}
                  </th>
                  <th className='bg-teal-200'>
                    <strong>P10</strong>
                  </th>
                  <th className='bg-teal-200'>
                    <strong>P25</strong>
                  </th>
                  <th className='bg-teal-200'>
                    <strong>P50</strong>
                  </th>
                  <th className='bg-teal-200'>
                    <strong>P75</strong>
                  </th>
                  <th className='bg-teal-200'>
                    <strong>P90</strong>
                  </th>
                  <th>
                    <strong>SLOPE</strong>
                  </th>
                  {linhas.map((item: any) => (
                    <th key={item.label}>
                      <strong>{item.label}</strong>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {marketData.length
                  ? marketData.map((i: any, index: number) => (
                    <tr
                      key={
                        i.id + '-' + Math.random().toString().substring(3, 8)
                      }
                    >
                      <td style={{ display: 'none' }}></td>
                      {!i.role ? <td>{i.grade}</td> : ''}
                      {i.role ? <td>{i.role}</td> : ''}
                      <td>
                        {selectedIntermedOptionLine.id != 'icp' &&
                          selectedIntermedOptionLine.id != 'ilp'
                          ? formatBrMoneyAndNull(i.p10)
                          : i.p10}
                      </td>
                      <td>
                        {selectedIntermedOptionLine.id != 'icp' &&
                          selectedIntermedOptionLine.id != 'ilp'
                          ? formatBrMoneyAndNull(i.p25)
                          : i.p25}
                      </td>
                      <td>
                        {selectedIntermedOptionLine.id != 'icp' &&
                          selectedIntermedOptionLine.id != 'ilp'
                          ? formatBrMoneyAndNull(i.p50)
                          : i.p50}
                      </td>
                      <td>
                        {selectedIntermedOptionLine.id != 'icp' &&
                          selectedIntermedOptionLine.id != 'ilp'
                          ? formatBrMoneyAndNull(i.p75)
                          : i.p75}
                      </td>
                      <td>
                        {selectedIntermedOptionLine.id != 'icp' &&
                          selectedIntermedOptionLine.id != 'ilp'
                          ? formatBrMoneyAndNull(i.p90)
                          : i.p90}
                      </td>
                      <td
                        style={{
                          color: i.slopeP50 && i.slopeP50 < 0 ? 'red' : '',
                        }}
                      >
                        {i.slopeP50 ? `${i.slopeP50}%` : ''}
                      </td>
                      <td>
                        {marketStatus != 'published' ?
                          <div className="form-control">
                            <label className="cursor-pointer label">
                              <input
                                type="checkbox"
                                className="checkbox checkbox-primary"
                                defaultChecked={i.checked}
                                // defaultChecked={defaultValueChecked(i)}
                                // ref={(el) => addToRef(el, i.grade)}
                                value={i.p50 || ''}
                                title={i.grade}
                                onClick={(e: any) => {
                                  const checked = e.target.checked;
                                  const value = e.target.value;
                                  const grade = e.target.title;
                                  // calculateMarket(checked, grade);
                                  setIsEdited(true);
                                  putCheckedMarket(checked, grade);
                                }}
                              />
                            </label>
                          </div>
                          : null}
                      </td>
                      <td className='bg-slate-50 border-x-2'>{formatSmooth(i.smoothed_p10)}</td>
                      <td className='bg-slate-50 border-x-2'>{formatSmooth(i.smoothed_p25)}</td>
                      <td className='bg-slate-50 border-x-2'>{formatSmooth(i.smoothed)}</td>
                      <td className='bg-slate-50 border-x-2'>{formatSmooth(i.smoothed_p75)}</td>
                      <td className='bg-slate-50 border-x-2'>{formatSmooth(i.smoothed_p90)}</td>
                      <td
                        style={{ color: i.slope && i.slope < 0 ? 'red' : '' }}
                      >
                        {i.smoothed && i.slope ? `${i.slope}%` : ''}
                      </td>
                      {linhas.map((item: any) => (
                        <td key={item.label}>
                          <input
                            type="checkbox"
                            className="checkbox checkbox-success"
                            value={0}
                            // disabled={true}
                            checked={isCheckedBreakpoint(item.label, i.grade)}
                            onChange={() => console.log('breakpoint')}
                          />
                        </td>
                      ))}
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
            {/* </div> */}
          </div>
        </>
      ) : null
      }

      <div className="divider"></div>

      {
        id ||
          (selectedIntermedOption &&
            marketFullData &&
            marketFullData.interpolations &&
            marketFullData.growth_between_general_market &&
            marketFullData.growth_between_lines &&
            marketFullData.growth_between_statistics) ? (
          <>
            <MarketAnalyses
              title="Mercado suavizado"
              marketFullData={marketFullData.interpolations}
              callback={() => handleOpen(0)}
              statusColapse={isOpen0}
              colapse={0}
              showAverage={true}
            />

            <MarketAnalyses
              title="Análise de crescimento entre linhas de remuneração"
              marketFullData={marketFullData.growth_between_lines}
              callback={() => handleOpen(1)}
              statusColapse={isOpen1}
              colapse={1}
            />

            <MarketAnalysesMeG
              title="Análise contra outro mercado"
              marketFullData={marketFullData.growth_between_general_market}
              callback={() => handleOpen(2)}
              statusColapse={isOpen2}
              colapse={2}
            />

            <MarketAnalyses
              title="Análise de crescimento entre estatísticas"
              marketFullData={marketFullData.growth_between_statistics}
              callback={() => handleOpen(3)}
              statusColapse={isOpen3}
              colapse={3}
            />
          </>
        ) : null
      }

      {
        !marketData.length ? (
          <NoQuestions>
            {selectedIntermedOption
              ? 'Item selecionado não localizado'
              : 'Nenhum item selecionado'}
          </NoQuestions>
        ) : null
      }
    </Container >
  );
}
