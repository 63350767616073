import { ModalConfirm } from '../../../../components/ModalConfirm';

interface ConfirmDeletePlanModalProps {
  isConfirmDeletePlanModalOpen: boolean;
  onCloseConfirmDeletePlan: () => void;
  handleConfirmDeletePlan: () => void;
  isLoading: boolean;
  planName: string;
}
export function ConfirmDeletePlanModal({
  isConfirmDeletePlanModalOpen,
  planName,
  onCloseConfirmDeletePlan,
  handleConfirmDeletePlan,
  isLoading,
}: ConfirmDeletePlanModalProps) {
  return (
    <ModalConfirm
      type="plan"
      isLoading={isLoading}
      callback={handleConfirmDeletePlan}
      closeModal={onCloseConfirmDeletePlan}
      openModal={isConfirmDeletePlanModalOpen}
      description={`*O plano: "${planName}" já está vinculado a esta empresa, ao confirmar, a empresa irá perder o vínculo!`}
    />
  );
}
