import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  MainTitle,
  MainContent,
  Select,
  HeaderListPositions,
  SectionListPositions,
  ItemPosition,
  SearchRow,
  PrimaryButton,
} from './styles';
import { AppError } from '../../error/AppError';
import {
  OptionSelect,
  Tenant,
  Match,
  SingleMatchGrade,
} from '../../interfaces';
import { useAdmin } from '../../contexts/AdminContext';
import { api } from '../../services/api';
import { ModalGradeMatch } from '../../components/ModalGradeMatch';
import { GlobalLoading } from '../../components/GlobalLoading';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import FileUploader from '../../components/FileUploader';
import { HttpStatus } from '../../enums/http-status';
import { ModalConfirmGradesToDelete } from '../../components/ModalConfirmGradesToDelete';

export interface GradesToDelete {
  id: string;
  title: string;
  code: number;
  _count: {
    tenant_positions: number;
    tenant_grade_matches: number;
  };
}

const GradesMatch = () => {
  const { loadGradesMatriz, gradesMatriz, updateTenantGradeMatriz } =
    useAdmin();
  const [tenantsOptions, setTenantsOptions] = useState<OptionSelect[]>([]);
  const [selectedCompanyOption, setSelectedCompanyOption] =
    useState<OptionSelect>();
  const [isShowModalGradeMatriz, setIsShowModalGradeMatriz] = useState(false);
  const { isLoading, handleSetIsLoading } = useGlobalLoading();
  const [gradesToDelete, setGradesToDelete] = useState<GradesToDelete[]>([]);
  const [
    isOpenModalGradesToDeleteConfirm,
    setIsOpenModalGradesToDeleteConfirm,
  ] = useState(false);
  const [fileUpload, setFileUpload] = useState<File | null>(null);

  const loadTenants = async () => {
    try {
      const { data } = await api.get<Tenant[]>('/tenants?paginate=false');
      const tenants = data.map((tenant) => ({
        label: tenant.name,
        value: tenant.id,
      }));

      setTenantsOptions(tenants);
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  };

  const handleFilter = async (tenant_id: string) => {
    const result = await loadGradesMatriz(tenant_id);

    if (result.length <= 0) {
      toast.error('Nenhum dado encontrado');
    }
  };

  const handleCloseModalGradesToDeleteConfirm = () => {
    setIsOpenModalGradesToDeleteConfirm(false);
    setGradesToDelete([]);
    handleResetFileUpload();
  };

  const handleResetFileUpload = () => {
    setFileUpload(null);
  };

  const handleGradesToDeleteConfirm = () => {
    handleUploadTemplateGrade(fileUpload);
    setIsOpenModalGradesToDeleteConfirm(false);
    setGradesToDelete([]);
  };

  const updateMatrizGradeMatch = async (
    matchAble: SingleMatchGrade[],
    tenant_id: string,
  ) => {
    try {
      await updateTenantGradeMatriz({
        matches: matchAble,
        tenant_id,
      });
      toast.success('Match atualizado com sucesso!');
      setIsShowModalGradeMatriz(false);

      await handleFilter(tenant_id);
    } catch (error) {
      AppError(
        error,
        `Problemas ao atualizar grade match. Verifique os dados fornecidos!`,
      );
    }
  };

  const setSelectTenantAndSearchAll = async (option: OptionSelect) => {
    setSelectedCompanyOption(option as OptionSelect);
    await handleFilter(option.value);
  };

  const handleUploadGradeRequest = async (file: File, tenantId: string) => {
    const formData = new FormData();
    formData.append('file', file);

    let gradesToDeleteQuery = '';

    if (gradesToDelete.length) {
      const grades = gradesToDelete.map((g) => g.title);
      gradesToDeleteQuery = grades.join(',');
    }

    try {
      await api.post(`/uploads/tenants/${tenantId}/grades`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          grades_to_delete: gradesToDeleteQuery,
        },
      });

      toast.success(`Upload de grades feito com sucesso!`);
      handleResetFileUpload();
    } catch (error: any) {
      if (error.response) {
        const { status, data } = error.response;

        if (status && status === HttpStatus.UNPROCESSABLE_ENTITY.valueOf()) {
          setGradesToDelete(data.data?.grades || []);
          setIsOpenModalGradesToDeleteConfirm(true);
          return;
        }
      }

      toast.error(`Houve algum problema ao tentar fazer upload!`);
    }
  };

  const handleUploadTemplateGrade = async (file: File | null) => {
    handleSetIsLoading(true);
    const tenantId = selectedCompanyOption?.value;

    if (!tenantId) {
      AppError(
        new Error('tenant id empty!'),
        `Problemas ao atualizar grade match. Verifique os dados fornecidos!`,
      );
      return;
    }

    if (file) {
      setFileUpload(file);
      await handleUploadGradeRequest(file, tenantId);
    }

    handleSetIsLoading(false);
  };

  useEffect(() => {
    loadTenants();
  }, []);

  return (
    <Container>
      {selectedCompanyOption && isShowModalGradeMatriz && (
        <ModalGradeMatch
          updateMatrizGradeMatch={updateMatrizGradeMatch}
          closeModal={() => setIsShowModalGradeMatriz(false)}
          tenantId={selectedCompanyOption.value}
        />
      )}
      {isOpenModalGradesToDeleteConfirm && (
        <ModalConfirmGradesToDelete
          handleCloseModalGradesToDeleteConfirm={
            handleCloseModalGradesToDeleteConfirm
          }
          handleGradesToDeleteConfirm={handleGradesToDeleteConfirm}
          gradesToDelete={gradesToDelete}
        />
      )}
      <Content>
        <h2 className="text-2xl font-bold">Grades Match</h2>
        <div className="divider"></div>

        <MainContent>
          <SearchRow>
            <Select
              onChange={(option) =>
                setSelectTenantAndSearchAll(option as OptionSelect)
              }
              options={tenantsOptions}
              isSearchable={true}
              maxMenuHeight={150}
              placeholder="Escolha uma empresa"
            />

            {selectedCompanyOption && (
              <>
                <PrimaryButton
                  style={{ marginLeft: '1rem', marginRight: '1rem' }}
                  onClick={() => setIsShowModalGradeMatriz(true)}
                  disabled={isLoading}
                >
                  Alterar / Criar Match
                </PrimaryButton>

                <FileUploader
                  buttonLabel="Upload template grade"
                  handleFile={(file) => handleUploadTemplateGrade(file)}
                  buttonDisabled={isLoading}
                />
              </>
            )}
          </SearchRow>

          {gradesMatriz.length > 0 && (
            <>
              <div className="grid grid-cols-1 gap-4 mt-6">
                <div className="card bg-base-100 shadow-xl">
                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td>Grade da Empresa</td>
                          <td>Número da grade</td>
                          <td>Ponto Mínimo</td>
                          <td>Ponto Médio</td>
                          <td>Ponto Máximo</td>
                          <td>Grade SMRTComp</td>
                          <td>Empresa</td>
                        </tr>
                      </thead>
                      <tbody>
                        {gradesMatriz && (
                          <>
                            {gradesMatriz.map((gradeMatriz, index) => (
                              <tr key={index}>
                                <td>{gradeMatriz.tenant_grade.title}</td>
                                <td>{gradeMatriz.tenant_grade.code}</td>
                                <td>{gradeMatriz.tenant_grade.minimum}</td>
                                <td>{gradeMatriz.tenant_grade.medium}</td>
                                <td>{gradeMatriz.tenant_grade.maximum}</td>
                                <td>{gradeMatriz.system_grade.code}</td>
                                <td>{gradeMatriz.tenant.name}</td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </MainContent>
      </Content>
    </Container>
  );
};

export default GradesMatch;
