import { ButtonHTMLAttributes } from 'react';
import { Button } from '../Button';

type ButtonPrimaryProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  title: string;
  size?: 'sm' | 'lg' | 'md';
  color?: 'primary' | 'secondary';
};

export function ButtonPrimary({ title, size, ...rest }: ButtonPrimaryProps) {
  return (
    <Button size={size} {...rest}>
      {title}
    </Button>
  );
}
