import { Stitches } from '../../../src/styles/stitches';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';

export const Container = Stitches.styled('div', {});

export const Header = Stitches.styled('div', {
  width: '100%',
  borderBottom: '1px solid #dddddd',
  padding: '10px',
  fontWeight: 'bold',
  marginBottom: '15px',
  fontSize: '1.5rem !important',
});

export const Form = Stitches.styled('form', {
});

export const ContainerButton = Stitches.styled('div', {
  margin: '1px',
});

export const Content = Stitches.styled('div', {
  color: '#c53030',
  fontSize: '0.75rem',
});

export const DatabaseData = Stitches.styled('div', {
  display: 'flex',

  input: {
    width: '100%',
  },
  select: {
    width: '150px',
  },
});

export const IconAlert = Stitches.styled('div', {
  color: 'red',
  cursor: 'pointer',
});

export const IconFiArrowRight = styled(FiArrowRight)`
  font-size: 118px;
  margin-right: 1rem;
  color: blue !important;
  cursor: pointer;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  span {
    background-color: var(--black-light);
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    margin: 0 0.125rem;
  }
`;

export const ArrowLeft = styled.button`
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: var(--background-modal);
  }

  svg {
    font-size: 17px;
  }
`;

export const ArrowRight = styled.button`
  background-color: var(--primary);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: var(--background-modal);
  }

  svg {
    font-size: 17px;
  }
`;
