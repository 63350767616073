import { useLocation, useNavigate } from 'react-router-dom';
import { FiUsers, FiClipboard } from 'react-icons/fi';
import { Container, Content, Item, FirstItem, Title, Line } from './styles';
import { MenuButton } from './menu-button';
import { DASHBOARD_CONSTANTS } from './constants';
import { IconsSvgSrc } from '../../../src/shared/icons';

const {
  DashboardIconGraySvg,
  DashboardIconWhiteSvg,
  DashboardIconPrimarySvg,
  FileTrayFullIconGraySvg,
  FileTrayFullIconWhiteSvg,
  FileTrayFullIconPrimarySvg,
  HomeIconGraySvg,
  HomeIconWhiteSvg,
  HomeIconPrimarySvg,
  StrategyIconGraySvg,
  StrategyIconWhiteSvg,
  StrategyIconPrimarySvg,
  StructureIconGraySvg,
  StructureIconWhiteSvg,
  StructureIconPrimarySvg,
  BellIconGraySvg,
  MailIconGraySvg,
  CloudUploadIconGraySvg,
  CloudUploadIconWhiteSvg,
  CloudUploadIconPrimarySvg,
  TrophyIconGraySvg,
  TrophyIconWhiteSvg,
  TrophyIconPrimarySvg,
} = IconsSvgSrc;

const Menu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateMenu = (route: string) => {
    navigate(route);
  };

  function validActive(url: string) {
    return url === pathname ? true : false;
  }

  const { dataSubmenuMarket, dataMenuSector, dataSubmenuManagerAccess } =
    DASHBOARD_CONSTANTS;

  return (
    <Container>
      <Content>
        <MenuButton
          label="Usuários ADM"
          routerPath="/dashboard-users"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Acessos e Permissões"
          routerPath="/groups"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          subButtons={dataSubmenuManagerAccess}
          disabled={false}
        />

        <MenuButton
          label="Empresas"
          routerPath="/dashboard-companys"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Colaboradores"
          routerPath="/dashboard-contributors"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Opções presidência"
          routerPath="/dashboard-questions"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Vínculo de cargos"
          routerPath="/dashboard-job-bond"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Cargos SMRTComp"
          routerPath="/dashboard-smartpay-positions"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Grades match"
          routerPath="/dashboard-grades-match"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Descrições de Cargos"
          routerPath="/position-descriptions"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Nível de contribuição"
          routerPath="/contribution-levels"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Famílias"
          routerPath="/position-families"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Sub Famílias"
          routerPath="/position-subfamilies"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
        />

        <MenuButton
          label="Macro Setores / Setores"
          routerPath="/position-sector-group"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          disabled={false}
          subButtons={dataMenuSector}
        />

        <MenuButton
          label="Mercado"
          routerPath="/market-configuration"
          routerPrfix="/market-configuration"
          isCollapsed={false}
          iconSrcGray={FileTrayFullIconGraySvg}
          iconSrcWhite={FileTrayFullIconWhiteSvg}
          iconSrcPrimary={FileTrayFullIconPrimarySvg}
          subButtons={dataSubmenuMarket}
          disabled={false}
          // data-tip={isCollapsed ? 'Seus Dados' : ''}
        />
      </Content>
    </Container>
  );
};

export { Menu };
