import { Button } from '../../../components/Button';
import { ModalGeneric } from '../../../components/ModalGeneric';

interface ConfirmPublishMarketModalProps {
  isModalOpenDespublicar: boolean;
  setIsModalOpenDespublicar: (v: boolean) => void;
  handleProccessDespublicarOk: () => void;
  setIsModalOpenReprocess: (v: boolean) => void;
}

export function ConfirmPublishMarketModal({
  isModalOpenDespublicar,
  setIsModalOpenDespublicar,
  handleProccessDespublicarOk,
  setIsModalOpenReprocess
}: ConfirmPublishMarketModalProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalOpenDespublicar}
      setIsModalOpen={setIsModalOpenDespublicar}
    >
      <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
        Tem certeza que deseja Despublicar ?
        <br />
        <Button
          type="button"
          onClick={(e: any) => {
            setIsModalOpenDespublicar(false);
            handleProccessDespublicarOk();
          }}
        >
          Confirmar
        </Button>
        <button
          className="btn btn-ghost"
          onClick={() => setIsModalOpenReprocess(false)}
        >
          Cancelar
        </button>
      </div>
    </ModalGeneric>
  );
}
