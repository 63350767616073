import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { FiSearch } from 'react-icons/fi';
import { ModalConfirm } from '../../components/ModalConfirm';
import { ModalEditSmartPayPosition } from '../../components/ModalEditSmartPayPosition';

import {
  Container,
  Content,
  ButtonPrimary,
  IconPlus,
  SectionListPositions,
  HeaderListPositions,
  ItemCompany,
  MainTitle,
  MainContent,
  NoQuestions,
  ControlContainer,
} from './styles';
import {
  CreateOrUpdateSmartPayPosition,
  OptionSelect,
  SmartPayPosition,
} from '../../interfaces';
import { useEffect } from 'react';
import { useAdmin } from '../../contexts/AdminContext';
import { AppError } from '../../error/AppError';
import { api } from '../../services/api';
import { Select } from '../DashboardCompanyPositions/styles';
import { Input } from '../../components/Input';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { HttpStatus } from '../../enums/http-status';

interface OptionSelectSubfamily extends OptionSelect {
  family_id: string;
}

const DashboardSmartPayPositions = () => {
  const {
    getSmartPayPositions,
    smartPayPositions,
    deleteSmartPayPosition,
    updateSmartPayPosition,
    createSmartPayPosition,
  } = useAdmin();
  const { handleSetIsLoading } = useGlobalLoading();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalAccountPosition, setIsShowModalAccountPosition] =
    useState(false);
  const [currentEditPosition, setCurrentEditPosition] =
    useState<CreateOrUpdateSmartPayPosition | null>(null);
  const [currentDeletePosition, setCurrentDeletePosition] = useState('');
  const [familiesOptions, setFamiliesOptions] = useState();
  const [allSubFamiliesOptions, setAllSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [subFamiliesOptions, setSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [selectedFamilyOption, setSelectedFamilyOption] =
    useState<OptionSelect>();
  const [selectedSubFamilyOption, setSelectedSubFamilyOption] =
    useState<OptionSelect>();
  const [positionSearch, setPositionSearch] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  useEffect(() => {
    getSmartPayPositions();
    loadFamilies();
  }, []);

  useEffect(() => {
    handleSetIsLoading(true);
    getSmartPayPositions(
      selectedFamilyOption?.label,
      selectedSubFamilyOption?.label,
      positionSearch,
    );

    handleSetIsLoading(false);
  }, [selectedFamilyOption, selectedSubFamilyOption]);

  const selectEditPosition = (id: string) => {
    const [position] = smartPayPositions.filter(
      (position) => position.id === id,
    );

    if (!position) return;

    setCurrentEditPosition({
      id,
      code: position.code,
      description: position.description,
      title: position.title,
      sub_family_id: position.position_subfamily.id,
      contribution_level_id: position.contribution_level_id,
      grade_id: position.grade_id,
    });
    setIsShowModalAccountPosition(true);
  };

  const loadFamilies = async () => {
    try {
      const { data } = await api.get('/smartpay/positions/subfamilies');

      const auxFamily: Array<string> = [];
      const families = data
        .map((family) => {
          const id = family.position_family.id as string;
          if (!auxFamily.includes(id)) {
            auxFamily.push(id);
            return {
              label: family.position_family.title,
              value: family.position_family.id,
            };
          }

          return null;
        })
        .filter((boolean) => boolean);
      const subFamilies: OptionSelectSubfamily[] = data.map((family) => ({
        label: family.title,
        value: family.id,
        family_id: family.position_family.id,
      }));
      setFamiliesOptions(families);
      setSubFamiliesOptions(subFamilies);
      setAllSubFamiliesOptions(subFamilies);
    } catch (error) {
      AppError(error, `Problemas ao carregar família e subfamília!`);
    }
  };

  const actionDeleteSmartpayPosition = async () => {
    try {
      await deleteSmartPayPosition(currentDeletePosition);
      setIsShowModalConfirm(false);
      toast.success('Cargo removido com sucesso!');
    } catch (error: any) {
      AppError(error, `Erro ao tentar remover cargo!`);
    }
  };

  const actionAccountPosition = async (
    data: any,
    typeAction: 'update' | 'create',
  ) => {
    try {
      if (typeAction === 'create') {
        await createSmartPayPosition(data);
        toast.success('Cargo criado com sucesso!');
      } else {
        await updateSmartPayPosition(currentEditPosition?.id as string, data);
        toast.success('Cargo atualizado com sucesso!');
      }
      setIsShowModalAccountPosition(false);
    } catch (error) {
      AppError(
        error,
        `Problemas ao ${
          typeAction === 'create' ? 'criar' : 'atualizar'
        } cargo. Verifique os dados fornecidos!`,
      );
    }
  };

  const handleSetSelectedFamilyOption = (option: OptionSelect) => {
    setSelectedFamilyOption(option as OptionSelect);

    if (!option) {
      setSubFamiliesOptions(allSubFamiliesOptions);
      return;
    }

    const subFamiliesOptionsFiltered = allSubFamiliesOptions?.filter(
      (sf) => sf.family_id === option.value,
    );
    setSubFamiliesOptions(subFamiliesOptionsFiltered);
  };

  const handleChangeAction = useCallback(
    (type: string, position: SmartPayPosition) => {
      switch (type) {
        case 'edit':
          selectEditPosition(position.id);
          break;
        case 'delete':
          setCurrentDeletePosition(position.id);
          setIsShowModalConfirm(true);
          break;
        default:
          break;
      }
    },
    [selectEditPosition, setCurrentDeletePosition, setIsShowModalConfirm],
  );

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="position"
          callback={actionDeleteSmartpayPosition}
          closeModal={() => {
            setIsShowModalConfirm(false);
            setSelectedAction('Ações');
          }}
        />
      )}
      {isShowModalAccountPosition && (
        <ModalEditSmartPayPosition
          callback={actionAccountPosition}
          closeModal={() => {
            setIsShowModalAccountPosition(false);
            setSelectedAction('Ações');
          }}
          positionSmartPay={currentEditPosition}
        />
      )}

      <Content>
        <MainContent>
          <h2 className="text-2xl font-bold">Cargos SMRTComp</h2>
          <div className="divider"></div>

          <ControlContainer>
            <Select
              onChange={(option) =>
                handleSetSelectedFamilyOption(option as OptionSelect)
              }
              options={familiesOptions}
              isSearchable={false}
              isClearable={true}
              maxMenuHeight={150}
              placeholder="Escolha uma Família"
            />
            <Select
              onChange={(option) =>
                setSelectedSubFamilyOption(option as OptionSelect)
              }
              options={subFamiliesOptions}
              isClearable={true}
              isSearchable={false}
              maxMenuHeight={150}
              placeholder="Escolha uma SubFamília"
            />
            <input
              type="text"
              className="w-full max-w-xs m-2 input input-bordered"
              onChange={(e) => setPositionSearch(e.currentTarget.value)}
              value={positionSearch}
            />
            <ButtonPrimary
              className="mr-2 cursor-pointer"
              onClick={(e) => {
                getSmartPayPositions(
                  selectedFamilyOption?.label,
                  selectedSubFamilyOption?.label,
                  positionSearch,
                );
              }}
            >
              <FiSearch />
              Buscar
            </ButtonPrimary>
            <ButtonPrimary
              className="mr-2 cursor-pointer"
              onClick={() => {
                setCurrentEditPosition(null);
                setIsShowModalAccountPosition(true);
              }}
            >
              <IconPlus />
              Criar cargo
            </ButtonPrimary>
          </ControlContainer>

          {smartPayPositions.length > 0 ? (
            <>
              <table className="table w-full mt-6">
                <thead>
                  <tr>
                    <th>Cargo</th>
                    <th>Grade</th>
                    <th>Família</th>
                    <th>SubFamília</th>
                    <th colSpan={2}>Ações</th>
                  </tr>
                </thead>

                <tbody>
                  {smartPayPositions.map((position) => (
                    <tr key={position.id}>
                      <td>{position.title}</td>
                      <td>{position.system_grade.code}</td>
                      <td>
                        {position.position_subfamily.position_family.title}
                      </td>
                      <td>{position.position_subfamily.title}</td>
                      <td>
                        <select
                          value={
                            position.id === currentEditPosition?.id
                              ? selectedAction
                              : ''
                          }
                          className="w-[150px] select select-bordered"
                          onChange={(e) => {
                            handleChangeAction(e.target.value, position);
                            setSelectedAction(e.target.value);
                          }}
                        >
                          <option>Ações</option>
                          <option value="edit" key="edit">
                            Editar
                          </option>
                          <option value="delete" key="delete">
                            Excluir
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <NoQuestions>Nenhum cargo cadastrado</NoQuestions>
          )}
        </MainContent>
      </Content>
    </Container>
  );
};

export default DashboardSmartPayPositions;
