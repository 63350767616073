import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

export function useDashboardCompanySearch() {
  const [currentPage, setCurrentPage] = useQueryParam("cp", withDefault(NumberParam, 1), {
    removeDefaultsFromUrl: true,
  });

  const [limit, setLimit] = useQueryParam(
    "l",
    withDefault(NumberParam, 10),
    {
      removeDefaultsFromUrl: true,
    }
  );

  const [selectedTenant, setSelectedTenant] = useQueryParam(
    "c",
    withDefault(StringParam, ""),
    {
      removeDefaultsFromUrl: true,
    }
  );

  const [selectedPlan, setSelectedPlan] = useQueryParam(
    "p",
    withDefault(StringParam, ""),
    {
      removeDefaultsFromUrl: true,
    }
  );

  const [selectedPlanStatus, setSelectedPlanStatus] = useQueryParam(
    "pS",
    withDefault(StringParam, ""),
    {
      removeDefaultsFromUrl: true,
    }
  );

  const [selectedTenantType, setSelectedTenantType] = useQueryParam(
    "tT",
    withDefault(StringParam, ""),
    {
      removeDefaultsFromUrl: true,
    }
  );

  return {
    selectedTenant,
    selectedPlan,
    currentPage,
    setCurrentPage,
    setSelectedTenant,
    setSelectedPlan,
    selectedPlanStatus,
    setSelectedPlanStatus,
    selectedTenantType,
    setSelectedTenantType,
    limit,
    setLimit,
  };
}
