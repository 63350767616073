import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MainTitle = styled.p`
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--text-body);
  border-bottom: 1px solid var(--black-light);
`;

export const MainContent = styled.div`
  padding: 1rem;
`;

export const ControlContainer = styled.div`
  display: flex;
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--text-body-clean);

  p {
    text-align: center;
    max-width: 220px;
    width: 100%;
  }

  span {
    text-align: center;
    max-width: 80px;
    width: 100%;
  }

  div {
    margin-left: auto;
    width: 60px;
  }
`;

export const SectionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  max-height: 620px;
  overflow-y: auto;
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--text-body-clean);
  padding: 0.5rem;

  & + & {
    margin-top: 1rem;
  }

  p {
    text-align: center;
    max-width: 220px;
    width: 100%;
  }

  span {
    text-align: center;
    max-width: 80px;
    width: 100%;
  }

  div {
    margin-left: auto;
    width: 60px;
    display: flex;
    justify-content: space-between;

    svg {
      font-size: 20px;
      cursor: pointer;
    }
  }
`;
