import { Button } from '../../../components/Button';
import { ModalGeneric } from '../../../components/ModalGeneric';

interface CloneMarketModalConfirmProps {
  isModalOpenClone: boolean;
  setIsModalOpenClone: (v: boolean) => void;
}

export function CloneMarketModalConfirm({
  isModalOpenClone,
  setIsModalOpenClone,
}: CloneMarketModalConfirmProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalOpenClone}
      setIsModalOpen={setIsModalOpenClone}
    >
      <div className="grid grid-cols-1 gap-4" style={{ width: '350px' }}>
        <div className="w-full max-w-xs form-control">
          <label className="label">
            <strong className="label-text">Título do mercado</strong>
          </label>
          <input
            type="text"
            placeholder=""
            className="w-full max-w-xs input input-bordered"
          />
        </div>
        <br />
        <Button type="button" onClick={() => setIsModalOpenClone(false)}>
          Confirmar clone
        </Button>
        <button
          className="btn btn-ghost"
          onClick={() => setIsModalOpenClone(false)}
        >
          Cancelar
        </button>
      </div>
    </ModalGeneric>
  );
}
