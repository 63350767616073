import { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { FiX } from 'react-icons/fi';

import { Button } from '../../components/Button';
import { AppError } from '../../error/AppError';
import { CreateOrUpdatePositionCompany, OptionSelect } from '../../interfaces';
import { api } from '../../services/api';
import { Input } from '../Input';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  Select,
  FormContent,
  Error,
  SectionSelect,
} from './styles';

interface Props {
  closeModal: () => void;
  callback: (
    data: CreateOrUpdatePositionCompany,
    typeAction: 'update' | 'create',
  ) => void;
  positionCompany: CreateOrUpdatePositionCompany | null;
}

interface SubmitForm {
  description: string;
  title: string;
  code: string;
  level: {
    value: string;
  };
  tenant: {
    value: string;
  };
}

const ModalEditCompanyPosition = ({
  closeModal,
  callback,
  positionCompany,
}: Props) => {
  const [optionsTenants, setOptionTenants] = useState<OptionSelect[]>([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();

  const submit = (data: SubmitForm) => {
    const { description, code, tenant, title } = data;

    callback(
      {
        code,
        description,
        title,
        tenant: tenant?.value || '',
      },
      !!positionCompany ? 'update' : 'create',
    );
    closeModal();
  };

  const loadTenants = async () => {
    try {
      const {
        data: { data },
      } = await api.get('/tenants');
      const companys = data.map((company) => ({
        label: company.name,
        value: company.id,
      }));

      setOptionTenants(companys);
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  };

  useEffect(() => {
    loadTenants();
  }, []);

  return (
    <BackgroundContainerModal>
      <div>
        <HeaderModal>
          <p>Alterar cargo</p>
          <FiX onClick={closeModal} />
        </HeaderModal>
        <ContentModal>
          <Form onSubmit={handleSubmit(submit)}>
            <FormContent>
              <Input
                type="text"
                placeholder="Título"
                {...register('title', {
                  required: true,
                  value: positionCompany?.title,
                })}
                error={!!errors.description}
                helperText={!!errors.description ? 'Campo Obrigatório' : ''}
              />
              <Input
                type="text"
                placeholder="Descrição"
                {...register('description', {
                  value: positionCompany?.description as string,
                })}
                error={!!errors.description}
                helperText={!!errors.description ? 'Campo Obrigatório' : ''}
              />
              <Input
                type="text"
                placeholder="Código"
                {...register('code', {
                  value: positionCompany?.code,
                })}
              />
              {/* {optionsLevels.length > 0 && (
                <SectionSelect>
                  <Controller
                    name="level"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        defaultValue={optionsLevels.filter(
                          (option) =>
                            positionCompany?.position_level_id?.toLowerCase() ===
                            option.label.toLowerCase()
                        )}
                        options={optionsLevels}
                        isSearchable={false}
                        maxMenuHeight={150}
                        placeholder="Escolha um nível"
                      />
                    )}
                  />
                  {!!errors.title && <Error>Campo Obrigatório</Error>}
                </SectionSelect>
              )}
              {!positionCompany && (
                <div>
                  <Controller
                    name="tenant"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={optionsTenants}
                        isSearchable={false}
                        maxMenuHeight={150}
                        placeholder="Escolha um cargo da empresa"
                      />
                    )}
                  />
                  {!!errors.title && <Error>Campo Obrigatório</Error>}
                </div>
              )} */}
            </FormContent>
            <ContainerButton>
              <Button type="submit">
                {!!positionCompany ? 'Editar' : 'Enviar'}
              </Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
    </BackgroundContainerModal>
  );
};

export { ModalEditCompanyPosition };
