import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import { Button } from '../../components/Button';
import { Company, CreateOrUpdateCompany } from '../../interfaces';
import { removeMask } from '../../utils/removeMask';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  FormContent,
  InputWithMask,
  SectionInputMask,
  InputWrapper,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeUserPasswordErrors } from '../change-user-password-errors';

yup.addMethod(yup.string, 'strongPassword', function strongPasswordMethod() {
  return this.test('strongPasswordTest', '', function (value) {
    const { path, createError } = this;

    const errors: ValidationError[] = [];

    if (!/^(?=.*[a-z])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-lowercase-letter',
        }),
      );
    }

    if (!/^(?=.*[A-Z])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-uppercase-letter',
        }),
      );
    }

    if (!/^(?=.*[!@#$%^&*])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-special-character',
        }),
      );
    }

    if (errors.length > 0) {
      return new ValidationError(
        errors.map((error) => error.message).join(', '),
      );
    }

    return true;
  });
});

type Props = {
  closeModal: () => void;
  callback: (
    data: CreateOrUpdateCompany,
    typeAction: 'update' | 'create',
  ) => void;
  company?: Company | null | undefined;
  companyId?: string;
}

interface CreateOrUpdateCompanyData
  extends Pick<
    CreateOrUpdateCompany,
    | 'name'
    | 'trading_name'
    | 'phone'
    | 'cnpj'
    | 'email'
    | 'tenant_type'
    | 'is_on_market_statistic'
    | 'is_using_br_currency'
    | 'should_enable_mfa'
    | 'enable_fast_upload'
  > {
  user: {
    name: string;
    email: string;
    password?: string;
  };
  subsidiaries: string[] | undefined;
}

type SelectProps = {
  label: string;
  value: string;
}

const ModalAccountCompany = ({ closeModal, company, callback, companyId }: Props) => {
  const [passwordLengthError, setPasswordLengthError] = useState(true);
  const [uppercaseError, setUppercaseError] = useState(true);
  const [lowercaseError, setLowercaseError] = useState(true);
  const [specialCharacterError, setSpecialCharacterError] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const companySchema = yup.object({
    name: yup.string().required(),
    trading_name: yup.string().required(),
    phone: yup.string().required(),
    cnpj: yup.string().required(),
    email: yup.string().required(),
    tenant_type: yup.string().oneOf(["independent", "holding", "subsidiary"]).required(),
    is_on_market_statistic: yup.boolean().default(false),
    is_using_br_currency: yup.boolean().default(true).nullable(),
    should_enable_mfa: yup.boolean().default(true).nullable(),
    enable_fast_upload: yup.boolean().default(false),
    user: yup.object({
      name: yup.string().required(),
      email: yup.string().required(),
      password: !company
        ? yup
          .string()
          .min(8, 'password-min-length')
          .max(16, 'password-max-length')
          .strongPassword()
          .required()
        : yup.string(),
    }),
    subsidiaries: yup.array().of(yup.string().required()),
  });

  const {
    setValue,
    register,
    handleSubmit,
    control,
    formState: { errors, touchedFields },
  } = useForm<CreateOrUpdateCompanyData>({
    resolver: yupResolver(companySchema),
  });

  const submit = async (data: any) => {
    await callback(
      {
        ...data,
        cnpj: removeMask(data.cnpj),
        phone: removeMask(data.phone),
      },
      company ? 'update' : 'create',
    );
    closeModal();
  };

  async function testValidate(value: string) {
    try {
      await companySchema.validate(
        {
          user: {
            password: value,
          },
        },
        { abortEarly: false },
      );
    } catch (e: any) {
      const errors = (e as ValidationError).errors;
      if (
        String(errors).includes('password-min-length') ||
        String(errors).includes('password-max-length')
      ) {
        setPasswordLengthError(true);
      } else {
        setPasswordLengthError(false);
      }

      if (String(errors).includes('password-uppercase-letter')) {
        setUppercaseError(true);
      } else {
        setUppercaseError(false);
      }

      if (String(errors).includes('password-lowercase-letter')) {
        setLowercaseError(true);
      } else {
        setLowercaseError(false);
      }

      if (String(errors).includes('password-special-character')) {
        setSpecialCharacterError(true);
      } else {
        setSpecialCharacterError(false);
      }
    }
  }

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      <Form
        autoComplete="off"
        onSubmit={handleSubmit(submit)}
        style={{ minWidth: '700px', minHeight: '50vh' }}
      >
        <div className="max-w-[1000px]">
          <HeaderModal>
            <p>Empresa</p>
          </HeaderModal>
          <ContentModal>
            <FormContent>
              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">Nome da empresa</strong>
                  </label>
                  <input
                    type="text"
                    className="w-full input input-bordered"
                    placeholder="Nome da empresa"
                    {...register('name', {
                      required: true,
                      value: company?.name,
                    })}
                    // error={!!errors.email}
                    // helperText={!!errors.email ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.name ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">Nome Comercial</strong>
                  </label>
                  <input
                    type="text"
                    className="w-full input input-bordered"
                    placeholder="Nome Comercial"
                    {...register('trading_name', {
                      required: true,
                      value: company?.trading_name,
                    })}
                    // error={!!errors.trading_name}
                    // helperText={
                    //   !!errors.trading_name ? 'Campo Obrigatório' : ''
                    // }
                  />
                </div>
                <Content>
                  {!!errors.trading_name ? 'Campo Obrigatório' : ''}
                </Content>
              </div>

              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">E-mail</strong>
                  </label>
                  <input
                    type="email"
                    className="w-full input input-bordered"
                    placeholder="E-mail"
                    {...register('email', {
                      required: true,
                      value: company?.email,
                    })}
                    // error={!!errors.email}
                    // helperText={!!errors.email ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.email ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">Nome do usuário</strong>
                  </label>
                  <input
                    type="text"
                    className="w-full input input-bordered"
                    placeholder="Nome do usuário"
                    {...register('user.name', {
                      required: true,
                      value: company?.user?.name,
                    })}
                    // error={!!errors.user?.name}
                    // helperText={!!errors.user?.name ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.user?.name ? 'Campo Obrigatório' : ''}
                </Content>
              </div>

              <div className="flex-auto w-full">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">Telefone</strong>
                  </label>
                  <SectionInputMask>
                    <InputWithMask isErrored={!!errors.phone}>
                      <Controller
                        name="phone"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={company?.phone}
                        render={({ field }) => (
                          <InputMask
                            mask="(99) 99999-9999"
                            placeholder="Telefone"
                            {...field}
                          />
                        )}
                      />
                    </InputWithMask>
                    {errors.phone && <p>Campo Obrigatório</p>}
                  </SectionInputMask>
                </div>
                {/* <Content>{!!errors.user ? 'Campo Obrigatório' : ''}</Content> */}
              </div>

              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">E-mail do usuário</strong>
                  </label>

                  <input
                    type="email"
                    placeholder="E-mail do usuário"
                    className="w-full input input-bordered"
                    {...register('user.email', {
                      required: true,
                      value: company?.user?.email,
                    })}
                    // error={!!errors.user?.email}
                    // helperText={!!errors.user?.email ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.user?.email ? 'Campo Obrigatório' : ''}
                </Content>
              </div>

              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">CNPJ</strong>
                  </label>

                  <SectionInputMask>
                    <InputWithMask isErrored={!!errors.cnpj}>
                      <Controller
                        name="cnpj"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={company?.cnpj}
                        render={({ field }) => (
                          <InputMask
                            mask="99.999.999/9999-99"
                            placeholder="CNPJ"
                            {...field}
                          />
                        )}
                      />
                    </InputWithMask>
                    {errors.cnpj && <p>Campo Obrigatório</p>}
                  </SectionInputMask>
                </div>
                {/* <Content>{!!errors.name ? 'Campo Obrigatório' : ''}</Content> */}
              </div>

              {!company && (
                <div className="flex-auto">
                  <div className="w-full form-control">
                    <label className="label">
                      <strong className="label-text">Senha do usuário</strong>
                    </label>

                    <InputWrapper>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        className="w-full input input-bordered"
                        placeholder="Senha do usuário"
                        maxLength={16}
                        {...register('user.password', {
                          required: company ? false : true,
                          value: '',
                        })}
                        onChange={(e) => {
                          setValue('user.password', e.target.value, {
                            shouldValidate: true,
                          });
                          testValidate(e.target.value);
                        }}
                      />
                      {showPassword ? (
                        <FiEyeOff
                          size={20}
                          onClick={() => setShowPassword(false)}
                          className=''
                        />
                      ) : (
                        <FiEye
                          size={20}
                          onClick={() => setShowPassword(true)}
                        />
                      )}
                    </InputWrapper>
                  </div>
                  <Content>
                    {!!errors.user?.password ? 'Campo Obrigatório' : ''}
                  </Content>
                  {touchedFields.user?.password && (
                    <ChangeUserPasswordErrors
                      passwordLengthError={passwordLengthError}
                      uppercaseError={uppercaseError}
                      lowercaseError={lowercaseError}
                      specialCharacterError={specialCharacterError}
                    />
                  )}
                </div>
              )}
              {company && <div></div>}
            </FormContent>

            <div className="divider"></div>

            <FormContent>
              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">Tipo da empresa</strong>
                  </label>

                  <select
                    className="w-[200px] select select-bordered"
                    defaultValue="independent"
                    {...register("tenant_type", {
                      required: true,
                      value: company?.tenant_configurations?.[0]?.tenant_type,
                    })}
                  >
                    <option>Tipo da empresa</option>
                    <option value="independent" key="independent">
                      Independente
                    </option>
                    <option value="holding" key="holding">
                      Holding
                    </option>
                    <option value="subsidiary" key="subsidiary">
                      Filial
                    </option>
                  </select>
                </div>
                <Content>{!!errors.tenant_type ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <div className="flex-auto">
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">
                      Participa do mercado?
                    </strong>

                    <input
                      type="checkbox"
                      className="w-6 max-w-xs input input-bordered"
                      {...register('is_on_market_statistic', {
                        required: false,
                        value: company ? company?.is_on_market_statistic : true,
                      })}
                    />
                  </label>
                </div>
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">
                      Utiliza moeda brasileira?
                    </strong>

                    <input
                      type="checkbox"
                      className="w-6 max-w-xs input input-bordered"
                      {...register('is_using_br_currency', {
                        required: false,
                        value: company
                          ? company?.tenant_configurations?.[0]
                            ?.is_using_br_currency
                          : true,
                      })}
                    />
                  </label>
                </div>
                <div className="w-full form-control">
                  <label className="label">
                    <strong className="label-text">
                      Ativar MFA
                    </strong>

                    <input
                      type="checkbox"
                      className="w-6 max-w-xs input input-bordered"
                      {...register('should_enable_mfa', {
                        required: false,
                        value: company
                          ? company?.tenant_configurations?.[0]
                            ?.should_enable_mfa
                          : true,
                      })}
                    />
                  </label>
                </div>
                {/* <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Fast Upload ?</strong>

                    <input
                      type="checkbox"
                      className="w-6 max-w-xs input input-bordered"
                      {...register('enable_fast_upload', {
                        required: false,
                        value: company ? company?.enable_fast_upload : true,
                      })}
                    />
                  </label>
                </div> */}
              </div>
            </FormContent>
            {/* <ContainerButton>
              <Button type="submit">{!!company ? 'Editar' : 'Enviar'}</Button>
            </ContainerButton> */}
          </ContentModal>
        </div>
        <ContainerButton>
          <Button type="submit">{!!company ? 'Editar' : 'Enviar'}</Button>
        </ContainerButton>
      </Form>
    </Modal>
  );
};

export { ModalAccountCompany };
