import { useEffect, useState, useCallback } from 'react';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';

import {
  FiSearch,
  FiRefreshCw,
  FiArrowLeft,
  FiArrowRight,
  FiAlertTriangle,
  FiEye,
  FiPlus,
  FiXCircle,
} from 'react-icons/fi';
import { FcHighPriority } from 'react-icons/fc';
import makeAnimated from 'react-select/animated';
import { Button } from '../../components/Button';
import {
  Container,
  Form,
  ContainerButton,
  Content,
  DatabaseData,
  IconAlert,
  IconFiArrowRight,
  Pagination,
  ArrowLeft,
  ArrowRight,
} from './styles';
import { api } from '../../services/api';
import { Tenant, OptionSelect, MarketStatistic } from '../../interfaces';
import { ModalGeneric } from '../../components/ModalGeneric';
import { toast } from 'react-toastify';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Input } from '../../components/Input';
import { ButtonPrimary } from '../DashboardUsers/styles';
import { MarketStatisticStatus } from '../../enums/market';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { AppError } from '../../error/AppError';
import {
  MarketItemDataCustom,
  ModalMarketSmoothing,
} from '../../components/ModalMarketSmoothing';
import { formatDateFull } from '../../shared/utils';
import { MarketTypes } from '../../shared/types';
import { SubmitCreateMarketForm } from './CreateMarketModal/types';
import { CreateMarketModal } from './CreateMarketModal';
import { PublishMarketModal } from './PublishMarketModal';
import { ConfirmPublishMarketModal } from './ConfirmPublishMarketModal';
import { ConfirmProcessMarketModal } from './ConfirmProcessMarketModal';
import { MarketErrorModal } from './MarketErrorModal';
import { CloneMarketModalConfirm } from './CloneMarketModalConfirm';
import { MarketParametersEditConfirmModal } from './MarketParametersEditConfirmModal';
import { MarketPermissionModal } from './MarketPermissionModal';
import { truncate } from 'fs/promises';
import { HttpStatus } from '../../enums/http-status';

const animatedComponents = makeAnimated();

interface selectProps {
  label: string;
  value: string;
}

export interface ItemsVisibility {
  type: 'community' | 'sector' | 'internal';
  value: string;
  id: string;
}

export default function MarketConfiguration() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [marketProcess, setMarketProcess] = useState<MarketStatistic | null>(
    null,
  );
  const [marketId, setMarketId] = useState('');
  const [marketType, setMarketType] = useState<MarketTypes | null>(null);
  const [isModalOpenReprocess, setIsModalOpenReprocess] = useState(false);
  const [isModalOpenPublicar, setIsModalOpenPublicar] = useState(false);
  const [isModalOpenDespublicar, setIsModalOpenDespublicar] = useState(false);
  const [isModalOpenClone, setIsModalOpenClone] = useState(false);
  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const [dataMarketPermission, setDataMarketPermission] = useState([]);
  const [setores, setSetores] = useState<OptionSelect[]>([]);
  const [companies, setCompanies] = useState<OptionSelect[]>([]);
  const [family, setFamily] = useState<selectProps[]>([]);
  const [familySelected, setFamilySelected] = useState<any>([]);
  const [position, setPosition] = useState<selectProps[]>([]);
  const [familyFilter, setFamilyFilter] = useState('');
  const [visibility, setVisibility] = useState('');

  const [visibilityPermission, setVisibilityPermission] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [selectedOptionsSetores, setSelectedOptionsSetores] = useState([
    { label: 'Todos', value: '' },
  ]);
  const [clearTodos, setClearTodos] = useState(true);
  const [clearTodosSetores, setClearTodosSetores] = useState(true);
  const [selectedOptionsFamilia, setSelectedOptionsFamilia] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [clearTodosFamilia, setClearTodosFamilia] = useState(true);
  const [selectedOptionsCargos, setSelectedOptionsCargos] = useState([
    { label: 'N/A', value: '' },
  ]);
  const [clearTodosCargos, setClearTodosCargos] = useState(true);
  const [selectedOptionsNiveis, setSelectedOptionsNiveis] = useState([
    { label: 'Todos', value: '' },
  ]);
  const [clearTodosNiveis, setClearTodosNiveis] = useState(true);
  const [selectedOptionsCompanies, setSelectedOptionsCompanies] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [clearTodosCompanies, setClearTodosCompanies] = useState(true);
  const [marketList, setMarketList] = useState<MarketStatistic[]>([]);
  const [isCreate, setIsCreate] = useState(true);
  const [marketData, setMarketData] = useState<MarketStatistic | null>(null);
  const [monthData, setMonthData] = useState<any>([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [isModalOpenErrorInfo, setIsModalOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [disableInput, setDisableInput] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPagePositions, setLastPagePositions] = useState(1);
  const [statusProcess, setStatusProcess] = useState<any>('not_processed');
  const [isModalConfirmEdit, setIsModalConfirmEdit] = useState(false);
  const [isType, setIsType] = useState('');
  const [isReload, setIsReload] = useState(true);
  const [timer, setTimer] = useState(800000);
  const [timerReload, setTimerReload] = useState(10000);
  const [exclusaoInclusao, setExclusaoInclusao] = useState(0);
  const [showClose, setShowClose] = useState<boolean>(true);
  const [itemsAddPermission, setItemsAddPermission] = useState<
    ItemsVisibility[]
  >([]);
  const [tenantsShowAllPositions, setTenantsShowAllPositions] = useState<
    string[]
  >([]);
  const [sectorsShowAllPositions, setSectorsShowAllPositions] = useState<
    string[]
  >([]);

  const [companyPermission, setCompanyPermission] = useState({
    id: '',
    value: '',
  });
  const [sectorPermission, setSectorPermission] = useState({
    id: '',
    value: '',
  });

  const [companiesUsingMarketCount, setCompaniesUsingMarketCount] = useState<
    number | undefined
  >(undefined);
  const [
    isLoadingCheckCompaniesUsingMarket,
    setIsLoadingCheckCompaniesUsingMarket,
  ] = useState(false);

  const [selectedAction, setSelectedAction] = useState('');

  const levels = [
    { value: '', label: 'Todos' },
    { value: 'N0', label: 'N0' },
    { value: 'N1', label: 'N1' },
    { value: 'N2', label: 'N2' },
    // { value: 'Não valiados', label: 'Não avaliados' },
  ];

  async function loadCompanies() {
    const { data } = await api.get<Tenant[]>('/tenants?paginate=false');
    const tenants = data.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }));

    setCompanies([{ label: 'Todas', value: '' }, ...tenants]);
  }

  async function loadSetores() {
    const { data } = await api.get<Tenant[]>('/sectors/titles?paginate=false');
    const tenants = data.map((tenant) => ({
      label: tenant.title,
      value: tenant.id,
    }));
    setSetores([{ label: 'Todos', value: '' }, ...tenants]);
  }

  async function loadMonths() {
    const monthNames = [
      'janeiro|01',
      'fevereiro|02',
      'março|03',
      'abril|04',
      'maio|05',
      'junho|06',
      'julho|07',
      'agosto|08',
      'setembro|09',
      'outubro|10',
      'novembro|11',
      'dezembro|12',
    ];
    setMonthData(monthNames);
  }

  async function loadCargos() {
    let familiesFilter = '';
    await family.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesFilter += family.label + ',';
      }
    });

    const { data } = await api.get(`/smartpay/positions?${familiesFilter}`);
    const positionsList = data.map((pos) => {
      return {
        label: pos.title,
        value: pos.id,
      };
    });
    setPosition([{ label: 'N/A', value: '' }, ...positionsList]);
  }

  async function loadFamilies() {
    const { data } = await api.get('/smartpay/positions/subfamilies');

    const auxFamily: Array<string> = [];
    const families: any = data
      .map((family) => {
        const id = family.position_family.id as string;
        if (!auxFamily.includes(id)) {
          auxFamily.push(id);
          return {
            label: family.position_family.title,
            value: family.position_family.id,
          };
        }

        return null;
      })
      .filter((boolean) => boolean);

    setFamily([{ label: 'Todas', value: '' }, ...families]);
    loadCargos();
  }

  async function loadPositions() {
    if (familyFilter) {
      const filterFamily = `families=${familyFilter}`;
      const { data } = await api.get(`/smartpay/positions?${filterFamily}`);
      const positionsList = data.map((pos) => {
        return {
          label: pos.title,
          value: pos.id,
        };
      });
      setPosition([{ label: 'N/A', value: '' }, ...positionsList]);
    }
  }

  async function selectNiveis(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todos') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todos') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosNiveis) {
      setClearTodosNiveis(true);
      setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
    } else {
      setClearTodosNiveis(false);
      setSelectedOptionsNiveis(dados);
    }
  }

  async function selectFamily(data: any) {
    setFamilySelected(data);
    setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosFamilia) {
      setClearTodosFamilia(true);
      setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodosFamilia(false);
      setSelectedOptionsFamilia(dados);
    }

    let familiesAll = '';
    await family.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesAll += family.label + ',';
      }
    });

    let familiesFilter = '';
    await data.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesFilter += family.label + ',';
      }
    });

    await setFamilyFilter(
      dados2 && !clearTodosFamilia
        ? familiesAll.substring(0, familiesAll.length - 1)
        : familiesFilter.substring(0, familiesFilter.length - 1),
    );
  }

  function selectCargos(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'N/A') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'N/A') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosCargos) {
      setClearTodosCargos(true);
      setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
    } else {
      setClearTodosCargos(false);
      setSelectedOptionsCargos(dados);
    }
  }

  function selectCompanies(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosCompanies) {
      setClearTodosCompanies(true);
      setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodosCompanies(false);
      setSelectedOptionsCompanies(dados);
    }
  }

  function saveConfiguration() {
    toast.error('Validação de todas as regras');
  }

  const handleChange = async (options) => {
    const dados = options.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      }
    });

    let dados2 = false;
    options.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodos) {
      setClearTodos(true);
      setSelectedOptions([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodos(false);
      setSelectedOptions(dados);
    }
  };

  const handleChangeSetores = async (options) => {
    const dados = options.map(function (itens, i) {
      if (itens.label !== 'Todos') {
        return itens;
      }
    });

    let dados2 = false;
    options.map(function (itens, i) {
      if (itens.label === 'Todos') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosSetores) {
      setClearTodosSetores(true);
      setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
    } else {
      setClearTodosSetores(false);
      setSelectedOptionsSetores(dados);
    }
  };

  // function handleSubmit() {
  //   toast.error('handleSubmit');
  // }

  function getDefaultValue() {
    return { label: 'Todas', value: '' };
  }

  async function listMarket(currentPage) {
    try {
      const limit = 10;
      handleSetIsLoading(true);
      setMarketList([]);
      const { data: dataMarket, status } = await api.get<{
        data: MarketStatistic[];
        last_page: number;
      }>(`/markets/statistics/?page=${currentPage}&limit=${limit}`);
      const dados = dataMarket.data;
      setMarketList(dados);
      setLastPagePositions(dataMarket.last_page);
      handleSetIsLoading(false);
      setShowClose(true);
    } catch (err) {
      handleSetIsLoading(false);
      toast.error('Erro ao buscar dados do Mercado!');
    }
  }

  function handleProccess(market: MarketStatistic) {
    setIsModalOpenReprocess(true);
    setMarketProcess(market);
  }

  function handlePublicar(market: any) {
    setIsModalOpenPublicar(true);
    setMarketId(market.id);
    setMarketType(market.type);
  }

  function handleDespublicar(market: any) {
    setIsModalOpenDespublicar(true);
    setMarketId(market.id);
    setMarketType(market.type);
    handleCheckCompaniesUsingMarket(market.id);
  }

  async function handleProccessConfirmed(id: string) {
    try {
      const { data: dataProcess } = await api.post(
        `/markets/statistics/${id}/process`,
      );
      toast.success('Processo criado com sucesso.');
      listMarket(currentPage);
    } catch (err) {
      toast.error('Erro ao realizar o processar!');
    }
  }

  async function handleCheckCompaniesUsingMarket(market_id: number) {
    try {
      setIsLoadingCheckCompaniesUsingMarket(true);
      const retorno: any = await api.get(
        `/markets/statistics/${market_id}/check-using`,
      );

      if (retorno.data.length) {
        setCompaniesUsingMarketCount(retorno.data.length);
      }
    } catch (err) {
      toast.error('Erro ao verfificar as empresas usando este mercado!');
    } finally {
      setIsLoadingCheckCompaniesUsingMarket(false);
    }
  }

  async function handleProccessDespublicarOk() {
    try {
      const parseData = {
        status: false,
      };

      const retorno = await api.put(
        `/markets/statistics/${marketId}/publish`,
        parseData,
      );

      if (retorno?.data?.data?.status === 'unpublished') {
        toast.success('Processo despublicado com sucesso.');
      }

      listMarket(currentPage);
    } catch (err) {
      toast.error('Erro ao despublicar o processo.');
    }
  }

  function getMarketPtStatus(status: string) {
    if (status === MarketStatisticStatus.NOT_PROCESSED.valueOf()) {
      return 'Não Processado';
    }
    if (status === MarketStatisticStatus.PROCESSED.valueOf()) {
      return 'Processado';
    }
    if (status === MarketStatisticStatus.PROCESSING.valueOf()) {
      return 'Processando';
    }
    if (status === MarketStatisticStatus.INTERPOLATED.valueOf()) {
      return 'Suavizado';
    }
    if (status === MarketStatisticStatus.PUBLISHED.valueOf()) {
      return 'Publicado';
    }
    if (status === MarketStatisticStatus.IN_QUEUE.valueOf()) {
      return 'Em Fila';
    }
    return '';
  }

  async function handlUpdatePermissions(permissions: any) {
    try {
      const response = await api.patch(
        `/markets/statistics/${marketId}/visibility`,
        permissions,
      );

      if (response.status === HttpStatus.OK.valueOf()) {
        toast.success('Permissões atualizadas');
        listMarket(currentPage);

        setIsModalOpenPermission(false);
        setVisibilityPermission('');
        setItemsAddPermission([]);
        setMarketId('');
        setMarketType(null);
        setTenantsShowAllPositions([]);
        setSectorsShowAllPositions([]);
      }
    } catch (err) {
      toast.success('Erro ao tentar atualizar permissões do mercado!');
    }
  }

  function handleViewPermissions(market: MarketStatistic) {
    setVisibilityPermission('');
    setItemsAddPermission([]);
    setMarketId(market.id);
    setMarketType(market.type);
    setTenantsShowAllPositions(market.tenants_show_all_positions);
    setSectorsShowAllPositions(market.sectors_show_all_positions);

    if (market.visibility === 'community') {
      const companiesVisibility = companies.filter((c) =>
        market.tenants_visibility.includes(c.value),
      );

      if (!companiesVisibility.length) {
        setItemsAddPermission([{ type: 'community', value: '', id: '' }]);
      } else {
        const communityItems: ItemsVisibility[] = companiesVisibility.map(
          (c) => ({ type: 'community', value: c?.label, id: c?.value }),
        );
        setItemsAddPermission(communityItems);
      }
    } else if (market.visibility === 'sector') {
      const sectorsVisibility = setores.filter((c) =>
        market.sectors_visibility.includes(c.value),
      );

      if (!sectorsVisibility.length) {
        setItemsAddPermission([{ type: 'sector', value: '', id: '' }]);
      } else {
        const sectorsItems: ItemsVisibility[] = sectorsVisibility.map((c) => ({
          type: 'sector',
          value: c?.label,
          id: c?.value,
        }));
        setItemsAddPermission(sectorsItems);
      }
    } else if (market.visibility === 'internal') {
      const v4Id = uuidv4();
      setItemsAddPermission([{ type: 'internal', value: '', id: v4Id }]);
    }

    setIsModalOpenPermission(true);
  }

  function afterPublish() {
    setIsModalOpenPermission(true);
    setShowClose(true);
    setIsModalOpenPublicar(false);
  }

  const handleChangeAction = useCallback((type: string, market) => {
    setMarketId(market?.id);

    switch (type) {
      case 'edit_not_processed':
        setIsModalOpen(true);
        setIsCreate(false);
        setMarketData(market);
        // handleSelect(market);
        setDisableInput(false);
        setStatusProcess(market.status);
        setVisibility('');
        setIsReload(false);
        break;
      case 'edit_processed':
        setIsCreate(false);
        // handleSelect(market);
        setDisableInput(false);
        setMarketData(market);
        setStatusProcess(market.status);
        setVisibility('');
        setIsModalConfirmEdit(true);
        setIsReload(false);
        break;
      case 'soften':
        navigate(`/market-smoothing/${market.id}`);
        break;
      case 'process_or_reprocess':
        handleProccess(market);
        break;
      case 'publish':
        handlePublicar(market);
        break;
      case 'unpublish':
        handleDespublicar(market);
        break;
      case 'delete':
        break;
      default:
        break;
    }
  }, []);

  const handleBackToActionInitial = useCallback(() => {
    setSelectedAction('Ações');
  }, [setSelectedAction]);

  useEffect(() => {
    loadPositions();
  }, [familyFilter]);

  useEffect(() => {
    isModalOpen || isModalOpenPublicar ? setTimer(0) : setTimer(8000000);
  }, [isModalOpen, isModalOpenPublicar]);

  useEffect(() => {
    isModalConfirmEdit ? setTimer(0) : setTimer(8000000);
  }, [isModalConfirmEdit]);

  useEffect(() => {
    loadMonths();
    listMarket(currentPage);
    loadCompanies();
    loadSetores();
    loadFamilies();
  }, [currentPage]);

  return (
    <Container>
      <>
        <ConfirmPublishMarketModal
          isModalOpenDespublicar={isModalOpenDespublicar}
          setIsModalOpenDespublicar={setIsModalOpenDespublicar}
          handleProccessDespublicarOk={handleProccessDespublicarOk}
          setIsModalOpenReprocess={setIsModalOpenReprocess}
        />
        <PublishMarketModal
          isModalOpenPublicar={isModalOpenPublicar}
          setIsModalOpenPublicar={(e) => {
            setIsModalOpenPublicar(e);
            handleBackToActionInitial();
          }}
          marketId={marketId}
          marketType={marketType}
          afterPublish={afterPublish}
        />
        <ConfirmProcessMarketModal
          isModalOpenReprocess={isModalOpenReprocess}
          setIsModalOpenReprocess={(e) => {
            setIsModalOpenReprocess(e);
            handleBackToActionInitial();
          }}
          marketProcess={marketProcess}
          handleProccessConfirmed={handleProccessConfirmed}
        />
        <MarketErrorModal
          isModalOpenErrorInfo={isModalOpenErrorInfo}
          setIsModalOpenErrorInfo={setIsModalOpenErrorInfo}
          errorInfo={errorInfo}
        />
        <CloneMarketModalConfirm
          isModalOpenClone={isModalOpenClone}
          setIsModalOpenClone={setIsModalOpenClone}
        />
        <MarketParametersEditConfirmModal
          isModalConfirmEdit={isModalConfirmEdit}
          setIsModalConfirmEdit={(e) => {
            setIsModalConfirmEdit(e);
            handleBackToActionInitial();
          }}
          setIsModalOpen={setIsModalOpen}
        />
        <CreateMarketModal
          isModalOpen={isModalOpen}
          setIsModalOpen={(e) => {
            setIsModalOpen(e);
            handleBackToActionInitial();
          }}
          statusProcess={statusProcess}
          disableInput={disableInput}
          marketData={marketData}
          monthData={monthData}
          setIsType={setIsType}
          setVisibility={setVisibility}
          visibility={visibility}
          companies={companies}
          selectedOptionsCompanies={selectedOptionsCompanies}
          selectCompanies={selectCompanies}
          selectedOptionsNiveis={selectedOptionsNiveis}
          levels={levels}
          selectNiveis={selectNiveis}
          family={family}
          selectedOptionsFamilia={selectedOptionsFamilia}
          selectFamily={selectFamily}
          exclusaoInclusao={exclusaoInclusao}
          setExclusaoInclusao={setExclusaoInclusao}
          position={position}
          selectedOptionsCargos={selectedOptionsCargos}
          selectCargos={selectCargos}
          selectedOptions={selectedOptions}
          handleChange={handleChange}
          clearTodos={clearTodos}
          isType={isType}
          setores={setores}
          selectedOptionsSetores={selectedOptionsSetores}
          handleChangeSetores={handleChangeSetores}
          isCreate={isCreate}
          clearTodosSetores={clearTodosSetores}
          familyFilter={familyFilter}
          clearTodosNiveis={clearTodosNiveis}
          clearTodosCompanies={clearTodosCompanies}
          setSelectedOptionsSetores={setSelectedOptionsSetores}
          setSelectedOptionsCompanies={setSelectedOptionsCompanies}
          setSelectedOptionsFamilia={setSelectedOptionsFamilia}
          setSelectedOptionsCargos={setSelectedOptionsCargos}
          setSelectedOptions={setSelectedOptions}
          setSelectedOptionsNiveis={setSelectedOptionsNiveis}
          listMarket={listMarket}
          currentPage={currentPage}
          setMarketData={setMarketData}
        />
        <MarketPermissionModal
          marketType={marketType}
          isModalOpenPermission={isModalOpenPermission}
          setIsModalOpenPermission={setIsModalOpenPermission}
          showClose={showClose}
          setVisibilityPermission={setVisibilityPermission}
          visibilityPermission={visibilityPermission}
          tenantsShowAllPositions={tenantsShowAllPositions}
          setTenantsShowAllPositions={setTenantsShowAllPositions}
          sectorsShowAllPositions={sectorsShowAllPositions}
          setSectorsShowAllPositions={setSectorsShowAllPositions}
          companies={companies}
          setores={setores}
          itemsAddPermission={itemsAddPermission}
          setItemsAddPermission={setItemsAddPermission}
          companyPermission={companyPermission}
          sectorPermission={sectorPermission}
          setCompanyPermission={setCompanyPermission}
          setSectorPermission={setSectorPermission}
          handlUpdatePermissions={handlUpdatePermissions}
        />
      </>
      <h2 className="text-2xl font-bold">Mercado</h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 shadow-xl card bg-base-100">
          <div className="flex justify-end">
            <ButtonPrimary
              onClick={() => {
                setIsCreate(true);
                setIsModalOpen(true);
                setIsReload(false);
                setMarketData(null);
                // reset();
                setDisableInput(false);
                setStatusProcess('not_processed');
                setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
                setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
                setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
                setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
                setSelectedOptions([{ label: 'Todas', value: '' }]);
                setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
              }}
              className="mb-5"
            >
              Criar novo mercado
            </ButtonPrimary>
          </div>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>
                    <button
                      className="flex-1 btn btn-sm btn-success"
                      onClick={() => {
                        listMarket(currentPage);
                      }}
                    >
                      <FiRefreshCw style={{ cursor: 'pointer' }} />
                    </button>
                  </th>
                  <th colSpan={3}></th>
                  <th>Título do mercado</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>Data base</th>
                  <th>Metodologia de cálculo</th>
                  <th>Data processamentos</th>
                  <th>Usuário processamento</th>
                  <th style={{ width: '15px' }}></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {marketList && (
                  <>
                    {marketList.map((market, index) => (
                      <tr key={index}>
                        <td>
                          <FiSearch
                            style={{ cursor: 'pointer' }}
                            onClick={(e: any) => {
                              setIsModalOpen(true);
                              setIsCreate(false);
                              setMarketData(market);
                              // handleSelect(market);
                              setDisableInput(true);
                            }}
                          />
                        </td>
                        <td>
                          <FiEye
                            style={{ cursor: 'pointer' }}
                            onClick={(e: any) => {
                              handleViewPermissions(market);
                            }}
                          />
                        </td>
                        <td>
                          <button
                            disabled={
                              ['processing', 'not_processed'].includes(
                                market.status,
                              )
                                ? true
                                : false
                            }
                            className="flex-1 btn btn-sm btn-success"
                            onClick={() =>
                              navigate(`/market-intermiate-data/${market.id}`)
                            }
                          >
                            Ver QA
                          </button>
                        </td>
                        <td>
                          <button
                            className="flex-1 btn btn-sm btn-success"
                            disabled={
                              ['processing', 'not_processed'].includes(
                                market.status,
                              )
                                ? true
                                : false
                            }
                            onClick={() =>
                              navigate(`/market-result-data/${market.id}`)
                            }
                          >
                            Ver Publicado
                          </button>
                        </td>
                        <td>{market.name}</td>
                        <td>
                          {market.type === 'grade'
                            ? 'SmrtGrade'
                            : market.type === 'position'
                            ? 'SmrtCargo'
                            : market.type === 'position-grade'
                            ? 'SmrtCargo/Grade'
                            : ''}
                        </td>
                        <td>{getMarketPtStatus(market.status)}</td>
                        <td>{`${market.month_reference} ${market.year_reference} `}</td>
                        <td>
                          {market.calculation_method == 'percentile'
                            ? 'Percentis'
                            : 'Média'}
                        </td>
                        <td>{formatDateFull(market.processed_date)}</td>
                        <td>{market.super_admin_created.name}</td>
                        <td>
                          {market.has_error && (
                            <FcHighPriority
                              style={{
                                cursor: 'pointer',
                                fontSize: 20,
                              }}
                              onClick={(e: any) => {
                                setIsModalOpenErrorInfo(true);
                                setErrorInfo(market.error || '');
                              }}
                            />
                          )}
                        </td>
                        <td>
                          <select
                            value={
                              market?.id === marketId ? selectedAction : ''
                            }
                            className="w-[150px] select select-bordered"
                            onChange={(e) => {
                              handleChangeAction(e.target.value, market);
                              setSelectedAction(e.target.value);
                            }}
                          >
                            <option>Ações</option>
                            {market.status !==
                            MarketStatisticStatus.PUBLISHED.valueOf() ? (
                              <>
                                {market.status === 'not_processed' ? (
                                  <option
                                    value="edit_not_processed"
                                    key="edit_not_processed"
                                  >
                                    Editar
                                  </option>
                                ) : (
                                  <option
                                    value="edit_processed"
                                    key="edit_processed"
                                  >
                                    Editar
                                  </option>
                                )}
                              </>
                            ) : null}
                            <option
                              value="soften"
                              key="soften"
                              disabled={
                                ['processing', 'not_processed'].includes(
                                  market.status,
                                ) || market.type === 'position'
                                  ? true
                                  : false
                              }
                            >
                              Suavizar
                            </option>
                            <option
                              value="process_or_reprocess"
                              key="process_or_reprocess"
                              disabled={[
                                MarketStatisticStatus.PUBLISHED.valueOf(),
                                MarketStatisticStatus.PROCESSING.valueOf(),
                              ].includes(market.status)}
                            >
                              {market.status ===
                              MarketStatisticStatus.NOT_PROCESSED.valueOf()
                                ? 'Processar'
                                : 'Reprocessar'}
                            </option>
                            {market.status !==
                            MarketStatisticStatus.PUBLISHED.valueOf() ? (
                              <option
                                value="publish"
                                key="publish"
                                disabled={
                                  (!(
                                    market.status ===
                                    MarketStatisticStatus.INTERPOLATED.valueOf()
                                  ) &&
                                    market.type === 'grade') ||
                                  (!(
                                    market.status ===
                                    MarketStatisticStatus.PROCESSED.valueOf()
                                  ) &&
                                    market.type === 'position')
                                }
                              >
                                Publicar
                              </option>
                            ) : market.status ===
                              MarketStatisticStatus.PUBLISHED.valueOf() ? (
                              <option
                                value="unpublish"
                                key="unpublish"
                                disabled={
                                  market.status ===
                                  MarketStatisticStatus.NOT_PROCESSED.valueOf()
                                    ? true
                                    : false
                                }
                              >
                                Despublicar
                              </option>
                            ) : null}
                          </select>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination>
        <ArrowLeft
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FiArrowLeft />
        </ArrowLeft>
        <span>{currentPage}</span>
        <ArrowRight
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === lastPagePositions}
        >
          <FiArrowRight />
        </ArrowRight>
      </Pagination>
    </Container>
  );
}
