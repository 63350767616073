import { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import {
  Container,
  Content,
  SectionFilter,
  SectionListUsers,
  ItemUser,
  MainTitle,
  MainContent,
  ArrowLeft,
  ArrowRight,
  Pagination,
  HeaderListUsers,
  Select,
  NoUsers,
} from './styles';
import { useAdmin } from '../../contexts/AdminContext';
import { api } from '../../services/api';
import { AppError } from '../../error/AppError';
import { OptionSelect } from '../../interfaces';

const DashboardContributors = () => {
  const [options, setOptions] = useState<OptionSelect[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionSelect>();

  const { pagination, getContributors, contributors } = useAdmin();

  const [currentPage, setCurrentPage] = useState(1);

  const loadTenants = async () => {
    try {
      const { data } = await api.get('/tenants?paginate=false');
      const companys = data.map((company) => ({
        label: company.name,
        value: company.id,
      }));

      setOptions(companys);
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  };

  useEffect(() => {
    loadTenants();
  }, []);

  useEffect(() => {
    if (selectedOption?.value) {
      getContributors(selectedOption.value, currentPage);
    } else {
      getContributors('', currentPage);
    }
  }, [selectedOption, currentPage]);

  return (
    <Container>
      <Content>
        <h2 className="text-2xl font-bold">Colaboradores</h2>
        <div className="divider"></div>

        <MainContent>
          {contributors.length > 0 ? (
            <>
              <SectionFilter>
                <Select
                  onChange={(option) =>
                    setSelectedOption(option as OptionSelect)
                  }
                  options={options}
                  isSearchable={false}
                  maxMenuHeight={150}
                  placeholder="Escolha uma empresa"
                />
              </SectionFilter>

              <div className="grid grid-cols-1 gap-4 mt-6">
                <div className="card bg-base-100 shadow-xl">
                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td>Título</td>
                          <td>Descrição</td>
                          <td>Família</td>
                        </tr>
                      </thead>
                      <tbody>
                        {contributors && (
                          <>
                            {contributors.map((contributor, index) => (
                              <tr key={index}>
                                <td>{contributor.name}</td>
                                <td>{contributor.email}</td>
                                <td>{contributor.tenant.name}</td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Pagination>
                <ArrowLeft
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FiArrowLeft />
                </ArrowLeft>
                <span>{currentPage}</span>
                <ArrowRight
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    pagination.lastPageContributors === 0 ||
                    currentPage === pagination.lastPageContributors
                  }
                >
                  <FiArrowRight />
                </ArrowRight>
              </Pagination>
            </>
          ) : (
            <NoUsers>Nenhum usuário cadastrado</NoUsers>
          )}
        </MainContent>
      </Content>
    </Container>
  );
};

export default DashboardContributors;
