import { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiEdit, FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Input } from '../../components/Input';
import { ModalEditPositionDescription } from '../../components/ModalEditPositionDescription';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { AppError } from '../../error/AppError';
import { OptionSelect, UpdatePositionDescription } from '../../interfaces';
import { api } from '../../services/api';
import { ButtonPrimary, Select } from '../DashboardCompanyPositions/styles';
import { ArrowLeft, ArrowRight, Pagination } from '../DashboardUsers/styles';
import * as s from './styles';

interface RequestPositionDescriptionsProps {
  page?: number;
  limit?: number;
  family?: string;
  sub_family?: string;
  position?: string;
}

export interface PositionDescriptions {
  id: string;
  position: string;
  sub_family_description: string | null;
  contribution_level: string | null;
  broad_position: string | null;
  cbo: string | null;
  cbo_description: string | null;
  typical_formation: string | null;
  obs: string | null;
  main_activities: string | null;
}

interface OptionSelectSubfamily extends OptionSelect {
  family_id: string;
}

export default function PositionDescriptions() {
  const [positionDescriptions, setPositionDescriptions] = useState<
    PositionDescriptions[]
  >([]);
  const [currentEditing, selectCurrentEditing] =
    useState<PositionDescriptions | null>(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const { handleSetIsLoading } = useGlobalLoading();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [familiesOptions, setFamiliesOptions] = useState();
  const [allSubFamiliesOptions, setAllSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [subFamiliesOptions, setSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [selectedFamilyOption, setSelectedFamilyOption] =
    useState<OptionSelect>();
  const [selectedSubFamilyOption, setSelectedSubFamilyOption] =
    useState<OptionSelect>();
  const [positionSearch, setPositionSearch] = useState('');
  const [updated, setUpdated] = useState(false);

  const requestPositionDescriptions = async ({
    page = 1,
    limit = 10,
    family = '',
    sub_family = '',
    position = '',
  }: RequestPositionDescriptionsProps) => {
    handleSetIsLoading(true);

    let query = `?page=${page}&limit=${limit}`;

    if (family) {
      query = query.concat(`&family=${family}`);
    }
    if (sub_family) {
      query = query.concat(`&sub_family=${sub_family}`);
    }
    if (position) {
      query = query.concat(`&position=${position}`);
    }

    try {
      const positionsPaginate = await api.get(
        `smartpay/positions/descriptions${query}`,
      );

      const data: PositionDescriptions[] = positionsPaginate.data.data;
      setPositionDescriptions(data);
      setLastPage(positionsPaginate.data.last_page);
    } catch (err) {
      toast.error('erro ao carregar lista');
    }
    handleSetIsLoading(false);
  };

  const handleUpdatePositionDescription = async ({
    id,
    broad_position,
    cbo,
    cbo_description,
    typical_formation,
    obs,
    main_activities
  }: UpdatePositionDescription) => {
    handleSetIsLoading(true);
    try {
      await api.put(`/smartpay/positions/${id}/descriptions`, {
        broad_position,
        cbo,
        cbo_description,
        typical_formation,
        obs,
        main_activities
      });
      toast.success('Alterado com sucesso!');
      setUpdated(true);
    } catch (err) {
      toast.error('Erro ao tentar fazer alteração!');
    }

    handleSetIsLoading(false);
  };

  const handleSetSelectedFamilyOption = (option: OptionSelect) => {
    setSelectedFamilyOption(option as OptionSelect);

    if (!option) {
      setSubFamiliesOptions(allSubFamiliesOptions);
      return;
    }

    const subFamiliesOptionsFiltered = allSubFamiliesOptions?.filter(
      (sf) => sf.family_id === option.value,
    );
    setSubFamiliesOptions(subFamiliesOptionsFiltered);
  };

  const loadFamilies = async () => {
    try {
      const { data } = await api.get('/smartpay/positions/subfamilies');

      const auxFamily: Array<string> = [];
      const families = data
        .map((family) => {
          const id = family.position_family.id as string;
          if (!auxFamily.includes(id)) {
            auxFamily.push(id);
            return {
              label: family.position_family.title,
              value: family.position_family.id,
            };
          }

          return null;
        })
        .filter((boolean) => boolean);
      const subFamilies: OptionSelectSubfamily[] = data.map((family) => ({
        label: family.title,
        value: family.id,
        family_id: family.position_family.id,
      }));
      setFamiliesOptions(families);
      setSubFamiliesOptions(subFamilies);
      setAllSubFamiliesOptions(subFamilies);
    } catch (error) {
      AppError(error, `Problemas ao carregar família e subfamília!`);
    }
  };

  useEffect(() => {
    const loadFamilyFilters = loadFamilies;
    loadFamilyFilters();
  }, []);

  useEffect(() => {
    const loadData = requestPositionDescriptions;
    loadData({
      page: currentPage,
      family: selectedFamilyOption?.label,
      sub_family: selectedSubFamilyOption?.label,
    });
    setUpdated(false);
  }, [currentPage, selectedFamilyOption, selectedSubFamilyOption, updated]);

  return (
    <>
      {showModalConfirm && currentEditing && (
        <ModalEditPositionDescription
          closeModal={() => setShowModalConfirm(false)}
          callback={(data) => handleUpdatePositionDescription(data)}
          currentData={currentEditing}
        />
      )}
      <s.Container>
        <s.Content>
          <h2 className="text-2xl font-bold">Position descriptions</h2>
          <div className="divider"></div>

          <s.MainContent>
            <s.ControlContainer>
              <Select
                onChange={(option) =>
                  handleSetSelectedFamilyOption(option as OptionSelect)
                }
                options={familiesOptions}
                isSearchable={false}
                isClearable={true}
                maxMenuHeight={150}
                placeholder="Escolha uma Família"
              />
              <Select
                onChange={(option) =>
                  setSelectedSubFamilyOption(option as OptionSelect)
                }
                options={subFamiliesOptions}
                isClearable={true}
                isSearchable={false}
                maxMenuHeight={150}
                placeholder="Escolha uma SubFamília"
              />
              <input
                type="text"
                className="w-full max-w-xs m-2 mt-2 input input-bordered"
                onChange={(e) => setPositionSearch(e.currentTarget.value)}
                value={positionSearch}
              />
              <ButtonPrimary
                className="mt-2"
                onClick={(e) => {
                  requestPositionDescriptions({
                    family: selectedFamilyOption?.label,
                    sub_family: selectedSubFamilyOption?.label,
                    position: positionSearch,
                  });
                }}
              >
                <FiSearch />
                Buscar
              </ButtonPrimary>
            </s.ControlContainer>

            <div className="grid grid-cols-1 gap-4 mt-6">
              <div className="shadow-xl card bg-base-100">
                <div className="overflow-x-auto">
                  <table
                    className="table w-full"
                    style={{
                      width: '100% !important',
                    }}
                  >
                    <thead>
                      <tr>
                        <td>Cargo</td>
                        <td>Desc. Sub Família</td>
                        <td>Nível de Contrib.</td>
                        <td>Cargo Amplo</td>
                        <td>#CBO</td>
                        <td>Descrição CBO</td>
                        <td>Formação típica</td>
                        <td>Principais Atividades</td>
                        <td>Observação</td>
                        <td
                          style={{
                            width: '50px !important',
                          }}
                        >
                          Ações
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {positionDescriptions && (
                        <>
                          {positionDescriptions.map(
                            (positionDescription, index) => (
                              <tr key={index}>
                                <td>{positionDescription.position}</td>
                                <td>
                                  {positionDescription.sub_family_description}
                                </td>
                                <td>
                                  {positionDescription.contribution_level}
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    whiteSpace: 'inherit',
                                    width: '250px',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {positionDescription.broad_position}
                                </td>
                                <td>{positionDescription.cbo}</td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    width: '250px',
                                    whiteSpace: 'inherit',
                                    overflow: 'hidden',
                                  }}
                                >
                                  {positionDescription.cbo_description}
                                </td>
                                <td>{positionDescription.typical_formation}</td>
                                <td>{positionDescription.main_activities}</td>
                                <td>{positionDescription.obs}</td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    width: '20px',
                                    whiteSpace: 'inherit',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <FiEdit
                                      onClick={() => {
                                        selectCurrentEditing(
                                          positionDescription,
                                        );
                                        setShowModalConfirm(true);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ),
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <Pagination>
              <ArrowLeft
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <FiArrowLeft />
              </ArrowLeft>
              <span>{currentPage}</span>
              <ArrowRight
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={lastPage === 0 || currentPage === lastPage}
              >
                <FiArrowRight />
              </ArrowRight>
            </Pagination>
          </s.MainContent>
        </s.Content>
      </s.Container>
    </>
  );
}
