import { useCallback, useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import {
  Container,
  Content,
  SectionListQuestions,
  QuestionCard,
  MainTitle,
  MainContent,
  ArrowLeft,
  ArrowRight,
  Pagination,
  NoQuestions,
  IconPlus,
  PrimaryButton,
  QuestionCardHeader,
  QuestionCardContent,
  QuestionCardFooter,
  QuestionCardOption,
} from './styles';
import { AppError } from '../../error/AppError';
import { MatrizQuestion, OptionGroup } from '../../interfaces';
import { ModalConfirm } from '../../components/ModalConfirm';
import { ModalQuestion } from '../../components/ModalQuestion';
import { useAdmin } from '../../contexts/AdminContext';
import { toast } from 'react-toastify';

const DashboardQuestions = () => {
  const {
    loadQuestions,
    optionGroups,
    createQuestion,
    deleteQuestion,
    updateQuestions,
  } = useAdmin();

  const [currentQuestion, setCurrentQuestion] = useState(optionGroups[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalQuestion, setIsShowModalQuestion] = useState(false);
  const [currentDeleteQuestion, setCurrentDeleteQuestion] = useState('');
  const [currentEditQuestion, setCurrentEditQuestion] =
    useState<OptionGroup | null>(null);

  const getQuestions = useCallback(async () => {
    try {
      await loadQuestions();
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  }, [loadQuestions]);

  const selectEditQuestion = (id: string) => {
    const [optionGroup] = optionGroups.filter((user) => user.id === id);

    if (!optionGroup) return;

    setCurrentEditQuestion(optionGroup);
    setIsShowModalQuestion(true);
  };

  const actionDeleteQuestion = async () => {
    try {
      setCurrentPage(1);
      await deleteQuestion(currentDeleteQuestion);
      setIsShowModalConfirm(false);
    } catch (error) {
      AppError(error, `Problemas ao remover essa questão`);
    }
  };

  const actionModalMatrizQuestion = async (
    data: any,
    typeAction: 'update' | 'create',
  ) => {
    try {
      if (typeAction === 'create') {
        await createQuestion(data);
        toast.success('Pergunta criada com sucesso!');
      } else {
        await updateQuestions(currentEditQuestion?.id as string, data);
        toast.success('Pergunta atualizada com sucesso!');
      }
      setIsShowModalQuestion(false);
    } catch (error) {
      AppError(
        error,
        `Problemas ao ${
          typeAction === 'create' ? 'criar' : 'atualizar'
        } usuário. Verifique os dados fornecidos!`,
      );
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    setCurrentQuestion(optionGroups[currentPage - 1]);
  }, [currentPage, optionGroups]);
  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="question"
          callback={actionDeleteQuestion}
          closeModal={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalQuestion && (
        <ModalQuestion
          callback={actionModalMatrizQuestion}
          closeModal={() => setIsShowModalQuestion(false)}
          optionGroup={currentEditQuestion}
        />
      )}
      <Content>
        <h2 className="text-2xl font-bold">Opções da presidência</h2>
        <div className="divider"></div>

        <MainContent>
          <PrimaryButton
            onClick={() => {
              setCurrentEditQuestion(null);
              setIsShowModalQuestion(true);
            }}
          >
            <IconPlus />
            Criar pergunta
          </PrimaryButton>
          {optionGroups.length > 0 ? (
            <>
              <SectionListQuestions>
                <QuestionCard>
                  <QuestionCardHeader>
                    Grupo de opções nº {currentPage}
                  </QuestionCardHeader>
                  <QuestionCardContent>
                    <p>
                      Título: <span>{currentQuestion?.title}</span>
                    </p>
                    <p>Alternativas:</p>
                    {currentQuestion?.options.map((question) => (
                      <QuestionCardOption key={question.id}>
                        {question.title} - {question.weight} pontos
                      </QuestionCardOption>
                    ))}
                  </QuestionCardContent>
                  <QuestionCardFooter>
                    <PrimaryButton
                      onClick={() =>
                        selectEditQuestion(currentQuestion?.id as string)
                      }
                    >
                      Editar grupo
                    </PrimaryButton>
                    {/* <PrimaryButton
                        onClick={() => {
                          setCurrentDeleteQuestion(
                            currentQuestion?.id as string,
                          );
                          setIsShowModalConfirm(true);
                        }}
                      >
                        Excluir grupo
                      </PrimaryButton> */}
                  </QuestionCardFooter>
                </QuestionCard>
                <Pagination>
                  <ArrowLeft
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <FiArrowLeft />
                  </ArrowLeft>
                  <span>{currentPage}</span>
                  <ArrowRight
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === optionGroups.length}
                  >
                    <FiArrowRight />
                  </ArrowRight>
                </Pagination>
              </SectionListQuestions>
            </>
          ) : (
            <NoQuestions>Nenhuma pergunta cadastrada</NoQuestions>
          )}
        </MainContent>
      </Content>
    </Container>
  );
};

export default DashboardQuestions;
