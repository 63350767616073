import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import 'dayjs/locale/pt-br';

dayjs.extend(utc);
dayjs.extend(isToday);

export class DateProvider {
  dateNow(date?: string | Date): Date {
    return dayjs(date).toDate();
  }

  dateToString(date: Date, lang: 'eng' | 'pt_br' = 'pt_br'): string {
    let format = '';

    if (lang === 'eng') {
      format = 'YYYY-MM-DD';
    }
    if (lang === 'pt_br') {
      format = 'DD/MM/YYYY';
    }

    return dayjs(date).utc().format(format);
  }

  remainingToNextMonth() {
    const date = new Date();
    const time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    const days =
      time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    return days;
  }

  getNowYear(): number {
    return this.dateNow().getFullYear();
  }

  getNowMonthNumber(plusOne = true): number {
    if (plusOne) {
      return this.dateNow().getMonth() + 1;
    }

    return this.dateNow().getMonth();
  }

  compareInHours(start_date: Date, end_date: Date): number {
    const end_data_utc = this.convertToUTC(end_date);
    const start_date_utc = this.convertToUTC(start_date);

    return dayjs(end_data_utc).diff(start_date_utc, 'hours');
  }

  compareInDays(start_date: Date, end_date: Date): number {
    const end_data_utc = this.convertToUTC(end_date);
    const start_date_utc = this.convertToUTC(start_date);

    return dayjs(end_data_utc).diff(start_date_utc, 'days');
  }

  compareInYears(start_date: Date, end_date: Date): number {
    const end_data_utc = this.convertToUTC(end_date);
    const start_date_utc = this.convertToUTC(start_date);

    return Math.abs(dayjs(end_data_utc).diff(start_date_utc, 'years'));
  }

  compareWithNowInDays(dateToCompare: Date): number {
    const date_now_utc = this.convertToUTC(this.dateNow());
    const date_to_compare = this.convertToUTC(dateToCompare);
    return Math.abs(dayjs(date_now_utc).diff(date_to_compare, 'days'));
  }

  dateMonthLessFromDateMonthNow(dateToCompare: Date): boolean {
    return dayjs(dateToCompare).isBefore(dayjs(this.dateNow()));
  }

  dateMonthEqualLessFromDate(dateToCompare: Date, baseDate: Date): boolean {
    return (
      dayjs(dateToCompare).isSame(baseDate) ||
      dayjs(dateToCompare).isBefore(dayjs(baseDate))
    );
  }

  dateMonthLessFromDate(dateToCompare: Date, baseDate: Date): boolean {
    return dayjs(dateToCompare).isBefore(dayjs(baseDate));
  }

  dateMonthHigherFromDate(dateToCompare: Date, baseDate: Date): boolean {
    return dayjs(dateToCompare).isAfter(dayjs(baseDate));
  }

  sortDates(dates: string[], order = 'asc') {
    const firstOrder = order === 'asc' ? 1 : -1;
    const secondOrder = order === 'asc' ? -1 : 1;

    const datesSorted = [
      ...dates.sort((a, b) =>
        dayjs(a).isAfter(dayjs(b)) ? firstOrder : secondOrder,
      ),
    ];

    return datesSorted;
  }

  dateMonthEqualFromDate(dateToCompare: Date, baseDate: Date): boolean {
    return dayjs(dateToCompare).isSame(dayjs(baseDate));
  }

  dateIsEqualAfterFromDate(afterDate: Date, baseDate: Date): boolean {
    return (
      dayjs(afterDate).isSame(baseDate) || dayjs(afterDate).isAfter(baseDate)
    );
  }

  getMonthDifferenceFromDateMonthNow(dateToCompare: Date): number {
    return dayjs(this.dateNow()).diff(dayjs(dateToCompare), 'month');
  }

  addDays(days: number): Date {
    return dayjs().add(days, 'days').toDate();
  }

  addMinutes(minutes: number): Date {
    return dayjs().add(minutes, 'minutes').toDate();
  }

  addHours(hours: number): Date {
    return dayjs().add(hours, 'hour').toDate();
  }

  convertToUTC(date: Date): string {
    return dayjs(date).utc().local().format();
  }

  convertToUTCISO(date: Date | string): string {
    return dayjs(date).utc().local().toISOString();
  }

  serialDateToJSDate(excelSerialDate: number): Date {
    return new Date(Date.UTC(0, 0, excelSerialDate - 1));
  }

  serialDateToISODate(excelSerialDate: number): string {
    return this.serialDateToJSDate(excelSerialDate).toISOString();
  }

  acceptJustDateFormat(s: string) {
    const isDateSerial = !isNaN(Number(s));

    if (isDateSerial) {
      return s;
    }

    return s.replace(/[^0-9 /]/g, '').trim();
  }

  serialDateToISODateString(excelSerialDate: number): string {
    return this.serialDateToISODate(excelSerialDate).split('T')[0];
  }

  compareIfBefore(start_date: Date, end_date: Date): boolean {
    return dayjs(start_date).isBefore(end_date);
  }

  isMonthAssertDifferenceFromNow(date: Date, distance = 1) {
    const difference = this.getMonthDifferenceFromDateMonthNow(date);

    return difference === distance;
  }

  getFirstDayPreviousMonth() {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() - 1, 1);
  }

  getMonthByStringDate(date: Date, lang = 'pt-br') {
    return dayjs(date).locale(lang).format('MMMM');
  }

  getNowMonthName(lang = 'pt-br') {
    return dayjs().locale(lang).format('MMMM');
  }

  convertPTbrDateToEn(date: string) {
    if (!this.isValidePTbrDate) {
      return date;
    }

    const dateParsed = date.replace(/\//g, '-');

    const splitted = dateParsed.split('-');

    const enDate = [splitted[2], splitted[1], splitted[0]].join('-');

    return enDate;
  }

  isValideEnDate(str: string) {
    const yearMonthDayFormat =
      /^\d{4}(\-)(((0)[0-9])|((1)[0-2]))(\-)([0-2][0-9]|(3)[0-1])$/;

    return yearMonthDayFormat.test(str);
  }

  isValidePTbrDate(str: string) {
    //30/12/2020
    const dayMonthYearFormat =
      /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    //   /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    //12/30/2020
    // const monthDayYearFormat =
    //   /^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/;
    //2020/12/30
    // const yearMonthDayFormat =
    //   /^\d{4}(\/)(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])$/;

    return dayMonthYearFormat.test(str);
  }

  isIsoDate(str: string) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d.toISOString() === str;
  }

  subYear(date: Date, sub: number) {
    return dayjs(date).subtract(sub, 'year').toDate();
  }

  addYear(date: Date, add: number) {
    return dayjs(date).add(add, 'year').toDate();
  }

  subMonth(date: Date | string, sub: number) {
    return dayjs(date).subtract(sub, 'month').toDate();
  }

  addMonth(date: Date, add: number) {
    return dayjs(date).add(add, 'month').toDate();
  }

  subDay(date: Date, sub: number) {
    return dayjs(date).subtract(sub, 'day').toDate();
  }

  addDay(date: Date, add: number) {
    return dayjs(date).add(add, 'day').toDate();
  }

  convertUtcDate(date: Date) {
    return new Date(date + 'Z');
  }

  parseStringToUTCISODate(date: string) {
    let dateParsed: null | string = null;

    if (isNaN(Number(date)) && this.isValidePTbrDate(date)) {
      dateParsed = this.convertToUTCISO(this.convertPTbrDateToEn(date));
    } else if (isNaN(Number(date)) && this.isValideEnDate(date)) {
      dateParsed = this.convertToUTCISO(date);
    } else if (!isNaN(Number(date))) {
      dateParsed = this.serialDateToISODate(Number(date));
    }

    return dateParsed;
  }

  isDateExpired(date: Date) {
    return !(
      dayjs(date).isAfter(dayjs(this.dateNow())) || dayjs(date).isToday()
    );
  }

  getDateFromMonthNumberOnList(monthNumber: number, dates: string[]) {
    return dates.find((date) => dayjs(date).month() === monthNumber);
  }
}
