import ReactSelect, { StylesConfig } from 'react-select';
import chroma from 'chroma-js';
import { CSSProperties } from 'react';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export interface OptionSelect {
  label: string;
  value: string;
}
interface SelectProps {
  options: OptionSelect[];
  defaultValue?: OptionSelect;
  value?: OptionSelect;
  isSearchable?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  color?: string;
  onChange?: (option: OptionSelect) => void;
  enableDot?: boolean;
  isClearable?: boolean;
  height?: string;
  styles?: CSSProperties;
}

export function CustomSelect({
  options,
  defaultValue,
  value,
  isSearchable = false,
  placeholder,
  maxMenuHeight,
  color,
  onChange,
  enableDot = false,
  isClearable = false,
  styles,
}: SelectProps) {

  const primaryColor = color ?? '#F56A00';
  const colourStyles: StylesConfig<{ label: string; value: string }> = {
    container: (base, state) => ({
      ...base,
      zIndex: "999"
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(primaryColor);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? primaryColor
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : primaryColor,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? primaryColor
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    valueContainer: styles => ({
      ...styles,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
    }),
    input: (styles) => ({ ...styles, ...(enableDot && {...dot()}) }),
    placeholder: (styles) => ({ ...styles, ...(enableDot && {...dot('#ccc')}) }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...(enableDot && {
        ...dot(primaryColor),
      }),
    }),
  };

  return (
    <ReactSelect
      defaultValue={defaultValue}
      value={value}
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      isClearable={isClearable}
      onChange={(option) => onChange && onChange(option as OptionSelect)}
      maxMenuHeight={maxMenuHeight}
      styles={{
        ...colourStyles,
        ...styles,
      }}
    />
  );
}
