import { FiAlertTriangle } from 'react-icons/fi';
import { ModalGeneric } from '../../../components/ModalGeneric';
import { Button } from '../../../components/Button';

interface MarketParametersEditConfirmModalProps {
  isModalConfirmEdit: boolean;
  setIsModalConfirmEdit: (v: boolean) => void;
  setIsModalOpen: (v: boolean) => void;
}

export function MarketParametersEditConfirmModal({
  isModalConfirmEdit,
  setIsModalConfirmEdit,
  setIsModalOpen,
}: MarketParametersEditConfirmModalProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalConfirmEdit}
      setIsModalOpen={setIsModalConfirmEdit}
    >
      <div className="grid grid-cols-1 gap-4" style={{ width: '550px' }}>
        Tem certeza que deseja editar os parâmetros?
        <br />
        <div className="mt-5 mb-8 shadow-lg alert alert-warning">
          <div>
            <FiAlertTriangle />
            <span>
              Atenção, todos os dados do mercado serão zerados e novos dados
              serão gerados baseados nos parâmetros atualizados.
            </span>
          </div>
        </div>
        <Button
          type="button"
          onClick={(e: any) => {
            setIsModalOpen(true);
            setIsModalConfirmEdit(false);
          }}
        >
          Confirmar
        </Button>
        <button
          className="btn btn-ghost"
          onClick={() => setIsModalConfirmEdit(false)}
        >
          Cancelar
        </button>
      </div>
    </ModalGeneric>
  );
}
