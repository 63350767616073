import { Stitches } from "../../styles/stitches";

export const BackButtonWrapper = Stitches.styled('button', {
  width: 'fit-content',
  height: '$2',
  color: '$gray400',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$2',
  backgroundColor: 'transparent',
  borderRadius: '$default',
  marginBottom: '$2',

  '& > svg': {
    width: '$iconSmall',
    height: '$iconSmall',
    color: '$gray400',
  },
});
