import { useEffect, useState, useCallback } from 'react';
import { FiPlus, FiSearch, FiXCircle } from 'react-icons/fi';
import { Button } from '../../components/Button';
import { Container, ContainerButton, Form } from './styles';
import { api } from '../../services/api';
import { ModalGeneric } from '../../components/ModalGeneric';
import { toast } from 'react-toastify';
import { ButtonPrimary } from '../DashboardUsers/styles';
import './styles.css';
import { AppError } from '../../error/AppError';
import { useForm } from 'react-hook-form';
import { Content } from '../../pages/MarketConfiguration/styles';
import { ETenantRolesTag } from '../../enums/roles';

export default function Plans() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const [roles, setRoles] = useState([]);
  const [plans, setPlans] = useState<string[]>([]);
  const [itemAddRole, setItemAddRole] = useState<string>('');
  const [itemsAddPermission, setItemsAddPermission] = useState<string[]>([]);
  const [itemDelete, setItemDelete] = useState<string>('');
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [handleEdit, setHandleEdit] = useState(false);
  const [handleShow, setHandleShow] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [checkedElement, setCheckedElement] = useState({
    id: '',
    type: '',
    checked: false,
  });
  const [planSelected, setPlanSelected] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    try {
      const roles = itemsAddPermission.map((item: any) => {
        return item;
      });

      let retorno;
      if (handleEdit) {
        const parseData = {
          name: data.nomePlano,
          description: data.descricao,
          tag: data.tag,
          price: 0,
          roles: roles,
        };
        retorno = await api.put(`/plans/${planSelected.id}`, parseData);
      } else {
        const parseData = {
          name: data.nomePlano,
          description: data.descricao,
          tag: data.tag,
          price: 0,
          roles: roles,
        };
        retorno = await api.post('/plans', parseData);
      }

      if (retorno.status === 201 || retorno.status === 200) {
        toast.success(
          handleEdit
            ? 'Plano editado com sucesso.'
            : 'Plano criado com sucesso.',
        );
        getPlans();
        getRoles();
        reset();
        setItemsAddPermission([]);
        setGroups([]);
        setIsModalOpenPermission(false);
      }
    } catch (error) {
      AppError(error, `Problemas ao criar perfil!`);
    }
  };

  async function getPlans() {
    const { data } = await api.get('/plans');
    // const data = ['Gold', 'Silver', 'Black'];
    setPlans(data);
  }

  async function getPermissions() {
    const { data } = await api.get('/permissions');
    setPermissions(data);
  }

  async function getRoles() {
    const { data } = await api.get('/roles', {
      params: {
        tag: ETenantRolesTag.MASTER.valueOf(),
      },
    });
    setRoles(data);
  }

  async function handleAddPermission() {
    if (itemAddRole) {
      const { data } = await api.get(`/roles/${itemAddRole}`);
      const isExists = itemsAddPermission.filter(
        (item: any) => item === itemAddRole,
      );
      if (!isExists.length) {
        setItemsAddPermission((prev) => [...prev, itemAddRole]);
        setGroups((prev) => [...prev, data]);
      }
    }
  }

  function findRoleName(id: string) {
    const filterRoles: any = roles.filter(
      (permission: any) => permission.id === id,
    );
    return filterRoles[0]?.name;
  }

  function handleDelete(item: any) {
    setItemDelete(item['id']);
    setIsModalOpenDelete(true);
  }

  async function handleDeletePlano() {
    try {
      const id = itemDelete;
      const retorno = await api.delete(`/plans/${id}`);
      toast.success('Plano excluido com sucesso.');
      getPlans();
      getRoles();
      reset();
    } catch (error) {
      AppError(error, `Problemas ao excluir Plano!`);
    }
  }

  function findPermissionName(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].name;
  }

  function findPermissionTitle(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].title;
  }

  function findChecked(id: string, permission: any, item: string) {
    if (item == 'show') {
      return permission.show === true ? true : false;
    }
    if (item == 'create') {
      return permission.create === true ? true : false;
    }
    if (item == 'delete') {
      return permission.delete === true ? true : false;
    }
    if (item == 'update') {
      return permission.update === true ? true : false;
    }
    if (item == 'disable') {
      return permission.disable === true ? true : false;
    }
  }

  function handleDeletePermission(item: string) {
    itemsAddPermission.splice(
      itemsAddPermission.findIndex((e) => e === item),
      1,
    );

    if (itemsAddPermission.length > 0) {
      setItemsAddPermission((prev) => [...prev]);
    } else {
      setItemsAddPermission((prev) => []);
    }
  }

  async function handleAddPermissionEdit(itemAddRole) {
    if (itemAddRole) {
      const { data } = await api.get(`/roles/${itemAddRole}`);
      const isExists = itemsAddPermission.filter(
        (item: any) => item === itemAddRole,
      );
      if (!isExists.length) {
        setGroups((prev) => [...prev, data]);
      }
    }
  }

  async function handlePermission(permission: any) {
    setItemsAddPermission([]);
    const permissionItenns = await permission.roles.map(
      (item: any, index: number) => {
        handleAddPermissionEdit(item);
        setItemsAddPermission((prev) => [...prev, item]);
      },
    );
  }

  const handleChangeAction = useCallback(
    (type: string, plan) => {
      switch (type) {
        case 'edit':
          setIsModalOpenPermission(true);
          setHandleEdit(true);
          setHandleShow(false);
          setPlanSelected(plan);
          handlePermission(plan);
          reset();
          setCheckedElement({
            id: '',
            type: '',
            checked: false,
          });
          break;
        case 'delete':
          handleDelete(plan);
          break;
        default:
          break;
      }
    },
    [
      setIsModalOpenPermission,
      setHandleEdit,
      setHandleShow,
      setPlanSelected,
      handlePermission,
      reset,
      setCheckedElement,
      handleDelete,
    ],
  );

  useEffect(() => {
    getPlans();
    getRoles();
    getPermissions();
  }, []);

  return (
    <Container>
      <h2 className="text-2xl font-bold">Planos</h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 shadow-xl card bg-base-100">
          <div className="flex justify-end">
            <ButtonPrimary
              onClick={() => {
                setIsModalOpenPermission(true);
                setHandleEdit(false);
                reset();
                setItemsAddPermission([]);
              }}
              className="mb-5"
            >
              Criar novo plano
            </ButtonPrimary>
          </div>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th></th>
                  <th>Nome Plano</th>
                  <th>Descrição</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {plans.map((plan: any) => (
                  <tr>
                    <td className="w-[50px]">
                      <FiSearch
                        style={{ cursor: 'pointer' }}
                        onClick={(e: any) => {
                          setIsModalOpenPermission(true);
                          setHandleEdit(true);
                          setHandleShow(true);
                          setPlanSelected(plan);
                          handlePermission(plan);
                          reset();
                          setCheckedElement({
                            id: '',
                            type: '',
                            checked: false,
                          });
                        }}
                      />
                    </td>
                    <td className="w-[350px]">{plan?.name}</td>
                    <td>{plan.description}</td>
                    <td>
                      <select
                        value={
                          plan?.id === planSelected?.id ? selectedAction : ''
                        }
                        className="w-[150px] select select-bordered"
                        onChange={(e) => {
                          handleChangeAction(e.target.value, plan);
                          setSelectedAction(e.target.value);
                        }}
                      >
                        <option>Ações</option>
                        <option value="edit" key="edit">
                          Editar
                        </option>
                        <option value="delete" key="delete">
                          Excluir
                        </option>
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ModalGeneric
        isModalOpen={isModalOpenPermission}
        setIsModalOpen={(e) => {
          setIsModalOpenPermission(e);
          setSelectedAction('Ações');
        }}
        full={true}
      >
        <Form onSubmit={handleSubmit(submit)}>
          <div className="grid gap-4 min-w-[800px] mb-8">
            <div className="flex-auto w-64">
              <div className="w-full">
                <label className="label">
                  <strong className="label-text">
                    Nome do plano
                    <label className="text-error">*</label>
                  </strong>
                </label>

                <input
                  type="text"
                  {...register('nomePlano', {
                    required: true,
                  })}
                  defaultValue={handleEdit ? planSelected['name'] : null}
                  className="w-full max-w-xs input input-bordered"
                  disabled={handleShow}
                />
              </div>
              <Content>
                {!!errors.nomePlano ? 'Campo Obrigatório' : null}
              </Content>
            </div>

            <div className="flex-auto w-64">
              <div className="w-full">
                <label className="label">
                  <strong className="label-text">Tag</strong>
                </label>

                <input
                  type="text"
                  {...register('tag', {
                    required: false,
                  })}
                  disabled={handleShow}
                  defaultValue={handleEdit ? planSelected['tag'] : null}
                  className="w-full max-w-xs input input-bordered"
                />
              </div>
              <Content>{!!errors.tag ? 'Campo Obrigatório' : null}</Content>
            </div>

            <div className="flex-auto w-64">
              <div className="w-full">
                <label className="label">
                  <strong className="label-text">Preço base</strong>
                </label>

                <input
                  type="text"
                  {...register('price', {
                    required: false,
                  })}
                  disabled={handleShow}
                  className="w-full max-w-xs input input-bordered"
                />
              </div>
              <Content>{!!errors.tag ? 'Campo Obrigatório' : null}</Content>
            </div>

            <div className="w-full">
              <label className="label">
                <strong className="label-text">Descrição</strong>
              </label>

              <textarea
                className="input input-bordered w-[750px] h-[100px]"
                {...register('descricao')}
                disabled={handleShow}
                defaultValue={handleEdit ? planSelected['description'] : null}
              ></textarea>
            </div>
            {!handleShow && (
              <>
                <div className="shadow-lg alert alert-info">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      className="flex-shrink-0 w-6 h-6 stroke-current"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span>Adicione os perfis que contemplam este plano</span>
                  </div>
                </div>

                <div className="flex-auto w-64">
                  <div className="flex w-full">
                    <label className="label">
                      <strong className="label-text">Perfis</strong>
                    </label>

                    <select
                      className="w-[350px] select select-bordered"
                      disabled={handleShow}
                      {...register('perfis', {
                        required: groups.length == 0 ? true : false,
                      })}
                      onChange={(e: any) => {
                        setItemAddRole(e.target.value);
                      }}
                    >
                      <option></option>
                      {roles.map((item: any) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>

                    <div
                      className="btn btn-sm btn-success w-[50px] h-[50px] ml-3"
                      onClick={handleAddPermission}
                    >
                      <FiPlus />
                    </div>
                  </div>
                  <Content>{!groups.length ? 'Campo Obrigatório' : ''}</Content>
                </div>
              </>
            )}
          </div>

          <div className="flex mb-8">
            <table className="table w-full table-compact table-zebra">
              <tbody>
                <tr className="border-b dark:border-neutral-200">
                  <td colSpan={3}>
                    <strong>Perfil</strong>
                  </td>
                </tr>
                {itemsAddPermission.map((item: any) => (
                  <tr className="border-b dark:border-neutral-200">
                    <td>
                      <strong>{findRoleName(item)}</strong>
                    </td>
                    <td>
                      <div className="collapse">
                        <input type="checkbox" />
                        <div className="collapse-title">
                          <strong>Permissões</strong>
                        </div>
                        <div className="collapse-content">
                          <table className="table w-full table-compact table-zebra">
                            <thead>
                              <tr className="border-b dark:border-neutral-200">
                                <td>Nome</td>
                                <td>Título</td>
                                <td>Visualizar</td>
                                <td>Adicionar</td>
                                <td>Alterar</td>
                                <td>Desabilitar</td>
                                <td>Excluir</td>
                              </tr>
                            </thead>
                            <tbody>
                              {groups
                                .filter((itemGroup) => itemGroup['id'] == item)
                                .map((filteredGroup) => (
                                  <>
                                    {filteredGroup['permissions'].map(
                                      (itemPermission: any) => (
                                        <tr>
                                          <td>
                                            <strong>
                                              {
                                                <strong>
                                                  {findPermissionName(
                                                    itemPermission['id'],
                                                  )}
                                                </strong>
                                              }
                                            </strong>
                                          </td>
                                          <td>
                                            <strong>
                                              {
                                                <strong>
                                                  {findPermissionTitle(
                                                    itemPermission['id'],
                                                  )}
                                                </strong>
                                              }
                                            </strong>
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="checkbox checkbox-success"
                                              disabled
                                              checked={findChecked(
                                                item,
                                                itemPermission,
                                                'show',
                                              )}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="checkbox checkbox-success"
                                              checked={findChecked(
                                                item,
                                                itemPermission,
                                                'create',
                                              )}
                                              disabled
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="checkbox checkbox-success"
                                              disabled
                                              checked={findChecked(
                                                item,
                                                itemPermission,
                                                'update',
                                              )}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="checkbox checkbox-success"
                                              disabled
                                              checked={findChecked(
                                                item,
                                                itemPermission,
                                                'disable',
                                              )}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              className="checkbox checkbox-success"
                                              disabled
                                              checked={findChecked(
                                                item,
                                                itemPermission,
                                                'delete',
                                              )}
                                            />
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                    <td>
                      {!handleShow && (
                        <div
                          onClick={() => handleDeletePermission(item)}
                          className="btn btn-sm btn-error w-[50px] h-[50px]"
                        >
                          <FiXCircle />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {!handleShow && (
            <ContainerButton>
              <Button type="submit">
                {handleEdit ? 'Editar Plano' : 'Criar Plano'}
              </Button>
            </ContainerButton>
          )}

          {handleShow && (
            <ContainerButton>
              <Button onClick={() => setIsModalOpenPermission(false)}>
                Fechar
              </Button>
            </ContainerButton>
          )}
        </Form>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={(e) => {
          setIsModalOpenDelete(e);
          setSelectedAction('Ações');
        }}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
          Tem certeza que deseja Excluir ?
          <br />
          <Button
            type="button"
            onClick={(e: any) => {
              setIsModalOpenDelete(false);
              handleDeletePlano();
            }}
          >
            Confirmar
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => {
              setIsModalOpenDelete(false);
              setSelectedAction('Ações');
            }}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>
    </Container>
  );
}
