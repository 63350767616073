import styled, { css } from 'styled-components';

export const FooterModal = styled.div`
  padding: 1rem;
  display: flex;
`;

export const Button = styled.button<{ default?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  max-width: 250px;
  height: 45px;
  color: var(--white);
  border-radius: 10px;
  transition: background-color 0.3s;
  text-transform: uppercase;

  ${(props) =>
    props.default &&
    css`
      background-color: var(--primary);
    `}

  ${(props) =>
    !props.default &&
    css`
      background-color: var(--error);
    `}

  & + button {
    margin-left: 0.5rem;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--black-light);

  > p {
    padding: 1rem;
    font-weight: 700;
  }

  > svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  padding: 1rem;
  max-height: 70vh;
  overflow-y: auto;
`;
