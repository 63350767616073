import { useState, useContext } from 'react';
import { createContext, ReactNode } from 'react';

import {
  Company,
  Contributor,
  CreateOrUpdateCompany,
  CreateOrUpdateUser,
  CreateOrUpdatePositionCompany,
  EditPosition,
  MatrizQuestion,
  Position,
  PositionCompany,
  User,
  SmartPayPosition,
  CreateOrUpdateSmartPayPosition,
  GradesMatriz,
  UpdateGradeMatriz,
  OptionGroup,
} from '../interfaces';
import { api } from '../services/api';
import { useGlobalLoading } from './GlobalLoadingContext';
import { queryClient } from '../App';

interface AdminContextProviderProps {
  children: ReactNode;
}

interface AdminContextData {
  createCompany: (data: CreateOrUpdateCompany) => Promise<void>;
  updateCompany: (id: string, data: CreateOrUpdateCompany) => Promise<void>;
  deleteCompany: (id: string) => Promise<void>;
  getCompanys: (
    page?: number,
    limit?: number,
    tenantId?: string,
    planId?: string,
    planExpiryDate?: string,
    planStatus?: string,
    tenantType?: string
  ) => Promise<void>;
  createUser: (data: CreateOrUpdateUser) => Promise<void>;
  getUsers: (name?: string, page?: number, limit?: number) => Promise<void>;
  updateUser: (id: string, data: CreateOrUpdateUser) => Promise<void>;
  deleteUser: (id: string) => Promise<void>;
  getContributors: (
    id?: string,
    page?: number,
    limit?: number,
  ) => Promise<void>;
  loadQuestions: () => Promise<void>;
  createQuestion: (data: MatrizQuestion) => Promise<void>;
  deleteQuestion: (id: string) => Promise<void>;
  updateQuestions: (id: string, data: OptionGroup) => Promise<void>;
  updatePositionMatriz: (data: EditPosition) => Promise<void>;
  updateTenantGradeMatriz: (data: UpdateGradeMatriz) => Promise<void>;
  loadPositionsMatriz: (
    idCompany: string,
    idFamily: string,
    idSubFamily: string,
    page?: number,
    limit?: number,
  ) => Promise<Position[]>;
  loadGradesMatriz: (idCompany: string) => Promise<GradesMatriz[]>;
  createPosition: (data: CreateOrUpdatePositionCompany) => Promise<void>;
  getPositions: (id?: string, page?: number, limit?: number) => Promise<void>;
  updatePosition: (
    id: string,
    data: CreateOrUpdatePositionCompany,
  ) => Promise<void>;
  deletePosition: (id: string, tenantId: string) => Promise<void>;
  getSmartPayPositions: (
    family?: string,
    sub_family?: string,
    position?: string,
  ) => Promise<void>;
  createSmartPayPosition: (
    data: CreateOrUpdateSmartPayPosition,
  ) => Promise<void>;
  updateSmartPayPosition: (
    id: string,
    data: CreateOrUpdateSmartPayPosition,
  ) => Promise<void>;
  deleteSmartPayPosition: (id: string) => Promise<void>;
  companies: Company[];
  users: User[];
  contributors: Contributor[];
  optionGroups: OptionGroup[];
  positions: Position[];
  positionsCompany: PositionCompany[];
  smartPayPositions: SmartPayPosition[];
  gradesMatriz: GradesMatriz[];
  pagination: {
    totalCountCompany: number;
    lastPageCompany: number;
    lastPageUsers: number;
    lastPageContributors: number;
    lastPageQuestions: number;
    lastPagePositionsMatriz: number;
    lastPagePositions: number;
  };
}

const AdminContext = createContext<AdminContextData>({} as AdminContextData);

export const AdminProvider = ({ children }: AdminContextProviderProps) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [gradesMatriz, setGradesMatriz] = useState<GradesMatriz[]>([]);
  const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [positionsCompany, setPositionsCompany] = useState<PositionCompany[]>(
    [],
  );
  const [smartPayPositions, setSmartPayPositions] = useState<
    SmartPayPosition[]
  >([]);
  const [pagination, setPagination] = useState({
    lastPageCompany: 1,
    totalCountCompany : 0,
    lastPageUsers: 1,
    lastPageContributors: 1,
    lastPageQuestions: 1,
    lastPagePositionsMatriz: 1,
    lastPagePositions: 1,
  });
  const { handleSetIsLoading } = useGlobalLoading();

  const createCompany = async (company: CreateOrUpdateCompany) => {
    try {
      handleSetIsLoading(true);
      const {
        data: { tenant },
      } = await api.post('/tenants', company);

      const {
        id,
        name,
        trading_name,
        phone,
        cnpj,
        email,
        email_index,
        plans,
        is_on_market_statistic,
        is_using_br_currency,
      } = tenant;

      setCompanies([
        ...companies,
        {
          id,
          name,
          trading_name,
          phone,
          cnpj,
          email,
          email_index,
          is_on_market_statistic,
          is_using_br_currency,
          plans,
        },
      ]);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const getCompanys = async (
    page = 1,
    limit = 10,
    tenantId = '',
    planId = '',
    planExpiryDate = '',
    planStatus = '',
    tenant_type = ''
  ) => {
    try {
      handleSetIsLoading(true);
      const {
        data: { count, data, last_page },
      } = await api.get(`/tenants?page=${page}&limit=${limit}`, {
        params: {
          ...(tenantId && {
            tenant_id: tenantId,
          }),
          ...(planId && {
            plan_id: planId,
          }),
          ...(planExpiryDate && {
            plan_expiry_date: planExpiryDate,
          }),
          ...(planStatus && {
            plan_status: planStatus,
          }),
          ...(tenant_type && {
            tenant_type: tenant_type,
          })
        }
      });

      setPagination({
        ...pagination,
        lastPageCompany: last_page,
        totalCountCompany: count,
      });
      setCompanies(data);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updateCompany = async (id: string, data: CreateOrUpdateCompany) => {
    try {
      handleSetIsLoading(true);

      await api.put(`/tenants/${id}`, data);
      const newCompanys = companies.map((company) => {
        if (company.id === id) {
          return {
            id: id,
            email: data.email,
            email_index: data.email_index,
            name: data.name,
            trading_name: data.trading_name,
            cnpj: data.cnpj,
            phone: data.phone,
            tenant_type: data.tenant_type,
            is_on_market_statistic: true,
            is_using_br_currency: Boolean(data.is_using_br_currency),
            plans: data.plans,
            users: [
              {
                id: data.user?.id as string,
                email: data.user?.email as string,
                name: data.user?.name as string,
              },
            ],
          };
        }

        return company;
      });

      setCompanies(newCompanys);

      queryClient.invalidateQueries('get-tenants');
      queryClient.invalidateQueries(`get-company-${id}`);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const deleteCompany = async (id: string) => {
    try {
      handleSetIsLoading(true);
      await api.delete(`/tenants/${id}`);
      const companysFiltered = companies.filter((company) => company.id !== id);
      setCompanies(companysFiltered);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const createUser = async ({ name, email, password }: CreateOrUpdateUser) => {
    try {
      handleSetIsLoading(true);
      await api.post('/users/', { name, email, password });
      await getUsers();
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const getUsers = async (name = '', page = 1, limit = 10) => {
    let url = `/users/?page=${page}&limit=${limit}`;

    if (name) {
      url += `&name=${name}`;
    }

    try {
      handleSetIsLoading(true);
      const {
        data: { data, last_page },
      } = await api.get(url);

      setPagination({
        ...pagination,
        lastPageUsers: last_page,
      });
      setUsers(data);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updateUser = async (id: string, data: CreateOrUpdateUser) => {
    try {
      handleSetIsLoading(true);
      await api.put(`/users/${id}`, data);

      const newUsers = users.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            ...data,
          };
        }

        return user;
      });

      setUsers(newUsers);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const deleteUser = async (id: string) => {
    try {
      handleSetIsLoading(true);
      await api.delete(`/users/${id}`);
      const usersFiltered = users.filter((user) => user.id !== id);
      setUsers(usersFiltered);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const getContributors = async (id = '', page = 1, limit = 10) => {
    let url = `/tenants/users?page=${page}&limit=${limit}`;

    if (id) {
      url += `&tenant_id=${id}`;
    }

    try {
      handleSetIsLoading(true);
      const {
        data: { data, last_page },
      } = await api.get(url);

      setPagination({
        ...pagination,
        lastPageContributors: last_page,
      });

      setContributors(data);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const loadQuestions = async () => {
    try {
      handleSetIsLoading(true);
      const { data } = await api.get<OptionGroup[]>(
        `/options/groups?type=matriz-question`,
      );

      setPagination({
        ...pagination,
        lastPageQuestions: data.length,
      });

      const dataFormatted = data.sort((a, b) =>
        a.order === b.order ? 0 : a.order > b.order ? 1 : -1,
      );
      setOptionGroups(dataFormatted);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const createQuestion = async (matriz: MatrizQuestion) => {
    try {
      handleSetIsLoading(true);
      const {
        data: { matrizquestion },
      } = await api.post('/matriz/questions', {
        ...matriz,
        order: optionGroups.length + 1,
      });

      setPagination({
        ...pagination,
        lastPageQuestions: optionGroups.length + 1,
      });

      setOptionGroups([...optionGroups, matrizquestion]);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const deleteQuestion = async (id: string) => {
    try {
      handleSetIsLoading(true);
      await api.delete(`/matriz/questions/${id}`);

      await loadQuestions();
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updateQuestions = async (id: string, optionGroup: OptionGroup) => {
    try {
      handleSetIsLoading(true);
      const {
        data: { matrizquestion },
      } = await api.put(`/options/groups/${id}`, optionGroup);

      const newQuestions = optionGroups.map((question) => {
        if (question.id === id) {
          return {
            ...question,
            ...matrizquestion,
          };
        }

        return question;
      });

      setOptionGroups(newQuestions);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const loadPositionsMatriz = async (
    idCompany: string,
    idFamily: string,
    idSubFamily: string,
    page = 1,
    limit = 10,
  ) => {
    const url = `/tenants/positions/matrizes?tenant_id=${idCompany}&family_id=${idFamily}&sub_family_id=${idSubFamily}&page=${page}&limit=${limit}`;

    try {
      handleSetIsLoading(true);
      const {
        data: { data, last_page },
      } = await api.get(url);

      setPagination({
        ...pagination,
        lastPagePositionsMatriz: last_page,
      });

      setPositions(data);
      return data;
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const loadGradesMatriz = async (idCompany: string) => {
    const url = `/tenants/grades/matrizes?paginate=false&tenant_id=${idCompany}`;

    try {
      handleSetIsLoading(true);
      const { data } = await api.get(url);

      setGradesMatriz(data);
      return data;
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updatePositionMatriz = async (data: EditPosition) => {
    const { id, tenant_id, system_position_id, tenant_position_id } = data;
    try {
      handleSetIsLoading(true);
      await api.put(
        `/tenants/positions/matrizes/${id}`,
        {
          tenant_position_id,
          system_position_id,
        },
        {
          headers: {
            tenant_id,
          },
        },
      );
      setPositions([]);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updateTenantGradeMatriz = async (data: UpdateGradeMatriz) => {
    const { matches, tenant_id } = data;
    try {
      handleSetIsLoading(true);
      await api.put(`/tenants/${tenant_id}/grades/matches`, {
        matches,
      });
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const createPosition = async (position: CreateOrUpdatePositionCompany) => {
    try {
      handleSetIsLoading(true);
      await api.post('/tenants/positions', position);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const getPositions = async (id = '', page = 1, limit = 100) => {
    let url = `/tenants/positions?page=${page}&limit=${limit}`;

    if (id) {
      url += `&tenant_id=${id}`;
    }

    try {
      handleSetIsLoading(true);
      const {
        data: { data, last_page },
      } = await api.get(url);

      setPagination({
        ...pagination,
        lastPagePositions: last_page,
      });

      setPositionsCompany(data);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updatePosition = async (
    id: string,
    data: CreateOrUpdatePositionCompany,
  ) => {
    const { code, description, title, tenant_id } = data;

    try {
      handleSetIsLoading(true);
      await api.put(
        `/tenants/positions/${id}`,
        { code, description, title },
        {
          headers: {
            tenant_id: tenant_id || '',
          },
        },
      );
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const deletePosition = async (id: string, tenantId: string) => {
    try {
      handleSetIsLoading(true);
      await api.delete(`/tenants/positions/${id}`, {
        headers: {
          tenant_id: tenantId,
        },
      });
      const positionCompanysFiltered = positionsCompany.filter(
        (company) => company.id !== id,
      );
      setPositionsCompany(positionCompanysFiltered);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const getSmartPayPositions = async (
    family?: string,
    sub_family?: string,
    position?: string,
  ) => {
    const query = `?families=${family || ''}&sub_families=${
      sub_family || ''
    }&position=${position || ''}`;
    try {
      handleSetIsLoading(true);
      const { data } = await api.get(`smartpay/positions${query}`);
      setSmartPayPositions(data);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const createSmartPayPosition = async (
    position: CreateOrUpdateSmartPayPosition,
  ) => {
    try {
      handleSetIsLoading(true);
      await api.post('/smartpay/positions', position);

      await getSmartPayPositions();
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const updateSmartPayPosition = async (
    id: string,
    data: CreateOrUpdateSmartPayPosition,
  ) => {
    try {
      handleSetIsLoading(true);
      await api.put(`/smartpay/positions/${id}`, data);
      await getSmartPayPositions();
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  const deleteSmartPayPosition = async (id: string) => {
    try {
      handleSetIsLoading(true);
      await api.delete(`/smartpay/positions/${id}`);
      const positionSmartPayFiltered = smartPayPositions.filter(
        (company) => company.id !== id,
      );
      setSmartPayPositions(positionSmartPayFiltered);
    } catch (error) {
      throw error;
    } finally {
      handleSetIsLoading(false);
    }
  };

  return (
    <AdminContext.Provider
      value={{
        pagination,
        companies,
        users,
        contributors,
        optionGroups,
        positions,
        positionsCompany,
        smartPayPositions,
        gradesMatriz,
        createCompany,
        updateCompany,
        getCompanys,
        deleteCompany,
        getUsers,
        createUser,
        updateUser,
        deleteUser,
        getContributors,
        loadQuestions,
        createQuestion,
        deleteQuestion,
        updateQuestions,
        updatePositionMatriz,
        updateTenantGradeMatriz,
        loadPositionsMatriz,
        loadGradesMatriz,
        getPositions,
        createPosition,
        deletePosition,
        updatePosition,
        getSmartPayPositions,
        createSmartPayPosition,
        updateSmartPayPosition,
        deleteSmartPayPosition,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
