import { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import {
  FiEdit,
  FiSearch,
  FiArrowLeft,
  FiArrowRight,
  FiAlertTriangle,
} from 'react-icons/fi';
import { FcHighPriority } from 'react-icons/fc';
import makeAnimated from 'react-select/animated';
import { Button } from '../../components/Button';
import {
  Container,
  Form,
  ContainerButton,
  Content,
  DatabaseData,
  IconAlert,
  IconFiArrowRight,
  Pagination,
  ArrowLeft,
  ArrowRight,
} from './styles';
import { api } from '../../services/api';
import { Tenant, OptionSelect } from '../../interfaces';
import { ModalGeneric } from '../../components/ModalGeneric';
import { toast } from 'react-toastify';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Input } from '../../components/Input';
import { ButtonPrimary } from '../DashboardUsers/styles';
import { MarketStatisticStatus } from '../../enums/market';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { useNavigate } from 'react-router-dom';
import './styles.css';
import { AppError } from '../../error/AppError';
import { ModalMarketSmoothing } from '../../components/ModalMarketSmoothing';
import { formatDateFull } from '../../shared/utils';

const animatedComponents = makeAnimated();

interface selectProps {
  label: string;
  value: string;
}

interface SubmitForm {
  filters: any;
  mercado: {
    value: string;
  };
  database: {
    value: string;
  };
  tipo: {
    value: string;
  };
  permissao: {
    value: string;
  };
  mesesRetroativos: {
    value: string;
  };
  metodologia: {
    value: string;
  };
  elegiveisComissao: {
    value: string | null;
  };
  niveis: {
    value: [];
  };
  cargaHoraria: {
    value: string | null;
  };
  familia: {
    value: [];
  };
  cargos: {
    value: [];
  };
  incentivosCurto: {
    value: string | null;
  };
  incentivosLongo: {
    value: string | null;
  };
  genero: {
    value: string;
  };
  raca: {
    value: string;
  };
  contratacao: {
    value: string;
  };
  empresas: {
    value: [];
  };
  companies: {
    value: [];
  };
  setores: {
    value: [];
  };
  month: {
    value: [];
  };
}

export default function MarketPublished() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcess, setIsProcess] = useState('');
  const [isPublicar, setIsPublicar] = useState('');
  const [isModalOpenReprocess, setIsModalOpenReprocess] = useState(false);
  const [isModalOpenPublicar, setIsModalOpenPublicar] = useState(false);
  const [isModalOpenDespublicar, setIsModalOpenDespublicar] = useState(false);
  const [isModalOpenClone, setIsModalOpenClone] = useState(false);
  const [setores, setSetores] = useState<OptionSelect[]>([]);
  const [companies, setCompanies] = useState<OptionSelect[]>([]);
  const [family, setFamily] = useState<selectProps[]>([]);
  const [familySelected, setFamilySelected] = useState<any>([]);
  const [position, setPosition] = useState<selectProps[]>([]);
  const [familyFilter, setFamilyFilter] = useState('');
  const [visibility, setVisibility] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [selectedOptionsSetores, setSelectedOptionsSetores] = useState([
    { label: 'Todos', value: '' },
  ]);
  const [clearTodos, setClearTodos] = useState(true);
  const [clearTodosSetores, setClearTodosSetores] = useState(true);
  const [selectedOptionsFamilia, setSelectedOptionsFamilia] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [clearTodosFamilia, setClearTodosFamilia] = useState(true);
  const [selectedOptionsCargos, setSelectedOptionsCargos] = useState([
    { label: 'N/A', value: '' },
  ]);
  const [clearTodosCargos, setClearTodosCargos] = useState(true);
  const [selectedOptionsNiveis, setSelectedOptionsNiveis] = useState([
    { label: 'Todos', value: '' },
  ]);
  const [clearTodosNiveis, setClearTodosNiveis] = useState(true);
  const [selectedOptionsCompanies, setSelectedOptionsCompanies] = useState([
    { label: 'Todas', value: '' },
  ]);
  const [clearTodosCompanies, setClearTodosCompanies] = useState(true);
  const [marketList, setMarketList] = useState<any>([]);
  const [isCreate, setIsCreate] = useState(true);
  const [marketData, setMarketData] = useState<any>(null);
  const [monthData, setMonthData] = useState<any>([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [isModalOpenErrorInfo, setIsModalOpenErrorInfo] = useState(false);
  const [isErrorInfo, setIsErrorInfo] = useState(true);
  const [disableInput, setDisableInput] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPagePositions, setLastPagePositions] = useState(1);
  const [statusProcess, setStatusProcess] = useState<any>('not_processed');
  const [isModalConfirmEdit, setIsModalConfirmEdit] = useState(false);
  const [isType, setIsType] = useState('');
  const [isReload, setIsReload] = useState(true);
  const [timer, setTimer] = useState(800000);
  const [timerReload, setTimerReload] = useState(10000);

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();

  const submit = async (data: SubmitForm) => {
    const {
      mercado,
      database,
      tipo,
      permissao,
      mesesRetroativos,
      metodologia,
      elegiveisComissao,
      cargaHoraria,
      incentivosCurto,
      incentivosLongo,
      genero,
      raca,
      contratacao,
      month,
    } = data;

    try {
      const dateG = new Date();
      const yearCurrent = new Date().getFullYear();
      const allfamily = selectedOptionsFamilia
        .filter((e) => e.label === 'Todas')
        .map((pos) => {
          return true;
        });
      const familia = familyFilter.split(',').map((pos) => {
        return pos;
      });

      const mercadoItem =
        disableInput || statusProcess !== 'not_processed'
          ? marketData.name
          : mercado;
      const tipoItem =
        disableInput || statusProcess !== 'not_processed'
          ? marketData.type
          : tipo;
      let cargos;
      if (typeof selectedOptionsCargos[0].label === 'undefined') {
        cargos = selectedOptionsCargos.map((pos) => {
          if (typeof pos.label !== 'undefined') {
            return pos.label;
          } else {
            return '';
          }
        });
      } else if (selectedOptionsCargos[0].label === 'N/A') {
        cargos = [];
      } else {
        cargos = selectedOptionsCargos.map((pos) => {
          return pos.label;
        });
      }

      let empresas;
      if (clearTodos) {
        empresas = [];
      } else {
        empresas = selectedOptions
          .filter((e) => typeof e != 'undefined')
          .map((pos) => {
            return pos.value;
          });
      }

      let setores;
      if (clearTodosSetores) {
        setores = [];
      } else {
        setores = selectedOptionsSetores
          .filter((e) => typeof e != 'undefined')
          .map((pos) => {
            return pos.value;
          });
      }

      let niveis;
      if (clearTodosNiveis) {
        niveis = [];
      } else {
        niveis = selectedOptionsNiveis
          .filter((e) => e.value !== 'Todos')
          .map((pos) => {
            return pos.value;
          });
      }

      niveis = niveis
        .filter((e) => e !== '')
        .map((pos) => {
          return pos;
        });

      let companiesProcess;
      if (clearTodosCompanies) {
        companiesProcess = [];
      } else {
        companiesProcess = selectedOptionsCompanies
          .filter((e) => e.value !== 'Todas')
          .map((pos) => {
            return pos.value;
          });
      }
      companiesProcess = companiesProcess
        .filter((e) => e !== '')
        .map((pos) => {
          return pos;
        });

      const dadosDate = database;
      const parseData = {
        name: mercadoItem,
        type: tipoItem,
        calculation_method: metodologia,
        visibility: permissao,
        retroactive_months: mesesRetroativos,
        companies_to_process: companiesProcess,
        month_reference:
          monthData[parseInt(String(dadosDate).split('-')[1]) - 1].split(
            '|',
          )[0],
        year_reference: String(dadosDate).split('-')[0],
        filters: {
          position_levels: niveis,
          monthly_workload: cargaHoraria ? cargaHoraria : 0,
          families: allfamily.length === 0 ? familia : [],
          positions_to_exclude: cargos,
          sales_commission_eligibility: elegiveisComissao
            ? elegiveisComissao
            : null,
          icp_eligibility: incentivosCurto ? incentivosCurto : null,
          ilp_eligibility: incentivosLongo ? incentivosLongo : null,
          gender: genero,
          ethnicity: raca,
          contract_type: contratacao,
          companies: empresas,
          sectors: setores,
        },
      };

      if (isCreate) {
        const retorno = await api.post('/markets/statistics', parseData);
        toast.success('Mercado criado com sucesso.');
        setIsModalOpen(false);
        reset();
        setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
        setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
        setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
        setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
        setSelectedOptions([{ label: 'Todas', value: '' }]);
        setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
        listMarket(currentPage);
      } else {
        const id = marketData.id;
        const retorno = await api.put(`/markets/statistics/${id}`, parseData);
        toast.success('Mercado criado com sucesso.');
        setIsModalOpen(false);
        reset();
        setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
        setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
        setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
        setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
        setSelectedOptions([{ label: 'Todas', value: '' }]);
        setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
        listMarket(currentPage);
      }
    } catch (err: any) {
      AppError(err);
    }
  };

  const levels = [
    { value: '', label: 'Todos' },
    { value: 'N0', label: 'N0' },
    { value: 'N1', label: 'N1' },
    { value: 'N2', label: 'N2' },
    // { value: 'Não valiados', label: 'Não avaliados' },
  ];

  async function loadCompanies() {
    const { data } = await api.get<Tenant[]>('/tenants?paginate=false');
    const tenants = data.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }));

    setCompanies([{ label: 'Todas', value: '' }, ...tenants]);
  }

  async function loadSetores() {
    const { data } = await api.get<Tenant[]>('/sectors/titles?paginate=false');
    const tenants = data.map((tenant) => ({
      label: tenant.title,
      value: tenant.id,
    }));
    setSetores([{ label: 'Todos', value: '' }, ...tenants]);
  }

  async function loadMonths() {
    const monthNames = [
      'janeiro|01',
      'fevereiro|02',
      'março|03',
      'abril|04',
      'maio|05',
      'junho|06',
      'julho|07',
      'agosto|08',
      'setembro|09',
      'outubro|10',
      'novembro|11',
      'dezembro|12',
    ];
    setMonthData(monthNames);
  }

  async function loadCargos() {
    let familiesFilter = '';
    await family.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesFilter += family.label + ',';
      }
    });

    const { data } = await api.get(`/smartpay/positions?${familiesFilter}`);
    const positionsList = data.map((pos) => {
      return {
        label: pos.title,
        value: pos.id,
      };
    });
    setPosition([{ label: 'N/A', value: '' }, ...positionsList]);
  }

  async function loadFamilies() {
    const { data } = await api.get('/smartpay/positions/subfamilies');

    const auxFamily: Array<string> = [];
    const families: any = data
      .map((family) => {
        const id = family.position_family.id as string;
        if (!auxFamily.includes(id)) {
          auxFamily.push(id);
          return {
            label: family.position_family.title,
            value: family.position_family.id,
          };
        }

        return null;
      })
      .filter((boolean) => boolean);

    setFamily([{ label: 'Todas', value: '' }, ...families]);
    loadCargos();
  }

  async function loadPositions() {
    if (familyFilter) {
      const filterFamily = `families=${familyFilter}`;
      const { data } = await api.get(`/smartpay/positions?${filterFamily}`);
      const positionsList = data.map((pos) => {
        return {
          label: pos.title,
          value: pos.id,
        };
      });
      setPosition([{ label: 'N/A', value: '' }, ...positionsList]);
    }
  }

  async function selectNiveis(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todos') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todos') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosNiveis) {
      setClearTodosNiveis(true);
      setSelectedOptionsNiveis([{ label: 'Todos', value: '' }]);
    } else {
      setClearTodosNiveis(false);
      setSelectedOptionsNiveis(dados);
    }
  }

  async function selectFamily(data: any) {
    setFamilySelected(data);
    setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosFamilia) {
      setClearTodosFamilia(true);
      setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodosFamilia(false);
      setSelectedOptionsFamilia(dados);
    }

    let familiesAll = '';
    await family.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesAll += family.label + ',';
      }
    });

    let familiesFilter = '';
    await data.map((family: selectProps) => {
      if (family.label !== 'Todas') {
        familiesFilter += family.label + ',';
      }
    });

    await setFamilyFilter(
      dados2 && !clearTodosFamilia
        ? familiesAll.substring(0, familiesAll.length - 1)
        : familiesFilter.substring(0, familiesFilter.length - 1),
    );
  }

  function selectCargos(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'N/A') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'N/A') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosCargos) {
      setClearTodosCargos(true);
      setSelectedOptionsCargos([{ label: 'N/A', value: '' }]);
    } else {
      setClearTodosCargos(false);
      setSelectedOptionsCargos(dados);
    }
  }

  function selectCompanies(data: any) {
    const dados = data.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      } else {
        return '';
      }
    });

    let dados2 = false;
    data.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosCompanies) {
      setClearTodosCompanies(true);
      setSelectedOptionsCompanies([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodosCompanies(false);
      setSelectedOptionsCompanies(dados);
    }
  }

  const handleChange = async (options) => {
    const dados = options.map(function (itens, i) {
      if (itens.label !== 'Todas') {
        return itens;
      }
    });

    let dados2 = false;
    options.map(function (itens, i) {
      if (itens.label === 'Todas') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodos) {
      setClearTodos(true);
      setSelectedOptions([{ label: 'Todas', value: '' }]);
    } else {
      setClearTodos(false);
      setSelectedOptions(dados);
    }
  };

  const handleChangeSetores = async (options) => {
    const dados = options.map(function (itens, i) {
      if (itens.label !== 'Todos') {
        return itens;
      }
    });

    let dados2 = false;
    options.map(function (itens, i) {
      if (itens.label === 'Todos') {
        dados2 = true;
      }
    });

    if (dados2 && !clearTodosSetores) {
      setClearTodosSetores(true);
      setSelectedOptionsSetores([{ label: 'Todos', value: '' }]);
    } else {
      setClearTodosSetores(false);
      setSelectedOptionsSetores(dados);
    }
  };

  function getDefaultValue() {
    return { label: 'Todas', value: '' };
  }

  async function listMarket(currentPage) {
    try {
      const limit = 10;
      handleSetIsLoading(true);
      setMarketList([]);
      const { data: dataMarket } = await api.get(
        `/markets/statistics/?page=${currentPage}&limit=${limit}`,
      );
      const dados = dataMarket.data;
      setMarketList(dados);
      setLastPagePositions(dataMarket.last_page);
      handleSetIsLoading(false);
    } catch (err) {
      handleSetIsLoading(false);
      toast.error('Erro ao buscar dados do Mercado!');
    }
  }

  const handleSelect = async (data: SubmitForm) => {
    reset();
    setMarketData('');
    setSelectedOptionsFamilia([]);
    setSelectedOptionsFamilia([{ label: 'Todas', value: '' }]);
    setMarketData(data);
    if (data.filters.position_levels) {
      const niveis: any = await data.filters.position_levels.map((nivel) => {
        return {
          label: nivel,
          value: nivel,
        };
      });
      setSelectedOptionsNiveis(niveis);
    }
    if (data.filters.families) {
      const familyList = await family
        .filter((e) => data.filters.families.indexOf(e.label) !== -1)
        .map((pos) => {
          return pos;
        });
      await selectFamily(familyList);
    }
    if (data.filters.companies) {
      const companiesList = await companies
        .filter((e) => data.filters.companies.indexOf(e.value) !== -1)
        .map((pos) => {
          return pos;
        });
      handleChange(companiesList);
    }
    if (data.filters.positions_to_exclude) {
      const cargosExclusList = await position
        .filter(
          (e) => data.filters.positions_to_exclude.indexOf(e.label) !== -1,
        )
        .map((pos) => {
          return pos;
        });
      await selectCargos(cargosExclusList);
    }
    if (data.filters.sectors) {
      const sectorsList = await setores
        .filter((e) => data.filters.sectors.indexOf(e.value) !== -1)
        .map((pos) => {
          return pos;
        });
      await handleChangeSetores(sectorsList);
    }
  };

  async function handleProccessConfirmed(isProcess: string) {
    try {
      const { data: dataProcess } = await api.post(
        `/markets/statistics/${isProcess}/process`,
      );
      toast.success('Processo  criado com sucesso.');
      listMarket(currentPage);
    } catch (err) {
      toast.error('Erro ao realizar o processar!');
    }
  }

  async function handleProccessDespublicarOk(isProcess: string) {
    try {
      const parseData = {
        status: false,
      };
      const retorno = await api.put(
        `/markets/statistics/${isPublicar}/publish`,
        parseData,
      );
      toast.success('Mercado Despublicado com sucesso!');
      listMarket(currentPage);
    } catch (err) {
      toast.error('Erro ao despublicar mercado!');
    }
  }

  function getMarketPtStatus({
    was_interpolated,
    status,
  }: {
    was_interpolated: boolean;
    status: string;
  }) {
    if (was_interpolated) {
      return 'Suavizado';
    }
    if (status === MarketStatisticStatus.NOT_PROCESSED.valueOf()) {
      return 'Não Processado';
    }
    if (status === MarketStatisticStatus.PROCESSED.valueOf()) {
      return 'Processado';
    }
    if (status === MarketStatisticStatus.PROCESSING.valueOf()) {
      return 'Processando';
    }
    if (status === MarketStatisticStatus.PUBLISHED.valueOf()) {
      return 'Publicado';
    }
    if (status === MarketStatisticStatus.IN_QUEUE.valueOf()) {
      return 'Em Fila';
    }
    return '';
  }

  useEffect(() => {
    loadPositions();
  }, [familyFilter]);

  const decrementTimer = useCallback(() => {
    setTimer((oldTimer) => oldTimer - 1);
  }, []);

  useEffect(() => {
    isModalOpen ? setTimer(0) : setTimer(8000000);
    if (timer <= 0) {
      return;
    }
    listMarket(currentPage);
    const timeoutFunction = setInterval(decrementTimer, timerReload);
    return () => clearInterval(timeoutFunction);
  }, [decrementTimer, timer]);

  useEffect(() => {
    isModalOpen ? setTimer(0) : setTimer(8000000);
  }, [isModalOpen]);

  useEffect(() => {
    isModalConfirmEdit ? setTimer(0) : setTimer(8000000);
  }, [isModalConfirmEdit]);

  useEffect(() => {
    loadMonths();
    listMarket(currentPage);
    loadCompanies();
    loadSetores();
    loadFamilies();
  }, [currentPage]);

  return (
    <Container>
      <h2 className="text-2xl font-bold">Mercados Publicados </h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 shadow-xl card bg-base-100">
          <div className="flex justify-end"></div>

          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th></th>
                  <th>Título do mercado</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th>Data base</th>
                  <th>Metodologia de cálculo</th>
                  <th>Data processamento</th>
                  <th>Usuário processamento</th>
                </tr>
              </thead>
              <tbody>
                {marketList && (
                  <>
                    {marketList.map((market, index) => (
                      <tr key={index}>
                        <td>
                          <FiSearch
                            style={{ cursor: 'pointer' }}
                            onClick={(e: any) => {
                              setIsModalOpen(true);
                              setIsCreate(false);
                              handleSelect(market);
                              setDisableInput(true);
                            }}
                          />
                        </td>
                        <td>{market.name}</td>
                        <td>
                          {market.type === 'grade'
                            ? 'SmrtGrade'
                            : market.type === 'position'
                            ? 'SmrtCargo'
                            : market.type === 'position-grade'
                            ? 'SmrtCargo/Grade'
                            : ''}
                        </td>
                        <td>{getMarketPtStatus(market)}</td>
                        <td>{`${market.month_reference} ${market.year_reference} `}</td>
                        <td>
                          {market.calculation_method == 'percentile'
                            ? 'Percentis'
                            : 'Média'}
                        </td>
                        <td>{formatDateFull(market.processed_date)}</td>
                        <td>{market.super_admin_created.name}</td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination>
        <ArrowLeft
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FiArrowLeft />
        </ArrowLeft>
        <span>{currentPage}</span>
        <ArrowRight
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === lastPagePositions}
        >
          <FiArrowRight />
        </ArrowRight>
      </Pagination>

      <ModalGeneric
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        full={true}
      >
        <div
          className="grid grid-cols-1 gap-4"
          style={{ width: '1800px !important' }}
        >
          <div className="pb-28 basis-4/5">
            {statusProcess !== 'not_processed' && (
              <div className="mt-5 mb-8 shadow-lg alert alert-warning">
                <div>
                  <FiAlertTriangle />
                  <span>
                    Atenção, todos os dados do mercado serão zerados e novos
                    dados serão gerados baseados nos parâmetros atualizados.
                  </span>
                </div>
              </div>
            )}

            <div className="overflow-x-auto w-[1300px]">
              <div className="flex">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Título do mercado
                        <label className="text-error">*</label>
                      </strong>
                    </label>
                    <input
                      className={
                        !!errors.mercado
                          ? 'input input-bordered input-error w-full max-w-xs'
                          : 'input input-bordered w-full max-w-xs'
                      }
                      type="text"
                      placeholder="Mercado"
                      {...register('mercado', {
                        required: true,
                        disabled:
                          disableInput || statusProcess !== 'not_processed'
                            ? true
                            : false,
                      })}
                      defaultValue={marketData ? marketData.name : ''}
                      // error={!!errors.mercado}
                      // helperText={!!errors.mercado ? 'Campo Obrigatório' : ''}
                    />
                  </div>
                  <Content>
                    {!!errors.mercado ? 'Campo Obrigatório' : ''}
                  </Content>
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Data base <label className="text-error">*</label>
                      </strong>
                    </label>
                    <DatabaseData>
                      <Input
                        type="month"
                        min={2018}
                        placeholder="Ano"
                        disabled={disableInput}
                        defaultValue={
                          marketData
                            ? marketData.year_reference +
                              '-' +
                              monthData
                                .filter(
                                  (e) =>
                                    e.split('|')[0] ==
                                    marketData.month_reference,
                                )
                                .map((monthLis) => {
                                  return monthLis.split('|')[1];
                                })
                            : ''
                        }
                        {...register('database', { required: true })}
                        error={!!errors.database}
                        // helperText={!!errors.database ? 'Campo Obrigatório' : ''}
                      />
                    </DatabaseData>
                    <Content>
                      {!!errors.database ? 'Campo Obrigatório' : ''}
                    </Content>
                  </div>
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Tipo
                        <label className="text-error">*</label>
                      </strong>
                    </label>
                    <select
                      {...register('tipo', {
                        required: true,
                        disabled:
                          disableInput || statusProcess !== 'not_processed'
                            ? true
                            : false,
                      })}
                      // disabled={
                      //   disableInput || statusProcess !== 'not_processed'
                      // }
                      onChange={(e: any) => {
                        setIsType(e.target.value);
                      }}
                      className={
                        !!errors.tipo
                          ? 'select select-error w-full max-w-xs'
                          : 'w-full max-w-xs select select-bordered'
                      }
                    >
                      <option selected></option>
                      <option
                        value="grade"
                        selected={
                          marketData ? 'grade' === marketData.type : false
                        }
                      >
                        SmrtGrade
                      </option>
                      <option
                        value="position"
                        selected={
                          marketData ? 'position' === marketData.type : false
                        }
                      >
                        SmrtCargo
                      </option>
                      <option
                        value="position-grade"
                        selected={
                          marketData
                            ? 'position-grade' === marketData.type
                            : false
                        }
                      >
                        SmrtCargo/Grade
                      </option>
                    </select>
                  </div>
                  <Content>{!!errors.tipo ? 'Campo Obrigatório' : ''}</Content>
                </div>
              </div>

              <div className="flex">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Permissão de visibilidade
                        <label className="text-error">*</label>
                      </strong>
                    </label>

                    <select
                      {...register('permissao', { required: true })}
                      className={
                        !!errors.permissao
                          ? 'select select-error w-full max-w-xs'
                          : 'w-full max-w-xs select select-bordered'
                      }
                      disabled={disableInput}
                      onChange={(e: any) => {
                        setVisibility(e.target.value);
                      }}
                    >
                      <option selected></option>
                      <option
                        value="internal"
                        selected={
                          marketData
                            ? 'internal' === marketData.visibility
                            : false
                        }
                      >
                        Uso interno
                      </option>
                      <option
                        value="community"
                        selected={
                          marketData
                            ? 'community' === marketData.visibility
                            : false
                        }
                      >
                        Comunidade
                      </option>
                      <option
                        value="sector"
                        selected={
                          marketData
                            ? 'sector' === marketData.visibility
                            : false
                        }
                      >
                        Setor
                      </option>
                    </select>
                  </div>
                  <Content>
                    {!!errors.permissao ? 'Campo Obrigatório' : ''}
                  </Content>
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Meses retroativos </strong>
                    </label>

                    <select
                      {...register('mesesRetroativos', { required: true })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected></option>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i: any) => (
                        <option key={i} selected={i === 6}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Content>
                    {!!errors.mesesRetroativos ? 'Campo Obrigatório' : ''}
                  </Content>
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Metodologia de cálculo
                        <label className="text-error">*</label>
                      </strong>
                    </label>

                    <select
                      {...register('metodologia', { required: true })}
                      disabled={disableInput}
                      className={
                        !!errors.permissao
                          ? 'select select-error w-full max-w-xs'
                          : 'w-full max-w-xs select select-bordered'
                      }
                    >
                      <option selected></option>
                      <option
                        value="percentile"
                        selected={
                          marketData
                            ? 'percentile' === marketData.calculation_method
                            : false
                        }
                      >
                        Percentis
                      </option>
                      <option
                        value="average"
                        selected={
                          marketData
                            ? 'average' === marketData.calculation_method
                            : false
                        }
                      >
                        Média
                      </option>
                    </select>
                  </div>
                  <Content>
                    {!!errors.metodologia ? 'Campo Obrigatório' : ''}
                  </Content>
                </div>
              </div>

              {(visibility && visibility === 'community') ||
              (marketData &&
                marketData.visibility === 'community' &&
                visibility === '') ? (
                <div className="flex">
                  <div className="flex-auto w-64">
                    <div className="w-full max-w-xs form-control">
                      <label className="label">
                        <strong className="label-text">Empresas</strong>
                      </label>

                      <Select
                        closeMenuOnSelect={false}
                        isDisabled={disableInput}
                        components={animatedComponents}
                        isMulti
                        options={companies}
                        value={selectedOptionsCompanies}
                        onChange={selectCompanies}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 650,
                          }),
                        }}
                      />
                    </div>
                    <Content>
                      {selectedOptionsCompanies.length === 0
                        ? 'Campo Obrigatório'
                        : ''}
                    </Content>
                  </div>
                </div>
              ) : null}

              <div className="divider">Filtros</div>

              <div className="flex">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Elegíveis a comissão
                      </strong>
                    </label>

                    <select
                      {...register('elegiveisComissao', { required: false })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        value="not-eligibles"
                        selected={
                          marketData
                            ? 'not-eligibles' ===
                              marketData.filters.sales_commission_eligibility
                            : false
                        }
                      >
                        Não elegíveis
                      </option>
                      <option
                        value="eligibles"
                        selected={
                          marketData
                            ? 'eligibles' ===
                              marketData.filters.sales_commission_eligibility
                            : false
                        }
                      >
                        Elegíveis
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.elegiveisComissao ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Níveis</strong>
                    </label>

                    <div className="form-control">
                      <Select
                        closeMenuOnSelect={false}
                        isDisabled={disableInput}
                        components={animatedComponents}
                        isMulti
                        value={selectedOptionsNiveis}
                        options={levels}
                        onChange={selectNiveis}
                      />
                    </div>
                    {/* <Content>
                      {selectedOptionsNiveis.length === 0
                        ? 'Campo Obrigatório'
                        : ''}
                    </Content> */}
                  </div>
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Carga horária </strong>
                    </label>

                    <select
                      {...register('cargaHoraria', { required: false })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        selected={
                          marketData
                            ? '220' == marketData.filters.monthly_workload
                            : false
                        }
                      >
                        220
                      </option>
                      <option
                        selected={
                          marketData
                            ? '180' == marketData.filters.monthly_workload
                            : false
                        }
                      >
                        180
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.cargaHoraria ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
              </div>

              <div className="flex pt-3">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Família</strong>
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      isDisabled={disableInput}
                      components={animatedComponents}
                      isMulti
                      options={family}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 600,
                        }),
                      }}
                      onChange={selectFamily}
                      value={selectedOptionsFamilia}
                    />
                  </div>
                  {/* <Content>
                    {selectedOptionsFamilia.length === 0
                      ? 'Campo Obrigatório'
                      : ''}
                  </Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Exclusão de cargos</strong>
                    </label>

                    <div className="form-control">
                      <Select
                        closeMenuOnSelect={false}
                        isDisabled={disableInput}
                        components={animatedComponents}
                        isMulti
                        options={position}
                        onChange={selectCargos}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 530,
                          }),
                        }}
                        value={selectedOptionsCargos}
                      />
                    </div>
                    <Content>
                      {selectedOptionsCargos.length === 0
                        ? 'Campo Obrigatório'
                        : ''}
                    </Content>
                  </div>
                </div>
              </div>

              <div className="flex pt-3">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Incentivos de curto prazo
                      </strong>
                    </label>

                    <select
                      {...register('incentivosCurto', { required: false })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        value="not-eligibles"
                        selected={
                          marketData
                            ? 'not-eligibles' ==
                              marketData.filters.icp_eligibility
                            : false
                        }
                      >
                        Não elegíveis
                      </option>
                      <option
                        value="eligibles"
                        selected={
                          marketData
                            ? 'eligibles' == marketData.filters.icp_eligibility
                            : false
                        }
                      >
                        Elegíveis
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.incentivosCurto ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">
                        Incentivos de longo prazo
                      </strong>
                    </label>

                    <select
                      {...register('incentivosLongo', { required: false })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        value="not-eligibles"
                        selected={
                          marketData
                            ? 'not-eligibles' ==
                              marketData.filters.ilp_eligibility
                            : false
                        }
                      >
                        Não elegíveis
                      </option>
                      <option
                        value="eligibles"
                        selected={
                          marketData
                            ? 'eligibles' == marketData.filters.ilp_eligibility
                            : false
                        }
                      >
                        Elegíveis
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.incentivosLongo ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Gênero</strong>
                    </label>

                    <select
                      {...register('genero', { required: false })}
                      disabled={disableInput}
                      className="w-[full] max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'masculino' == marketData.filters.gender
                            : false
                        }
                        value="masculino"
                      >
                        Masculino
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'feminino' == marketData.filters.gender
                            : false
                        }
                        value="feminino"
                      >
                        Feminino
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'outros' == marketData.filters.gender
                            : false
                        }
                        value="outros"
                      >
                        Outros
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.genero ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
              </div>

              <div className="flex pt-3">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Raça</strong>
                    </label>

                    <input
                      className="w-full max-w-xs input input-bordered"
                      type="text"
                      placeholder="Raça"
                      {...register('raca', {
                        required: false,
                        disabled: disableInput ? true : false,
                      })}
                      defaultValue={
                        marketData ? marketData.filters.ethnicity : ''
                      }
                    />
                  </div>
                  {/* <Content>{!!errors.raca ? 'Campo Obrigatório' : ''}</Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Contratação</strong>
                    </label>

                    <select
                      {...register('contratacao', { required: false })}
                      disabled={disableInput}
                      className="w-full max-w-xs select select-bordered"
                    >
                      <option selected value="">
                        Todos
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'CLT' == marketData.filters.contract_type
                            : false
                        }
                      >
                        CLT
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'PJ' == marketData.filters.contract_type
                            : false
                        }
                      >
                        PJ
                      </option>
                      <option
                        selected={
                          marketData
                            ? 'Outro' == marketData.filters.contract_type
                            : false
                        }
                      >
                        Outro
                      </option>
                    </select>
                  </div>
                  {/* <Content>
                    {!!errors.contratacao ? 'Campo Obrigatório' : ''}
                  </Content> */}
                </div>
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Empresas</strong>
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      {...register('companies', {
                        required:
                          selectedOptions.length <= 5 && !clearTodos
                            ? true
                            : false,
                      })}
                      isDisabled={disableInput}
                      name="companies"
                      components={animatedComponents}
                      isMulti
                      options={companies}
                      value={selectedOptions}
                      onChange={handleChange}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor:
                            selectedOptions.length <= 5 &&
                            !clearTodos &&
                            isType === 'grade'
                              ? 'red'
                              : selectedOptions.length <= 3 &&
                                !clearTodos &&
                                (isType === 'position-grade' ||
                                  isType === 'position')
                              ? 'red'
                              : '',
                        }),
                      }}
                    />
                  </div>
                  <Content>
                    {selectedOptions.length <= 5 &&
                    !clearTodos &&
                    isType === 'grade'
                      ? 'Campo Obrigatório com no minímo 6 empresas'
                      : ''}
                    {selectedOptions.length <= 3 &&
                    !clearTodos &&
                    (isType === 'position-grade' || isType === 'position')
                      ? 'Campo Obrigatório com no minímo 4 empresas'
                      : ''}
                  </Content>
                </div>
              </div>

              <div className="flex">
                <div className="flex-auto w-64">
                  <div className="w-full max-w-xs form-control">
                    <label className="label">
                      <strong className="label-text">Setor</strong>
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      {...register('setores', {
                        required:
                          selectedOptions.length <= 5 && !clearTodos
                            ? true
                            : false,
                      })}
                      isDisabled={disableInput}
                      name="setores"
                      components={animatedComponents}
                      isMulti
                      options={setores}
                      value={selectedOptionsSetores}
                      onChange={handleChangeSetores}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: 900,
                          borderColor:
                            selectedOptions.length <= 5 &&
                            !clearTodos &&
                            isType === 'grade'
                              ? 'red'
                              : selectedOptions.length <= 3 &&
                                !clearTodos &&
                                (isType === 'position-grade' ||
                                  isType === 'position')
                              ? 'red'
                              : '',
                        }),
                      }}
                    />
                  </div>
                  <Content>
                    {selectedOptions.length <= 5 &&
                    !clearTodos &&
                    isType === 'grade'
                      ? 'Campo Obrigatório com no minímo 6 empresas'
                      : ''}
                    {selectedOptions.length <= 3 &&
                    !clearTodos &&
                    (isType === 'position-grade' || isType === 'position')
                      ? 'Campo Obrigatório com no minímo 4 empresas'
                      : ''}
                  </Content>
                </div>
              </div>

              <div className="flex">
                <div className="flex-auto w-64 pt-7">
                  {/* <Button type="submit" onClick={saveConfiguration}>
                      Salvar parâmetros
                    </Button> */}
                  <Container>
                    <Form onSubmit={handleSubmit(submit)}>
                      {!disableInput && (
                        <ContainerButton>
                          <Button type="submit">
                            {isCreate
                              ? 'Salvar Parâmetros'
                              : 'Atualizar Parâmetros'}
                          </Button>
                        </ContainerButton>
                      )}
                    </Form>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenPublicar}
        setIsModalOpen={setIsModalOpenPublicar}
      >
        <div
          className="grid grid-cols-1 gap-4"
          style={{ width: '1800px !important' }}
        >
          <div className="overflow-x-auto w-[1450px]">
            <h3 className="text-lg font-bold">Publicar</h3>
            <br />
            <div className="flex">
              <ModalMarketSmoothing
                closeModal={() => setIsModalOpenPublicar(false)}
                marketId={isPublicar}
                marketType={marketData?.type}
              />
            </div>
          </div>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenDespublicar}
        setIsModalOpen={setIsModalOpenDespublicar}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
          Tem certeza que deseja Despublicar ?
          <br />
          <Button
            type="button"
            onClick={(e: any) => {
              setIsModalOpenDespublicar(false);
              handleProccessDespublicarOk(isProcess);
            }}
          >
            Confirmar
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => setIsModalOpenReprocess(false)}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenReprocess}
        setIsModalOpen={setIsModalOpenReprocess}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
          Tem certeza que deseja{' '}
          {isProcess === 'not_processed' ? 'processar' : 'reprocessar'}
          <br />
          <Button
            type="button"
            onClick={(e: any) => {
              setIsModalOpenReprocess(false);
              handleProccessConfirmed(isProcess);
            }}
          >
            Confirmar
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => setIsModalOpenReprocess(false)}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenClone}
        setIsModalOpen={setIsModalOpenClone}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '350px' }}>
          <div className="w-full max-w-xs form-control">
            <label className="label">
              <strong className="label-text">Título do mercado </strong>
            </label>
            <input
              type="text"
              placeholder=""
              className="w-full max-w-xs input input-bordered"
            />
          </div>
          <br />
          <Button type="button" onClick={() => setIsModalOpenClone(false)}>
            Confirmar clone
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => setIsModalOpenClone(false)}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalOpenErrorInfo}
        setIsModalOpen={setIsModalOpenErrorInfo}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '550px' }}>
          <div className="w-full max-w-xs form-control">
            <label className="label">
              <strong className="label-text">Descrição do Erro</strong>
            </label>
          </div>

          <div className="shadow-lg alert">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="flex-shrink-0 w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>{isModalOpenErrorInfo ? isErrorInfo : ''}</span>
            </div>
          </div>
          <button
            className="btn btn-ghost"
            onClick={() => setIsModalOpenErrorInfo(false)}
          >
            Fechar
          </button>
        </div>
      </ModalGeneric>

      <ModalGeneric
        isModalOpen={isModalConfirmEdit}
        setIsModalOpen={setIsModalConfirmEdit}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '550px' }}>
          Tem certeza que deseja editar os parâmetros?
          <br />
          <div className="mt-5 mb-8 shadow-lg alert alert-warning">
            <div>
              <FiAlertTriangle />
              <span>
                Atenção, todos os dados do mercado serão zerados e novos dados
                serão gerados baseados nos parâmetros atualizados.
              </span>
            </div>
          </div>
          <Button
            type="button"
            onClick={(e: any) => {
              setIsModalOpen(true);
              setIsModalConfirmEdit(false);
            }}
          >
            Confirmar
          </Button>
          <button
            className="btn btn-ghost"
            onClick={() => setIsModalConfirmEdit(false)}
          >
            Cancelar
          </button>
        </div>
      </ModalGeneric>
    </Container>
  );
}
