import styled from 'styled-components';
import ReactLoading from 'react-loading';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: ${(p) => (p.show ? 'block' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999999;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LoadingBox = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  top: 50%;
  left: 50%;
`;

export const Loader = styled(ReactLoading).attrs({
  color: 'var(--primary)',
})``;
