import { FiX } from 'react-icons/fi';
import { GradesToDelete } from '../../pages/GradesMatch';
import { Button } from '../Button';
import { ButtonOutline } from '../ButtonOutline';
import { ButtonPrimary } from '../ButtonPrimary';
import {
  BackgroundContainerModal,
  ContainerButton,
  HeaderModal,
  ContentModal,
  ObsAlert,
  CloseButtonContainer,
  GradesToDeleteContainer,
  GradesToDeleteDescription,
} from './styles';

interface ModalConfirmGradesToDeleteProps {
  handleCloseModalGradesToDeleteConfirm: () => void;
  handleGradesToDeleteConfirm: () => void;
  gradesToDelete: GradesToDelete[];
}

export function ModalConfirmGradesToDelete({
  handleCloseModalGradesToDeleteConfirm,
  handleGradesToDeleteConfirm,
  gradesToDelete,
}: ModalConfirmGradesToDeleteProps) {
  const getGradeTitle = () => {
    let title = '';

    if (gradesToDelete.length > 1) {
      title = ' grades carregados ';
    }

    if (gradesToDelete.length === 1) {
      title = ' grade carregado ';
    }

    return title;
  };

  return (
    <BackgroundContainerModal>
      <div>
        <CloseButtonContainer>
          <FiX onClick={handleCloseModalGradesToDeleteConfirm} />
        </CloseButtonContainer>
        <HeaderModal>
          <p>Upload Grade Match</p>
        </HeaderModal>

        {gradesToDelete.length >= 1 && (
          <>
            <GradesToDeleteDescription>
              Atenção! O arquivo atual não contempla {gradesToDelete.length}
              {getGradeTitle()} anteriormente. Deseja remover?
            </GradesToDeleteDescription>
            <ObsAlert>
              OBS: As grades removidas irã perder o vinculo de matching, bem
              como o vinculo com cargos da empresa feitos anteriormente.
            </ObsAlert>
            <ContentModal>
              {gradesToDelete.map((g) => (
                <GradesToDeleteContainer key={g.title}>
                  <p>Grade: {g.title}</p>
                  <span>
                    Existe match:
                    {!!g._count.tenant_grade_matches ? ' sim' : ' não'}
                  </span>
                  <span>
                    Qtd. Cargos vinculados: {String(g._count.tenant_positions)}
                  </span>
                </GradesToDeleteContainer>
              ))}
            </ContentModal>
            <ContainerButton>
              <ButtonOutline
                onClick={handleCloseModalGradesToDeleteConfirm}
                title="Cancelar"
              />
              <ButtonPrimary
                onClick={handleGradesToDeleteConfirm}
                title="Remover"
              />
            </ContainerButton>
          </>
        )}
      </div>
    </BackgroundContainerModal>
  );
}
