import { useEffect, useState } from 'react';
import { Container, ContainerButton, Form } from './styles';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import './styles.css';
import { AppError } from '../../error/AppError';
import { useForm } from 'react-hook-form';
import { Content } from '../../pages/MarketConfiguration/styles';
import { useParams } from 'react-router-dom';
import { FiPlus, FiXCircle } from 'react-icons/fi';
import { DatabaseData } from './styles';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import moment from 'moment';
import { DateProvider } from '../../shared/providers/date-provider';
import { BackButton } from '../../components/back-button';
import { Company } from '../../interfaces';
import { TENANT_TYPES_NAMES } from '../../shared/constants';
import { ConfirmDeletePlanModal } from './modals/confirm-delete-plan';
import { HttpStatus } from '../../enums/http-status';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { formatDateFull } from '../../shared/utils';

const dateProvider = new DateProvider();

export default function CompanyPlans() {
  const { id } = useParams();

  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const [isConfirmDeletePlanModalOpen, setIsConfirmDeletePlanModalOpen] =
  useState(false);
  const [company, setCompany] = useState<Company>();
  const [plans, setPlans] = useState<string[]>([]);
  const [planToDelete, setPlanToDelete] = useState<any>();
  const [planToEdit, setPlanToEdit] = useState<any>();
  const [disableSave, setDisableSave] = useState(true);
  const [itemAddRole, setItemAddRole] = useState<string>('');
  const [itemsAddPermission, setItemsAddPermission] = useState<string[]>([]);
  const [handleShow, setHandleShow] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const [disableInput, setDisableInput] = useState(false);
  const [isDtStart, setIsDtStart] = useState('');
  const [isDtEnd, setIsDtEnd] = useState('');
  const [itensPlans, setItensPlans] = useState<any>([
    {
      id: '',
      start_date: '',
      expire_date: '',
    },
  ]);
  const [isDtOk, setIsDtOk] = useState(false);
  const [isLodingDeletePlanRequest, setIsLodingDeletePlanRequest] =
    useState(false);
  const [isLodingEditPlanRequest, setIsLodingEditPlanRequest] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    try {
      const plans = itensPlans
        .filter((itemGroup) => itemGroup['plan'] != '')
        .map((item: any) => {
          return {
            id: item.id,
            start_date: item.start_date,
            expire_date: item.expire_date,
          };
        });

      const parseData = {
        plans: plans,
      };

      const retorno = await api.patch(`/tenants/${id}/plans`, parseData);

      if (retorno.status === 201 || retorno.status === 200) {
        toast.success('Plano salvo com sucesso.');
        getPlans();
        reset();
        setItemsAddPermission([]);
        setGroups([]);
        setIsModalOpenPermission(false);
        setItensPlans([]);
        getCompany();
        setIsDtStart('');
        setIsDtEnd('');
      }

      setDisableSave(true);
    } catch (error) {
      AppError(error, `Problemas salvar plano!`);
    }
  };

  async function getPlans() {
    const { data } = await api.get('/plans');
    setPlans(data);
  }
  
  async function getCompany() {
    const { data } = await api.get(`/tenants/${id}`);
    setCompany(data.tenant);
    setItensPlans([
      ...data.tenant.plans.map((p) => ({
        ...p,
        is_saved_plan: true,
      })),
    ]);
  }

  async function handleAddPermission() {
    if (itemAddRole) {
      const isExists: any = itensPlans.filter(
        (item: any) => item.id === itemAddRole,
      );

      if (!isExists.length) {
        let updatedValue = {};
        updatedValue = {
          id: itemAddRole,
          plan_id: itemAddRole,
          start_date: dateProvider.convertToUTCISO(isDtStart),
          expire_date: dateProvider.convertToUTCISO(isDtEnd),
          is_saved_plan: false,
        };

        setItensPlans((prev) => [...prev, updatedValue]);
        reset();
        setIsDtStart('');
        setIsDtEnd('');
        setDisableSave(false);
      } else {
        toast.error('Este plano já foi adicionado!');
      }
    }
  }

  async function handleEditPlanRequest() {
    if (planToEdit) {
      if (planToEdit.is_saved_plan) {
        try {
          setIsLodingEditPlanRequest(true);

          const updateTenantPlanDto = {
            start_date: planToEdit.start_date,
            expire_date: planToEdit.expire_date,
          };

          const response = await api.put(
            `/tenants/${id}/plans/${planToEdit.id}`,
            updateTenantPlanDto,
          );

          if (response && response.status === HttpStatus.OK) {
            getCompany();
            toast.success('Plano da empresa editado com sucesso!');
          }
        } catch {
          toast.error('Erro ao tentar editar plano da empresa!');
        } finally {
          setIsLodingEditPlanRequest(false);
          setPlanToEdit(undefined);
        }
      } else {
        const itemPlanIndexFound: any = itensPlans.findIndex(
          (item: any) => item.id === planToEdit.id,
        );
        const itemPlanCopy =
          itemPlanIndexFound !== -1 ? itensPlans[itemPlanIndexFound] : null;

        if (itemPlanCopy) {
          let updatedValue = {};
          updatedValue = {
            ...itemPlanCopy,
            start_date: planToEdit.start_date,
            expire_date: planToEdit.expire_date,
            is_saved_plan: false,
          };

          const itensPlansCopy: any[] = [...itensPlans];

          itensPlansCopy[itemPlanIndexFound] = updatedValue;

          setItensPlans([...itensPlansCopy]);
          setPlanToEdit(undefined);
        }
      }
    }
  }

  function handleSetDateOnEditPlan(date: string, type: 'start' | 'end') {
    if (planToEdit) {
      const typeDate = type === 'start' ? 'start_date' : 'expire_date';

      const planToEditCopy = {
        ...planToEdit,
      };

      planToEditCopy[typeDate] = dateProvider.convertToUTCISO(date);

      setPlanToEdit({
        ...planToEditCopy,
      });
    }
  }

  async function handleDeletePlanRequest() {
    if (planToDelete) {
      try {
        setIsLodingDeletePlanRequest(true);

        const response = await api.delete(
          `/tenants/${id}/plans/${planToDelete.id}`,
        );
        if (response && response.status === HttpStatus.OK) {
          getCompany();
          toast.success('Plano da empresa removido com sucesso!');
        }
      } catch {
        toast.error('Erro ao tentar remover plano da empresa!');
      } finally {
        setIsLodingDeletePlanRequest(false);
      }

      setIsConfirmDeletePlanModalOpen(false);
    }
  }

  function handleDeletePermission(plan?: any) {
    const planDelete = plan || planToDelete;

    if (planDelete) {
      itensPlans.splice(
        itensPlans.findIndex((e) => e.id === planDelete['id']),
        1,
      );
      if (itensPlans.length > 0) {
        setItensPlans((prev) => [...prev]);
      } else {
        setItensPlans((prev) => []);
      }
      setDisableSave(false);
      setIsConfirmDeletePlanModalOpen(false);
    }
  }

  function findPlanName(id: string) {
    const filterPlans: any = plans.filter(
      (permission: any) => permission.id === id,
    );
    return filterPlans.length ? filterPlans[0].name : '';
  }

  function resolveDate(dateCheck: string) {
    return dateProvider.dateToString(dateCheck as any);
  }

  function calculateDays(start_date: string, expire_date: string) {
    const resdaysBetweenult = moment(expire_date).diff(start_date, 'days');
    const days = resdaysBetweenult <= 0 ? 0 : resdaysBetweenult;
    return days;
  }

  function calculateMinutes(start_date: string, expire_date: string) {
    const resMinutesBetweenult = moment(expire_date).diff(
      start_date,
      'minutes',
    );
    const minutes = resMinutesBetweenult <= 0 ? 0 : resMinutesBetweenult;
    return minutes;
  }

  function isPlanSatusActive(expire_date: string) {
    const currentDate = moment().utcOffset(0, true).toISOString();
    const resHoursBetweenult = calculateMinutes(currentDate, expire_date);
    return resHoursBetweenult <= 0 ? false : true;
  }

  function getStatusTitle(expire_date: string) {
    const currentDate = moment().utcOffset(0, true).toISOString();
    const daysRemain = calculateDays(currentDate, expire_date);
    const minutesRemain = calculateMinutes(currentDate, expire_date);

    if (daysRemain > 0) {
      let day = 'dia';
      if (daysRemain > 1) {
        day = `${day}s`;
      }
      return `Restam ${daysRemain} ${day} para o plano expirar!`;
    }

    if (minutesRemain > 0) {
      const hoursRemain = Math.floor(minutesRemain / 60);
      const minutes = minutesRemain % 60;

      let hour = 'hora';
      if (minutesRemain > 1) {
        hour = `${hour}s`;
      }
      return `Restam ${hoursRemain}:${minutes} ${hour} para o plano expirar!`;
    }

    return 'Plano expirado!';
  }

  function handleDeletePlan(plan: any) {
    if (plan.is_saved_plan) {
      setIsConfirmDeletePlanModalOpen(true);
      setPlanToDelete(plan);
      return;
    }

    handleDeletePermission(plan);
  }

  function onCheckDate(expire_date: string) {
    if (isDtStart) {
      const resdaysBetweenult = moment(expire_date).diff(isDtStart, 'days');
      if (resdaysBetweenult <= 0) {
        toast.error('Data fim não pode ser menor ou igual a  data inicio!');
        setIsDtOk(false);
      } else {
        setIsDtOk(true);
      }
    } else {
      toast.error('Data inicio não foi definida!');
    }
  }

  function onCheckDateInicio(start_date: string) {
    if (isDtEnd) {
      const resdaysBetweenult = moment(isDtEnd).diff(start_date, 'days');
      if (resdaysBetweenult <= 0) {
        toast.error('Data fim não pode ser menor ou igual a  data inicio!');
        setIsDtOk(false);
      } else {
        setIsDtOk(true);
      }
    } else {
      toast.error('Data fim não foi definida!');
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    getCompany();
  }, [id]);

  return (
    <Container>
      <ConfirmDeletePlanModal
        planName={findPlanName(planToDelete?.plan_id)}
        isLoading={isLodingDeletePlanRequest}
        isConfirmDeletePlanModalOpen={isConfirmDeletePlanModalOpen}
        onCloseConfirmDeletePlan={() => {
          setPlanToDelete(undefined);
          setIsConfirmDeletePlanModalOpen(false);
        }}
        handleConfirmDeletePlan={() => {
          // handleDeletePermission();
          handleDeletePlanRequest();
        }}
      />
      <BackButton />

      <h2 className="text-2xl font-bold">Empresa - Plano</h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        <div className="p-4 shadow-xl card bg-base-100">
          {company ? (
            <div className="flex w-full mb-7">
              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">Empresa</strong>
                </label>
                {company.name}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">CNPJ</strong>
                </label>
                {company.cnpj}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">E-mail</strong>
                </label>
                {company.email}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">Tipo da empresa</strong>
                </label>
                {TENANT_TYPES_NAMES[company?.tenant_configurations?.[0]?.tenant_type || ''] || ''}
              </div>

              {company?.tenant_configurations?.[0]?.tenant_type === 'subsidiary' && (
                <div className="flex-auto w-64">
                  <label className="label">
                    <strong className="label-text">Holding</strong>
                  </label>
                  {company?.tenant_father?.name}
                </div>
              )}
            </div>
          ) : null}

          <Form onSubmit={handleSubmit(submit)}>
            <div className="grid gap-4 min-w-[800px] mb-8 border-t pt-5">
              <div className="flex w-full">
                <div className="flex-auto">
                  <label className="label">
                    <strong className="label-text">
                      Plano
                      <label className="text-error">*</label>
                    </strong>
                  </label>
                  <select
                    className="w-[350px] select select-bordered"
                    disabled={handleShow}
                    {...register('nomePlano', {
                      required: false,
                    })}
                    onChange={(e: any) => {
                      setItemAddRole(e.target.value);
                    }}
                  >
                    <option></option>
                    {plans.map((item: any) => (
                      <option value={item.id} key={item.name}>{item.name}</option>
                    ))}
                  </select>

                  {/* <Content>{!plans.length ? 'Campo Obrigatório' : ''}</Content> */}
                  <Content>
                    {!!errors.nomePlano ? 'Campo Obrigatório' : null}
                  </Content>
                </div>

                <div className="flex-auto w-8">
                  <label className="label">
                    <strong className="label-text">
                      Data de início
                      <label className="text-error">*</label>
                    </strong>
                  </label>

                  <DatabaseData>
                    <Input
                      type="date"
                      min={2018}
                      style={{ cursor: 'pointer' }}
                      disabled={disableInput}
                      onChange={(e: any) => {
                        setIsDtStart(e.target.value);
                        onCheckDateInicio(e.target.value);
                      }}
                    />
                  </DatabaseData>
                </div>

                <div className="flex-auto w-8">
                  <label className="label">
                    <strong className="label-text">
                      Data fim
                      <label className="text-error">*</label>
                    </strong>
                  </label>

                  <DatabaseData>
                    <Input
                      type="date"
                      min={2018}
                      style={{ cursor: 'pointer' }}
                      placeholder="Ano"
                      disabled={disableInput}
                      onChange={(e: any) => {
                        setIsDtEnd(e.target.value);
                        onCheckDate(e.target.value);
                      }}
                    // helperText={!!errors.database ? 'Campo Obrigatório' : ''}
                    />
                  </DatabaseData>
                </div>
                <div className="flex-auto w-2">
                  <label className="label">
                    <strong className="label-text">
                      Dias
                      <label className="text-error">*</label>
                    </strong>
                  </label>
                  <div className='mt-2'>
                    <strong className="text-3xl label-text">
                      {isDtEnd && isDtStart && (
                        <>{calculateDays(isDtStart, isDtEnd)}</>
                      )}
                      {!isDtEnd && !isDtStart && (
                        <strong className="label-text">-</strong>
                      )}
                    </strong>
                  </div>
                </div>

                <fieldset
                  style={{ marginTop: '31px' }}
                  className="btn btn-sm btn-success w-[50px] h-[50px] ml-3"
                  onClick={() => handleAddPermission()}
                  disabled={!isDtEnd || !isDtStart || !isDtOk ? true : false}
                >
                  <FiPlus />
                </fieldset>
              </div>
              <div className="flex w-full">
                <div className="flex-auto w-64">
                {itensPlans.length > 0 && (
                    <>
                      <table className="table w-full table-compact table-zebra">
                        <tbody>
                          <tr className="border-b dark:border-neutral-200">
                            <td>
                              <strong>Plano</strong>
                            </td>
                            <td>
                              <strong>Data Início </strong>
                            </td>
                            <td>
                              <strong>Data Fim </strong>
                            </td>
                            <td>Status</td>
                            <td>
                              <strong>Dias </strong>
                            </td>
                            <td>
                              <strong>Dias a Expirar</strong>
                            </td>
                            <td>
                              <strong>Ações</strong>
                            </td>
                          </tr>
                          {itensPlans
                            .filter((itemGroup) => itemGroup['id'] !== '')
                            .map((item: any, index: number) => (
                              <tr
                                className="border-b dark:border-neutral-200"
                                key={index}
                              >
                                <td>
                                  <strong>
                                    {findPlanName(item['plan_id'])}
                                  </strong>
                                </td>
                                <td>
                                  <div>
                                    {planToEdit && planToEdit.id === item.id ? (
                                      <DatabaseData>
                                        <Input
                                          type="date"
                                          min={2018}
                                          defaultValue={formatDateFull(
                                            item['start_date'],
                                            'en',
                                          )}
                                          style={{ cursor: 'pointer' }}
                                          placeholder="Ano"
                                          onChange={(e: any) => {
                                            handleSetDateOnEditPlan(
                                              e.target.value,
                                              'start',
                                            );
                                          }}
                                        />
                                      </DatabaseData>
                                    ) : (
                                      <strong>
                                        {resolveDate(item['start_date'])}
                                      </strong>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    {planToEdit && planToEdit.id === item.id ? (
                                      <DatabaseData>
                                        <Input
                                          type="date"
                                          min={2018}
                                          defaultValue={formatDateFull(
                                            item['expire_date'],
                                            'en',
                                          )}
                                          style={{ cursor: 'pointer' }}
                                          placeholder="Ano"
                                          onChange={(e: any) => {
                                            handleSetDateOnEditPlan(
                                              e.target.value,
                                              'end',
                                            );
                                          }}
                                        />
                                      </DatabaseData>
                                    ) : (
                                      <strong>
                                        {resolveDate(item['expire_date'])}
                                      </strong>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {isPlanSatusActive(item['expire_date']) ? (
                                    <div
                                      title={getStatusTitle(
                                        item['expire_date'],
                                      )}
                                      className="w-4 h-4 bg-green-500 border rounded-full"
                                    ></div>
                                  ) : (
                                    <div
                                      title={getStatusTitle(
                                        item['expire_date'],
                                      )}
                                      className="w-4 h-4 bg-red-500 border rounded-full"
                                    ></div>
                                  )}
                                </td>
                                <td>
                                  <strong>
                                    {calculateDays(
                                      item['start_date'],
                                      item['expire_date'],
                                    )}
                                  </strong>
                                </td>
                                <td>
                                  <strong>
                                    {calculateDays(
                                      moment().utcOffset(0, true).toISOString(),
                                      item['expire_date'],
                                    )}
                                  </strong>
                                </td>
                                <td>
                                  <div
                                    onClick={() => {
                                      if (planToEdit) {
                                        if (planToEdit.id !== item.id) {
                                          return;
                                        }
                                        handleEditPlanRequest();
                                        return;
                                      }

                                      setPlanToEdit(item);
                                    }}
                                    className="btn btn-sm btn-error w-[50px] h-[50px] mr-3"
                                    style={{
                                      backgroundColor:
                                        planToEdit && planToEdit.id !== item.id
                                          ? '#c8c7c7'
                                          : '',
                                    }}
                                  >
                                    {planToEdit && planToEdit.id === item.id ? (
                                      <FaCheck />
                                    ) : (
                                      <FaEdit />
                                    )}
                                  </div>
                                  <div
                                    onClick={() => {
                                      if (
                                        planToEdit &&
                                        planToEdit.id !== item.id
                                      ) {
                                        return;
                                      }
                                      if (!planToEdit) {
                                        handleDeletePlan(item);
                                        return;
                                      }

                                      setPlanToEdit(undefined);
                                    }}
                                    className="btn btn-sm btn-error w-[50px] h-[50px]"
                                    style={{
                                      backgroundColor: planToEdit
                                        ? '#c8c7c7'
                                        : '',
                                    }}
                                  >
                                    <FiXCircle />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>

            <ContainerButton>
              <Button type="submit" disabled={disableSave}>Salvar</Button>
            </ContainerButton>
          </Form>
        </div>
      </div>
    </Container>
  );
}
