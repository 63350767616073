import { useQuery } from 'react-query';
import { api } from '../../../services/api';

export type GetPlansResponse = {
  created_at: Date;
  description: string;
  id: string;
  name: string;
  price: number;
  roles: string[]
  tag: string
  updated_at: Date;
}[];

export function useGetPlans() {
  const {
    data: plans,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: 'get-plans',
    queryFn: async () => {
      const data = await api.get<GetPlansResponse>('/plans');

      if (data) {
        return data.data;
      }

      return null;
    },
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retry: 2,
  });

  return {
    plans,
    refetch,
    isLoading,
    isFetching,
  };
}
