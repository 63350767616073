/* eslint-disable no-nested-ternary */
import { ButtonHTMLAttributes, useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MenuButtonItem,
  MenuButtonSubButton,
  MenuButtonSubButtonsWrapper,
  MenuButtonWrapper,
} from './styles';

type MenuSubButtonType = {
  label: string;
  to: string;
  disabled: boolean;
};

type MenuButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  isCollapsed: boolean;
  routerPath: string;
  iconSrcGray?: string;
  iconSrcWhite?: string;
  iconSrcPrimary?: string;
  subButtons?: MenuSubButtonType[];
  isWithNestedRoutes?: boolean;
  disabled?: boolean;
  routerPrfix?: string;
};

export function MenuButton({
  label,
  iconSrcGray,
  iconSrcWhite,
  iconSrcPrimary,
  isCollapsed,
  routerPath,
  subButtons = [],
  isWithNestedRoutes = false,
  disabled = false,
  routerPrfix = '',
  ...props
}: MenuButtonProps) {
  const actualRouter = useLocation();
  const navigate = useNavigate();
  const [isHoverButton, setIsHoverButton] = useState(false);
  const [isOpenSubButtons, setIsOpenSubButtons] = useState(false);
  const [opendSubmenu, setOpendSubmenu] = useState(false);

  const { pathname } = actualRouter;
  const formattedRouter = routerPath[0] === '/' ? routerPath : `/${routerPath}`;
  const isPathActive =
    pathname === formattedRouter ||
    (routerPrfix && pathname.includes(routerPrfix));

  function isRouteActive() {
    const subRoute = subButtons.some((subButton) => pathname === subButton.to);
    const nestedRoute =
      isWithNestedRoutes && pathname.includes(formattedRouter);
    return isPathActive || subRoute || nestedRoute;
  }

  function isSubRouteActive(subRoute: string) {
    return pathname === subRoute;
  }

  const handleRedirect = (to: string) => {
    navigate(to);
  };

  const getIconSrc = useCallback(() => {
    const src = isCollapsed
      ? isRouteActive()
        ? iconSrcPrimary
        : iconSrcGray
      : isRouteActive()
      ? iconSrcWhite
      : iconSrcGray;

    if (!isRouteActive() && isHoverButton) {
      return iconSrcPrimary;
    }

    return src;
  }, [isCollapsed, isHoverButton, actualRouter]);

  return (
    <MenuButtonWrapper>
      <MenuButtonItem
        {...props}
        color={isRouteActive() && !isCollapsed ? 'selected' : 'notSelected'}
        onClick={() => {
          handleRedirect(formattedRouter);
          setIsOpenSubButtons(!isOpenSubButtons);
        }}
        state={isCollapsed ? 'collapsed' : undefined}
        onMouseEnter={() => setIsHoverButton(true)}
        onMouseLeave={() => setIsHoverButton(false)}
        disabled={disabled}
      >
        <img
          src={getIconSrc()}
          alt={label}
          style={{ opacity: disabled ? 0.3 : 1 }}
        />
        {!isCollapsed && (
          <>
            {disabled ? (
              <label className={`disabled-${disabled}`}>{label}</label>
            ) : (
              <span className={`disabled-${disabled}`}>{label}</span>
            )}
          </>
        )}
      </MenuButtonItem>

      {isRouteActive() && (
        <MenuButtonSubButtonsWrapper
          state={
            isCollapsed
              ? isOpenSubButtons && subButtons.length > 0
                ? 'collapsed'
                : 'inactive'
              : undefined
          }
          onMouseLeave={() => {
            if (opendSubmenu === true) {
              setIsOpenSubButtons(false);
              setOpendSubmenu(false);
            }
          }}
          onMouseOver={() => setOpendSubmenu(true)}
        >
          {isCollapsed && (
            <button
              type="button"
              className="close-subbuttons"
              aria-label="Close"
              onClick={() => setIsOpenSubButtons(false)}
            >
              <FiX />
            </button>
          )}

          {subButtons.map((item) => (
            <MenuButtonSubButton
              key={item.label}
              onClick={() => !item.disabled && handleRedirect(item.to)}
              type="button"
              state={
                item.disabled
                  ? 'disabled'
                  : isSubRouteActive(item.to)
                  ? 'selected'
                  : 'notSelected'
              }
            >
              {item.label}
            </MenuButtonSubButton>
          ))}
        </MenuButtonSubButtonsWrapper>
      )}
    </MenuButtonWrapper>
  );
}
