import styled from 'styled-components';
import ReactSelect from 'react-select';

export const BackgroundContainerModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--background-modal);
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: 5rem 0 0;
  color: var(--text-body);

  > div {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    width: 90%;
    background-color: var(--white);
    overflow-y: auto;
    z-index: 2;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--black-light);

  > p {
    padding: 1rem;
    font-weight: 700;
  }

  > svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  padding: 1rem;
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1rem;

  > div {
    width: 45%;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  width: 100%;
  max-width: 210px;
`;

export const SectionSelect = styled.div``;

export const Select = styled(ReactSelect)`
  max-width: 280px;
  margin-top: 2rem;
  width: 100%;
`;

export const Error = styled.p`
  height: 20px;
  margin: 10px 0 0;
  font-size: 0.75rem;
  color: var(--error);
`;
