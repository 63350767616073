import React, { createContext, useContext, useState } from 'react';

interface GlobalLoadingContextProps {
  isLoading: boolean;
  handleSetIsLoading: (isLoading: boolean) => void;
}

const GlobalLoadingContext = createContext<GlobalLoadingContextProps>(
  {} as GlobalLoadingContextProps,
);

interface GlobalLoadingContextProviderProps {
  children: React.ReactNode;
}

export function GlobalLoadingContextProvider({
  children,
}: GlobalLoadingContextProviderProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSetIsLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
  };

  return (
    <GlobalLoadingContext.Provider value={{ isLoading, handleSetIsLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
}

export function useGlobalLoading() {
  return useContext(GlobalLoadingContext);
}
