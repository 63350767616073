import styled from 'styled-components';

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--black-light);

  > p {
    padding: 1rem;
    font-weight: 700;
  }

  > svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  padding: 1rem;
  max-height: 70vh;
  min-height: 30vh;
  overflow-y: auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 50%;

    > div {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;

    > div {
      padding: 0 1rem;
      width: 100%;

      > div {
        max-width: 100%;
      }
    }
  };
`;

export const ContainerButton = styled.div`
  padding: 1rem 1rem 0;
  width: 100%;
  max-width: 210px;
`;
