import { useEffect, useState } from 'react';
import { FiEdit, FiPlus, FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { Menu } from '../../components/Menu';
import { ModalSubFamily } from '../../components/ModalSubFamily';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import {
  OptionSelect,
  PositionFamily,
  PositionSubFamily,
} from '../../interfaces';
import { api } from '../../services/api';
import { Select } from '../DashboardContributors/styles';
import { ButtonPrimary } from '../DashboardUsers/styles';
import * as s from './styles';

interface RequestSubFamiliesListProps {
  page?: number;
  limit?: number;
  title?: string;
  family?: string;
}

export default function SubFamilies() {
  const [subfamiliesList, setSubfamiliesList] = useState<PositionSubFamily[]>(
    [],
  );
  const { handleSetIsLoading } = useGlobalLoading();
  const [titleSearch, setTitleSearch] = useState('');
  const [familySelectOptions, setFamilySelectOptions] = useState<
    OptionSelect[]
  >([]);
  const [familySelected, setFamilySelected] = useState<OptionSelect>();
  const [currentSubFamilyEdit, setCurrentSubFamilyEdit] =
    useState<PositionSubFamily | null>(null);
  const [showModal, setShowModal] = useState(false);

  const list = async ({
    page = 1,
    limit = 10,
    title = '',
    family = '',
  }: RequestSubFamiliesListProps) => {
    handleSetIsLoading(true);

    let query = `?page=${page}&limit=${limit}`;

    if (title) {
      query = query.concat(`&title=${title}`);
    }

    if (family) {
      query = query.concat(`&family=${family}`);
    }

    try {
      const { data } = await api.get<PositionSubFamily[]>(
        `smartpay/positions/subfamilies${query}`,
      );
      setSubfamiliesList(data);
    } catch (err) {
      toast.error('Erro ao buscar dados!');
    }

    handleSetIsLoading(false);
  };

  const listFamiliesSelectable = async () => {
    try {
      const { data } = await api.get<PositionFamily[]>(
        `smartpay/positions/families`,
      );
      const familySelectData = data.map((pf) => {
        return {
          label: pf.title,
          value: pf.id,
        };
      });

      setFamilySelectOptions(familySelectData);
    } catch (err) {
      toast.error('Erro ao buscar dados!');
    }

    handleSetIsLoading(false);
  };

  const handleCreateUpdateSubFamily = async (data: PositionSubFamily) => {
    handleSetIsLoading(true);
    const dataParse = {
      title: data.title,
      description: data.description,
      position_family_id: data.position_family_id,
    };

    const url = 'smartpay/positions/subfamilies';

    try {
      if (currentSubFamilyEdit?.id) {
        await api.put(`${url}/${currentSubFamilyEdit.id}`, dataParse);

        toast.success('Alterado com sucesso!');
      } else {
        await api.post(url, dataParse);

        toast.success('Criado com sucesso!');
      }
      await list({});
      setShowModal(false);
    } catch (err: any) {
      console.log(err.message);
      toast.error('Erro ao efetuar operação!');
    }

    handleSetIsLoading(false);
  };

  useEffect(() => {
    const loadData = () => {
      list({ title: titleSearch, family: familySelected?.label });
      listFamiliesSelectable();
    };
    loadData();
  }, []);

  return (
    <s.Container>
      {showModal && (
        <ModalSubFamily
          closeModal={() => setShowModal(false)}
          callback={(data: any) => handleCreateUpdateSubFamily(data)}
          data={currentSubFamilyEdit}
          familyOptions={familySelectOptions}
        />
      )}
      <s.Content>
        <h2 className="text-2xl font-bold">Sub Famílias</h2>
        <div className="divider"></div>

        <s.MainContent>
          <s.ControlContainer>
            <Select
              onChange={(option) => setFamilySelected(option as OptionSelect)}
              options={familySelectOptions}
              isSearchable={true}
              isClearable={true}
              maxMenuHeight={150}
              placeholder="Escolha uma família"
            />
            <input
              type="text"
              className="input input-bordered w-full max-w-xs m-2 mt-0"
              onChange={(e) => setTitleSearch(e.currentTarget.value)}
              value={titleSearch}
            />
            <ButtonPrimary
              onClick={(e) => {
                list({
                  title: titleSearch,
                  family: familySelected?.label,
                });
              }}
            >
              <FiSearch />
              Buscar
            </ButtonPrimary>
            <ButtonPrimary
              onClick={(e) => {
                setCurrentSubFamilyEdit(null);
                setShowModal(true);
              }}
            >
              <FiPlus />
              Criar
            </ButtonPrimary>
          </s.ControlContainer>
          <div className="grid grid-cols-1 gap-4 mt-6">
            <div className="card bg-base-100 shadow-xl">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <td>Título</td>
                      <td>Descrição</td>
                      <td>Família</td>
                      <td>Ações</td>
                    </tr>
                  </thead>
                  <tbody>
                    {subfamiliesList && (
                      <>
                        {subfamiliesList.map((subFamily, index) => (
                          <tr key={index}>
                            <td>{subFamily.title}</td>
                            <td
                              style={{
                                textAlign: 'left',
                                width: '65%',
                                whiteSpace: 'inherit',
                                overflow: 'hidden',
                              }}
                            >
                              {subFamily.description}
                            </td>
                            <td>{subFamily.position_family.title}</td>
                            <td>
                              <div
                                style={{
                                  cursor: 'pointer',
                                }}
                              >
                                <FiEdit
                                  onClick={() => {
                                    setCurrentSubFamilyEdit(subFamily);
                                    setShowModal(true);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </s.MainContent>
      </s.Content>
    </s.Container>
  );
}
