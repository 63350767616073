import { Stitches } from "../../styles/stitches";

const CommonStyles = {
  borderRadius: '$default',
  padding: '0 $2',
  width: '100%',
  fontWeight: '$medium',
  transition: 'background-color 0.3s',
  fontSize: '$body',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$2',
  cursor: 'pointer',
  whiteSpace: 'nowrap',

  '&:disabled': {
    cursor: 'not-allowed',
  },
};

const CommonButton = Stitches.styled('button', {
  ...CommonStyles,

  variants: {
    size: {
      small: {
        height: '$1',
        border: '2px solid',

        '& > svg': {
          width: '$iconSmall',
          height: '$iconSmall',
        },
      },
      medium: {
        height: '$2',

        '& > svg': {
          width: '$iconMedium',
          height: '$iconMedium',
        },
      },
      large: {
        height: '$3',
        fontSize: '$subtitle',

        '& > svg': {
          width: '$iconLarge',
          height: '$iconLarge',
        },
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});

export const ButtonPrimaryWrapper = Stitches.styled(CommonButton, {
  '&:disabled': {
    background: '$gray100',
  },

  variants: {
    color: {
      primary: {
        background: '$primary',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$primaryDark',
        },

        '& > svg': {
          color: '$white',
        },
      },
      gray400: {
        background: '$gray400',
        color: '$white',

        '&:not(:disabled):hover': {
          background: '$gray300',
        },

        '& > svg': {
          color: '$white',
        },
      },
    },
  },

  defaultVariants: {
    color: 'primary',
  },
});

export const PaginationWrapper = Stitches.styled('div', {
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$1',

  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '$2',

    '& > strong': {
      fontSize: '$small',
      color: '$gray400',
    },
  },

  '& > strong': {
    fontSize: '$extraSmall',
    color: '$gray400',
  },
});

export const PaginationNumber = Stitches.styled('div', {
  width: '37px',
  height: '37px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid $gray80',
  borderRadius: '5px',
  cursor: 'pointer',
  backgroundColor: '$gray80',
  '&:hover': {
    backgroundColor: '$gray70',
  },

  variants: {
    bgcolor: {
      default: {
        backgroundColor: '$gray80',
      },
      selected: {
        backgroundColor: '$gray70',
        border: '2px solid $gray100',
      },
    },
    type: {
      disabled: {
        cursor: 'not-allowed',
      },
    },
  },
});
