import { FiX } from 'react-icons/fi';

import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  FooterModal,
  Button,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: () => void;
  openModal?: boolean;
  isLoading?: boolean;
  description?: string;
  type:
    | 'user'
    | 'company'
    | 'question'
    | 'position'
    | 'sector'
    | 'group'
    | 'plan';
}

const ModalConfirm = ({
  closeModal,
  openModal = true,
  callback,
  type,
  description = '',
}: Props) => {
  const title = {
    user: 'Usuário',
    company: 'Empresa',
    question: 'Pergunta',
    position: 'Cargo',
    sector: 'Setor',
    group: 'Grupo',
    plan: 'Plano',
  }
  const options = {
    user: 'esse usuário',
    company: 'essa empresa',
    question: 'essa pergunta',
    position: 'esse cargo',
    sector: 'esse setor',
    group: 'esse grupo',
    plan: 'esse plano',
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>Remover {title[type]}</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>
        <ContentModal>
          <p>Tem certeza que deseja remover {options[type]}?</p>
        </ContentModal>
        {description && (
          <ContentModal>
            <p>{description}</p>
          </ContentModal>
        )}
        <FooterModal>
          <Button default={true} onClick={closeModal}>
            Cancelar
          </Button>
          <Button default={false} onClick={callback}>
            Confirmar
          </Button>
        </FooterModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalConfirm };
