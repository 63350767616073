import styled from 'styled-components';

export const FileUploaderContainer = styled.div`
  display: flex;
`;

export const InputUploader = styled.input`
  display: none;
`;

export const ChoseFileButton = styled.button`
  background-color: var(--primary);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ChoseFileContainer = styled.div`
  display: flex;
  margin-right: 1rem;
`;

interface FileChosenBoxProps {
  isError: boolean;
}

export const FileChosenBox = styled.div<FileChosenBoxProps>`
  ${(p) =>
    p.isError &&
    `
        border: 1px red solid;
    `}
  padding: 0.5rem;
  display: flex;
  width: 12rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center;
  background-color: #eee;
`;

export const FileUploadButton = styled.button`
  background-color: var(--primary);
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  color: #fff;
  border-radius: 5px;

  &:hover {
    background-color: var(--primary-hover);
  }
`;

export const FileChosenTitle = styled.span``;

export const ErrorMessage = styled.span`
  font-size: 0.8rem;
  color: red;
  font-style: italic;
`;
