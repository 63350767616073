import { useCallback, useEffect, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Content } from '../../pages/MarketConfiguration/styles';
import { AppError } from '../../error/AppError';
import { api } from '../../services/api';
import {
  formatClass,
  defaultValueSlope,
  formatBrDecimalCasa,
  generateRandString,
  isObjEmpty,
  removeDuplicate,
} from '../../shared/utils';
import CurrencyInput from 'react-currency-masked-input';
import { Container, ContainerButton, Select } from './styles';
import { Button } from '../../components/Button';
import styled from 'styled-components';
import ReactLoading from 'react-loading';
import './styles.css';
import { ArrowRight } from '../../pages/DashboardCompany/styles';
import { toast } from 'react-toastify';
import CurrencyInput2 from 'react-currency-input-field';
import { CustomSelect, OptionSelect } from '../Select';
import { InputEditable } from './InputEditable';
import { MarketLines, MarketPercentils, MarketTypes } from '../../shared/types';
import { SingleCheckbox } from '../checkboxs';
import { MarketItemData } from '../../interfaces';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { MarketTableGradePublish } from './MarketTableGradePublish';
import { MarketTablePositionPublish } from './MarketTablePositionPublish';

interface Props {
  marketId: string;
  marketType: MarketTypes;
  closeModal: () => void;
  afterPublish?: () => void;
}

interface ItemsParsed {
  id: string;
  p10_interpolated_personalized?: number | null;
  p25_interpolated_personalized?: number | null;
  p50_interpolated_personalized?: number | null;
  p75_interpolated_personalized?: number | null;
  p90_interpolated_personalized?: number | null;
}

export interface InputValues {
  id: string;
  type: string;
  market: any;
  value: string;
  selected?: boolean;
}

export interface MarketItemDataCustom extends MarketItemData {
  position_selected?: boolean;
}

export interface SelectedMarketPosition {
  [market_id: string]: {
    system_position_id: string;
    selected: boolean;
  };
}

const ModalMarketSmoothing = ({
  closeModal,
  marketId,
  marketType,
  afterPublish,
}: Props) => {
  const [marketDataOriginal, setMarketDataOriginal] = useState<
    MarketItemDataCustom[]
  >([]);
  const [marketData, setMarketData] = useState<MarketItemDataCustom[]>([]);
  const [isAbleToPublish, setIsAbleToPublish] = useState(true);
  const [valueNew, setValueNew] = useState<any>([]);
  const [valueNew10, setValueNew10] = useState<InputValues[]>([]);
  const [valueNew25, setValueNew25] = useState<InputValues[]>([]);
  const [valueNew50, setValueNew50] = useState<InputValues[]>([]);
  const [valueNew75, setValueNew75] = useState<InputValues[]>([]);
  const [valueNew90, setValueNew90] = useState<InputValues[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [publish, setPublish] = useState<boolean>(false);
  const [dadosAlterados, setDadosAlterados] = useState<boolean>(false);
  const [variationClass10, setVariationClass10] = useState<any>([]);
  const [variationClass25, setVariationClass25] = useState<any>([]);
  const handleFocus = (event) => event.target.select();
  const [valueSlope10, setValueSlope10] = useState<any>([]);
  const [grades, setGrades] = useState<OptionSelect[]>([]);
  const [minGradeRangeFixed, setMinGradeRangeFixed] = useState('');
  const [maxGradeRangeFixed, setMaxGradeRangeFixed] = useState('');
  const [minGradeRange, setMinGradeRange] = useState<OptionSelect>();
  const [maxGradeRange, setMaxGradeRange] = useState<OptionSelect>();
  const [selectedIntermedOptionLine, setSelectedIntermedOptionLine] =
    useState<OptionSelect>({
      value: 'sb',
      label: 'SB',
    });
  const [subFamiliesOptions, setSubfamiliesOptions] = useState<OptionSelect[]>(
    [],
  );
  const [subFamilySelected, setSubFamilySelected] = useState<OptionSelect>();
  const [selectedMarketPositions, setSelectedMarketPositions] =
    useState<SelectedMarketPosition>({});
  const { handleSetIsLoading } = useGlobalLoading();
  const [showOnlyWithValues, setShowOnlyWithValues] = useState(false);

  const linhas = [
    {
      value: 'sb',
      label: 'SB',
    },
    {
      value: 'tda',
      label: 'TDA',
    },
    {
      value: 'td',
      label: 'TD',
    },
    {
      value: 'icp',
      label: 'ICP',
    },
    {
      value: 'ilp',
      label: 'ILP',
    },
    {
      value: 'rd',
      label: 'RD',
    },
    {
      value: 'rda',
      label: 'RDA',
    },
  ];

  const checkIsAbleToSelectPosition = (
    market: MarketItemDataCustom,
    checkForCurrent = false,
  ) => {
    if (checkForCurrent) {
      const current = valueNew50.find((v) => v.market.id === market.id);
      if (current && Number(current?.value)) {
        return true;
      }
    }

    if (market.p50_interpolated_personalized) {
      return true;
    } else if (!market.p50_interpolated_personalized && market.p50) {
      return true;
    } else if (!market.p50_interpolated_personalized && !market.p50) {
      return false;
    } else {
      return false;
    }
  };

  const getPositionsSelectedIds = () => {
    const positionsSelected: string[] = [];
    Object.keys(selectedMarketPositions).map((key) => {
      if (selectedMarketPositions[key].selected) {
        positionsSelected.push(
          selectedMarketPositions[key].system_position_id as string,
        );
      }
    });

    return positionsSelected;
  };

  async function listMarketData() {
    try {
      if (marketId) {
        const positionsSelected: string[] = getPositionsSelectedIds();
        
        const { data } = await api.get(`/markets/statistics/items/`, {
          params: {
            market_statistic_id: marketId,
            statistic_name: selectedIntermedOptionLine.value,
            sub_family_id: subFamilySelected?.value,
            min_grade_range: Number(minGradeRange?.value || 0),
            max_grade_range: Number(maxGradeRange?.value || 0),
            market_positions_selected_ids: isObjEmpty(selectedMarketPositions)
              ? null
              : positionsSelected.join(','),
            limit: 3000,
          },
        });

        if (data && data.data.length > 0) {
          const selectedMaketPositions: SelectedMarketPosition = {};

          const marketListData: MarketItemDataCustom[] = data.data.map(
            (item: MarketItemDataCustom) => {
              if (marketType === 'position') {
                selectedMaketPositions[item.id] = {
                  system_position_id: item.system_position_id as string,
                  selected: checkIsAbleToSelectPosition(item),
                };
              }

              if (marketType === 'grade') {
                if (
                  Number(item.grade) === 7 ||
                  Number(item.grade) === 13 ||
                  Number(item.grade) === 19
                ) {
                  item.checked = true;
                  item.is_break_point = true;
                } else {
                  item.checked = item.is_break_point === true ? true : false;
                }
              }

              return item;
            },
          );

          setMarketDataOriginal(marketListData);
          setMarketData(marketListData);
          if (marketType === 'position') {
            setSelectedMarketPositions(selectedMaketPositions);
          }

          const grades = marketListData.map((d) => ({
            label: d.grade,
            value: d.grade,
          })) as { label: string; value: string }[];
          setGrades(grades);

          // if (data?.extra) {
          //   setIsAbleToPublish(data.extra.is_able_to_publish);
          // }
        }
      }
      //  handleSetIsLoading(false);
    } catch (error) {
      AppError(error, `Problemas ao carregar mercado!`);
    }
  }

  const filterOnlyWithValues = useCallback(
    (marketData: MarketItemDataCustom[] = []) => {
      const data = marketData.filter((market) => {
        if (
          market.p10_final ||
          market.p25_final ||
          market.p50_final ||
          market.p75_final ||
          market.p90_final
        ) {
          return true;
        }

        return false;
      });

      setMarketData(data);
    },
    [],
  );

  useEffect(() => {
    if (showOnlyWithValues) {
      filterOnlyWithValues(marketDataOriginal);
    } else {
      setMarketData([...marketDataOriginal]);
    }
  }, [showOnlyWithValues]);

  async function handlePublishValues() {
    setPublish(true);
  }

  const getItemsParsed = async (
    getJustEdited = true,
  ): Promise<ItemsParsed[]> => {
    const values = [...valueNew10, ...valueNew25, ...valueNew50, ...valueNew75, ...valueNew90];
    const idsFound = removeDuplicate(values.map(data => data.id));

    const items: ItemsParsed[] = idsFound.map((id) => {
      const defaultValues = marketData.find(m => m.id === id);
 
      const p10DefaultFound = defaultValues?.p10_final;
      const p25DefaultFound = defaultValues?.p25_final;
      const p50DefaultFound = defaultValues?.p50_final;
      const p75DefaultFound = defaultValues?.p75_final;
      const p90DefaultFound = defaultValues?.p90_final;
      const p10Found = values.find((v) => v.id === id && v.type === 'p10');
      const p25Found = values.find((v) => v.id === id && v.type === 'p25');
      const p50Found = values.find((v) => v.id === id && v.type === 'p50');
      const p75Found = values.find((v) => v.id === id && v.type === 'p75');
      const p90Found = values.find((v) => v.id === id && v.type === 'p90');

      
      const p10Value =  p10Found?.value === '' ? null : Number(p10Found?.value);
      const p10EditedExists =  p10Found !== undefined;
      const p25Value =  p25Found?.value === '' ? null : Number(p25Found?.value);
      const p25EditedExists =  p25Found !== undefined;
      const p50Value =  p50Found?.value === '' ? null : Number(p50Found?.value);
      const p50EditedExists =  p50Found !== undefined;
      const p75Value =  p75Found?.value === '' ? null : Number(p75Found?.value);
      const p75EditedExists =  p75Found !== undefined;
      const p90Value =  p90Found?.value === '' ? null : Number(p90Found?.value);
      const p90EditedExists =  p90Found !== undefined;

      const valuesParsed = {
        id,
        p10_interpolated_personalized: p10EditedExists && p10Value !== p10DefaultFound ? p10Value : undefined,
        p25_interpolated_personalized: p25EditedExists && p25Value !== p25DefaultFound ? p25Value : undefined,
        p50_interpolated_personalized: p50EditedExists && p50Value !== p50DefaultFound ? p50Value : undefined,
        p75_interpolated_personalized: p75EditedExists && p75Value !== p75DefaultFound ? p75Value : undefined,
        p90_interpolated_personalized: p90EditedExists && p90Value !== p90DefaultFound ? p90Value : undefined,
      };

      return valuesParsed;
    });
    
    return items;
  };

  async function handleSaveValues() {
    handleSetIsLoading(true);
    const itemsMustUpdateGeral = await getItemsParsed();

    const itemsMustUpdate = itemsMustUpdateGeral.filter(
      (x) => typeof x !== 'undefined',
    );

    if (itemsMustUpdate.length > 0) {
      if (marketId) {
        setLoad(true);
        await api.put(
          `/markets/statistics/${marketId}/items/personalizations`,
          {
            personalizations: itemsMustUpdate,
          },
        );
        setDadosAlterados(false);
        handleSetIsLoading(false);
        toast.success('Dados suavizados gravados com sucesso.');
        listMarketData();
        setLoad(false);
        return;
      }
    }

    handleSetIsLoading(false);
  }

  const getLabelPercentilByMarketType = (
    marketType,
    marketPercentils: MarketPercentils = 'p10',
  ) => {
    let label = marketPercentils.toUpperCase();
    if (marketType === 'grade') {
      label = label + ' Suavizado';
    }
    return label;
  };

  async function handleValue(
    market: MarketItemDataCustom,
    value: any,
    type: string,
  ) {
    let baseValue: any[] = [];
    let baseSetState: any = null;
    const valueParsed = value
      .replace('R$', '')
      .replace('.', '')
      .replace('.', '')
      .replace('.', '')
      .replace(',', '.')
      .trim();

    if (type === 'p10') {
      baseValue = valueNew10;
      baseSetState = setValueNew10;
    }
    if (type === 'p25') {
      baseValue = valueNew25;
      baseSetState = setValueNew25;
    }
    if (type === 'p50') {
      baseValue = valueNew50;
      baseSetState = setValueNew50;

      if (marketType === 'position' && !Number(valueParsed)) {
        const selectedCopy = selectedMarketPositions;
        setSelectedMarketPositions({
          ...selectedCopy,
          [market.id]: {
            ...selectedCopy[market.id],
            selected: false,
          },
        });
      }
    }
    if (type === 'p75') {
      baseValue = valueNew75;
      baseSetState = setValueNew75;
    }
    if (type === 'p90') {
      baseValue = valueNew90;
      baseSetState = setValueNew90;
    }

    setDadosAlterados(true);
    baseSetState(baseValue.filter((x) => x.id !== market.id));
    baseSetState((prevFriends) => [
      ...prevFriends,
      {
        id: market.id,
        type: type,
        market: market,
        value: valueParsed,
      },
    ]);
  }

  async function handleGetSubFamilies() {
    try {
      const { data } = await api.get('/smartpay/positions/subfamilies');
      const subFamiliesOptions = data.map((d) => ({
        label: d.title,
        value: d.id,
      }));
      setSubfamiliesOptions(subFamiliesOptions);
    } catch (err) {
      toast.error('Erro ao buscar subfamilias!');
    }
  }

  async function handleProcessPublicarOk() {
    try {
      handleSetIsLoading(true);

      const parseData = {
        status: true,
        min_grade_range: minGradeRange?.value || '',
        max_grade_range: maxGradeRange?.value || '',
        market_positions_selected_ids: getPositionsSelectedIds(),
      };
      const { data: dataProcess } = await api.put(
        `/markets/statistics/${marketId}/publish`,
        parseData,
      );

      handleSetIsLoading(false);
      setDadosAlterados(false);
      toast.success(
        'Mercado Publicado com sucesso. Valide as permissões de visibilidade.',
      );
      if(afterPublish) {
        afterPublish();
      }
    } catch (err) {
      handleSetIsLoading(false);
      toast.error('Erro ao publicar o Mercado!');
    }
  }

  const getInputTooltipValue = (
    item: any,
    type: 'p10' | 'p25' | 'p50' | 'p75' | 'p90',
  ) => {
    const prefix = ['icp', 'ilp'].includes(selectedIntermedOptionLine.value)
      ? ''
      : 'R$ ';

    const was_personalized = item[`was_${type}_interpolated_personalized`];

    const valueLabel = marketType === 'position' ? "Bruto" : "Suavizado";

    if(was_personalized) {
      return (
        `Personalizado manual: ${prefix}` +
        formatBrDecimalCasa(item[`${type}_interpolated_personalized`])
      );
    }

    if (item[`${type}_final`] === null) {
      return '-';
    }
    
    return `${valueLabel} base: ${prefix}` + formatBrDecimalCasa(item[`${type}_final`]);
  };

  const isGradeBetweenMinAndMax = (grade: string) => {
    if (minGradeRange?.value && maxGradeRange?.value) {
      const gradeNumber = Number(grade);
      if (
        gradeNumber >= Number(minGradeRange.value) &&
        gradeNumber <= Number(maxGradeRange.value)
      ) {
        return true;
      }
    }

    return false;
  };

  const handleSetGradeRange = (option: OptionSelect, type: 'min' | 'max') => {
    if (type === 'min') {
      if (
        maxGradeRange?.value &&
        Number(option.value) >= Number(maxGradeRange.value)
      ) {
        toast.error('O mínimo deve ser menor que o máximo range!');
        return;
      }

      setMinGradeRange(option);
    }

    if (type === 'max') {
      if (
        minGradeRange?.value &&
        Number(option.value) <= Number(minGradeRange.value)
      ) {
        toast.error('O máximo deve ser maior que o mínimo range!');
        return;
      }

      setMaxGradeRange(option);
    }

    listMarketData();
  };

  const handleCheckedPosition = (
    checked: boolean,
    market: MarketItemDataCustom,
  ) => {
    const selectedCopy = selectedMarketPositions;
    const isAbleToSelectPosition = checkIsAbleToSelectPosition(market, true);

    if (checked && !isAbleToSelectPosition) {
      toast.error(
        `A mediana para o cargo: "${market.role}" deve ser preenchida!`,
      );

      setSelectedMarketPositions({
        ...selectedCopy,
        [market.id]: {
          ...selectedCopy[market.id],
          selected: false,
        },
      });
      return;
    }

    if (checked && isAbleToSelectPosition) {
      setSelectedMarketPositions({
        ...selectedCopy,
        [market.id]: {
          ...selectedCopy[market.id],
          selected: true,
        },
      });
      return;
    }

    setSelectedMarketPositions({
      ...selectedCopy,
      [market.id]: {
        ...selectedCopy[market.id],
        selected: false,
      },
    });
  };

  useEffect(() => {
    if (
      grades.length &&
      !minGradeRange &&
      !maxGradeRange &&
      marketType === 'grade'
    ) {
      const min = grades[grades.length - 1];
      const max = grades[0];
      setMinGradeRangeFixed(min.value);
      setMaxGradeRangeFixed(max.value);

      setMinGradeRange(min);
      setMaxGradeRange(max);
    }
  }, [grades]);

  useEffect(() => {
    setMarketData([]);
    setGrades([]);
    listMarketData();
    handleGetSubFamilies();
  }, [selectedIntermedOptionLine, subFamilySelected]);

  return (
    <>
      {!publish ? (
        <>
          {!marketData.length ? (
            <>
              <div className="w-full text-right"></div>
              <div className="w-full text-right">
                <ReactLoading
                  type="spin"
                  color={'#F56A00'}
                  height={67}
                  width={75}
                />
                ;
              </div>
            </>
          ) : (
            <div>
              <div className="flex items-center w-full mb-2">
                <div className="flex items-center mr-4">
                  <span className="w-14 ">Linha</span>
                  <div>
                    <CustomSelect
                      onChange={(option) => {
                        setSelectedIntermedOptionLine(option as OptionSelect);
                      }}
                      enableDot
                      options={linhas}
                      isSearchable={false}
                      maxMenuHeight={350}
                      value={linhas.find(
                        (option) =>
                          option.value === selectedIntermedOptionLine.value,
                      )}
                      placeholder="Linha"
                    />
                  </div>
                </div>
                {marketType === 'grade' ? (
                  <>
                    <div className="flex items-center mr-8">
                      <span className="mr-2 w-34">Grade min.</span>
                      <div>
                        <CustomSelect
                          onChange={(option) => {
                            if (option) {
                              handleSetGradeRange(option, 'min');
                            } else {
                              setMinGradeRange(undefined);
                            }
                          }}
                          options={grades}
                          isSearchable={false}
                          maxMenuHeight={350}
                          value={minGradeRange}
                          placeholder="min"
                          isClearable={false}
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <span className="mr-2 w-34">Grade max.</span>
                      <div>
                        <CustomSelect
                          onChange={(option) => {
                            if (option) {
                              handleSetGradeRange(option, 'max');
                            } else {
                              setMaxGradeRange(undefined);
                            }
                          }}
                          options={grades}
                          isSearchable={false}
                          maxMenuHeight={350}
                          value={maxGradeRange}
                          placeholder="max"
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {!minGradeRange && !maxGradeRange && (
                  <>
                    <div className="flex items-center ml-4 mr-4">
                      <span className="mr-2 w-34">Sub. Familia</span>
                      <div>
                        <CustomSelect
                          onChange={(option) => {
                            setSubFamilySelected(option);
                          }}
                          options={subFamiliesOptions}
                          isSearchable={true}
                          isClearable={true}
                          maxMenuHeight={350}
                          value={subFamilySelected}
                          placeholder="selecione a sub. Familia"
                        />
                      </div>
                    </div>
                    {marketType === 'position' && (
                      <div className="flex items-center mr-4">
                      <span className="mr-2 w-34">
                        exibir somente com valores
                      </span>
                      <div>
                        <SingleCheckbox
                          enableLoading={true}
                          onChange={() => {
                            setShowOnlyWithValues(!showOnlyWithValues);
                          }}
                          value={showOnlyWithValues}
                        />
                      </div>
                    </div>
                    )}
                  </>
                )}
              </div>

              <div className="grid w-full grid-cols-2">
                <div className="col-span-2 text-center">
                  {marketType === 'grade' ? (
                    <MarketTableGradePublish
                      marketType={marketType}
                      marketData={marketData}
                      isGradeBetweenMinAndMax={isGradeBetweenMinAndMax}
                      handleCheckedPosition={handleCheckedPosition}
                      selectedMarketPositions={selectedMarketPositions}
                      getInputTooltipValue={getInputTooltipValue}
                      handleValue={handleValue}
                      dadosAlterados={dadosAlterados}
                      selectedIntermedOptionLine={selectedIntermedOptionLine}
                      minGradeRange={minGradeRange}
                      maxGradeRange={maxGradeRange}
                      defaultValueSlope={defaultValueSlope}
                      valueNew10={valueNew10}
                      valueNew25={valueNew25}
                      valueNew50={valueNew50}
                      valueNew75={valueNew75}
                      valueNew90={valueNew90}
                    />
                  ) : (
                    <MarketTablePositionPublish
                      marketType={marketType}
                      marketData={marketData}
                      isGradeBetweenMinAndMax={isGradeBetweenMinAndMax}
                      handleCheckedPosition={handleCheckedPosition}
                      selectedMarketPositions={selectedMarketPositions}
                      getInputTooltipValue={getInputTooltipValue}
                      handleValue={handleValue}
                      dadosAlterados={dadosAlterados}
                      selectedIntermedOptionLine={selectedIntermedOptionLine}
                      minGradeRange={minGradeRange}
                      maxGradeRange={maxGradeRange}
                      valueNew10={valueNew10}
                      valueNew25={valueNew25}
                      valueNew50={valueNew50}
                      valueNew75={valueNew75}
                      valueNew90={valueNew90}
                    />
                  )}
                  {dadosAlterados ? (
                    <>
                      <br></br>
                      <br></br>
                      <div className="shadow-lg alert alert-warning alertSalvar">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="flex-shrink-0 w-6 h-6 stroke-current"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                          <span>
                            Atenção: Existem valores alterados não salvos,
                            clique em <b>Atualizar Valores</b> para salva-los!
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div className="col-span-2">
                  <Container>
                    <ContainerButton>
                      <Button
                        disabled={load || !dadosAlterados}
                        style={{ marginRight: '15px' }}
                        onClick={(e: any) => {
                          handleSaveValues();
                        }}
                      >
                        Atualizar Valores
                      </Button>

                      <Button
                        disabled={load || !isAbleToPublish || dadosAlterados}
                        style={{ marginRight: '5px' }}
                        onClick={(e: any) => {
                          handlePublishValues();
                        }}
                      >
                        Publicar Mercado
                      </Button>
                    </ContainerButton>
                  </Container>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="grid grid-cols-1 gap-4"
          style={{ width: '450px', marginLeft: '35%' }}
        >
          Tem certeza que deseja Publicar ?
          <br />
          {dadosAlterados ? (
            <>
              <div className="shadow-lg alert alert-warning alertSalvar">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-shrink-0 w-6 h-6 stroke-current"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>
                    <p>Atenção: Existem valores alterados não salvos.</p>
                    <p>
                      Para atualizar clique em <b>Cancelar</b> e depois em{' '}
                      <b>Atualizar Valores</b> !
                    </p>
                  </span>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          <Button
            type="button"
            onClick={(e: any) => {
              setPublish(false);
              handleProcessPublicarOk();
            
            }}
          >
            Confirmar
          </Button>
          <button className="btn btn-ghost" onClick={() => setPublish(false)}>
            Cancelar
          </button>
        </div>
      )}
    </>
  );
};

export { ModalMarketSmoothing };
