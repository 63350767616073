import {
  HeaderModal,
  ContentModal,
  FooterModal,
  Button,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';
import { LoadingSpinner } from '../Spinners/Loader';

type Props = {
  closeModal: () => void;
  callback: () => void;
  isLoading?: boolean;
}

const ModalConfirmDeleteSubsidiary = ({ closeModal, callback, isLoading = false }: Props) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      <div>
        <HeaderModal>
          <p>Remover filial</p>
        </HeaderModal>
        <ContentModal>
          <p>Tem certeza que deseja remover esta filial?</p>
        </ContentModal>
        <FooterModal>
          <Button default={true} onClick={closeModal}>
            Cancelar
          </Button>
          <Button default={false} onClick={callback}>
            {isLoading ? <LoadingSpinner /> : 'Remover'}
          </Button>
        </FooterModal>
      </div>
    </Modal>
  );
};

export { ModalConfirmDeleteSubsidiary };
