import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  size?: 'sm' | 'lg' | 'md';
  color?: 'primary' | 'secondary';
  isOutline?: boolean;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  size = 'md',
  color = 'primary',
  isOutline = false,
  loading,
  disabled,
  ...rest
}) => (
  <Container
    isOutline={isOutline}
    color={disabled ? 'secondary' : color}
    size={size}
    type="button"
    disabled={disabled}
    {...rest}
  >
    {loading ? 'Carregando...' : children}
  </Container>
);

export { Button };
