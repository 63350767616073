import React, { useState } from 'react';
import { PrimaryButton } from '../../pages/GradesMatch/styles';
import { Button } from '../Button';
import * as s from './styles';

interface FileUploaderProps {
  buttonLabel: string;
  handleFile: (file: File) => void;
  buttonDisabled?: boolean;
}

export default function FileUploader({
  buttonLabel,
  handleFile,
  buttonDisabled = false,
}: FileUploaderProps) {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState('...');
  const [showError, setShowError] = useState(false);

  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };

  const handleUpload = () => {
    if (!file) {
      setShowError(true);
      return;
    }
    handleFile(file);
    setFile(null);
    setFileName('...');

    if (hiddenFileInput && hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = '';
    }
  };

  const handleSetFileName = (originalFileName: string) => {
    const limit = 20;
    const length = originalFileName.length > limit;

    if (length) {
      const newName = originalFileName.substring(0, limit - 3).concat('...');
      return setFileName(newName);
    }
    setFileName(originalFileName);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const fileUploaded = event.target.files[0];
      setFile(fileUploaded);
      handleSetFileName(fileUploaded.name);
      setShowError(false);
    }
  };

  return (
    <div>
      <s.FileUploaderContainer>
        <s.InputUploader
          accept=".xlsx"
          type="file"
          ref={hiddenFileInput}
          onInput={handleChange}
        />
        <s.ChoseFileContainer>
          <s.FileChosenBox isError={showError} title={file ? file?.name : ''}>
            <s.FileChosenTitle>{fileName}</s.FileChosenTitle>
          </s.FileChosenBox>
          <s.ChoseFileButton disabled={buttonDisabled} onClick={handleClick}>
            Escolha o arquivo
          </s.ChoseFileButton>
        </s.ChoseFileContainer>

        <s.FileUploadButton disabled={buttonDisabled} onClick={handleUpload}>
          {buttonLabel}
        </s.FileUploadButton>
      </s.FileUploaderContainer>
      {showError && (
        <s.ErrorMessage>Nenhum arquivo foi escolhido.</s.ErrorMessage>
      )}
    </div>
  );
}
