import { useState, useEffect, useCallback } from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Button } from '../../components/Button';
import { AppError } from '../../error/AppError';
import { OptionSelect, Position } from '../../interfaces';
import { api } from '../../services/api';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  Select,
} from './styles';

interface Props {
  closeModal: () => void;
  callback: (idSmartPayPosition: string, idCompanyPosition: string) => void;
  matchPosition: Position;
}

const ModalJobBond = ({ closeModal, callback, matchPosition }: Props) => {
  const [smartpayCompanyPosition, setSmartpayCompanyPosition] =
    useState<OptionSelect>({} as OptionSelect);
  const [companyPosition, setCompanyPosition] = useState<OptionSelect>(
    {} as OptionSelect,
  );

  const [smartPayPositions, setSmartPayPositions] = useState<OptionSelect[]>(
    [],
  );
  const [companyPositions, setCompanyPositions] = useState<OptionSelect[]>([]);

  const submit = () => {
    if (!companyPosition.value) {
      toast.error('Selecione um cargo da empresa!');
      return;
    }

    callback(matchPosition.system_position.id, companyPosition.value);
    closeModal();
  };

  const loadCompanyPositions = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/tenants/positions?paginate=false&tenant_id=${matchPosition.tenant_id}`,
      );
      const companys = data.map((company) => ({
        label: company.title,
        value: company.id,
      }));

      setCompanyPositions(companys);
    } catch (error) {
      AppError(error, `Problemas ao carregar cargos!`);
    }
  }, [matchPosition]);

  useEffect(() => {
    loadCompanyPositions();
  }, [loadCompanyPositions]);

  return (
    <BackgroundContainerModal>
      <div>
        <HeaderModal>
          <p>Matriz de Cargo</p>
          <FiX onClick={closeModal} />
        </HeaderModal>
        <ContentModal>
          <Form>
            <div>
              <Select
                onChange={(option) =>
                  setSmartpayCompanyPosition(option as OptionSelect)
                }
                options={smartPayPositions}
                isSearchable={false}
                maxMenuHeight={150}
                placeholder="Escolha um cargo da smartpay"
                value={{
                  value: matchPosition.system_position.id,
                  label: matchPosition.system_position.title,
                }}
                isDisabled
              />

              <Select
                onChange={(option) =>
                  setCompanyPosition(option as OptionSelect)
                }
                options={companyPositions}
                isSearchable={true}
                maxMenuHeight={150}
                placeholder="Escolha um cargo da empresa"
              />
            </div>
            <ContainerButton>
              <Button onClick={submit} type="button">
                Editar
              </Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
    </BackgroundContainerModal>
  );
};

export { ModalJobBond };
