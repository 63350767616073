import ReactLoading from 'react-loading';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import * as s from './styles';

export function GlobalLoading() {
  const { isLoading } = useGlobalLoading();

  return (
    <s.Container show={isLoading}>
      <s.Wrapper>
        <s.LoadingBox>
          <s.Loader type={'spin'} />
        </s.LoadingBox>
      </s.Wrapper>
    </s.Container>
  );
}
