import { Stitches } from '../../../src/styles/stitches';

export const Container = Stitches.styled('div', {
  display: 'flex',
  height: '100vh',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

export const Content = Stitches.styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  // width: '100%',
  flexGrow: 1,
});

export const ContentForm = Stitches.styled('div', {
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '500px',
  padding: '50px',
  // borderRadius: '20px',
  // backgroundColor: '$gray80',
  // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
});

export const Form = Stitches.styled('form', {
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '25rem',
  width: '100%',
  fontWeight: 'bold',

  '& div': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '12px',

    a: {
      color: 'var($gray400)',
    },
  },
});

export const ContainerButton = Stitches.styled('div', {
  marginTop: '2rem',
});

export const Logo = Stitches.styled('img', {
  width: '14rem',
  margin: 'auto',
});

export const ImgInitial = Stitches.styled('img', {
  width: '30rem',
  margin: 'auto',
});
