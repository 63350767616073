import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import BackgroundImg from '../../assets/svg/home-admin.svg';

import {
  Container,
  Content,
  ContentForm,
  Form,
  ContainerButton,
  Logo,
  ImgInitial,
} from './styles';
import logo from '../../assets/png/logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { SignInProps } from '../../interfaces';
import { AppError } from '../../error/AppError';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';

const SignIn = () => {
  const { signIn } = useAuth();
  const { handleSetIsLoading } = useGlobalLoading();

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInProps>();

  const submit = async (data: SignInProps) => {
    try {
      handleSetIsLoading(true);
      await signIn(data);
    } catch (error) {
      toast.warn('Credenciais Inválidas!');
    } finally {
      handleSetIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <ContentForm>
          <Form onSubmit={handleSubmit(submit)}>
            <Logo src={logo} alt="Logo SmartPay" />
            <Input
              type="email"
              placeholder="E-mail"
              {...register('email', { required: true })}
              error={!!errors.email}
              helperText={!!errors.email ? 'Campo Obrigatório' : ''}
            />
            <Input
              icon={showPassword ? FiEyeOff : FiEye}
              callback={() => setShowPassword(!showPassword)}
              placeholder="Senha"
              type={showPassword ? 'text' : 'password'}
              {...register('password', { required: true })}
              error={!!errors.password}
              helperText={!!errors.password ? 'Campo Obrigatório' : ''}
            />
            <div>
              <Link to="/recover-password">Recuperar Senha</Link>
            </div>
            <ContainerButton>
              <Button type="submit">Entrar</Button>
            </ContainerButton>
          </Form>
        </ContentForm>
      </Content>
      <Content style={{ background: '#f5f5f5' }}>
        <ImgInitial src={BackgroundImg} alt="SMRTComp Login" />
      </Content>
    </Container>
  );
};

export default SignIn;
