import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';

import { BackButtonWrapper } from './styles';

type Props = typeof BackButtonWrapper.defaultProps;

export function BackButton({ ...props }: Omit<Props, 'onClick'>) {
  const navigate = useNavigate();
  const location = useLocation();

  function handleRedirect() {
    navigate(-1);
  }

  return location.pathname != '/home' ? (
    <BackButtonWrapper {...props} onClick={() => handleRedirect()}>
      <FiArrowLeft />
      Voltar
    </BackButtonWrapper>
  ) : null;
}
