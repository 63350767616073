import { useEffect, useState } from 'react';
import { FiEdit, FiPlus, FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Input } from '../../components/Input';
import { ModalContributionLevel } from '../../components/ModalContributionLevel';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { ContributionLevel, OptionSelect } from '../../interfaces';
import { api } from '../../services/api';
import { Select } from '../DashboardContributors/styles';
import { ButtonPrimary } from '../DashboardUsers/styles';
import * as s from './styles';

interface RequestContributionLevelListProps {
  page?: number;
  limit?: number;
  title?: string;
  type?: string;
}

export function ContributionLevels() {
  const [contributionLevelList, setContributionLevelList] = useState<
    ContributionLevel[]
  >([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [typeSelect, setTypeSelect] = useState<OptionSelect>();
  const [titleSearch, setTitleSearch] = useState('');
  const [currentContributionEdit, setCurrentContributionEdit] =
    useState<ContributionLevel | null>(null);
  const [showModal, setShowModal] = useState(false);

  const list = async ({
    page = 1,
    limit = 10,
    type = '',
    title = '',
  }: RequestContributionLevelListProps) => {
    handleSetIsLoading(true);

    let query = `?page=${page}&limit=${limit}`;

    if (type) {
      query = query.concat(`&type=${type}`);
    }

    if (title) {
      query = query.concat(`&title=${title}`);
    }

    try {
      const { data } = await api.get<ContributionLevel[]>(
        `smartpay/contributions/levels${query}`,
      );
      setContributionLevelList(data);
    } catch (err) {
      toast.error('Erro ao buscar dados!');
    }

    handleSetIsLoading(false);
  };

  const handleCreateUpdateContributionLevel = async (
    data: ContributionLevel,
  ) => {
    handleSetIsLoading(true);
    const dataParse = {
      title: data.title,
      code: data.code,
      description: data.description,
      type: data.type,
    };

    try {
      if (currentContributionEdit?.id) {
        await api.put(
          `smartpay/contributions/levels/${currentContributionEdit.id}`,
          dataParse,
        );

        toast.success('Alterado com sucesso!');
      } else {
        await api.post(`smartpay/contributions/levels`, dataParse);

        toast.success('Criado com sucesso!');
      }
      await list({});
      setShowModal(false);
    } catch (err: any) {
      console.log(err.message);
      toast.error('Erro ao efetuar operação!');
    }

    handleSetIsLoading(false);
  };

  useEffect(() => {
    const loadData = list;
    loadData({ type: typeSelect?.label });
  }, []);

  return (
    <s.Container>
      {showModal && (
        <ModalContributionLevel
          closeModal={() => setShowModal(false)}
          callback={(data: any) => handleCreateUpdateContributionLevel(data)}
          data={currentContributionEdit}
        />
      )}
      <s.Content>
        <h2 className="text-2xl font-bold">Nível de contribuição</h2>
        <div className="divider"></div>

        <s.MainContent>
          <s.ControlContainer>
            <Select
              onChange={(option) => setTypeSelect(option as OptionSelect)}
              options={[
                { label: 'position', value: 'position' },
                { label: 'grade', value: 'grade' },
              ]}
              isSearchable={false}
              isClearable={true}
              maxMenuHeight={150}
              placeholder="Escolha um tipo"
            />
            <input
              type="text"
              className="input input-bordered w-full max-w-xs m-2 mt-0"
              onChange={(e) => setTitleSearch(e.currentTarget.value)}
              value={titleSearch}
            />
            <ButtonPrimary
              onClick={(e) => {
                list({
                  type: typeSelect?.label,
                  title: titleSearch,
                });
              }}
            >
              <FiSearch />
              Buscar
            </ButtonPrimary>
            <ButtonPrimary
              onClick={(e) => {
                setCurrentContributionEdit(null);
                setShowModal(true);
              }}
            >
              <FiPlus />
              Criar
            </ButtonPrimary>
          </s.ControlContainer>

          <div className="grid grid-cols-1 gap-4 mt-6">
            <div className="card bg-base-100 shadow-xl">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <td>Título</td>
                      <td>Código</td>
                      <td>Descrição</td>
                      <td>Tipo</td>
                      <td>Ações</td>
                    </tr>
                  </thead>
                  <tbody>
                    {contributionLevelList && (
                      <>
                        {contributionLevelList.map(
                          (contributionLevel, index) => (
                            <tr key={index}>
                              <td>{contributionLevel.title}</td>
                              <td>{contributionLevel.code}</td>
                              <td>{contributionLevel.description}</td>
                              <td>{contributionLevel.type}</td>
                              <td>
                                <div
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <FiEdit
                                    onClick={() => {
                                      setCurrentContributionEdit(
                                        contributionLevel,
                                      );
                                      setShowModal(true);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ),
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </s.MainContent>
      </s.Content>
    </s.Container>
  );
}
