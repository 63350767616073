import { Modal } from 'react-responsive-modal';
import {
  HeaderModal,
  ContentModal,
  FooterModal,
  Button,
} from './styles';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: () => void;
}

const ConfirmOpenHoldingManageModal = ({ closeModal, callback }: Props) => {
  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      <div>
        <HeaderModal>
          <p>Cadastrar filiais</p>
        </HeaderModal>
        <ContentModal>
          <p>Deseja adicionar filiais a essa holding?</p>
        </ContentModal>
        <FooterModal>
          <Button default={true} onClick={closeModal}>
            Cancelar
          </Button>
          <Button default={false} onClick={callback}>
            Adicionar filiais
          </Button>
        </FooterModal>
      </div>
    </Modal>
  );
};

export { ConfirmOpenHoldingManageModal };
