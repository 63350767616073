import { Routes, Route, Navigate } from 'react-router-dom';

import VerifyRouter from './router';
import Template from '../pages/Template';
import SignIn from '../pages/SignIn';
import DashboardUsers from '../pages/DashboardUsers';
import DashboardCompany from '../pages/DashboardCompany';
import DashboardContributors from '../pages/DashboardContributors';
import DashboardQuestions from '../pages/DashboardQuestions';
import DashboardJobBond from '../pages/DashboardJobBond';
import RecoverPassword from '../pages/RecoverPassword';
import ConfirmPassword from '../pages/ConfirmPassword';
import DashboardCompanyPositions from '../pages/DashboardCompanyPositions';
import DashboardSmartpayPositions from '../pages/DashboardSmartpayPositions';
import GradesMatch from '../pages/GradesMatch';
import PositionDescriptions from '../pages/PositionDescriptions';
import { ContributionLevels } from '../pages/ContributionLevels';
import Families from '../pages/Families';
import SubFamilies from '../pages/SubFamilies';
import MarketIntermediate from '../pages/MarketIntermediate';
import MarketConfiguration from '../pages/MarketConfiguration';
import MarketResult from '../pages/MarketResult';
import MarketSmoothing from '../pages/MarketSmoothing';
import MarketPublished from '../pages/MarketPublished';
import Sector from '../pages/Sector';
import SectorGroup from '../pages/SectorGroup';
import { useAuth } from '../contexts/AuthContext';
import { ReactElement } from 'react';
import Groups from '../pages/Groups';
import Plans from '../pages/Plans';
import CompanyPlans from '../pages/CompanyPlans';
import CompanyUsers from '../pages/CompanyUsers';

type PrivateRouteProps = {
  children: ReactElement;
};

function PrivateRoute({ children }: PrivateRouteProps) {
  const { getToken } = useAuth();
  return getToken() ? (
    <Template>{children}</Template>
  ) : (
    <Navigate to={{ pathname: '/' }} />
  );
}

const Router = () => (
  <Routes>
    <Route path="/" element={<SignIn />} />
    <Route path="/recover-password" element={<RecoverPassword />} />
    <Route
      path="/dashboard-users"
      element={
        <PrivateRoute>
          <DashboardUsers />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-companys"
      element={
        <PrivateRoute>
          <DashboardCompany />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-contributors"
      element={
        <PrivateRoute>
          <DashboardContributors />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-questions"
      element={
        <PrivateRoute>
          <DashboardQuestions />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-job-bond"
      element={
        <PrivateRoute>
          <DashboardJobBond />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-smartpay-positions"
      element={
        <PrivateRoute>
          <DashboardSmartpayPositions />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-grades-match"
      element={
        <PrivateRoute>
          <GradesMatch />
        </PrivateRoute>
      }
    />
    <Route
      path="/position-descriptions"
      element={
        <PrivateRoute>
          <PositionDescriptions />
        </PrivateRoute>
      }
    />
    <Route
      path="/contribution-levels"
      element={
        <PrivateRoute>
          <ContributionLevels />
        </PrivateRoute>
      }
    />
    <Route
      path="/position-families"
      element={
        <PrivateRoute>
          <Families />
        </PrivateRoute>
      }
    />
    <Route
      path="/position-subfamilies"
      element={
        <PrivateRoute>
          <SubFamilies />
        </PrivateRoute>
      }
    />
    <Route
      path="/position-sector"
      element={
        <PrivateRoute>
          <Sector />
        </PrivateRoute>
      }
    />
    <Route
      path="/confirm-password"
      element={
        <PrivateRoute>
          <ConfirmPassword />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-company-positions"
      element={
        <PrivateRoute>
          <DashboardCompanyPositions />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-configuration"
      element={
        <PrivateRoute>
          <MarketConfiguration />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-intermiate-data/:id"
      element={
        <PrivateRoute>
          <MarketIntermediate />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-intermiate-data"
      element={
        <PrivateRoute>
          <MarketIntermediate />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-result-data"
      element={
        <PrivateRoute>
          <MarketResult />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-result-data/:id"
      element={
        <PrivateRoute>
          <MarketResult />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-smoothing"
      element={
        <PrivateRoute>
          <MarketSmoothing />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-smoothing/:id"
      element={
        <PrivateRoute>
          <MarketSmoothing />
        </PrivateRoute>
      }
    />
    <Route
      path="/market-published"
      element={
        <PrivateRoute>
          <MarketPublished />
        </PrivateRoute>
      }
    />
    <Route
      path="/position-sector-group"
      element={
        <PrivateRoute>
          <SectorGroup />
        </PrivateRoute>
      }
    />
    <Route
      path="/groups"
      element={
        <PrivateRoute>
          <Groups />
        </PrivateRoute>
      }
    />
    <Route
      path="/plans"
      element={
        <PrivateRoute>
          <Plans />
        </PrivateRoute>
      }
    />
    <Route
      path="/company-plans/:id"
      element={
        <PrivateRoute>
          <CompanyPlans />
        </PrivateRoute>
      }
    />
    <Route
      path="/company-users/:id"
      element={
        <PrivateRoute>
          <CompanyUsers />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default Router;
