import axios from 'axios';
import {
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  deleteRefreshToken,
  deleteToken,
} from '../storage/BrowserStorage';

import history from './history';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (options) => {
  const token = getToken();

  if (token && options.headers) {
    options.headers.authorization = `Bearer ${token}`;
  }
  return options;
});

interface fn {
  (param: string): void;
}

let isRefreshing = false;
let subscribers: fn[] = [];

function subscribeTokenRefresh(cb: fn) {
  subscribers.push(cb);
}

export function refreshToken() {
  return axios.post(process.env.REACT_APP_API_URL + '/auth/refresh', {
    token: getRefreshToken(),
  });
}

function onRefreshed(token: string) {
  subscribers.map((cb) => cb(token));
}

function logoutSystem() {
  deleteRefreshToken();
  deleteToken();
  history.push('/');
}

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (!err.response) {
      return Promise.reject(err);
    }

    const {
      config,
      response: { status },
    } = err;

    const originalRequest = config;
    if (status && status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        refreshToken()
          .then((response) => {
            const { data } = response;
            isRefreshing = false;
            api.defaults.headers['authorization'] = `Bearer ${data.token}`;
            onRefreshed(data.token);
            setToken(data.token);
            setRefreshToken(data.refresh_token);
            subscribers = [];
          })
          .catch(() => {
            logoutSystem();
          });
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(err);
  },
);

export { api };
