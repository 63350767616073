import { useState } from 'react';
import { FiX, FiEdit, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Header } from '../../components/Header';

import { Menu } from '../../components/Menu';
import { ModalConfirm } from '../../components/ModalConfirm';
import { ModalEditCompanyPosition } from '../../components/ModalEditCompanyPosition';

import {
  Container,
  Content,
  Main,
  ButtonPrimary,
  IconPlus,
  SectionListCompanys,
  HeaderListCompanys,
  ItemCompany,
  MainTitle,
  MainContent,
  ArrowLeft,
  ArrowRight,
  Pagination,
  NoQuestions,
  SectionFilter,
  Select,
} from './styles';
import { CreateOrUpdatePositionCompany, OptionSelect } from '../../interfaces';
import { useEffect } from 'react';
import { useAdmin } from '../../contexts/AdminContext';
import { AppError } from '../../error/AppError';
import { api } from '../../services/api';

const DashboardCompanyPositions = () => {
  const {
    getPositions,
    positionsCompany,
    pagination,
    deletePosition,
    updatePosition,
    createPosition,
  } = useAdmin();

  const [selectedOption, setSelectedOption] = useState<OptionSelect>();
  const [options, setOptions] = useState<OptionSelect[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalAccountPosition, setIsShowModalAccountPosition] =
    useState(false);
  const [currentEditPosition, setCurrentEditPosition] =
    useState<CreateOrUpdatePositionCompany | null>(null);
  const [currentDeletePosition, setCurrentDeletePosition] = useState({
    id: '',
    tenantId: '',
  });

  const selectEditPosition = (id: string) => {
    const [position] = positionsCompany.filter(
      (position) => position.id === id,
    );

    if (!position) return;

    setCurrentEditPosition({
      id: position.id,
      code: position.code,
      description: position.description,
      title: position.title,
      tenant_id: position.tenant.id,
    });
    setIsShowModalAccountPosition(true);
  };

  const actionDeletePositionCompany = async () => {
    try {
      await deletePosition(
        currentDeletePosition.id,
        currentDeletePosition.tenantId,
      );
      setCurrentDeletePosition({
        id: '',
        tenantId: '',
      });
      setIsShowModalConfirm(false);
      toast.success('Cargo removido com sucesso!');
    } catch (error) {
      AppError(error, `Problemas ao remover cargo`);
    }
  };

  const ActionAccountPosition = async (
    data: any,
    typeAction: 'update' | 'create',
  ) => {
    try {
      if (typeAction === 'create') {
        const { code, description, title, tenant } = data;
        await createPosition({
          code,
          description,
          title,
          tenant_id: tenant,
        });
        getPositions();
        toast.success('Cargo criado com sucesso!');
      } else {
        const { code, description, title } = data;
        await updatePosition(currentEditPosition?.id as string, {
          code,
          description,
          title,
          tenant_id: currentEditPosition?.tenant_id as string,
        });
        getPositions();
        toast.success('Cargo atualizado com sucesso!');
      }
      setIsShowModalAccountPosition(false);
    } catch (error) {
      AppError(
        error,
        `Problemas ao ${
          typeAction === 'create' ? 'criar' : 'atualizar'
        } cargo. Verifique os dados fornecidos!`,
      );
    }
  };

  const loadTenants = async () => {
    try {
      const {
        data: { data },
      } = await api.get('/tenants');
      const companys = data.map((company) => ({
        label: company.name,
        value: company.id,
      }));

      setOptions(companys);
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  };

  useEffect(() => {
    loadTenants();
  }, []);

  useEffect(() => {
    if (selectedOption?.value) {
      getPositions(selectedOption?.value, currentPage);
    } else {
      getPositions('', currentPage);
    }
  }, [selectedOption, currentPage]);

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="position"
          callback={actionDeletePositionCompany}
          closeModal={() => setIsShowModalConfirm(false)}
        />
      )}
      {isShowModalAccountPosition && (
        <ModalEditCompanyPosition
          callback={ActionAccountPosition}
          closeModal={() => setIsShowModalAccountPosition(false)}
          positionCompany={currentEditPosition}
        />
      )}

      <Menu />
      <Content>
        <Header />
        <Main>
          <MainTitle>Cargos de Empresa</MainTitle>
          <MainContent>
            <ButtonPrimary
              onClick={() => {
                setCurrentEditPosition(null);
                setIsShowModalAccountPosition(true);
              }}
            >
              <IconPlus />
              Criar cargo
            </ButtonPrimary>
            {positionsCompany.length > 0 ? (
              <>
                <SectionFilter>
                  <Select
                    onChange={(option) =>
                      setSelectedOption(option as OptionSelect)
                    }
                    options={options}
                    isSearchable={false}
                    maxMenuHeight={150}
                    placeholder="Escolha uma empresa"
                  />
                </SectionFilter>
                <HeaderListCompanys>
                  <p>Cargo</p>
                  <p>Empresa</p>
                  <div>Ações</div>
                </HeaderListCompanys>
                <SectionListCompanys>
                  {positionsCompany.map((company) => (
                    <ItemCompany key={company.id}>
                      <p>{company.title}</p>
                      <p>{company.tenant.name}</p>
                      <div>
                        <FiEdit
                          onClick={() => {
                            selectEditPosition(company.id);
                          }}
                        />
                        <FiX
                          onClick={() => {
                            setCurrentDeletePosition({
                              id: company.id,
                              tenantId: company.tenant.id,
                            });
                            setIsShowModalConfirm(true);
                          }}
                        />
                      </div>
                    </ItemCompany>
                  ))}
                </SectionListCompanys>
                <Pagination>
                  <ArrowLeft
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <FiArrowLeft />
                  </ArrowLeft>
                  <span>{currentPage}</span>
                  <ArrowRight
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === pagination.lastPagePositions}
                  >
                    <FiArrowRight />
                  </ArrowRight>
                </Pagination>
              </>
            ) : (
              <NoQuestions>Nenhum cargo cadastrado</NoQuestions>
            )}
          </MainContent>
        </Main>
      </Content>
    </Container>
  );
};

export default DashboardCompanyPositions;
