import { PasswordsErrorsContainer } from './styles';
import { IoIosClose } from 'react-icons/io';
import { IoCheckmark } from 'react-icons/io5';

type ChangeUserPasswordErrorsProps = {
  passwordLengthError: boolean;
  uppercaseError: boolean;
  lowercaseError: boolean;
  specialCharacterError: boolean;
};

type ErrorMessageProps = {
  showError?: boolean;
  errorMessage: string;
};

export const ChangeUserPasswordErrors = ({
  passwordLengthError,
  uppercaseError,
  lowercaseError,
  specialCharacterError,
}: ChangeUserPasswordErrorsProps) => {
  const ErrorMessage = ({
    showError = false,
    errorMessage,
  }: ErrorMessageProps) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {showError ? (
          <IoIosClose className="error" size={35} color="#ff0000" />
        ) : (
          <IoCheckmark
            className="success"
            size={30}
            color="#22D66A"
            style={{
              margin: '6px',
            }}
          />
        )}
        <p style={{ fontSize: "0.9rem", color: showError ? '#ff0000' : '' }}>{errorMessage}</p>
      </div>
    );
  };

  return (
    <PasswordsErrorsContainer>
      <ErrorMessage
        showError={passwordLengthError}
        errorMessage="Sua senha deve conter entre 8 e 16 caracteres"
      />
      <ErrorMessage
        showError={uppercaseError}
        errorMessage="Sua senha deve ter pelo menos 1 letra maiúscula"
      />
      <ErrorMessage
        showError={lowercaseError}
        errorMessage="Sua senha deve ter pelo menos 1 letra minúscula"
      />
      <ErrorMessage
        showError={specialCharacterError}
        errorMessage="Sua senha deve ter pelo menos 1 caractere especial"
      />
    </PasswordsErrorsContainer>
  );
};
