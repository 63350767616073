import { FiPlus, FiXCircle } from 'react-icons/fi';
import { ModalGeneric } from '../../../components/ModalGeneric';
import {
  MarketStatistic,
  OptionSelect,
  OptionSelectId,
} from '../../../interfaces';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { ContainerButton } from '../styles';
import { Button } from '../../../components/Button';
import { MarketTypes } from '../../../shared/types';
import { SingleCheckbox } from '../../../components/checkboxs';
import { ItemsVisibility } from '..';
import { CustomSelect } from '../../../components/Select';

interface MarketPermissionModalProps {
  isModalOpenPermission: boolean;
  setIsModalOpenPermission: (v: boolean) => void;
  showClose: boolean;
  marketType: MarketTypes | null;
  setVisibilityPermission: (v: string) => void;
  visibilityPermission: string;
  tenantsShowAllPositions: string[];
  setTenantsShowAllPositions: (v: string[]) => void;
  sectorsShowAllPositions: string[];
  setSectorsShowAllPositions: (v: string[]) => void;
  companies: OptionSelect[];
  setores: OptionSelect[];
  itemsAddPermission: ItemsVisibility[];
  setItemsAddPermission: (v: ItemsVisibility[]) => void;
  companyPermission: OptionSelectId;
  sectorPermission: OptionSelectId;
  setCompanyPermission: (v: OptionSelectId) => void;
  setSectorPermission: (v: OptionSelectId) => void;
  handlUpdatePermissions: (v: any) => void;
}

type ItemType = 'sector' | 'community' | 'internal';

export function MarketPermissionModal({
  isModalOpenPermission,
  marketType,
  setIsModalOpenPermission,
  showClose,
  setVisibilityPermission,
  visibilityPermission,
  companies,
  setores,
  itemsAddPermission,
  setItemsAddPermission,
  companyPermission,
  sectorPermission,
  setCompanyPermission,
  setSectorPermission,
  handlUpdatePermissions,
  tenantsShowAllPositions = [],
  setTenantsShowAllPositions,
  sectorsShowAllPositions = [],
  setSectorsShowAllPositions,
}: MarketPermissionModalProps) {
  function handleRemovePermission(id: string, type: ItemType) {
    const updatedItemsPermission = itemsAddPermission.filter(
      (item: ItemsVisibility) => item.id !== id,
    );
    setItemsAddPermission(updatedItemsPermission);

    if (type === 'community') {
      const tenantsShowAllPositionsRemove = tenantsShowAllPositions.filter(
        (tenantId) => tenantId !== id,
      );
      setTenantsShowAllPositions([...tenantsShowAllPositionsRemove]);
    }

    if (type === 'sector') {
      const sectorsShowAllPositionsRemove = sectorsShowAllPositions.filter(
        (sectorId) => sectorId !== id,
      );
      setSectorsShowAllPositions([...sectorsShowAllPositionsRemove]);
    }
  }

  const handleGetDefaultValueShowAllPosition = (id: string, type: ItemType) => {
    if (type === 'community') {
      const companyValues = companies.filter((c) => !!c.value);
      if (
        (companyPermission.value?.toLowerCase() === 'todas' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')) &&
        tenantsShowAllPositions.length === companyValues.length
      ) {
        return true;
      }
      return tenantsShowAllPositions.some((tenantId) => tenantId === id);
    }

    if (type === 'sector') {
      const sectorValues = setores.filter((s) => !!s.value);
      if (
        (companyPermission.value?.toLowerCase() === 'todos' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')) &&
        sectorsShowAllPositions.length === sectorValues.length
      ) {
        return true;
      }
      return sectorsShowAllPositions.some((sectorId) => sectorId === id);
    }

    return false;
  };

  function handleSeTenantShowAllPosition(
    checked: boolean,
    id: string,
    type: ItemType,
  ) {
    if (checked) {
      if (type === 'community') {
        if (
          companyPermission.value?.toLowerCase() === 'todas' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')
        ) {
          const companiesIds = companies
            .filter((c) => !!c.value)
            .map((c) => c.value);
          setTenantsShowAllPositions([...companiesIds]);
          return;
        }
        setTenantsShowAllPositions([...tenantsShowAllPositions, id]);
      }
      if (type === 'sector') {
        if (
          sectorPermission.value?.toLowerCase() === 'todos' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')
        ) {
          const sectorsIds = setores
            .filter((s) => !!s.value)
            .map((s) => s.value);
          setSectorsShowAllPositions([...sectorsIds]);
          return;
        }
        setSectorsShowAllPositions([...sectorsShowAllPositions, id]);
      }
    } else {
      if (type === 'community') {
        if (
          companyPermission.value?.toLowerCase() === 'todas' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')
        ) {
          setTenantsShowAllPositions([]);
          return;
        }
        const tenantsShowAllPositionsRemove = tenantsShowAllPositions.filter(
          (tenantId) => tenantId !== id,
        );
        setTenantsShowAllPositions([...tenantsShowAllPositionsRemove]);
      }
      if (type === 'sector') {
        if (
          sectorPermission.value?.toLowerCase() === 'todos' ||
          (itemsAddPermission.length === 1 &&
            itemsAddPermission[0].value === '')
        ) {
          setSectorsShowAllPositions([]);
          return;
        }
        const sectorsShowAllPositionsRemove = sectorsShowAllPositions.filter(
          (sectorId) => sectorId !== id,
        );
        setSectorsShowAllPositions([...sectorsShowAllPositionsRemove]);
      }
    }
  }

  async function updatePermission() {
    let itemsToUpdate: any = {};

    const verifyInternal = itemsAddPermission.filter(
      (item: ItemsVisibility) => item.type === 'internal',
    );
    if (verifyInternal.length) {
      itemsToUpdate = {
        visibility: 'internal',
        tenants_visibility: [],
        sectors_visibility: [],
        tenants_show_all_positions: [],
        sectors_show_all_positions: [],
      };
    }

    const verifyCommunity = itemsAddPermission.filter(
      (item: ItemsVisibility) => item.type === 'community',
    );
    if (verifyCommunity.length) {
      let hasItem = false;
      const tenants_visibility = itemsAddPermission
        .filter((item: ItemsVisibility) => item.type === 'community')
        .map((item: ItemsVisibility) => {
          if (item.value) {
            hasItem = true;
            const filterCommmunity: any = companies.filter(
              (itemCompany: any) => itemCompany.label === item.value,
            )[0];
            return filterCommmunity.value;
          }
        });

      itemsToUpdate = {
        visibility: 'community',
        tenants_visibility: hasItem ? tenants_visibility : [],
        tenants_show_all_positions: tenantsShowAllPositions || [],
        sectors_show_all_positions: [],
      };
    }

    const verifySector = itemsAddPermission.filter(
      (item: ItemsVisibility) => item.type === 'sector',
    );
    if (verifySector.length) {
      let hasItem = false;
      const sectors_visibility = itemsAddPermission
        .filter((item: ItemsVisibility) => item.type === 'sector')
        .map((item: ItemsVisibility) => {
          if (item.value) {
            hasItem = true;
            const filterSector: any = setores.filter(
              (itemSector: any) => itemSector.label === item.value,
            )[0];
            return filterSector.value;
          }
        });

      itemsToUpdate = {
        visibility: 'sector',
        sectors_visibility: hasItem ? sectors_visibility : [],
        tenants_show_all_positions: [],
        sectors_show_all_positions: sectorsShowAllPositions || [],
      };
    }

    handlUpdatePermissions(itemsToUpdate);
  }

  function handleAddPermission() {
    const v4Id = uuidv4();

    const itemsAdded = itemsAddPermission;

    if (visibilityPermission === 'internal') {
      const valid = itemsAdded.filter(
        (item: ItemsVisibility) => item.type === 'internal',
      );
      if (!valid.length) {
        setItemsAddPermission([{ type: 'internal', value: '', id: v4Id }]);
      }
      return;
    }

    if (visibilityPermission === 'community') {
      const alreadyAdded = itemsAdded.filter(
        (item: ItemsVisibility) =>
          item.type === 'community' && item.value === companyPermission.value,
      );

      if (!alreadyAdded.length) {
        const justItemCommunity = itemsAdded.filter(
          (item: ItemsVisibility) =>
            item.type !== 'internal' &&
            item.type !== 'sector' &&
            item.value !== 'Todas' &&
            item.value !== '',
        );

        if (
          companyPermission.value === 'Todas' ||
          companyPermission.value === ''
        ) {
          setItemsAddPermission([{ type: 'community', value: '', id: v4Id }]);
        } else {
          setItemsAddPermission([
            ...justItemCommunity,
            {
              type: 'community',
              value: companyPermission.value,
              id: companyPermission.id,
            },
          ]);
        }
      }
    }

    if (visibilityPermission === 'sector') {
      const alreadyAdded = itemsAdded.filter(
        (item: ItemsVisibility) =>
          item.type === 'sector' && item.value === sectorPermission.value,
      );

      if (!alreadyAdded.length) {
        const justItemSector = itemsAdded.filter(
          (item: ItemsVisibility) =>
            item.type !== 'internal' &&
            item.type !== 'community' &&
            item.value !== 'Todos' &&
            item.value !== '',
        );

        if (
          sectorPermission.value === 'Todos' ||
          sectorPermission.value === ''
        ) {
          setItemsAddPermission([{ type: 'sector', value: '', id: v4Id }]);
        } else {
          setItemsAddPermission([
            ...justItemSector,
            {
              type: 'sector',
              value: sectorPermission.value,
              id: sectorPermission.id,
            },
          ]);
        }
      }
    }
  }

  return (
    <ModalGeneric
      isModalOpen={isModalOpenPermission}
      setIsModalOpen={setIsModalOpenPermission}
      full={true}
      showClose={showClose}
      styles={{
        flexDirection: 'column',
        minHeight: '50vh',
      }}
    >
      <div className="flex items-center gap-4 w-[800px] mb-8">
        <div className="w-[350px]">
          <label className="label">
            <strong className="label-text">
              Permissão de visibilidade
              <label className="text-error">*</label>
            </strong>
          </label>

          <CustomSelect
            onChange={(option) => {
              setVisibilityPermission(option.value);
              setCompanyPermission({ id: '', value: '' });
              setSectorPermission({ id: '', value: '' });
            }}
            isSearchable
            options={[
              {
                label: 'Uso interno',
                value: 'internal',
              },
              {
                label: 'Comunidade',
                value: 'community',
              },
              {
                label: 'Setor',
                value: 'sector',
              },
            ]}
          />
        </div>

        {companies.length &&
        visibilityPermission &&
        visibilityPermission === 'community' ? (
          <div className="w-full">
            <label className="label">
              <strong className="label-text">Empresas</strong>
            </label>
            <CustomSelect
              onChange={(option) => {
                setCompanyPermission({
                  id: option.value,
                  value: option.label,
                });
                setSectorPermission({ id: '', value: '' });
              }}
              options={companies.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
            />
          </div>
        ) : null}

        {visibilityPermission && visibilityPermission === 'sector' ? (
          <div className="w-full">
            <label className="label">
              <strong className="label-text">Setores</strong>
            </label>

            <div style={{ maxWidth: "15rem" }}>
              <CustomSelect
                onChange={(option) => {
                  setSectorPermission({
                    id: option.value,
                    value: option.label,
                  });
                  setCompanyPermission({ id: '', value: '' });
                }}
                options={setores.map((item) => ({
                  label: item.label,
                  value: item.value,
                }))}
                styles={{ maxWidth: "8rem" }}
              />
            </div>
          </div>
        ) : null}

        <div className="mt-9">
          <button
            className="btn btn-sm btn-success w-[50px] h-[50px]"
            onClick={handleAddPermission}
          >
            <FiPlus />
          </button>
        </div>
      </div>

      <div className="flex mb-8 max-h- overflow-x-auto">
        <table className="table w-full table-compact table-zebra">
          <tbody>
            {itemsAddPermission.map((item: ItemsVisibility) => (
              <tr
                key={item.type + '-' + item.value}
                className="border-b dark:border-neutral-200"
              >
                <td>
                  <strong>
                    {item.type === 'internal' && 'Interno'}
                    {item.type === 'community' && 'Comunidade'}
                    {item.type === 'sector' && 'Setor'}
                  </strong>
                </td>
                <td>
                  {(item.type === 'community' || item.type === 'sector') &&
                  item.value === ''
                    ? 'Todos'
                    : item.value}
                </td>
                {marketType === 'position' &&
                  (item.type === 'community' || item.type === 'sector') && (
                    <td>
                      <SingleCheckbox
                        label="Exibir todos os cargos"
                        value={handleGetDefaultValueShowAllPosition(
                          item.id,
                          item.type,
                        )}
                        onChange={(checked) =>
                          handleSeTenantShowAllPosition(
                            checked,
                            item.id,
                            item.type,
                          )
                        }
                      />
                    </td>
                  )}
                <td>
                  <button
                    className="btn btn-sm btn-error w-[50px] h-[50px]"
                    onClick={() => handleRemovePermission(item.id, item.type)}
                  >
                    <FiXCircle />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ContainerButton>
        <Button
          onClick={updatePermission}
          disabled={!itemsAddPermission.length}
        >
          Atualizar permissões
        </Button>
      </ContainerButton>
    </ModalGeneric>
  );
}
