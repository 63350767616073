import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '../../components/Button';
import { Company } from '../../interfaces';
import { Content } from '../../pages/MarketConfiguration/styles';
import Select, { ActionMeta, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  HeaderModal,
  ContentModal,
  Form,
  FormContent,
  ContainerButton,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetTenants } from '../../shared/hooks/useGetTenants';
import { formatDateFullWithHour, getMonthYearFromDateISO, truncateText } from '../../shared/utils';
import { FiXCircle } from 'react-icons/fi';
import { useUnlinkSubsidiary } from '../../pages/DashboardCompany/hooks/useUnlinkSubsidiary';
import { AppError } from '../../error/AppError';
import { ModalConfirmDeleteSubsidiary } from '../ModalConfirmDeleteSubsidiary';
import { LoadingSpinner } from '../Spinners/Loader';

const animatedComponents = makeAnimated();

const holdingSchema = yup.object({
  subsidiaries: yup
    .array()
    .min(1, "Selecione ao menos uma filial")
    .of(yup.string().required())
    .required("Selecione ao menos uma filial"),
});

type Props = {
  closeModal: () => void;
  callback: (data: { subsidiaries_ids: string[] }) => void;
  getCompanies: () => void;
  company?: Company | null | undefined;
}

type CreateOrUpdateBranchesHoldingData = {
  subsidiaries: string[];
}

type SelectProps = {
  label: string;
  value: string;
}

const HoldingManageModal = ({ closeModal, company, callback, getCompanies }: Props) => {
  const [selectedSubsidiaries, setSelectedSubsidiaries] = useState<SelectProps[]>([]);
  const [isShowModalConfirmDeleteSubsidiary, setIsShowModalConfirmDeleteSubsidiary] = useState(false);
  const [subsidiaryIdToDelete, setSubsidiaryIdToDelete] = useState<string>("");
  const { unlinkSubsidiary, isLoading } = useUnlinkSubsidiary(company?.id || '');
  const {
    tenants,
    isLoading: isLoadingTenants,
    refetch,
  } = useGetTenants({ paginate: false });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateOrUpdateBranchesHoldingData>({
    resolver: yupResolver(holdingSchema),
  });

  const nonHoldingTenants = useMemo(() => {
    return tenants?.filter(company =>
      company?.tenant_configurations?.[0]?.tenant_type !== 'holding'
      && !company.hasTenantFather);
  }, [tenants]);

  const tenantsOptions = useMemo(() => {
    return nonHoldingTenants?.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }));
  }, [tenants])

  const submit = async (data: { subsidiaries: string[] }) => {
    await callback({ subsidiaries_ids: data.subsidiaries });
    refetch();
    setSelectedSubsidiaries([]);
    setValue('subsidiaries', []);
  };

  const handleShowConfirmDeleteSubsidiaryModal = (subsidiaryId: string) => {
    setIsShowModalConfirmDeleteSubsidiary(true);
    setSubsidiaryIdToDelete(subsidiaryId);
  };

  const selectBranches = useCallback((data: MultiValue<SelectProps>) => {
    setSelectedSubsidiaries(data as SelectProps[]);
  }, [setSelectedSubsidiaries]);

  const actionDeleteSubsidiary = async () => {
    try {
      await unlinkSubsidiary(subsidiaryIdToDelete);

      setIsShowModalConfirmDeleteSubsidiary(false);
      refetch();
      getCompanies();
      setSelectedSubsidiaries([]);
    } catch (error) {
      AppError(error, `Problemas ao remover filial da holding`);
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={closeModal}
        classNames={{
          modal: 'customModal',
          overlayAnimationIn: 'customEnterOverlayAnimation',
          overlayAnimationOut: 'customLeaveOverlayAnimation',
          modalAnimationOut: 'customLeaveModalAnimation',
        }}
        center
        animationDuration={800}
      >
        <Form
          autoComplete="off"
          onSubmit={handleSubmit(submit)}
          style={{ minWidth: '900px' }}
        >
          <div className="max-w-[1000px] min-h-[300px]">
            <HeaderModal>
              <p>Adicionar filiais</p>
            </HeaderModal>
            <ContentModal>
              <FormContent>
                <div className="flex-auto max-w-sm">
                  <div className="w-full form-control">
                    <label className="label">
                      <strong className="label-text">Filiais</strong>
                    </label>

                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      placeholder="Selecione as filiais"
                      options={tenantsOptions}
                      value={selectedSubsidiaries}
                      onChange={(data: MultiValue<SelectProps>, _: ActionMeta<unknown>) => {
                        selectBranches(data);
                        setValue('subsidiaries', data.map((item) => item.value));
                      }}
                    />
                  </div>
                  <Content>{!!errors.subsidiaries ? 'Selecione ao menos uma filial' : ''}</Content>
                </div>
              </FormContent>

              {isLoadingTenants && (
                <div className="mt-6">
                  <LoadingSpinner />
                </div>
              )}

              {(company?.subsidiaries && company?.subsidiaries?.length > 0 && !isLoadingTenants) && (
                <div className="grid grid-cols-1 gap-4 mt-6">
                  <div className="shadow-xl card bg-base-100">
                    <div className="overflow-x-auto">
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Quantidade funcionários</th>
                            <th>Data base último upload</th>
                            <th>Data último upload</th>
                            <th>Remover</th>
                          </tr>
                        </thead>

                        <tbody>
                          {company?.subsidiaries?.map((subsidiary) => (
                            <tr key={subsidiary.id}>
                              <td>{truncateText(subsidiary.name)}</td>
                              <td>{subsidiary.email}</td>
                              <td>{subsidiary.employees_count}</td>
                              <td>{getMonthYearFromDateISO(subsidiary.lastBaseDate || '')}</td>
                              <td>{formatDateFullWithHour(subsidiary.lastUploadDate || '')}</td>
                              <td>
                                <div
                                  onClick={() => {
                                    handleShowConfirmDeleteSubsidiaryModal(subsidiary.id)
                                  }}
                                  className="btn btn-sm btn-error w-[50px] h-[50px]"
                                >
                                  <FiXCircle />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </ContentModal>
          </div>
          <ContainerButton>
            <Button type="submit">Adicionar filiais</Button>
          </ContainerButton>
        </Form>
      </Modal>

      {isShowModalConfirmDeleteSubsidiary && (
        <ModalConfirmDeleteSubsidiary
          callback={actionDeleteSubsidiary}
          isLoading={isLoading}
          closeModal={() => {
            setIsShowModalConfirmDeleteSubsidiary(false);
          }}
        />
      )}
    </>
  );
};

export { HoldingManageModal };
