import { useEffect, useState } from 'react';
import { Container, ContainerButton, Form, InputWrapper } from './styles';
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import './styles.css';
import { AppError } from '../../error/AppError';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import Modal from 'react-responsive-modal';
import { ModalGeneric } from '../../components/ModalGeneric';
import { Content } from '../../pages/MarketConfiguration/styles';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeUserPasswordErrors } from '../../components/change-user-password-errors';
import { Tooltip } from '../../components/tooltip-html';
import { BackButton } from '../../components/back-button';
import { FaCircle } from 'react-icons/fa';
import { ConfirmUnblockResetPassModal } from './ConfirmUnblockResetPassModal';
import { generateRandString } from '../../shared/utils';
import { TENANT_TYPES_NAMES } from '../../shared/constants';

yup.addMethod(yup.string, 'strongPassword', function strongPasswordMethod() {
  return this.test('strongPasswordTest', '', function (value) {
    const { path, createError } = this;

    const errors: ValidationError[] = [];

    if (!/^(?=.*[a-z])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-lowercase-letter',
        }),
      );
    }

    if (!/^(?=.*[A-Z])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-uppercase-letter',
        }),
      );
    }

    if (!/^(?=.*[!@#$%^&*])/.test(String(value))) {
      errors.push(
        createError({
          path,
          message: 'password-special-character',
        }),
      );
    }

    if (errors.length > 0) {
      return new ValidationError(
        errors.map((error) => error.message).join(', '),
      );
    }

    return true;
  });
});

const userSchema = yup.object({
  name: yup.string().required(),
  email: yup
    .string()
    .email('Esse campo deve ser um e-mail')
    .required('Campo Obrigatório'),
  perfil: yup.string().required(),
  is_tenant_admin: yup.boolean().default(false),
});

const changePasswordSchema = userSchema.shape({
  password: yup
    .string()
    .min(8, 'password-min-length')
    .max(16, 'password-max-length')
    .strongPassword()
    .required(),
});

interface SubmitForm {
  name: string;
  email: string;
  password: string;
  perfil: string;
  cpf: string;
  is_tenant_admin: boolean;
  roles: {
    value: string;
  };
}

export default function CompanyUsers() {
  const { id } = useParams();

  const [company, setCompany] = useState<any>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [itemAddRole, setItemAddRole] = useState<string>('');
  const [isShowModalUser, setIsShowModalUser] = useState(false);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowModalResetMFA, setIsShowModalResetMFA] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isSetUser, setIsSetUser] = useState<any>([]);
  const [itensPlans, setItensPlans] = useState<any>([
    {
      id: '',
      start_date: '',
      expire_date: '',
    },
  ]);
  const [users, setUsers] = useState([]);
  const [statusUser, setStatusUser] = useState('active');
  const [passwordLengthError, setPasswordLengthError] = useState(true);
  const [uppercaseError, setUppercaseError] = useState(true);
  const [lowercaseError, setLowercaseError] = useState(true);
  const [specialCharacterError, setSpecialCharacterError] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [
    isModalConfirmUnblockResetPassOpen,
    setIsModalConfirmUnblockResetPassOpen,
  ] = useState(false);

  const {
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<
    Pick<
      SubmitForm,
      'name' | 'email' | 'password' | 'perfil' | 'is_tenant_admin'
    >
  >({
    resolver: yupResolver(
      (isNewUser ? changePasswordSchema : userSchema) as yup.ObjectSchema<
        Pick<
          SubmitForm,
          'name' | 'email' | 'password' | 'perfil' | 'is_tenant_admin'
        >,
        yup.AnyObject,
        any,
        ''
      >,
    ),
  });

  const submit = async (data: any) => {
    try {
      if (isNewUser) {
        const parseData = {
          name: data.name,
          email: data.email,
          password: data.password,
          cpf: data.cpf,
          status: statusUser,
          is_tenant_admin: false,
          roles: [data.perfil],
        };
        const retorno = await api.post(`/tenants/${id}/users`, parseData);
        toast.success('Usuário criado com sucesso.');
      } else {
        const idUser = isSetUser.id;
        const parseData = {
          name: data.name,
          email: data.email,
          is_tenant_admin: false,
          status: statusUser,
          roles: [data.perfil],
        };
        const retorno = await api.put(
          `/tenants/${id}/users/${idUser}`,
          parseData,
        );
        toast.success('Usuário editado com sucesso.');
      }

      getUsers();
      setIsShowModalUser(false);
    } catch (error) {
      AppError(error, `Problemas incluir usuário!`);
    }
  };

  async function getGroups() {
    const { data } = await api.get(`/tenants/${id}/roles`);
    setRoles(data);
  }

  async function getCompany() {
    const { data } = await api.get(`/tenants/${id}`);
    setCompany(data.tenant);
    setItensPlans(data.tenant.plans);
  }

  async function getUsers() {
    const { data } = await api.get(`/tenants/${id}/users`);
    setUsers(data);
  }

  async function handleProccessDelete() {
    try {
      const user = isSetUser.id;
      const retorno = await api.delete(`/tenants/${id}/users/${user}`);
      toast.success('Usuário excluído com sucesso!');
      getUsers();
    } catch (err) {
      toast.error('Erro ao excluído o usuário!');
    }
  }

  async function handleProccessResetMfa() {
    try {
      const user = isSetUser.id;
      const response = await api.post(`/tenants/${id}/users/${user}/mfa/reset`);

      if (response) {
        toast.success('MFA resetado com sucesso!');
      }

      getUsers();
    } catch (err) {
      toast.error('Erro ao resetar o MFA do usuário!');
    }
  }

  async function handleAllowUserResetPassowod()  {
    try {
      const user_id = isSetUser.id;
      const response = await api.patch(`/tenants/${id}/users/${user_id}/allow-password/reset`);

      if (response) {
        toast.success('Resete de senha desbloqueado com sucesso!');
      }

      getUsers();
    } catch (err) {
      toast.error('Erro ao tentar desbloquear resete de senha do usuário!');
    }
  }

  function findRoleName(item: any) {
    if (item.role) {
      const filterRoles: any = roles.filter(
        (permission: any) => permission.id === item.role.id,
      );
      return filterRoles.length > 0 ? filterRoles[0].name : 'N/A';
    } else {
      return 'N/A';
    }
  }

  async function testValidate(value: string) {
    try {
      await userSchema.validate(
        {
          password: value,
        },
        { abortEarly: false },
      );
    } catch (e: any) {
      const errors = (e as ValidationError).errors;
      if (
        String(errors).includes('password-min-length') ||
        String(errors).includes('password-max-length')
      ) {
        setPasswordLengthError(true);
      } else {
        setPasswordLengthError(false);
      }

      if (String(errors).includes('password-uppercase-letter')) {
        setUppercaseError(true);
      } else {
        setUppercaseError(false);
      }

      if (String(errors).includes('password-lowercase-letter')) {
        setLowercaseError(true);
      } else {
        setLowercaseError(false);
      }

      if (String(errors).includes('password-special-character')) {
        setSpecialCharacterError(true);
      } else {
        setSpecialCharacterError(false);
      }
    }
  }

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    getCompany();
    getUsers();
  }, [id]);

  return (
    <Container>
      <BackButton />

      <h2 className="text-2xl font-bold">Empresa - Usuários</h2>
      <div className="divider"></div>

      <div className="grid grid-cols-1 gap-4">
        {isShowModalUser && (
          <Modal
            open={true}
            onClose={() => setIsShowModalUser(false)}
            classNames={{
              modal: 'customModal',
              overlayAnimationIn: 'customEnterOverlayAnimation',
              overlayAnimationOut: 'customLeaveOverlayAnimation',
              modalAnimationOut: 'customLeaveModalAnimation',
            }}
            center
            animationDuration={500}
          >
            <Form onSubmit={handleSubmit(submit)}>
              {(isNewUser || isSetUser) && (
                <div className="grid gap-4 min-w-[900px] mb-8 pt-5">
                  {/* <div className="grid justify-center w-full mb-9">
                    <div className="tabs">
                      <a className="tab tab-lifted text-[17px] tab-active">
                        <strong>Dados do usuário</strong>
                      </a>
                      <a className="tab tab-lifted text-[17px]">
                        <strong>Permissões</strong>
                      </a>
                    </div>
                  </div> */}

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex-auto w-100">
                      <div className="flex-auto w-128">
                        <label className="label">
                          <strong className="label-text">
                            Nome
                            <label className="text-error">*</label>
                          </strong>
                        </label>

                        <input
                          type="text"
                          placeholder="Nome"
                          {...register('name', {
                            required: true,
                          })}
                          defaultValue={isNewUser ? '' : isSetUser.name}
                          className="w-full max-w-xs input input-bordered"
                        />
                      </div>
                      <Content>
                        {errors.name ? 'Campo Obrigatório' : null}
                      </Content>
                    </div>

                    {/* {isNewUser === 'inativo' && (
                      <div className="flex-auto w-128">
                        <div className="flex-auto w-128">
                          <label className="label">
                            <strong className="label-text">
                              Cpf
                              <label className="text-error">*</label>
                            </strong>
                          </label>

                          <input
                            type="text"
                            placeholder="Cpf"
                            {...register('cpf', {
                              required: true,
                            })}
                            defaultValue={isNewUser ? '' : isSetUser.cpf}
                            className="w-full max-w-xs input input-bordered"
                          />
                        </div>
                        <Content>
                          {errors.cpf ? 'Campo Obrigatório' : null}
                        </Content>
                      </div>
                    )} */}
                    <div className="flex-auto w-128">
                      <div className="flex-auto w-128">
                        <label className="label">
                          <strong className="label-text">
                            Email
                            <label className="text-error">*</label>
                          </strong>
                        </label>

                        <input
                          type="text"
                          {...register('email', {
                            required: true,
                          })}
                          defaultValue={isNewUser ? '' : isSetUser.email}
                          placeholder="Email"
                          className="w-full max-w-xs input input-bordered"
                        />
                      </div>
                      <Content>{errors.email?.message || null}</Content>
                    </div>

                    <div className="flex-auto w-128">
                      <div className="flex-auto w-128">
                        <label className="label">
                          <strong className="label-text">
                            Perfil
                            <label className="text-error">*</label>
                          </strong>
                        </label>
                        <select
                          className="w-[320px] select select-bordered"
                          {...register('perfil', {
                            required: true,
                          })}
                          onChange={(e: any) => {
                            setItemAddRole(e.target.value);
                          }}
                        >
                          <option></option>
                          {roles.map((item: any) => (
                            <option
                              value={item.id}
                              key={item.name}
                              selected={
                                item.id ===
                                (!isNewUser ? isSetUser.role.id : '')
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Content>
                        {errors.perfil ? 'Campo Obrigatório' : null}
                      </Content>
                    </div>

                    {isNewUser && (
                      <div className="flex-auto w-128">
                        <div className="flex-auto w-140">
                          <label className="label">
                            <strong className="label-text">
                              Senha
                              <label className="text-error">*</label>
                            </strong>
                          </label>

                          <InputWrapper>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              maxLength={16}
                              {...register('password', {
                                required: true,
                              })}
                              placeholder="Senha"
                              className="w-full max-w-xs input input-bordered"
                              onChange={(e) => {
                                setValue('password', e.target.value, {
                                  shouldValidate: true,
                                });
                                testValidate(e.target.value);
                              }}
                            />
                            {showPassword ? (
                              <FiEyeOff
                                size={20}
                                onClick={() => setShowPassword(false)}
                                className=""
                              />
                            ) : (
                              <FiEye
                                size={20}
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </InputWrapper>
                        </div>
                        <Content>
                          {errors.password ? 'Campo Obrigatório' : null}
                        </Content>

                        {touchedFields.password && (
                          <ChangeUserPasswordErrors
                            passwordLengthError={passwordLengthError}
                            uppercaseError={uppercaseError}
                            lowercaseError={lowercaseError}
                            specialCharacterError={specialCharacterError}
                          />
                        )}
                      </div>
                    )}

                    <div className="flex-auto w-128">
                      <div className="flex-auto w-128">
                        <label className="label">
                          <strong className="label-text">
                            Status
                            <label className="text-error">*</label>
                          </strong>
                        </label>
                        <div className="flex gap-2">
                          <label className="flex gap-2 label">
                            <input
                              type="radio"
                              name="exclusao_inclusao"
                              checked={statusUser === 'active'}
                              onChange={() => setStatusUser('active')}
                            />
                            <strong className="label-text">Ativo</strong>
                          </label>

                          <label className="flex gap-2 label">
                            <input
                              type="radio"
                              name="exclusao_inclusao"
                              checked={statusUser === 'inactive'}
                              onChange={() => setStatusUser('inactive')}
                            />
                            <strong className="label-text">Inativo</strong>
                          </label>

                          <label className="flex gap-2 label">
                            <input
                              type="radio"
                              name="exclusao_inclusao"
                              checked={statusUser === 'blocked'}
                              onChange={() => setStatusUser('blocked')}
                            />
                            <strong className="label-text">Bloqueado</strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <ContainerButton>
                <Button type="submit">
                  {isNewUser ? 'Adicionar' : 'Alterar'}
                </Button>
              </ContainerButton>
            </Form>
          </Modal>
        )}

        <ConfirmUnblockResetPassModal
          isOpen={isModalConfirmUnblockResetPassOpen}
          setIsModalOpen={setIsModalConfirmUnblockResetPassOpen}
          handleConfirm={() => handleAllowUserResetPassowod()}
        />

        {isShowModalDelete && (
          <ModalGeneric
            isModalOpen={isShowModalDelete}
            setIsModalOpen={setIsShowModalDelete}
          >
            <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
              Tem certeza que deseja excluir ?
              <br />
              <Button
                type="button"
                onClick={(e: any) => {
                  setIsShowModalDelete(false);
                  handleProccessDelete();
                }}
              >
                Confirmar
              </Button>
              <button
                className="btn btn-ghost"
                onClick={() => setIsShowModalDelete(false)}
              >
                Cancelar
              </button>
            </div>
          </ModalGeneric>
        )}

        {isShowModalResetMFA && (
          <ModalGeneric
            isModalOpen={isShowModalResetMFA}
            setIsModalOpen={setIsShowModalResetMFA}
          >
            <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
              Tem certeza que deseja resetar o MFA do usuário {isSetUser?.name}?
              <br />
              <Button
                type="button"
                onClick={(e: any) => {
                  setIsShowModalResetMFA(false);
                  handleProccessResetMfa();
                }}
              >
                Confirmar
              </Button>
              <button
                className="btn btn-ghost"
                onClick={() => setIsShowModalResetMFA(false)}
              >
                Cancelar
              </button>
            </div>
          </ModalGeneric>
        )}

        <ContainerButton>
          <Button
            type="button"
            onClick={() => {
              setIsShowModalUser(true);
              setIsNewUser(true);
              reset();
              setStatusUser('active');
            }}
          >
            Adicionar novo usuário
          </Button>
        </ContainerButton>

        <div className="p-4 shadow-xl card bg-base-100">
          {company ? (
            <div className="flex w-full mb-7">
              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">Empresa</strong>
                </label>
                {company.name}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">CNPJ</strong>
                </label>
                {company.cnpj}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">Email</strong>
                </label>
                {company.email}
              </div>

              <div className="flex-auto w-64">
                <label className="label">
                  <strong className="label-text">Tipo da empresa</strong>
                </label>
                {TENANT_TYPES_NAMES[company?.tenant_configurations?.[0]?.tenant_type || ''] || ''}
              </div>

              {company?.tenant_configurations?.[0]?.tenant_type === 'subsidiary' && (
                <div className="flex-auto w-64">
                  <label className="label">
                    <strong className="label-text">Holding</strong>
                  </label>
                  {company?.tenant_father?.name}
                </div>
              )}
            </div>
          ) : null}

          <div className="grid gap-4 min-w-[800px] mb-8 border-t pt-5">
            <div className="flex w-full">
              <div className="flex-auto w-64 overflow-x-auto">
                {users.length > 0 && (
                  <>
                    <table className="table w-full table-compact table-zebra">
                      <tbody>
                        <tr className="border-b dark:border-neutral-200">
                          <td>
                            <strong>Nome</strong>
                          </td>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td>
                            <strong>Perfil</strong>
                          </td>
                          <td>
                            <strong>Status</strong>
                          </td>
                          <th>
                            <strong>MFA</strong>
                          </th>
                          <th
                            style={{
                              width: '200px',
                            }}
                          ></th>
                        </tr>
                        {users.map((item: any, index: number) => (
                          <tr className="border-b dark:border-neutral-200">
                            <td>
                              <strong>{item.name}</strong>
                            </td>
                            <td>
                              <strong>{item.email}</strong>
                            </td>
                            <td>{findRoleName(item)}</td>
                            <td>
                              <strong>
                                {item.status === 'active' ? 'Ativo' : item.status === 'blocked' ? "Bloqueado" : 'Inativo'}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {item?.is_active_mfa ? (
                                  <FaCircle color="#22D66A" size={15} />
                                ) : (
                                  <FaCircle color="#ff0000" size={15} />
                                )}
                              </strong>
                            </td>
                            <td className="flex justify-end gap-2">
                              <button
                                className="btn btn-sm btn-success"
                                onClick={(e: any) => {
                                  reset();
                                  setIsSetUser(item);
                                  setIsShowModalUser(true);
                                  setIsNewUser(false);
                                  setStatusUser(item.status);
                                }}
                              >
                                Alterar
                              </button>
                              <div>
                                <Tooltip
                                  tooltipKey={`${item.id}-${generateRandString()}`}
                                  fullText={
                                    !item?.is_active_mfa  ? 'Este usuário já está sem autenticação MFA!' : ""
                                  }
                                  disabled={item?.is_active_mfa}
                                  displayChildren={
                                    <button
                                      className="btn btn-sm btn-warning"
                                      onClick={(e: any) => {
                                        setIsSetUser(item);
                                        setIsShowModalResetMFA(true);
                                      }}
                                      disabled={!item?.is_active_mfa}
                                    >
                                      Resetar MFA
                                    </button>
                                  }
                                />
                              </div>

                              <div>
                                <Tooltip
                                  tooltipKey={`${item.id}-${generateRandString()}`}
                                  fullText={item?.is_allowed_to_reset_password ? "Este usuário já está apto a resetar senha!" : ""}
                                  disabled={!item?.is_allowed_to_reset_password}
                                  displayChildren={
                                    <button
                                      className="btn btn-sm btn-warning"
                                      onClick={() => {
                                        setIsSetUser(item);
                                        setIsModalConfirmUnblockResetPassOpen(
                                          true,
                                        );
                                      }}
                                      disabled={item?.is_allowed_to_reset_password}
                                    >
                                      Desbloquear resete de senha
                                    </button>
                                  }
                                />
                              </div>

                              <button
                                className="btn btn-sm btn-error"
                                onClick={(e: any) => {
                                  setIsSetUser(item);
                                  setIsShowModalDelete(true);
                                }}
                                style={{ width: '150px' }}
                              >
                                Excluir
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
