import styled from 'styled-components';
import ReactSelect from 'react-select';

export const BackgroundContainerModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--background-modal);
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: 5rem 0 0;
  color: var(--text-body);

  > div {
    display: flex;
    flex-direction: column;

    max-height: 30rem;
    width: 40rem;
    background-color: var(--white);
    overflow-y: auto;
    z-index: 2;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--black-light);

  > p {
    padding: 1rem;
    font-weight: 700;
  }

  > svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  // justify-content: space-around;
`;

export const ContentModal = styled.div`
  padding: 1rem;
  height: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    display: flex;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  width: 100%;
  max-width: 210px;
`;

export const Select = styled(ReactSelect)`
  margin: 1rem 0;
  max-width: 280px;
  width: 100%;

  & + div {
    margin-left: 2rem;
  }
`;
