import styled from 'styled-components';

interface ContainerButtonProps {
  size: 'sm' | 'lg' | 'md';
  color: 'primary' | 'secondary';
  isOutline: boolean;
}

export const Container = styled.button<ContainerButtonProps>`
  cursor: pointer;

  ${(p) =>
    !p.isOutline &&
    `
    background: ${
      p.color === 'primary' ? 'var(--primary);' : 'var(--secondary);'
    }
    color: #fff;`}

  ${(p) =>
    p.isOutline &&
    `
      border: 0.2rem solid ${
        p.color === 'primary' ? 'var(--primary);' : 'var(--secondary);'
      }
      background: #fff;
      color: ${p.color === 'primary' ? 'var(--primary);' : 'var(--secondary);'}
    `}
  ${(p) =>
    p.disabled &&
    `
      opacity: 0.4;
    `}

  border-radius: 8px;

  font-size: ${(p) => {
    if (p.size === 'sm') {
      return '0.9rem';
    }

    if (p.size === 'md') {
      return '1rem;';
    }

    if (p.size === 'lg') {
      return '1.1rem;';
    }

    return '1rem;';
  }};
  padding: ${(p) => {
    if (p.size === 'sm') {
      return '0.4rem 1.2rem;';
    }

    if (p.size === 'md') {
      return '0.6rem 2rem;';
    }

    if (p.size === 'lg') {
      return '0.8rem 2.4rem;';
    }

    return '100%;';
  }};
  width: 'fit-content';
  font-weight: 500;
  transition: background-color 0.3s;

  &:hover {
    ${(p) =>
      !p.isOutline &&
      !p.disabled &&
      `
    background: var(--primary-hover);

    `}

    ${(p) =>
      p.disabled &&
      `
    background: var(--secondary);

    `}
  }
`;
