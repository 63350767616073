import { Stitches } from '../../../src/styles/stitches';
import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Container = Stitches.styled('div', {});

export const Header = Stitches.styled('div', {
  width: '100%',
  borderBottom: '1px solid #dddddd',
  padding: '10px',
  fontWeight: 'bold',
  marginBottom: '15px',
});

export const Select = styled(ReactSelect)`
  margin: 1rem 0;
  width: 100%;
  min-width: 335px;
`;

export const NoQuestions = Stitches.styled('div', {
  fontSize: '1.5rem',
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '40%',
});
