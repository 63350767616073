import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalGeneric } from '../../components/ModalGeneric';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { api } from '../../services/api';
import { Container, Content } from './styles';
import { Button } from '../../components/Button';
import { ButtonPrimary } from '../DashboardUsers/styles';
import { IconPlus } from '../DashboardCompany/styles';
import { ContainerButton, Form } from '../SignIn/styles';
import { useForm } from 'react-hook-form';
import { ModalConfirm } from '../../components/ModalConfirm';

interface SubmitForm {
  id: {
    value: string;
  };
  name: {
    value: string;
  };
  description: {
    value: string;
  };
  sector_group_id: {
    value: string;
  };
}

export default function Sector() {
  const [sectorList, setSectorList] = useState<any>([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [sectorData, setSectorData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [groupsList, setGroupsList] = useState<any>([]);
  const [isCreate, setIsCreate] = useState(true);
  const [selected, setSelected] = useState();
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [currentDeleteSector, setCurrentDeleteSector] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();

  async function listSectors() {
    try {
      const { data: dataSector } = await api.get('/sectors');

      setSectorList(dataSector);
    } catch (err) {
      toast.error('Erro ao buscar dados do setor!');
    }

    handleSetIsLoading(false);
  }

  async function listGroups() {
    try {
      const { data: dataGroups } = await api.get('/sectors/groups');

      setGroupsList(dataGroups);
    } catch (err) {
      toast.error('Erro ao buscar dados de macro setores!');
    }

    handleSetIsLoading(false);
  }

  const submit = async (data: SubmitForm) => {
    const { name, description, sector_group_id } = data;
    try {
      const parseData = {
        name,
        description,
        sector_group_id,
      };
      if (isCreate) {
        const retorno = await api.post('/sectors', parseData);
        toast.success('Setor criado com sucesso.');
        listSectors();
        resetField('name');
        resetField('description');
        resetField('sector_group_id');
        setSectorData([]);
        setShowModal(false);
      } else {
        const id = sectorData.id;
        const retorno = await api.put(`/sectors/${id}`, parseData);
        toast.success('Setor editado com sucesso.');
        listSectors();
        resetField('name');
        resetField('description');
        resetField('sector_group_id');
        setSectorData([]);
        setShowModal(false);
      }
    } catch (err: any) {
      console.log(err.response);
      toast.error(err.response.data.message);
    }
  };

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const handleSelect = async (data: SubmitForm) => {
    reset();
    setSectorData(data);
  };

  const actionDeleteSector = async () => {
    try {
      const retorno = await api.delete(`/sectors/${currentDeleteSector}`);
      toast.success('Setor removido com sucesso.');
      listSectors();
      setIsShowModalConfirm(false);
    } catch (error) {
      toast.error(`Problemas ao remover setor`);
    }
  };

  const handleChangeAction = useCallback(
    (type: string, sector) => {
      switch (type) {
        case 'edit':
          setIsCreate(false);
          handleSelect(sector);
          setShowModal(true);
          break;
        case 'delete':
          handleSelect(sector);
          setCurrentDeleteSector(sector.id);
          setIsShowModalConfirm(true);
          break;
        default:
          break;
      }
    },
    [
      setIsCreate,
      handleSelect,
      setShowModal,
      setCurrentDeleteSector,
      setIsShowModalConfirm,
    ],
  );

  useEffect(() => {
    listSectors();
    listGroups();
  }, []);

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="sector"
          callback={actionDeleteSector}
          closeModal={() => {
            setIsShowModalConfirm(false);
            setSelectedAction('Ações');
          }}
        />
      )}
      <h2 className="text-2xl font-bold">Setor</h2>
      <div className="divider"></div>

      <ButtonPrimary
        onClick={() => {
          setIsCreate(true);
          setShowModal(true);
          setSectorData('');
          resetField('name');
          resetField('description');
          resetField('sector_group_id');
        }}
        className="mb-5"
      >
        <IconPlus />
        Adicionar Setor
      </ButtonPrimary>

      <table className="table w-full mt-6">
        <thead>
          <tr>
            <th>Macro Setor</th>
            <th>Setor</th>
            <th colSpan={2}>Ações</th>
          </tr>
        </thead>

        <tbody>
          {sectorList.map((sector, index) => (
            <tr key={index}>
              <td>{sector.sector_group?.name}</td>
              <td>{sector.name}</td>
              <td>
                <select
                  value={sector?.id === sectorData?.id ? selectedAction : ''}
                  className="w-[150px] select select-bordered"
                  onChange={(e) => {
                    handleChangeAction(e.target.value, sector);
                    setSelectedAction(e.target.value);
                  }}
                >
                  <option>Ações</option>
                  <option value="edit" key="edit">
                    Editar
                  </option>
                  <option value="delete" key="delete">
                    Excluir
                  </option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalGeneric
        isModalOpen={showModal}
        setIsModalOpen={(e) => {
          setShowModal(e);
          setSelectedAction('Ações');
        }}
        ariaHideApp={false}
      >
        <Container>
          <div className="grid grid-cols-1 gap-4" style={{ width: '400px' }}>
            <strong>Editar Setor</strong>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-bold">Grupo </span>
              </label>
              <select
                className="select select-bordered w-full"
                {...register('sector_group_id', { required: true })}
                value={selected}
                onChange={handleChange}
              >
                <option disabled selected></option>
                {showModal &&
                  groupsList.map((group, index) => (
                    <option
                      key={index}
                      value={group.id}
                      selected={group.id === sectorData.sector_group_id}
                    >
                      {group.name}
                    </option>
                  ))}
              </select>
              <Content>
                {!!errors.sector_group_id ? 'Campo Obrigatório' : ''}
              </Content>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-bold">Título do setor</span>
              </label>
              <input
                type="text"
                {...register('name', { required: true })}
                defaultValue={sectorData ? sectorData.name : ''}
                placeholder="Setor"
                className="input input-bordered w-full"
              />
              <Content>{!!errors.name ? 'Campo Obrigatório' : ''}</Content>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text font-bold">Descrição</span>
              </label>
              <textarea
                className="textarea textarea-bordered"
                {...register('description', { required: false })}
                placeholder="Descrição do setor"
              >
                {sectorData ? sectorData.description : ''}
              </textarea>
              {/* <Content>{!!errors.descricao ? 'Campo Obrigatório' : ''}</Content> */}
            </div>
            <div className="flex align-middle justify-center w-full">
              <Container>
                <Form onSubmit={handleSubmit(submit)}>
                  <ContainerButton>
                    <Button type="submit">
                      {isCreate ? 'Cadastrar' : 'Editar'}
                    </Button>
                  </ContainerButton>
                </Form>
              </Container>
            </div>
          </div>
        </Container>
      </ModalGeneric>
    </Container>
  );
}
