import styled from 'styled-components';
import { FiLogOut } from 'react-icons/fi';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 30px 0;
  background-color: var(--text-body-clean);
  color: var(--text-title);
`;

export const Title = styled.p`
  padding: 0 16px;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-primary);
`;

export const Logout = styled(FiLogOut)`
  font-size: 20px;
  margin-right: 1rem;
  cursor: pointer;
`;
