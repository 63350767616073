import { useMutation } from 'react-query';
import { api } from '../../../../services/api';
import { queryClient } from '../../../../App';

type LinkSubsidiaryRequest = {
  subsidiaries_ids: string[];
};

type LinkSubsidiaryResponse = {
  board_director: string;
  custom_name: string;
  subfamily: string | null;
  subfamily_id: string;
}[];

export function useLinkSubsidiary(tenantFatherId: string) {
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    async (payload: LinkSubsidiaryRequest) => {
      const { data } =
        await api.post<LinkSubsidiaryResponse>(
          `/tenants/${tenantFatherId}/subsidiary`, payload
        );

      if (data) {
        return data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`get-company-${tenantFatherId}`);
      }
    }
  );

  return {
    linkSubsidiary: mutateAsync,
    isSuccess,
    isLoading,
  };
}
