import { useState, useEffect, useCallback, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Button } from '../Button';
import { AppError } from '../../error/AppError';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  ContributionLevel,
  Match,
  OptionSelect,
  SmartpayGrade,
  TenantGrade,
} from '../../interfaces';
import { api } from '../../services/api';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  Select,
  SelectContainer,
  FormContent,
} from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../Input';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: (data: SubmitForm) => void;
  data: ContributionLevel | null;
}

interface SubmitForm {
  id?: string;
  title: string;
  code: string;
  description: string;
  type: string;
}

const ModalContributionLevel = ({ data, closeModal, callback }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitForm>();

  const title = !!data?.id ? 'Alterar' : 'Criar';

  const submit = (data: SubmitForm) => {
    callback(data);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>{title}</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>

        <ContentModal>
          <Form autoComplete="off" onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div className="flex-auto w-full mt-0">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Título </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('title', {
                      required: true,
                      value: data?.title,
                    })}
                    // error={!!errors.title}
                    // helperText={!!errors.title ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.title ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto w-full mt-4">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Código </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('code', {
                      required: true,
                      value: data?.code,
                    })}
                    // error={!!errors.code}
                    // helperText={!!errors.code ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.code ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto w-full mt-4">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Descrição </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('description', {
                      required: false,
                      value: data?.description || '',
                    })}
                    // error={!!errors.description}
                    // helperText={!!errors.description ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.description ? 'Campo Obrigatório' : ''}
                </Content>
              </div>

              <div>
                <span>Tipo:</span>
                {/* <Input
                  type="text"
                  {...register('type', {
                    required: true,
                    value: data?.type,
                  })}
                  error={!!errors.type}
                  helperText={!!errors.type ? 'Campo Obrigatório' : ''}
                /> */}

                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={data?.type || 'grade'}
                  render={({ field: { onChange, name, ref } }) => (
                    <Select
                      ref={ref}
                      name={name}
                      defaultValue={
                        data?.type
                          ? { label: data?.type, value: data?.type }
                          : { label: 'grade', value: 'grade' }
                      }
                      onChange={(val: any) => onChange(val.value)}
                      options={[
                        { label: 'position', value: 'position' },
                        { label: 'grade', value: 'grade' },
                      ]}
                      isSearchable={false}
                      isClearable={false}
                      maxMenuHeight={150}
                      placeholder="Escolha um tipo"
                    />
                  )}
                />
              </div>
            </FormContent>

            {/* <Select
                onChange={(option) => console.log('')}
                value={selectAbleSmartpayGrade[i]}
                options={smartPayGradesOptions as any}
                isSearchable={true}
                maxMenuHeight={150}
                placeholder="grade smartpay"
                isClearable={true}
              /> */}
            <ContainerButton>
              <Button type="submit">{title}</Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalContributionLevel };
