import { useQuery } from 'react-query';
import { api } from '../../../services/api';
import { Company } from '../../../interfaces';

type TenantType = 'independent' | 'holding' | 'subsidiary' | '';

type GetTenantsParams = {
  paginate: boolean
  tenant_type?: TenantType
}
export type GetTenantsResponse = Company[];

export function useGetTenants({ paginate = false, tenant_type = '' }: GetTenantsParams) {
  const {
    data: tenants,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  } = useQuery({
    queryKey: 'get-tenants',
    queryFn: async () => {
      const data = await api.get<GetTenantsResponse>('/tenants', {
        params: {
          paginate,
          ...(tenant_type && { tenant_type })
        }
      });

      if (data) {
        return data.data;
      }

      return null;
    },
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: Infinity,
    retry: 2,
  });

  return {
    tenants,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
