import { useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { CreateOrUpdateUser } from '../../interfaces';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  ContentForm,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: (data: CreateOrUpdateUser, typeAction: 'update' | 'create') => void;
  user?: CreateOrUpdateUser | null;
}

const ModalAccountUser = ({ closeModal, user, callback }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data: any) => {
    callback(data, user ? 'update' : 'create');
    closeModal();
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>Usuário</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>
        <ContentModal>
          <Form autoComplete="off" onSubmit={handleSubmit(submit)}>
            <ContentForm>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Nome </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    placeholder="Nome"
                    {...register('name', {
                      required: true,
                      value: user?.name,
                    })}
                    // error={!!errors.email}
                    // helperText={!!errors.email ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.name ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">E-mail </strong>
                  </label>
                  <input
                    type="email"
                    className="input input-bordered w-full max-w-xs"
                    placeholder="E-mail"
                    {...register('email', {
                      required: true,
                      value: user?.email,
                    })}
                    // error={!!errors.email}
                    // helperText={!!errors.email ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.email ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <div className="flex-auto w-64 mt-6">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Senha </strong>
                  </label>
                  <input
                    autoComplete="new-password"
                    className="input input-bordered w-full max-w-xs"
                    placeholder="Senha"
                    type="password"
                    {...register('password', { required: !user })}
                    // error={!!errors.password}
                    // helperText={!!errors.password ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.password ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
            </ContentForm>
            <ContainerButton>
              <Button type="submit">{!!user ? 'Editar' : 'Enviar'}</Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalAccountUser };
