import { Button } from '../../../components/Button';
import { ModalGeneric } from '../../../components/ModalGeneric';

interface ConfirmUnblockResetPassModalProps {
  isOpen: boolean;
  setIsModalOpen: (v: boolean) => void;
  handleConfirm: () => void;
}

export function ConfirmUnblockResetPassModal({
  isOpen,
  setIsModalOpen,
  handleConfirm,
}: ConfirmUnblockResetPassModalProps) {
  return (
    <ModalGeneric isModalOpen={isOpen} setIsModalOpen={setIsModalOpen}>
      <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
        Este usuário está temporariamente bloqueado para resete de senha por
        medidas de segurança. Tem certeza que deseja desbloquea-lo? Ao
        confirmar, o usuário estará apto a fazer o resete de senha novamente ou
        pelo "Esqueci minha senha" ou pela plataforma na página de "Perfil"!
        <br />
        <Button
          type="button"
          onClick={() => {
            setIsModalOpen(false);
            handleConfirm();
          }}
        >
          Confirmar
        </Button>
        <button className="btn btn-ghost" onClick={() => setIsModalOpen(false)}>
          Cancelar
        </button>
      </div>
    </ModalGeneric>
  );
}
