import { ModalGeneric } from '../../../components/ModalGeneric';

interface MarketErrorModalProps {
  isModalOpenErrorInfo: boolean;
  setIsModalOpenErrorInfo: (v: boolean) => void;
  errorInfo: string;
}

export function MarketErrorModal({
  isModalOpenErrorInfo,
  setIsModalOpenErrorInfo,
  errorInfo
}: MarketErrorModalProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalOpenErrorInfo}
      setIsModalOpen={setIsModalOpenErrorInfo}
    >
      <div className="grid grid-cols-1 gap-4" style={{ width: '550px' }}>
        <div className="w-full max-w-xs form-control">
          <label className="label">
            <strong className="label-text">Descrição do Erro</strong>
          </label>
        </div>

        <div className="shadow-lg alert">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="flex-shrink-0 w-6 h-6 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <span>{isModalOpenErrorInfo ? errorInfo : ''}</span>
          </div>
        </div>
        <button
          className="btn btn-ghost"
          onClick={() => setIsModalOpenErrorInfo(false)}
        >
          Fechar
        </button>
      </div>
    </ModalGeneric>
  );
}
