import { useForm, useFieldArray } from 'react-hook-form';
import { FiX, FiPlusCircle, FiXCircle } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { MatrizQuestion, OptionGroup } from '../../interfaces';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  FormContent,
  SectionAlternatives,
  ContainerOption,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: (data: OptionGroup, typeAction: 'update' | 'create') => void;
  optionGroup?: OptionGroup | null;
}

const ModalQuestion = ({ closeModal, optionGroup, callback }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OptionGroup>({
    defaultValues: {
      title: optionGroup?.title,
      options: optionGroup?.options || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const submit = (data: OptionGroup) => {
    if (data.options.length <= 0) {
      toast.error('Você deve adicionar ao menos 1 opção');
      return;
    }

    callback(data, optionGroup ? 'update' : 'create');
    closeModal();
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div className="w-[800px]">
        <HeaderModal>
          <p>Empresa</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>
        <ContentModal>
          <Form onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Nome </strong>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    placeholder="Título"
                    {...register('title', {
                      required: true,
                      value: optionGroup?.title,
                    })}
                    // error={!!errors.title}
                    // helperText={!!errors.title ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.title ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <SectionAlternatives>
                <p>
                  Opções
                  <FiPlusCircle
                    onClick={() => append({ title: '', weight: 0 })}
                  />
                </p>
                {fields.map((item, index) => (
                  <ContainerOption key={item.id}>
                    <input
                      placeholder="Titulo"
                      {...register(`options.${index}.title`, {
                        required: true,
                      })}
                    />
                    <input
                      placeholder="Valor"
                      {...register(`options.${index}.weight`, {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    <FiXCircle onClick={() => remove(index)} />
                  </ContainerOption>
                ))}
              </SectionAlternatives>
            </FormContent>
            <ContainerButton>
              <Button type="submit">
                {!!optionGroup ? 'Editar' : 'Enviar'}
              </Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalQuestion };
