import { useState, useCallback, useMemo } from 'react';
import {
  FiXCircle,
  FiPlus,
} from 'react-icons/fi';
import { toast } from 'react-toastify';
import { ModalConfirm } from '../../components/ModalConfirm';
import { ModalAccountCompany } from '../../components/ModalAccountCompany';
import {
  Container,
  Content,
  ButtonPrimary,
  IconPlus,
  NoQuestions,
  ControlContainer,
  Select,
  DatabaseData,
} from './styles';
import { api } from '../../services/api';
import { Company, CreateOrUpdateCompany, OptionSelect } from '../../interfaces';
import { useEffect } from 'react';
import { useAdmin } from '../../contexts/AdminContext';
import { AppError } from '../../error/AppError';
import { ModalGeneric } from '../../components/ModalGeneric';
import { useForm } from 'react-hook-form';
import { Form, ContainerButton } from './styles';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../shared/utils';
import { useGetTenants } from '../../shared/hooks/useGetTenants';
import { LoadingSpinner } from '../../components/Spinners/Loader';
import { FaCircle } from 'react-icons/fa';
import { Pagination } from '../../components/Pagination';
import { useGetPlans } from '../../shared/hooks/useGetPlans';
import { Input } from '../../components/Input';
import { useDashboardCompanySearch } from './hooks/useDashboardCompanySearch';
import { ConfirmOpenHoldingManageModal } from '../../components/ConfirmOpenHoldingManageModal';
import { HoldingManageModal } from '../../components/HoldingManageModal';
import { TENANT_TYPES_NAMES } from '../../shared/constants';
import { useGetCompany } from './hooks/useGetCompany';
import { useLinkSubsidiary } from './hooks/useLinkSubsidiary';

const plansStatusOptions = [
  { label: 'Ativo', value: 'active' },
  { label: 'Inativo', value: 'inactive' },
];

const companyTypesOptions = [
  { label: 'Holding', value: 'holding' },
  { label: 'Filial', value: 'subsidiary' },
  { label: 'Independente', value: 'independent' },
];

const DashboardCompany = () => {
  const {
    getCompanys,
    companies,
    pagination,
    deleteCompany,
    updateCompany,
    createCompany,
  } = useAdmin();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalAccountCompany, setIsShowModalAccountCompany] =
    useState(false);
  const [lastCompanyCreatedOrEditIsHolding, setLastCompanyCreatedOrEditIsHolding] =
    useState(false);
  const [isShowModalConfirmOpenHoldingManage, setIsShowModalConfirmOpenHoldingManage] =
    useState(false);
  const [isShowModalHoldingManage, setIsShowModalHoldingManage] =
    useState(false);
  const [currentEditCompany, setCurrentEditCompany] = useState<Company | null>(
    null,
  );
  const [currentDeleteCompany, setCurrentDeleteCompany] = useState('');
  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const [permissionItems, setPermissionItems] = useState<any>([]);
  const [permissions, setPermissions] = useState([]);
  const [handleShow, setHandleShow] = useState(false);
  const [handleEdit, setHandleEdit] = useState(false);
  const [itemAddPermission, setItemAddPermission] = useState<string>('');
  const [groups, setGroups] = useState<any>([]);
  const [currentCompany, setCurrentCompany] = useState<any>([]);
  const [isShowModalDisableMandatoryMFA, setIsShowModalDisableMandatoryMFA] =
    useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [planExpiryDate, setPlanExpiryDate] = useState('');

  const navigate = useNavigate();

  const { tenants, isLoading, isFetching, isFetched } = useGetTenants({ paginate: false });
  const { plans } = useGetPlans();
  const { company } = useGetCompany(currentEditCompany?.id || '');
  const { linkSubsidiary } = useLinkSubsidiary(currentEditCompany?.id || '');
  const {
    limit,
    setLimit,
    currentPage,
    setCurrentPage,
    selectedTenant,
    setSelectedTenant,
    selectedPlan,
    setSelectedPlan,
    selectedPlanStatus,
    setSelectedPlanStatus,
    selectedTenantType,
    setSelectedTenantType,
  } = useDashboardCompanySearch();

  const submit = async (data) => {
    try {
      const permissions = permissionItems.map((item: any) => {
        item.permission_id = item.id;
        return item;
      });

      const tenant_role_id = groups[0].id;
      const tenant_id = currentCompany.id;

      const parseData = {
        permissions,
      };
      const retorno = await api.patch(
        `tenants/${tenant_id}/roles/${tenant_role_id}/permissions`,
        parseData,
      );
      if (retorno.status === 201 || retorno.status === 200) {
        toast.success(
          handleEdit
            ? 'Perfil da Empresa editado com sucesso.'
            : 'Perfil da Empresa editado com sucesso.',
        );

        reset();
        setIsModalOpenPermission(false);
        setSelectedAction('Ações');
      }
    } catch (error) {
      AppError(error, `Problemas ao salvar perfil da empresa!`);
    }
  };

  const selectEditCompany = (company: Company) => {
    const companyCurrent = {
      ...company,
      is_on_market_statistic: (company as any).tenant_configurations[0]
        .is_on_market_statistic,
      enable_fast_upload: (company as any).tenant_configurations[0]
        .enable_fast_upload,
      user: {
        id: (company as any).users[0]?.id,
        email: (company as any).users[0]?.email,
        name: (company as any).users[0]?.name,
        password: '',
      },
    };

    setCurrentEditCompany(companyCurrent);
  };

  const actionDeleteCompany = async () => {
    try {
      await deleteCompany(currentDeleteCompany);
      setIsShowModalConfirm(false);
    } catch (error) {
      AppError(error, `Problemas ao remover companhia`);
    }
  };

  const ActionAccountCompany = async (
    data: any,
    typeAction: 'update' | 'create',
  ) => {
    try {
      setLastCompanyCreatedOrEditIsHolding(false);

      if (typeAction === 'create') {
        await createCompany(data);
        toast.success('Empresa criada com sucesso!');
      } else {
        await updateCompany(currentEditCompany?.id as string, data);
        toast.success('Empresa atualizada com sucesso!');
      }

      const currentTenantType = currentEditCompany?.tenant_configurations?.[0]?.tenant_type;
      if (data?.tenant_type === 'holding' && currentTenantType === 'holding'
        && data?.tenant_type !== currentTenantType) {
        setLastCompanyCreatedOrEditIsHolding(true);
        setCurrentEditCompany({ ...data, id: currentEditCompany?.id } as Company);
      }

      getCompanys(
        currentPage,
        limit,
        selectedTenant,
        selectedPlan,
        planExpiryDate,
        selectedPlanStatus,
        selectedTenantType
      );
      setIsShowModalAccountCompany(false);
    } catch (error) {
      AppError(
        error,
        `Problemas ao ${typeAction === 'create' ? 'criar' : 'atualizar'
        } companhia. Verifique os dados fornecidos!`,
      );
    } finally {
      companies;
    }
  };

  const ActionHoldingCompany = async (
    data: { subsidiaries_ids: string[] | undefined }
  ) => {
    try {
      await linkSubsidiary({
        subsidiaries_ids: data.subsidiaries_ids || [],
      });

      toast.success('Filial adicionada à holding com sucesso!');

      getCompanys(
        currentPage,
        limit,
        selectedTenant,
        selectedPlan,
        planExpiryDate,
        selectedPlanStatus,
        selectedTenantType
      );
      setIsShowModalAccountCompany(false);
    } catch (error) {
      AppError(error, 'Problemas ao adicionar filial à holding. Verifique os dados fornecidos');
    } finally {
      companies;
    }
  };

  function findPermissionName(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].name;
  }

  function findPermissionTitle(id: string) {
    const filterPermission: any = permissions.filter(
      (permission: any) => permission.id === id,
    );
    return filterPermission[0].title;
  }

  function handleCheckboxVizualizar(
    id: any,
    type: any,
    numItem: any,
    event: any,
  ) {
    permissionItems.map((item: any) => {
      if (String(item.id) === String(id)) {
        if (type === 'show') {
          item.show = event.target.checked;
        }
        if (type === 'create') {
          item.create = event.target.checked;
        }
        if (type === 'update') {
          item.update = event.target.checked;
        }
        if (type === 'delete') {
          item.delete = event.target.checked;
        }
        if (type === 'disable') {
          item.disable = event.target.checked;
        }
      }
      return item;
    });
  }

  function findChecked(id: string, type: string) {
    if (permissionItems) {
      const permission: any = permissionItems.filter((e: any) => e.id === id);

      if (permission.length) {
        if (type === 'show') {
          return permission[0].show;
        }
        if (type === 'create') {
          return permission[0].create;
        }
        if (type === 'delete') {
          return permission[0].delete;
        }
        if (type === 'update') {
          return permission[0].update;
        }
        if (type === 'disable') {
          return permission[0].disable;
        }
      }
    }
  }

  function handleDeletePermission(id: string) {
    const permissionItemsFilter = permissionItems.filter(
      (item: any) => item.id !== id,
    );
    setPermissionItems(permissionItemsFilter);
  }

  async function getPermissions() {
    const { data } = await api.get('/permissions');
    setPermissions(data);
  }

  async function getPermissionsCompany(id: string) {
    const { data } = await api.get(`/tenants/${id}/roles?only_admin=true`);
    setPermissionItems([]);
    if (data[0].permissions.length > 0) {
      data[0].permissions.map((item: any) => {
        const itemAddPermission = item.id;
        const newPermission = {
          id: itemAddPermission,
          show: item.show,
          create: item.create,
          update: item.update,
          delete: item.delete,
          disable: item.disable,
          value: null,
        };
        setPermissionItems((prev: any) => [...prev, newPermission]);
      });
    } else {
      setPermissionItems([]);
    }
  }

  function handleAddPermission() {
    if (itemAddPermission) {
      const isExists = permissionItems.filter(
        (item: any) => item.id === itemAddPermission,
      );
      if (!isExists.length) {
        const newPermission = {
          id: itemAddPermission,
          show: false,
          create: false,
          update: false,
          delete: false,
          disable: false,
          value: null,
        };
        setPermissionItems((prev: any) => [...prev, newPermission]);
      }
    }
  }

  async function getGroups(companyId: string) {
    const { data } = await api.get(
      `tenants/${companyId}/roles?only_admin=true`,
    );
    setGroups(data);
  }

  async function handleDisableMandatoryMfa() {
    try {
      const response = await api.post(
        `/tenants/${currentCompany?.id}/mfa/disable`,
      );

      if (response) {
        toast.success('MFA desativado com sucesso!');
      }

      getCompanys(
        currentPage,
        limit,
        selectedTenant,
        selectedPlan,
        planExpiryDate,
        selectedPlanStatus,
        selectedTenantType
      );
    } catch (err) {
      toast.error('Erro ao desativar MFA da empresa!');
    }
  }

  const handleChangeAction = useCallback((type: string, company: Company) => {
    selectEditCompany(company);
    setCurrentCompany(company);

    switch (type) {
      case 'edit_permissions':
        setIsModalOpenPermission(true);
        setHandleEdit(false);
        setHandleShow(false);
        reset();
        setCurrentCompany(company);
        getGroups(company.id);
        getPermissionsCompany(company.id);
        break;
      case 'users':
        navigate(`/company-users/${company.id}`);
        break;
      case 'plan':
        navigate(`/company-plans/${company.id}`);
        break;
      case 'disable_mfa':
        setCurrentCompany(company);
        setIsShowModalDisableMandatoryMFA(true);
        break;
      case 'add_subsidiaries':
        setIsShowModalHoldingManage(true);
        break;
      case 'edit':
        setIsShowModalAccountCompany(true);
        break;
      case 'delete':
        setCurrentDeleteCompany(company.id);
        setIsShowModalConfirm(true);
        break;
      default:
        break;
    }
  }, []);

  const calculateRemainingDays = useCallback((planExpiryDate: string) => {
    const expiryDate = new Date(planExpiryDate);
    const currentDate = new Date();

    const diffTime = Math.abs(expiryDate.getTime() - currentDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }, []);

  const tenantsOptions = useMemo(() => {
    return tenants?.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
    }));
  }, [tenants]);

  const plansOptions = useMemo(() => {
    return plans?.map((plan) => ({
      label: plan.name,
      value: plan.id,
    }));
  }, [plans]);

  useEffect(() => {
    getCompanys(
      currentPage,
      limit,
      selectedTenant,
      selectedPlan,
      planExpiryDate,
      selectedPlanStatus,
      selectedTenantType,
    );
  }, [currentPage]);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    if (lastCompanyCreatedOrEditIsHolding) {
      setIsShowModalConfirmOpenHoldingManage(true);
    }
  }, [lastCompanyCreatedOrEditIsHolding]);

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="company"
          callback={actionDeleteCompany}
          closeModal={() => {
            setIsShowModalConfirm(false);
            setSelectedAction('Ações');
          }}
        />
      )}
      {isShowModalAccountCompany && (
        <ModalAccountCompany
          callback={ActionAccountCompany}
          closeModal={() => {
            setIsShowModalAccountCompany(false);
            setSelectedAction('Ações');
          }}
          company={currentEditCompany}
          companyId={currentCompany?.id}
        />
      )}
      {isShowModalConfirmOpenHoldingManage && (
        <ConfirmOpenHoldingManageModal
          callback={() => {
            setIsShowModalHoldingManage(true);
            setIsShowModalConfirmOpenHoldingManage(false);
          }}
          closeModal={() => {
            setIsShowModalConfirmOpenHoldingManage(false);
            setSelectedAction('Ações');
          }}
        />
      )}
      {isShowModalHoldingManage && (
        <HoldingManageModal
          callback={ActionHoldingCompany}
          closeModal={() => {
            setIsShowModalHoldingManage(false);
            setSelectedAction('Ações');
          }}
          company={company}
          getCompanies={() => {
            getCompanys(
              currentPage,
              limit,
              selectedTenant,
              selectedPlan,
              planExpiryDate,
              selectedPlanStatus,
              selectedTenantType
            )
          }}
        />
      )}

      <Content>
        <h2 className="text-2xl font-bold">Empresa</h2>
        <div className="divider"></div>

        <ControlContainer>
          <div className='flex flex-col justify-start gap-2'>
            <div className='flex items-center w-full gap-2'>
              <Select
                value={tenantsOptions?.find(option => option.value === selectedTenant)}
                onChange={(option) => {
                  if (!option) {
                    setSelectedTenant('');
                  } else {
                    setSelectedTenant((option as OptionSelect).value);
                  }

                  setCurrentPage(1);

                  getCompanys(
                    1,
                    limit,
                    (option as OptionSelect)?.value || '',
                    selectedPlan,
                    planExpiryDate,
                    selectedPlanStatus,
                    selectedTenantType,
                  )
                }}
                options={tenantsOptions || []}
                isClearable
                isSearchable={true}
                maxMenuHeight={150}
                placeholder="Escolha uma empresa"
              />

              <Select
                value={plansOptions?.find(option => option.value === selectedPlan)}
                onChange={(option) => {
                  if (!option) {
                    setSelectedPlan('');
                  } else {
                    setSelectedPlan((option as OptionSelect).value);
                  }

                  getCompanys(
                    1,
                    limit,
                    selectedTenant,
                    (option as OptionSelect)?.value || '',
                    planExpiryDate,
                    selectedPlanStatus,
                    selectedTenantType,
                  )
                }}
                options={plansOptions || []}
                isClearable
                isSearchable={false}
                maxMenuHeight={150}
                placeholder="Escolha um plano"
              />

              <Select
                value={plansStatusOptions?.find(planStatus => planStatus.value === selectedPlanStatus)}
                onChange={(option) => {
                  if (!option) {
                    setSelectedPlanStatus('');
                  } else {
                    setSelectedPlanStatus((option as OptionSelect).value)
                  }

                  getCompanys(
                    1,
                    limit,
                    selectedTenant,
                    selectedPlan,
                    planExpiryDate,
                    (option as OptionSelect)?.value || '',
                    selectedTenantType,
                  );
                }}
                options={plansStatusOptions}
                isClearable
                isSearchable={false}
                maxMenuHeight={150}
                placeholder="Status do plano"
              />

              <Select
                value={companyTypesOptions?.find(companyType => companyType.value === selectedTenantType)}
                onChange={(option) => {
                  if (!option) {
                    setSelectedTenantType('');
                  } else {
                    setSelectedTenantType((option as OptionSelect).value)
                  }

                  getCompanys(
                    1,
                    limit,
                    selectedTenant,
                    selectedPlan,
                    planExpiryDate,
                    selectedPlanStatus,
                    (option as OptionSelect)?.value || '',
                  );
                }}
                options={companyTypesOptions}
                isClearable
                isSearchable={false}
                maxMenuHeight={150}
                placeholder="Tipo da empresa"
              />
            </div>

            <div className='ml-2 w-full'>
              <div className="flex-auto">
                <strong className="label-text flex items-center gap-1">
                  Data de vencimento do plano
                  <label className="text-error">*</label>
                </strong>

                <DatabaseData>
                  <Input
                    type="date"
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => {
                      setPlanExpiryDate(e.target.value)
                      getCompanys(
                        currentPage,
                        limit,
                        selectedTenant,
                        selectedPlan,
                        e.target.value,
                        selectedPlanStatus
                      );
                    }}
                  />
                </DatabaseData>
              </div>
            </div>
          </div>

          <ButtonPrimary
            onClick={() => {
              setCurrentEditCompany(null);
              setIsShowModalAccountCompany(true);
            }}
            className='flex m-2 mt-3 w-80'
          >
            <IconPlus />
            Adicionar Empresa
          </ButtonPrimary>
        </ControlContainer>

        {(isLoading || isFetching) ? (
          <LoadingSpinner />
        ) : (
          <>
            {(companies?.length > 0 && isFetched) ? (
              <>
                <div className="grid grid-cols-1 gap-4 mt-6">
                  <div className="shadow-xl card bg-base-100">
                    <div className="overflow-x-auto">
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>CNPJ</th>
                            <th>Dias para vencer</th>
                            <th>Tipo da empresa</th>
                            <th>Holding</th>
                            <th>MFA</th>
                            <th colSpan={5} className="text-center">
                              Ações
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {companies?.map((company) => (
                            <tr key={company.id}>
                              <td>{truncateText(company.name)}</td>
                              <td>{company.email}</td>
                              <td>{truncateText(company.cnpj, 14)}</td>
                              <td>{calculateRemainingDays(String(company?.plans?.[0]?.expire_date)) || "Sem plano"}</td>
                              <td>{TENANT_TYPES_NAMES[company?.tenant_configurations?.[0]?.tenant_type || ''] || ''}</td>
                              <td>{truncateText(company?.tenant_father?.name || '', 30)}</td>
                              <td>
                                {company?.tenant_configurations?.[0]?.should_enable_mfa ? (
                                  <FaCircle color="#22D66A" size={15} />
                                ) : (
                                  <FaCircle color="#ff0000" size={15} />
                                )}
                              </td>
                              <td>
                                <select
                                  value={
                                    company.id === currentCompany?.id
                                      ? selectedAction
                                      : ''
                                  }
                                  className="w-[200px] select select-bordered"
                                  onChange={(e) => {
                                    handleChangeAction(e.target.value, company);
                                    setSelectedAction(e.target.value);
                                  }}
                                >
                                  <option>Ações</option>
                                  <option value="edit_permissions" key="edit_permissions">
                                    Editar Permissões
                                  </option>
                                  <option value="users" key="users">
                                    Usuários
                                  </option>
                                  <option value="plan" key="plan">
                                    Plano
                                  </option>
                                  <option value="disable_mfa" key="disable_mfa">
                                    Desativar MFA
                                  </option>
                                  {company?.tenant_configurations?.[0]?.tenant_type === 'holding' && (
                                    <option value="add_subsidiaries" key="add_subsidiaries">
                                      Adicionar filiais
                                    </option>
                                  )}
                                  <option value="edit" key="edit">
                                    Editar
                                  </option>
                                  <option value="delete" key="delete">
                                    Excluir
                                  </option>
                                </select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='mt-4  '>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={pagination.lastPageCompany}
                    totalCount={pagination.totalCountCompany}
                    onNext={(limit: number) => {
                      setCurrentPage(currentPage + 1)
                      setLimit(limit)
                    }}
                    onPrev={(limit: number) => {
                      setCurrentPage(currentPage - 1)
                      setLimit(limit)
                    }}
                    onPage={(page: number, limit: number) => {
                      setCurrentPage(page);
                      setLimit(limit);
                    }}
                    totalLabel="Empresa(s)"
                    css={{ margin: '0 auto', paddingBottom: '$3' }}
                  />
                </div>
              </>
            ) : (
              <NoQuestions>Nenhuma empresa cadastrada</NoQuestions>
            )}
          </>
        )}
      </Content>
      <ModalGeneric
        isModalOpen={isModalOpenPermission}
        setIsModalOpen={(e) => {
          setIsModalOpenPermission(e);
          setSelectedAction('Ações');
        }}
        full={true}
      >
        <Form onSubmit={handleSubmit(submit)}>
          <div className="grid gap-4 min-w-[800px] mb-8 pt-5">
            <div className="flex-auto w-64">
              <div className="flex w-full">
                <label className="label">
                  <strong className="label-text">Permissão</strong>
                </label>

                <select
                  className="w-[350px] select select-bordered"
                  disabled={handleShow}
                  onChange={(e: any) => {
                    setItemAddPermission(e.target.value);
                  }}
                >
                  <option></option>
                  {permissions.map((item: any) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                <div
                  className="btn btn-sm btn-success w-[50px] h-[50px] ml-3"
                  onClick={handleAddPermission}
                >
                  <FiPlus />
                </div>
              </div>
            </div>
          </div>
          {permissionItems.length ? (
            <div className="flex mb-8">
              <table className="table w-full table-compact table-zebra">
                <tbody>
                  <tr className="border-b dark:border-neutral-200">
                    <td>Nome</td>
                    <td>Título</td>
                    <td>Visualizar</td>
                    <td>Adicionar</td>
                    <td>Alterar</td>
                    <td>Excluir</td>
                    <td>Desabilitar</td>
                    <td></td>
                  </tr>
                  {permissionItems.map((item: any, index) => (
                    <tr
                      className="border-b dark:border-neutral-200"
                      key={index}
                    >
                      <td>
                        <strong>{findPermissionName(item.id)}</strong>
                      </td>
                      <td>
                        <strong>{findPermissionTitle(item.id)}</strong>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'show',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'show')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'create',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'create')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'update',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'update')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'delete',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'delete')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-success"
                          onChange={(event) =>
                            handleCheckboxVizualizar(
                              item.id,
                              'disable',
                              index,
                              event,
                            )
                          }
                          defaultChecked={findChecked(item.id, 'disable')}
                          disabled={handleShow}
                        />
                      </td>
                      <td>
                        {!handleShow && (
                          <div
                            onClick={() => handleDeletePermission(item.id)}
                            className="btn btn-sm btn-error w-[50px] h-[50px]"
                          >
                            <FiXCircle />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}

          {permissionItems.length == 0 && (
            <div
              className="alert hadow-lg alert-warning alertSalvar"
              style={{ marginTop: '20px', marginBottom: '30px' }}
            >
              <div>
                <span>
                  Atenção: Adicione pelo menos uma permissão para cadastrar o
                  perfil !
                </span>
              </div>
            </div>
          )}

          {!handleShow && (
            <ContainerButton>
              <Button type="submit">Salvar</Button>
            </ContainerButton>
          )}

          {handleShow && (
            <ContainerButton>
              <Button
                onClick={() => {
                  setIsModalOpenPermission(false);
                  setSelectedAction('Ações');
                }}
              >
                Fechar
              </Button>
            </ContainerButton>
          )}
        </Form>
      </ModalGeneric>
      <ModalGeneric
        isModalOpen={isShowModalDisableMandatoryMFA}
        setIsModalOpen={(e) => {
          setIsShowModalDisableMandatoryMFA(e);
          setSelectedAction('Ações');
        }}
      >
        <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
          {!currentCompany?.tenant_configurations?.[0]?.should_enable_mfa ? (
            <>Esta empresa já está com autenticação MFA desativada!</>
          ) : (
            <>
              Tem certeza que deseja desativar o MFA da empresa{' '}
              {currentCompany?.name}?
              <br />
              <Button
                type="button"
                onClick={(e: any) => {
                  handleDisableMandatoryMfa();
                  setIsShowModalDisableMandatoryMFA(false);
                  setSelectedAction('Ações');
                }}
              >
                Confirmar
              </Button>
              <button
                className="btn btn-ghost"
                onClick={() => {
                  setIsShowModalDisableMandatoryMFA(false);
                  setSelectedAction('Ações');
                }}
              >
                Cancelar
              </button>
            </>
          )}
        </div>
      </ModalGeneric>
    </Container>
  );
};

export default DashboardCompany;
