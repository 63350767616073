import { Stitches } from '../../../src/styles/stitches';

export const Container = Stitches.styled('div', {
  display: 'grid',
  'input, textarea, select': {
    border: '2px solid @cccccc !important',
  },
});

export const Content = Stitches.styled('div', {
  color: '#c53030',
  fontSize: '0.75rem',
});
