/* eslint-disable no-useless-escape */
import { format, parseISO } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import { InputValues, MarketItemDataCustom } from '../../components/ModalMarketSmoothing';

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function truncateText(str: string, maxLength = 30) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}

export function formatDate(date: string): string {
  const formattedDate = format(parseISO(date), 'dd MMM yy', {
    locale: ptBR,
  });
  return formattedDate;
}

export function getMonthYearFromDateISO(date: string) {
  if (!date) return '';

  const parts = date.slice(0, -1).split('T');
  const dateComponent = parts[0];

  const formattedDate = format(parseISO(dateComponent), 'MMMM/yyyy', {
    locale: ptBR,
  });

  return formattedDate;
}

export function formatDateFull(date: string, localeDate: 'pt_br' | 'en' = 'pt_br' ): string {
  if (!date) return '';

  let dateObj: Date;
  if (date.includes('T')) {
    dateObj = new Date(date);
    dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
  } else {
    dateObj = new Date(date + 'T00:00:00');
  }
  
  const formatDateParse = localeDate === 'pt_br' ? 'dd/MM/yyyy' : 'yyyy-MM-dd';
  const localeDateParse = localeDate === 'pt_br' ? ptBR : enUS;

  const formattedDate = format(dateObj, formatDateParse, {
    locale: localeDateParse,
  });
  console.log("OPA formattedDate", formattedDate)
  return formattedDate;
}


export function formatDateFullWithHour(date: string): string {
  if (!date) return '';

  const formattedDate = format(parseISO(date), 'dd/MM/yyyy HH:mm:ss', {
    locale: ptBR,
  });
  return formattedDate;
}

export function formatDateHour(date: string): string {
  if (!date) return '';

  const formattedDate = format(parseISO(date), 'dd/MM/yyyy ', {
    locale: ptBR,
  });
  return formattedDate;
}

export function formatBrMoney(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatBrMoneyAndNull(value: number): string {
  if (value !== null) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(value);
  } else {
    return '';
  }
}

export function defaultValueSlope(
  marketData: MarketItemDataCustom[],
  index: number,
  total: number,
  pAtual: number | null,
  percentil: string,
  valueNew: InputValues[],
) {
  const percentils = ['p10', 'p25', 'p50', 'p75', 'p90'];

  if(!percentils.includes(percentil)) {
    return '0';
  }

  const getResult = (value1: number, value2: number) => {
    return value2 >= 1 ? value1 / value2 : 0;
  };

  let result: number;
  if (total === index + 1) {
    result = 0;
  } else {
      const itemAlterado = valueNew.filter(
        (x) => x.id === marketData[index].id,
      );
      const itemAlteradoFuturo = valueNew.filter(
        (x) => x.id === marketData[index + 1].id,
      );
      if (
        marketData[index + 1][`${percentil}_interpolated`] === 0 &&
        itemAlteradoFuturo.length === 0
      ) {
        result = 0;
      } else {
        const nextNumber = Number(marketData[index + 1][`${percentil}_interpolated`]);
        const actualNumber = Number(pAtual);
        const inputNumber = Number(itemAlterado[0]?.value);
        const inputUpdated = Number(itemAlteradoFuturo[0]?.value);

        if (itemAlterado.length > 0 && itemAlteradoFuturo.length === 0) {
          result = getResult(
            inputNumber,
            nextNumber,
          );
        } else if (itemAlterado.length > 0 && itemAlteradoFuturo.length > 0) {
          result = getResult(
            inputNumber,
            inputUpdated,
          );
        } else if (itemAlterado.length === 0 && itemAlteradoFuturo.length > 0) {
          result = getResult(actualNumber, inputUpdated);
        } else {
          result = getResult(actualNumber, nextNumber);
        }
      }
  }

  return result === 0 ? '0' : result.toFixed(2);
}

export function formatBrDecimal(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(value);
}

export function formatBrDecimalCasa(value: number): string {
  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

export function formatMoney({
  value,
  sign = 'R$',
  n = 2,
  x = 3,
  s = '.',
  c = ',',
}: {
  value: number;
  sign?: string;
  n?: number;
  x?: number;
  s?: string;
  c?: string;
}) {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = value.toFixed(Math.max(0, ~~n));

  const result = (c ? num.replace('.', c) : num).replace(
    new RegExp(re, 'g'),
    '$&' + (s || ','),
  );

  return `${sign} ${result}`;
}

type SetGridParams = {
  point: number;
  colSpan: number;
  inMinScreen?: boolean;
};

export function setGrid({
  point,
  colSpan,
  inMinScreen = false,
}: SetGridParams) {
  return {
    [`@media screen and (${inMinScreen ? 'min' : 'max'}-width: ${point}px)`]: {
      gridColumn: `span ${colSpan}`,
    },
  };
}

type DownloadFileFromServerParams = {
  contentType: string;
  filename: string;
  data: any;
};

// export const generateRandString = () => crypto.randomBytes(20).toString('hex');
function makeid(length: any) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const generateRandString = () => makeid(30);

export function downloadFileFromServer({
  contentType,
  data,
  filename,
}: DownloadFileFromServerParams) {
  const blob = new Blob([data], { type: contentType });
  const fileURL = URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = fileURL;
  link.setAttribute('download', removeDoubleQuotes(filename));

  document.body.appendChild(link);

  link.click();
  link.parentNode?.removeChild(link);
}

export function numberRound(n: number): number {
  return Math.round((n + Number.EPSILON) * 100) / 100;
}

export function removeDoubleQuotes(s: string) {
  return s.replace(/^"(.*)"$/, '$1');
}

export function removeDuplicate(v: string[]) {
  const uniqueArray = v.filter(function(item, pos) {
    return v.indexOf(item) == pos;
  });
  return uniqueArray;
}

export function uniqueValues(arr: any[], props: string[]) {
  return arr.filter(
    (v, i, a) =>
      a.findIndex((v2) => [...props].every((k) => v2[k] === v[k])) === i,
  );
}

export function firstLetterUpper(value: string): string {
  if (value) {
    const word = value.substring(1);

    return `${value[0].toUpperCase()}${word}`;
  } else {
    return '';
  }
}

export function fixedDecimal(value: number, dec = 2) {
  return Number(value.toFixed(dec));
}

export function unmaskValue(value: string, parse = true) {
  const unmasked = value
    .replace(/\./g, '')
    .replace(/,/g, '.')
    .replace(/\s/g, '')
    .replace(/%/g, '')
    .replace(/R\$/g, '')
    .trim();
  return parse ? Number(unmasked) : unmasked;
}

export function sortObjectsByNumberField<T>(
  objArray: T[],
  keyNumber: string,
  desc = true,
): T[] {
  let sorted: T[] = [];

  if (desc) {
    sorted = objArray.sort((a: any, b: any) => {
      return b[keyNumber] - a[keyNumber];
    });
  } else {
    sorted = objArray.sort((a: any, b: any) => {
      return a[keyNumber] - b[keyNumber];
    });
  }

  return sorted;
}

export function formatClass(
  value: any,
  valueNew: any,
  dadosAlterados: boolean,
): string {
  const dados = valueNew.filter((x) => x.id === value);
  if (dadosAlterados === true) {
    return dados.length == 0
      ? 'input input-bordered input-info w-full max-w-xs'
      : 'input input-bordered input-error w-full max-w-xs';
  } else {
    return 'input input-bordered input-info w-full max-w-xs';
  }
}

export function isObjEmpty (obj: any) {
  return Object.keys(obj).length === 0;
}