import { useState, useEffect, useCallback, useRef } from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Button } from '../Button';
import { AppError } from '../../error/AppError';
import { Modal } from 'react-responsive-modal';
import {
  Match,
  MatchAbleOption,
  MatchGradeError,
  OptionSelect,
  SingleMatchGrade,
  SmartpayGrade,
  TenantGrade,
} from '../../interfaces';
import { api } from '../../services/api';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  Select,
  SelectContainer,
} from './styles';
import { ButtonPrimary } from '../../pages/DashboardUsers/styles';

interface Props {
  closeModal: () => void;
  updateMatrizGradeMatch: (
    matches: SingleMatchGrade[],
    tenant_id: string,
  ) => void;
  tenantId: string;
}

const ModalGradeMatch = ({
  closeModal,
  updateMatrizGradeMatch,
  tenantId,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [smartPayGradesOptions, setSmartPayGradesOptions] = useState<
    OptionSelect[]
  >([]);
  const [selectAbleSmartpayGrade, setSelectAbleSmartpayGrade] =
    useState<MatchAbleOption>({});
  const [tenantGradesOptions, setTenantGradesOptions] = useState<
    OptionSelect[]
  >([]);
  const [matchAble, setMatchAble] = useState<Match | null>(null);
  const [invertAutoSelect, setInvertAutoSelect] = useState(false);
  const [matchGradeError, setMatchGradeError] =
    useState<MatchGradeError | null>({});

  const submit = () => {
    const matchSubmit: SingleMatchGrade[] = [];

    if (matchAble) {
      Object.keys(matchAble).map((key) => {
        matchSubmit.push(matchAble[key]);
      });
    }

    // if (!matchAble) return toast.warn('O match está vazio por favor preencha!');

    // if (tenantGradesOptions.length !== matchAble.length)
    //   return toast.warn('O match não está completo!');

    try {
      matchSubmit.map((m) => {
        if (!m.smartpay_grade_id)
          throw new Error('Deve ser selecionado todas as correspondências!');
      });
    } catch (error) {
      if (error instanceof Error) return toast.warn(error.message);
      return;
    }

    updateMatrizGradeMatch(matchSubmit, tenantId);
  };

  const loadSmartPayGrades = useCallback(async () => {
    try {
      const { data } = await api.get<SmartpayGrade[]>('/smartpay/grades');
      const gradeOptions = data.map((grade) => ({
        label: String(grade.code),
        value: grade.id,
      }));

      setSmartPayGradesOptions(gradeOptions);
    } catch (error) {
      AppError(error, `Problemas ao carregar smartpay grades!`);
    }
  }, []);

  const loadTenantGrades = useCallback(async () => {
    try {
      const { data } = await api.get<TenantGrade[]>(
        `/tenants/grades?tenant_id=${tenantId}`,
      );

      const matchAbles: SingleMatchGrade[] = data.map((tgo) => ({
        smartpay_grade_id: null,
        tenant_grade_id: tgo.id,
      }));

      const gradeOptions = data.map((grade) => ({
        label: grade.title || String(grade.code),
        value: grade.id,
        isDisabled: true,
      }));

      const matchSelect = matchAbles.reduce(
        (a, v, n) => ({ ...a, [v.tenant_grade_id]: v }),
        {},
      );

      const matchGradeError = {};

      gradeOptions.map((g) => {
        matchGradeError[g.value] = {
          grade: g.label,
          message: null,
        };
      });

      setMatchGradeError(matchGradeError);

      setMatchAble(matchSelect);
      setTenantGradesOptions(gradeOptions);

      const smrtCompGradeSelectObj = {};

      data.map((grade) => {
        smrtCompGradeSelectObj[grade.id] = {
          label: null,
          value: null,
        };
      });

      setSelectAbleSmartpayGrade({ ...smrtCompGradeSelectObj });
    } catch (error) {
      AppError(error, `Problemas ao carregar empresa grades!`);
    }
  }, [tenantId]);

  const setSmartpayGradeMatch = (
    tenantGradeOption: OptionSelect,
    smartpayGradeOption: OptionSelect,
    optionIndex: number,
  ) => {
    const matchAblesParsed: SingleMatchGrade[] = [];
    if (matchAble) {
      Object.keys(matchAble).map((key) => {
        matchAblesParsed.push(matchAble[key]);
      });
    }

    if (smartpayGradeOption) {
      const hasChangedAlready = matchAblesParsed.some(
        (m) => !!m.smartpay_grade_id,
      );

      if (optionIndex === 0 && !hasChangedAlready) {
        const firstSmartpayCode = Number(smartpayGradeOption.label);

        const filterOptions = smartPayGradesOptions.filter((o) => {
          if (invertAutoSelect) {
            return Number(o.label) >= firstSmartpayCode;
          }
          return Number(o.label) <= firstSmartpayCode;
        });

        const filterOptionsSorted = invertAutoSelect
          ? [...filterOptions].reverse()
          : filterOptions;

        const selectAbleSmartpayGradeCopy = selectAbleSmartpayGrade;

        Object.keys(selectAbleSmartpayGradeCopy).map((key, index) => {
          const hasFilterOptions = filterOptionsSorted[index];

          if (hasFilterOptions) {
            selectAbleSmartpayGradeCopy[key] = hasFilterOptions;
          }
        });

        setSelectAbleSmartpayGrade({ ...selectAbleSmartpayGradeCopy });

        const newMatchAble: SingleMatchGrade[] = matchAblesParsed.map(
          (match, i) => {
            return {
              tenant_grade_id: match.tenant_grade_id,
              smartpay_grade_id: filterOptionsSorted[i]
                ? filterOptionsSorted[i].value
                : null,
            };
          },
        );

        const newMatchAbleObj = newMatchAble.reduce(
          (a, v, n) => ({ ...a, [v.tenant_grade_id]: v }),
          {},
        );

        setMatchAble({ ...newMatchAbleObj });
        return;
      }

      const matchAlreadyExists = matchAblesParsed.find(
        (match) => match.smartpay_grade_id === smartpayGradeOption.value,
      );

      const matchGradeErrorCopy = matchGradeError;

      if (matchAlreadyExists) {
        if (
          matchGradeErrorCopy &&
          matchGradeErrorCopy[tenantGradeOption.value]
        ) {
          matchGradeErrorCopy[tenantGradeOption.value] = {
            ...matchGradeErrorCopy[tenantGradeOption.value],
            message: `matching para a grade do sistema: ${smartpayGradeOption.label} já foi selecionado!`,
          };

          setMatchGradeError({ ...matchGradeErrorCopy });
        }
      } else {
        if (
          matchGradeErrorCopy &&
          matchGradeErrorCopy[tenantGradeOption.value]
        ) {
          matchGradeErrorCopy[tenantGradeOption.value] = {
            ...matchGradeErrorCopy[tenantGradeOption.value],
            message: null,
          };

          setMatchGradeError({ ...matchGradeErrorCopy });
        }
      }

      const newMatchAble: SingleMatchGrade[] = matchAblesParsed.map((match) => {
        if (match.tenant_grade_id === tenantGradeOption.value) {
          return {
            tenant_grade_id: match.tenant_grade_id,
            smartpay_grade_id: smartpayGradeOption.value,
          };
        }

        return match;
      });

      const changeSelectAbleSmartpayGradeCopy = selectAbleSmartpayGrade;

      if (changeSelectAbleSmartpayGradeCopy) {
        Object.keys(changeSelectAbleSmartpayGradeCopy).map((key, i) => {
          if (optionIndex === i) {
            changeSelectAbleSmartpayGradeCopy[key] = {
              label: smartpayGradeOption.label,
              value: smartpayGradeOption.value,
            };
          }
        });
      }

      const newMatchAbleObj = newMatchAble.reduce(
        (a, v, n) => ({ ...a, [v.tenant_grade_id]: v }),
        {},
      );

      setSelectAbleSmartpayGrade({ ...changeSelectAbleSmartpayGradeCopy });
      setMatchAble({ ...newMatchAbleObj });

      return;
    }

    if (!smartpayGradeOption) {
      const clearMatchAble: SingleMatchGrade[] = matchAblesParsed.map(
        (match) => {
          if (match.tenant_grade_id === tenantGradeOption.value) {
            return {
              tenant_grade_id: match.tenant_grade_id,
              smartpay_grade_id: null,
            };
          }

          return match;
        },
      );

      const clearSelectAbleSmartpayGradeCopy = selectAbleSmartpayGrade;

      Object.keys(clearSelectAbleSmartpayGradeCopy).map((key) => {
        if (key === tenantGradeOption.value) {
          clearSelectAbleSmartpayGradeCopy[key] = {
            label: null,
            value: null,
          } as any;
        }
      });

      const newMatchAbleObj = clearMatchAble.reduce(
        (a, v, n) => ({ ...a, [v.tenant_grade_id]: v }),
        {},
      );

      const matchGradeErrorCopy = matchGradeError;

      if (matchGradeErrorCopy) {
        matchGradeErrorCopy[tenantGradeOption.value] = {
          ...matchGradeErrorCopy[tenantGradeOption.value],
          message: null,
        };
      }

      setSelectAbleSmartpayGrade({ ...clearSelectAbleSmartpayGradeCopy });
      setMatchAble({ ...newMatchAbleObj });
      setMatchGradeError({ ...matchGradeErrorCopy });
      return;
    }
  };

  const handleInvertAutoSelect = () => {
    setInvertAutoSelect(!invertAutoSelect);
  };

  const handleClearSelects = () => {
    const matchCopyClear = matchAble;

    if (matchCopyClear) {
      Object.keys(matchCopyClear).map((key) => {
        matchCopyClear[key] = {
          ...matchCopyClear[key],
          smartpay_grade_id: null,
        };
      });

      const clearSelectAbleSmartpayGradeCopy = selectAbleSmartpayGrade;

      Object.keys(clearSelectAbleSmartpayGradeCopy).map((key) => {
        clearSelectAbleSmartpayGradeCopy[key] = {
          label: null,
          value: null,
        } as any;
      });

      setSelectAbleSmartpayGrade({ ...clearSelectAbleSmartpayGradeCopy });
      setMatchAble({ ...matchCopyClear });
    }

    if (matchGradeError) {
      const matchGradeErrorCopy = matchGradeError;

      Object.keys(matchGradeErrorCopy).map((key) => {
        matchGradeErrorCopy[key] = {
          ...matchGradeErrorCopy[key],
          message: null,
        };
      });

      setMatchGradeError({ ...matchGradeErrorCopy });
    }
  };

  useEffect(() => {
    loadSmartPayGrades();
    loadTenantGrades();
    setIsLoading(false);
  }, [loadSmartPayGrades, loadTenantGrades]);

  return (
    <>
      {isLoading ? (
        <ReactLoading type="spin" color="#F56A00" height={67} width={75} />
      ) : (
        // <BackgroundContainerModal>
        <Modal
          open={true}
          onClose={closeModal}
          classNames={{
            modal: 'customModal w-[700px]',
            overlayAnimationIn: 'customEnterOverlayAnimation',
            overlayAnimationOut: 'customLeaveOverlayAnimation',
            modalAnimationOut: 'customLeaveModalAnimation',
          }}
          center
          animationDuration={800}
        >
          <div>
            <HeaderModal>
              <p>Alterar / Criar Match</p>
              {/* <FiX onClick={closeModal} /> */}
            </HeaderModal>

            {tenantGradesOptions.length >= 1 && (
              <ContentModal>
                <Form>
                  <SelectContainer className="flex justify-between mb-7">
                    <div className="flex items-center">
                      <span style={{ marginRight: '5px' }}>
                        Inverter ordem auto preenchimento:
                      </span>
                      <input
                        type="checkbox"
                        onChange={handleInvertAutoSelect}
                      />
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClearSelects}>
                        Desfazer
                      </ButtonPrimary>
                    </div>
                  </SelectContainer>

                  <SelectContainer>
                    <div>Grade Empresa</div>
                    <div>Grade SMRTComp</div>
                  </SelectContainer>
                  {tenantGradesOptions.map((tenantGradeOption, i) => {
                    return (
                      <SelectContainer
                        key={`select-container-${tenantGradeOption.value}`}
                        style={{ marginBottom: '2px' }}
                      >
                        <div style={{ width: '100%' }}>
                          <Select
                            value={tenantGradeOption}
                            isSearchable={false}
                            options={[tenantGradeOption]}
                            maxMenuHeight={150}
                            styles={{
                              container: (provided) => ({
                                ...provided,
                                marginBottom: 0,
                              }),
                            }}
                            placeholder="grade empresa"
                          />
                          {matchGradeError &&
                            matchGradeError[tenantGradeOption.value] &&
                            !!matchGradeError[tenantGradeOption.value]
                              .message && (
                              <span
                                style={{
                                  fontSize: '13px',
                                  color: 'var(--primary)',
                                }}
                              >
                                {
                                  matchGradeError[tenantGradeOption.value]
                                    .message
                                }
                              </span>
                            )}
                        </div>

                        <Select
                          onChange={(option) =>
                            setSmartpayGradeMatch(
                              tenantGradeOption,
                              option as any,
                              i,
                            )
                          }
                          value={
                            tenantGradeOption.value
                              ? selectAbleSmartpayGrade[tenantGradeOption.value]
                              : {
                                  label: null,
                                  value: null,
                                }
                          }
                          options={smartPayGradesOptions as any}
                          isSearchable={true}
                          maxMenuHeight={150}
                          placeholder="Grade SMRTComp"
                          isClearable={true}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              marginBottom: 0,
                            }),
                          }}
                        />
                      </SelectContainer>
                    );
                  })}

                  <ContainerButton>
                    <Button onClick={submit} type="button">
                      Alterar
                    </Button>
                  </ContainerButton>
                </Form>
              </ContentModal>
            )}
          </div>
          {/* </BackgroundContainerModal> */}
        </Modal>
      )}
    </>
  );
};

export { ModalGradeMatch };
