import { memo, useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { MarketPercentils, MarketTypes } from '../../../shared/types';
import { formatClass } from '../../../shared/utils';
import { OptionSelect } from '../../Select';
import { MarketItemDataCustom } from '..';

interface InputEditableProps {
  item: MarketItemDataCustom;
  type: MarketPercentils;
  selectedLine: string;
  currentValues: any;
  dadosAlterados: any;
  onChange: (item: MarketItemDataCustom, e: any, type: string) => void;
  isGradeBetweenMinAndMax: boolean;
  minGradeRange?: OptionSelect;
  maxGradeRange?: OptionSelect;
  marketType: MarketTypes;
}

function InputEditableComponent({
  item,
  marketType,
  selectedLine,
  type,
  currentValues,
  dadosAlterados,
  onChange,
  minGradeRange,
  maxGradeRange,
}: InputEditableProps) {

  const handleFocus = (event: any) => event.target.select();

  const getInputValue = (
    item: any,
    type: 'p10' | 'p25' | 'p50' | 'p75' | 'p90',
    marketType: string
  ) => {
    if (item[`${type}_final`] !== null) {
      return item[`${type}_final`];
    }
    // if (item[`${type}_interpolated_personalized`] !== null) {
    //   return item[`${type}_interpolated_personalized`];
    // }

    // if (item[`${type}_interpolated`] !== null) {
    //   return item[`${type}_interpolated`];
    // }

    // if (item[type] !== null) {
    //   return item[type];
    // }

    return '';
  };

  const isGradeBetweenMinAndMax = (grade: string) => {

    if (minGradeRange?.value && maxGradeRange?.value) {
      const gradeNumber = Number(grade);
      if (gradeNumber >= Number(minGradeRange.value) && gradeNumber <= Number(maxGradeRange.value)) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    if(item.role === 'Presidente') {
      console.log('Presidente', item);
    }
  }, [])

  return (
    <CurrencyInput
      className={`${formatClass(item.id, currentValues, dadosAlterados)} ${item[`was_${type}_interpolated_personalized`] ? 'text-primary-600 border-primary-600' : ''}`}
      name={`input-name_${type}`}
      disabled={marketType === 'grade' && minGradeRange && maxGradeRange ? !isGradeBetweenMinAndMax(item.grade) : false}
      defaultValue={getInputValue(item, type, marketType)}
      decimalsLimit={2}
      decimalScale={2}
      onFocus={handleFocus}
      decimalSeparator=","
      groupSeparator="."
      prefix={['icp', 'ilp'].includes(selectedLine) ? '' : 'R$ '}
      onChange={(e) => {
        onChange(item, e?.target?.value, type)
      }}
    />
  );
}

export const InputEditable = memo(InputEditableComponent, (prev, next) => {
  return prev === next;
});
