import { useAuth } from '../../contexts/AuthContext';
import { Container, Title, Logout } from './styles';

const Header = () => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Title>Admin SMRTComp</Title>
      <Logout onClick={signOut} />
    </Container>
  );
};

export { Header };
