import { Stitches } from '../../../src/styles/stitches';
import { FiPlus } from 'react-icons/fi';

export const Container = Stitches.styled('div', {
  height: '100%',
  display: 'flex',
});

export const Content = Stitches.styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexGrow: '1',
});

export const MainContent = Stitches.styled('div', {
  padding: '1rem',
  width: '100%',
});

export const MainTitle = Stitches.styled('div', {
  padding: '1rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  borderBottom: '1px solid $gray200',
});

export const ButtonPrimary = Stitches.styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 1.5rem',
  maxWidth: '250px',
  height: '45px',
  backgroundColor: '$primary',
  color: '$primaryClean',
  borderRadius: '10px',
  transition: 'background-color 0.3s',
  textTransform: 'uppercase',

  '&:hover': {
    background: '$primaryDark',
  },

  '& + button': {
    marginLeft: '0.5rem',
  },
});

export const IconPlus = Stitches.styled(FiPlus, {
  fontSize: '18px',
  marginRight: '1rem',
});

export const SectionListPositions = Stitches.styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1rem',
  maxHeight: '620px',
  overflowY: 'auto',
});

export const HeaderListPositions = Stitches.styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '2rem',
  paddingBottom: '0.5rem',
  borderBottom: '1px solid $primaryClean',

  '& p': {
    textAlign: 'center',
    maxWidth: '220px',
    width: '100%',
  },

  '& span': {
    textAlign: 'center',
    maxWidth: '80px',
    width: '100%',
  },

  '& div': {
    marginLeft: 'auto',
    width: '60px',
  },
});

export const ItemCompany = Stitches.styled('div', {
  display: 'flex',
  width: '100%',
  borderBottom: '1px solid $primaryClean',
  padding: '0.5rem',

  '& + &': {
    marginTop: '1rem',
  },

  '& p': {
    textAlign: 'center',
    maxWidth: '220px',
    width: '100%',
  },

  '& span': {
    textAlign: 'center',
    maxWidth: '80px',
    width: '100%',
  },

  '& div': {
    marginLeft: 'auto',
    width: '60px',
    display: 'flex',
    justifyContent: 'space-between',

    '& svg': {
      fontSize: '20px',
      cursor: 'pointer',
    },
  },
});

export const NoQuestions = Stitches.styled('div', {
  fontSize: '1.5rem',
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
});

export const ControlContainer = Stitches.styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
});
