import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalGeneric } from '../../components/ModalGeneric';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { api } from '../../services/api';
import { Container } from './styles';
import { ButtonPrimary } from '../DashboardUsers/styles';
import { IconPlus } from '../DashboardCompany/styles';
import { useForm } from 'react-hook-form';
import { ModalConfirm } from '../../components/ModalConfirm';

interface SubmitForm {
  name: string;
  description: string;
}
export default function SectorGroup() {
  const [sectorGroupList, setSectorGroupList] = useState<any>([]);
  const { handleSetIsLoading } = useGlobalLoading();
  const [sectorGroupData, setSectorGroupData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [groupName, setGroupName] = useState('');
  const [groupSecription, setGroupDescription] = useState('');
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [currentDeleteGroup, setCurrentDeleteGroup] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();

  const actionGroup = async (data: SubmitForm) => {
    try {
      if (data && data.name) {
        const parseData = {
          name: data.name,
          description: data.description,
          type: 'macro',
        };
        if (isCreate) {
          handleSetIsLoading(true);

          await api.post('/sectors/groups', parseData);
          toast.success('Grupo criado com sucesso.');
          resetField('name');
          resetField('description');
          setSectorGroupData([]);
          reset();
          listGroups();
          setShowModal(false);
          handleSetIsLoading(false);
        } else {
          const id = sectorGroupData.id;
          const retorno = await api.put(`/sectors/groups/${id}`, parseData);
          toast.success('Grupo editado com sucesso.');
          listGroups();
          resetField('name');
          resetField('description');
          setSectorGroupData([]);
          setShowModal(false);
          handleSetIsLoading(false);
        }
      }
    } catch (err: any) {
      console.log(err.response);
      toast.error(err.response.data.message);
    }
  };

  async function listGroups() {
    try {
      const { data: dataSector } = await api.get('/sectors/groups');

      setSectorGroupList(dataSector);
    } catch (err) {
      toast.error('Erro ao buscar dados!');
    }

    handleSetIsLoading(false);
  }

  const actionDeleteGroup = async () => {
    try {
      const retorno = await api.delete(`/sectors/groups/${currentDeleteGroup}`);
      toast.success('Grupo removido com sucesso.');
      listGroups();
      setIsShowModalConfirm(false);
    } catch (error) {
      toast.error(`Problemas ao remover grupo`);
    }
  };

  const handleChangeAction = useCallback(
    (type: string, group) => {
      switch (type) {
        case 'edit':
          setIsCreate(false);
          reset();
          setSectorGroupData(group);
          setShowModal(true);
          break;
        case 'delete':
          setCurrentDeleteGroup(group.id);
          setSectorGroupData(group);
          setIsShowModalConfirm(true);
          break;
        default:
          break;
      }
    },
    [
      setIsCreate,
      reset,
      setSectorGroupData,
      setShowModal,
      setCurrentDeleteGroup,
      setIsShowModalConfirm,
    ],
  );

  useEffect(() => {
    listGroups();
  }, []);

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="group"
          callback={actionDeleteGroup}
          closeModal={() => {
            setIsShowModalConfirm(false);
            setSelectedAction('Ações');
          }}
        />
      )}
      <h2 className="text-2xl font-bold">Grupos de setores</h2>
      <div className="divider"></div>

      <ButtonPrimary
        onClick={() => {
          reset();
          setSectorGroupData('');
          resetField('name');
          resetField('description');
          setIsCreate(true);
          setShowModal(true);
        }}
        className="mb-5"
      >
        <IconPlus />
        Adicionar Grupo
      </ButtonPrimary>

      <table className="table w-full  mb-10 mt-6">
        <thead>
          <tr>
            <th>Macro Setor</th>
            <th>Descrição</th>
            <th colSpan={2}>Ações</th>
          </tr>
        </thead>

        <tbody>
          {sectorGroupList.map((group, index) => (
            <tr key={index}>
              <td>{group.name}</td>
              <td>{group.description}</td>
              <td>
                <select
                  value={
                    group?.id === sectorGroupData?.id ? selectedAction : ''
                  }
                  className="w-[150px] select select-bordered"
                  onChange={(e) => {
                    handleChangeAction(e.target.value, group);
                    setSelectedAction(e.target.value);
                  }}
                >
                  <option>Ações</option>
                  <option value="edit" key="edit">
                    Editar
                  </option>
                  <option value="delete" key="delete">
                    Excluir
                  </option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ModalGeneric
        isModalOpen={showModal}
        setIsModalOpen={(e) => {
          setShowModal(e);
          setSelectedAction('Ações');
        }}
      >
        <Container>
          <div className="grid grid-cols-1 gap-4" style={{ width: '400px' }}>
            <form onSubmit={handleSubmit(actionGroup)}>
              <strong>Editar setor</strong>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text font-bold">Nome do grupo</span>
                </label>
                <input
                  type="text"
                  {...register('name', { required: true })}
                  placeholder="Nome do grupo"
                  defaultValue={sectorGroupData ? sectorGroupData.name : ''}
                  className="input input-bordered w-full"
                />
                <span style={{ color: 'red' }}>
                  {!!errors.name ? 'Campo Obrigatório' : ''}
                </span>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text font-bold">Descrição</span>
                </label>
                <textarea
                  {...register('description', { required: false })}
                  className="textarea textarea-bordered"
                  placeholder="Descrição"
                >
                  {sectorGroupData ? sectorGroupData.description : ''}
                </textarea>
                {/* <span style={{ color: 'red' }}>
                  {!!errors.description ? 'Campo Obrigatório' : ''}
                </span> */}
              </div>
              <div className="flex align-middle justify-center w-full mt-4">
                {isCreate ? (
                  <button className="btn-success-custom" type="submit">
                    Cadastrar
                  </button>
                ) : (
                  <button className="btn btn-success-custom" type="submit">
                    Editar
                  </button>
                )}
              </div>
            </form>
          </div>
        </Container>
      </ModalGeneric>
    </Container>
  );
}
