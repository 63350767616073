import { Stitches } from '../../../src/styles/stitches';

export const Container = Stitches.styled('div', {
  position: 'fixed',
  height: '90vh',
  width: '290px',
  top: '100px',
  boxShadow: 'var(--text-clear) 0px 2px 15px 0px',
  overflowY: 'auto',
  backgroundColor: 'var(--text-body-clean)',
  paddingBottom: '70px',
});

export const Content = Stitches.styled('div', {
  padding: '1.1rem 0',
});

export const Item = Stitches.styled('button', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  padding: '0.9rem 0 1rem 1rem',
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '50px 0 0 50px',

  '& svg': {
    fontSize: '22px',
    color: '$primary',
  },

  variants: {
    color: {
      active: {
        background: '$primary',
      },
      inactive: {
        background: 'transparent',
      },
    },
  },
});
export const FirstItem = Stitches.styled('button', {
  display: 'flex',
  width: '100%',
});

export const Logo = Stitches.styled('img', {
  height: '4rem',
  margin: '3rem 0',
});

export const Title = Stitches.styled('p', {
  marginLeft: '1rem',
  fontSize: '1rem',
  fontWeight: '500',
});

export const Line = Stitches.styled('div', {
  width: '5px',
  height: '50px',
  borderRadius: '50px',
  marginRight: '10px',

  variants: {
    color: {
      active: {
        background: '$primary',
      },
      inactive: {
        background: 'transparent',
      },
    },
  },
});
