import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FiX } from 'react-icons/fi';

import { Button } from '../../components/Button';
import { AppError } from '../../error/AppError';
import {
  CreateOrUpdateSmartPayPosition,
  OptionSelect,
  SmartpayGrade,
} from '../../interfaces';
import { api } from '../../services/api';
import { Input } from '../Input';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  Select,
  FormContent,
  Error,
  SectionSelect,
} from './styles';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface Props {
  closeModal: () => void;
  callback: (
    data: CreateOrUpdateSmartPayPosition,
    typeAction: 'update' | 'create',
  ) => void;
  positionSmartPay: CreateOrUpdateSmartPayPosition | null;
}

interface SubmitForm {
  description: string;
  title: string;
  code: string;
  grade: {
    value: string;
  };
  subfamily: {
    value: string;
  };
  contribution: {
    value: string;
  };
}

const ModalEditSmartPayPosition = ({
  closeModal,
  callback,
  positionSmartPay,
}: Props) => {
  const [optionsSubfamilies, setOptionsSubfamilies] = useState<OptionSelect[]>(
    [],
  );
  const [optionsContributionLevels, setOptionsContributionLevels] = useState<
    OptionSelect[]
  >([]);
  const [smartpayGrades, setSmartpayGrades] = useState<OptionSelect[]>([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitForm>();

  const submit = (data: SubmitForm) => {
    const { description, title, code, subfamily, contribution, grade } = data;
    const parseData = {
      code,
      description,
      title,
      sub_family_id: subfamily?.value,
      contribution_level_id: contribution?.value,
      grade_id: grade?.value,
    };

    callback(parseData, !!positionSmartPay ? 'update' : 'create');
    closeModal();
  };

  const loadContributionLevels = async () => {
    try {
      const { data } = await api.get('/smartpay/contributions/levels');
      const contributionLevels = data.map((c) => ({
        label: c.title,
        value: c.id,
      }));

      setOptionsContributionLevels(contributionLevels);
    } catch (error) {
      AppError(error, `Problemas ao carregar Níveis de contribuição!`);
    }
  };

  const loadSubFamilies = async () => {
    try {
      const { data } = await api.get('/smartpay/positions/subfamilies');
      const subfamilies = data.map((s) => ({
        label: s.title,
        value: s.id,
      }));

      setOptionsSubfamilies(subfamilies);
    } catch (error) {
      AppError(error, `Problemas ao carregar sub famílias!`);
    }
  };

  const loadSmartpayGrades = async () => {
    try {
      const { data } = await api.get<SmartpayGrade[]>('/smartpay/grades');
      const grades = data.map((grades) => ({
        label: String(grades.code),
        value: grades.id,
      }));

      setSmartpayGrades(grades);
    } catch (error) {
      AppError(error, `Problemas ao carregar sub famílias!`);
    }
  };

  useEffect(() => {
    loadSubFamilies();
    loadSmartpayGrades();
    loadContributionLevels();
  }, []);

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal className="w-[900px]">
          <p>Cargos SMRTComp</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>
        <ContentModal>
          <Form onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Título </strong>
                  </label>
                  <input
                    type="text"
                    className="w-full max-w-xs input input-bordered"
                    placeholder="Título"
                    {...register('title', {
                      required: true,
                      value: positionSmartPay?.title,
                    })}
                    // error={!!errors.description}
                    // helperText={!!errors.description ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.title ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Descrição </strong>
                  </label>
                  <input
                    type="text"
                    className="w-full max-w-xs input input-bordered"
                    placeholder="Descrição"
                    {...register('description', {
                      value: positionSmartPay?.description || '',
                    })}
                    // error={!!errors.description}
                    // helperText={!!errors.description ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.description ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
              <div className="flex-auto w-64">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Código </strong>
                  </label>
                  <input
                    type="text"
                    className="w-full max-w-xs input input-bordered"
                    placeholder="Código"
                    {...register('code', {
                      value: positionSmartPay?.code,
                    })}
                  />
                </div>
                <Content>{!!errors.code ? 'Campo Obrigatório' : ''}</Content>
              </div>
              <div>
                {optionsSubfamilies.length > 0 && (
                  <SectionSelect>
                    <Controller
                      name="subfamily"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={
                        optionsSubfamilies.find(
                          (option) =>
                            option.value === positionSmartPay?.sub_family_id,
                        ) as any
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          // defaultValue={optionsSubfamilies.filter(
                          //   (option) =>
                          //     option.value === positionSmartPay?.sub_family_id,
                          // )}
                          options={optionsSubfamilies}
                          isSearchable={false}
                          maxMenuHeight={150}
                          placeholder="Escolha uma sub familia"
                        />
                      )}
                    />
                    {!!errors.subfamily && <Error>Campo Obrigatório</Error>}
                  </SectionSelect>
                )}
                {optionsContributionLevels.length > 0 && (
                  <SectionSelect>
                    <Controller
                      name="contribution"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={
                        optionsContributionLevels.find(
                          (option) =>
                            option.value ===
                            positionSmartPay?.contribution_level_id,
                        ) as any
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={optionsContributionLevels}
                          isSearchable={false}
                          maxMenuHeight={150}
                          placeholder="Escolha uma nível de contribuição"
                        />
                      )}
                    />
                    {!!errors.contribution && <Error>Campo Obrigatório</Error>}
                  </SectionSelect>
                )}
                {smartpayGrades.length > 0 && (
                  <SectionSelect>
                    <Controller
                      name="grade"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={
                        smartpayGrades.find(
                          (option) =>
                            option.value === positionSmartPay?.grade_id,
                        ) as any
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={smartpayGrades}
                          isSearchable={false}
                          maxMenuHeight={150}
                          placeholder="Escolha uma grade"
                        />
                      )}
                    />
                    {!!errors.grade && <Error>Campo Obrigatório</Error>}
                  </SectionSelect>
                )}
              </div>
            </FormContent>
            <ContainerButton>
              <Button type="submit">
                {!!positionSmartPay ? 'Editar' : 'Enviar'}
              </Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalEditSmartPayPosition };
