import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Form = styled.form`
  margin-top: 6.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }

  > div:last-child {
    display: flex;
    align-items: center;

    a {
      color: var(--text-body);
      font-size: 0.875rem;
    }
  }
`;

export const ContainerButton = styled.div`
  margin-top: 2rem;
`;

export const Logo = styled.img`
  height: 5rem;
`;
