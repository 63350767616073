import ReactHTMLParser from 'html-react-parser';
import ReactTooltip from 'react-tooltip';

export function Tooltip({
  displayText,
  fullText,
  tooltipKey,
  className,
  children,
  disabled,
  displayChildren,
  css,
}: any) {
  return (
    <>
      <p data-tip data-for={tooltipKey} className={className} style={css}>
        {!displayChildren &&
          displayText &&
          ReactHTMLParser(String(displayText) || '')}
        {displayChildren && displayChildren}
      </p>
      <ReactTooltip
        disable={disabled}
        className="text-hide-tooltip"
        id={tooltipKey}
        place="top"
        type="info"
        effect="float"
        textColor="#f3f3f3"
        backgroundColor="#44546A"
        aria-haspopup="true"
      >
        {!children && fullText ? (
          <div>{ReactHTMLParser(String(fullText) || '')}</div>
        ) : (
          <>{children}</>
        )}
      </ReactTooltip>
    </>
  );
}
