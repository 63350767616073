import { Stitches } from '../../../src/styles/stitches';

export const Container = Stitches.styled('div', {
  // height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

export const DashboardTemplateHeader = Stitches.styled('header', {
  position: 'fixed',
  width: '100%',
  height: 100,
  backgroundColor: '#f9f9f9',
  borderBottom: '1px solid $gray90',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.9rem 0',
  zIndex: 1,

  '& button#menu-mobile-control': {
    width: '$3',
    height: '$3',
    backgroundColor: '$whiteSecond',
    borderRadius: '$default',
    fontSize: 0,

    '& > svg': {
      width: '$iconLarge',
      height: '$iconLarge',
      color: '$gray300',
    },
  },

  '@media screen and (max-width: 620px)': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 99,
  },
});

export const DashboardTemplateHeaderContainer = Stitches.styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '$4',
  padding: '0 $3',
});

export const DashboardTemplateHeaderRight = Stitches.styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$2',
  strong: {
    fontSize: '$extraSmall',
    fontWeight: '$semiBold',
    color: '$primary',
  },
});

export const DashboardTemplateHeaderLeft = Stitches.styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$10',

  '@media screen and (max-width: 900px)': {
    gap: '$3',
  },
});

export const DashboardTemplateBody = Stitches.styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
});

export const DashboardTemplateContent = Stitches.styled('main', {
  position: 'relative',
  width: '100%',
  left: '290px',
  top: '80px',
  // height: 'calc(100vh - 90px)',

  // overflowY: 'auto',
  padding: '$6 0',
  transition: 'width 0.3s ease-in-out',

  variants: {
    state: {
      full: {
        '@media screen and (min-width: 621px)': {
          width: 'calc(100% - $sideBarFull)',
        },
      },
      collapsed: {
        '@media screen and (min-width: 621px)': {
          width: 'calc(100% - $sideBarCollapsed)',
        },
      },
    },
  },

  defaultVariants: {
    state: 'full',
  },

  '@media screen and (max-width: 620px)': {
    minHeight: '100vh',
    paddingTop: 125,
  },
});

export const BasicContainer = Stitches.styled('div', {
  width: '100%',
  maxWidth: '100%',
  margin: '0 auto',
  padding: '0 $2',

  '@media (min-width: 1500px)': {
    maxWidth: '1600px',
    padding: '0 $4',
  },

  '@media (min-width: 1200px)  and (max-width: 1499px)': {
    maxWidth: '1180px',
  },

  '@media screen and (min-width: 980px) and (max-width: 1199px)': {
    maxWidth: '980px',
  },

  '@media screen and (min-width: 720px) and (max-width: 979px)': {
    maxWidth: '720px',
  },

  '@media screen and (max-width: 719px)': {
    maxWidth: '620px',
  },
});

export const Logout = Stitches.styled('div', {
  fontSize: '20px',
  marginRight: '1rem',
  cursor: 'pointer',
});
