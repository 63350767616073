import { useMutation } from 'react-query';
import { api } from '../../../../services/api';
import { queryClient } from '../../../../App';

type UnlinkSubsidiaryResponse = {
  board_director: string;
  custom_name: string;
  subfamily: string | null;
  subfamily_id: string;
}[];

export function useUnlinkSubsidiary(tenantFatherId: string) {
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    async (subsidiary_id: string) => {
      const { data } =
        await api.delete<UnlinkSubsidiaryResponse>(
          `/tenants/${subsidiary_id}/subsidiary`
        );

      if (data) {
        return data;
      }

      return null;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`get-company-${tenantFatherId}`);
      }
    }
  );

  return {
    unlinkSubsidiary: mutateAsync,
    isSuccess,
    isLoading,
  };
}
