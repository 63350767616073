import { useCallback, useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { ModalConfirm } from '../../components/ModalConfirm';
import { ModalAccountUser } from '../../components/ModalAccountUser';

import {
  Container,
  Content,
  ButtonPrimary,
  IconUserPlus,
  SectionFilter,
  SectionListUsers,
  ItemUser,
  MainTitle,
  MainContent,
  InputSearch,
  ArrowLeft,
  ArrowRight,
  Pagination,
  HeaderListUsers,
} from './styles';
import { CreateOrUpdateUser, User } from '../../interfaces';
import { useAdmin } from '../../contexts/AdminContext';
import { AppError } from '../../error/AppError';

const DashboardUsers = () => {
  const { users, getUsers, pagination, updateUser, deleteUser, createUser } =
    useAdmin();

  const [currentPage, setCurrentPage] = useState(1);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isShowModalAccountUser, setIsShowModalAccountUser] = useState(false);
  const [currentEditUser, setCurrentEditUser] =
    useState<CreateOrUpdateUser | null>(null);
  const [textFilter, setTextFilter] = useState('');
  const [isSearchWithFilter, setIsSearchWithFilter] = useState(false);
  const [currentDeleteUser, setCurrentDeleteUser] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const selectEditUser = (id: string) => {
    const [user] = users.filter((user) => user.id === id);

    if (!user) return;

    setCurrentEditUser(user);
    setIsShowModalAccountUser(true);
  };

  const filterUsers = async () => {
    try {
      if (!textFilter) return toast.error('Texto de busca está vazio!');
      setIsSearchWithFilter(true);
      await getUsers(textFilter);
    } catch (error) {
      AppError(error, 'Problemas ao relizar filtragem dos dados!');
    }
  };

  const ActionAccountUser = async (
    data: any,
    typeAction: 'update' | 'create',
  ) => {
    try {
      if (typeAction === 'create') {
        await createUser(data);
        toast.success('Usuário criado com sucesso!');
      } else {
        await updateUser(currentEditUser?.id as string, data);
        toast.success('Usuário atualizado com sucesso!');
      }
      setIsShowModalAccountUser(false);
    } catch (error) {
      AppError(
        error,
        `Problemas ao ${
          typeAction === 'create' ? 'criar' : 'atualizar'
        } usuário. Verifique os dados fornecidos!`,
      );
    }
  };

  const actionDeleteUser = async () => {
    try {
      await deleteUser(currentDeleteUser);
      setIsShowModalConfirm(false);
    } catch (error) {
      AppError(error, `Problemas ao remover usuário`);
    }
  };

  const handleChangeAction = useCallback(
    (type: string, user: User) => {
      switch (type) {
        case 'edit':
          selectEditUser(user.id);
          break;
        case 'delete':
          setCurrentDeleteUser(user.id);
          setIsShowModalConfirm(true);
          break;
        default:
          break;
      }
    },
    [selectEditUser, setCurrentDeleteUser, setIsShowModalConfirm],
  );

  useEffect(() => {
    if (isSearchWithFilter) {
      getUsers(textFilter, currentPage);
    } else {
      getUsers('', currentPage);
    }
  }, [currentPage, isSearchWithFilter]);

  return (
    <Container>
      {isShowModalConfirm && (
        <ModalConfirm
          type="user"
          callback={actionDeleteUser}
          closeModal={() => {
            setIsShowModalConfirm(false);
            setSelectedAction('Ações');
          }}
        />
      )}

      {isShowModalAccountUser && (
        <ModalAccountUser
          callback={ActionAccountUser}
          closeModal={() => {
            setIsShowModalAccountUser(false);
            setSelectedAction('Ações');
          }}
          user={currentEditUser}
        />
      )}
      <Content>
        <h2 className="text-2xl font-bold">Usuários ADM</h2>
        <div className="divider"></div>

        <MainContent>
          <ButtonPrimary
            onClick={() => {
              setCurrentEditUser(null);
              setIsShowModalAccountUser(true);
            }}
          >
            <IconUserPlus />
            Adicionar Usuário
          </ButtonPrimary>
          <SectionFilter>
            <InputSearch
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              placeholder="Busque pelo nome"
            />
            <ButtonPrimary onClick={filterUsers}>buscar</ButtonPrimary>
            <ButtonPrimary
              onClick={() => {
                setTextFilter('');
                setIsSearchWithFilter(false);
                setCurrentPage(1);
              }}
            >
              Limpar filtros
            </ButtonPrimary>
          </SectionFilter>

          <table className="table w-full mt-6">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th colSpan={2}>Ações</th>
              </tr>
            </thead>

            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <select
                      value={
                        user?.id === currentEditUser?.id ? selectedAction : ''
                      }
                      className="w-[150px] select select-bordered"
                      onChange={(e) => {
                        handleChangeAction(e.target.value, user);
                        setSelectedAction(e.target.value);
                      }}
                    >
                      <option>Ações</option>
                      <option value="edit" key="edit">
                        Editar
                      </option>
                      <option value="delete" key="delete">
                        Excluir
                      </option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination>
            <ArrowLeft
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FiArrowLeft />
            </ArrowLeft>
            <span>{currentPage}</span>
            <ArrowRight
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={
                pagination.lastPageUsers === 0 ||
                currentPage === pagination.lastPageUsers
              }
            >
              <FiArrowRight />
            </ArrowRight>
          </Pagination>
        </MainContent>
      </Content>
    </Container>
  );
};

export default DashboardUsers;
