import { useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { Button } from '../Button';
import { PositionFamily } from '../../interfaces';
import { Content } from '../../pages/MarketConfiguration/styles';
import {
  BackgroundContainerModal,
  HeaderModal,
  ContentModal,
  ContainerButton,
  Form,
  FormContent,
} from './styles';
import { useForm } from 'react-hook-form';
import { Input } from '../Input';
import { Modal } from 'react-responsive-modal';
import './styles.css';

interface SubmitForm {
  id?: string;
  title: string;
  description: string;
}

interface Props {
  closeModal: () => void;
  callback: (data: SubmitForm) => void;
  data: PositionFamily | null;
}

const ModalFamily = ({ data, closeModal, callback }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitForm>();

  const title = !!data?.id ? 'Alterar' : 'Criar';

  const submit = (data: SubmitForm) => {
    callback(data);
  };

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Modal
      open={true}
      onClose={closeModal}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      center
      animationDuration={800}
    >
      {/* <BackgroundContainerModal> */}
      <div>
        <HeaderModal>
          <p>{title}</p>
          {/* <FiX onClick={closeModal} /> */}
        </HeaderModal>

        <ContentModal>
          <Form autoComplete="off" onSubmit={handleSubmit(submit)}>
            <FormContent>
              <div className="flex-auto w-full mt-4">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Título </strong>
                  </label>

                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('title', {
                      required: true,
                      value: data?.title,
                    })}
                    // error={!!errors.title}
                    // helperText={!!errors.title ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>{!!errors.title ? 'Campo Obrigatório' : ''}</Content>
              </div>

              <div className="flex-auto w-full mt-0">
                <div className="w-full max-w-xs form-control">
                  <label className="label">
                    <strong className="label-text">Descrição </strong>
                  </label>

                  <input
                    type="text"
                    className="input input-bordered w-full max-w-xs"
                    {...register('description', {
                      required: false,
                      value: data?.description || '',
                    })}
                    // error={!!errors.description}
                    // helperText={!!errors.description ? 'Campo Obrigatório' : ''}
                  />
                </div>
                <Content>
                  {!!errors.description ? 'Campo Obrigatório' : ''}
                </Content>
              </div>
            </FormContent>

            <ContainerButton>
              <Button type="submit">{title}</Button>
            </ContainerButton>
          </Form>
        </ContentModal>
      </div>
      {/* </BackgroundContainerModal> */}
    </Modal>
  );
};

export { ModalFamily };
