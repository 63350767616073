import { InputValues, MarketItemDataCustom, SelectedMarketPosition } from '..';
import { MarketPercentils, MarketTypes } from '../../../shared/types';
import { OptionSelect } from '../../Select';
import { SingleCheckbox } from '../../checkboxs';
import { InputEditable } from '../InputEditable';

interface MarketTablePositionProps {
  marketType: MarketTypes;
  marketData: MarketItemDataCustom[];
  isGradeBetweenMinAndMax: (grade: string) => boolean;
  handleCheckedPosition: (
    checked: boolean,
    market: MarketItemDataCustom,
  ) => void;
  selectedMarketPositions: SelectedMarketPosition;
  getInputTooltipValue: (
    item: any,
    type: 'p10' | 'p25' | 'p50' | 'p75' | 'p90',
  ) => string;
  handleValue: (market: MarketItemDataCustom, value: any, type: string) => void;
  dadosAlterados: boolean;
  selectedIntermedOptionLine: OptionSelect;
  minGradeRange?: OptionSelect;
  maxGradeRange?: OptionSelect;
  defaultValueSlope: (
    marketData: any,
    index: number,
    total: number,
    pAtual: any,
    linha: string,
    valueNew: any,
  ) => string;
  valueNew10: InputValues[];
  valueNew25: InputValues[];
  valueNew50: InputValues[];
  valueNew75: InputValues[];
  valueNew90: InputValues[];
}

export function MarketTableGradePublish({
  marketType,
  marketData = [],
  isGradeBetweenMinAndMax,
  handleCheckedPosition,
  selectedMarketPositions,
  getInputTooltipValue,
  handleValue,
  dadosAlterados,
  selectedIntermedOptionLine,
  minGradeRange,
  maxGradeRange,
  defaultValueSlope,
  valueNew10,
  valueNew25,
  valueNew50,
  valueNew75,
  valueNew90,
}: MarketTablePositionProps) {
  return (
    <table className="table w-full table-compact">
      <thead>
        <tr>
          {(marketType === 'position' || marketType === 'position-grade') && (
            <th></th>
          )}
          <th className="borderLeft">Grade</th>
          <th className="borderLeft">P10 Suavizado</th>
          <th className="borderRight">Slope</th>

          <th className="borderLeft">P25 Suavizado</th>
          <th className="borderRight">Slope</th>

          <th className="borderLeft">P50 Suavizado</th>
          <th className="borderRight">Slope</th>

          <th className="borderLeft">P75 Suavizado</th>
          <th className="borderRight">Slope</th>

          <th className="borderLeft">P90 Suavizado</th>
          <th className="borderRight">Slope</th>
        </tr>
      </thead>
      <tbody>
        {marketData.length
          ? marketData.map((i: MarketItemDataCustom, index) => (
              <tr
                key={`${index}`}
                className={`${isGradeBetweenMinAndMax(i.grade) && 'text'}`}
              >
                <td
                  className={`borderLeft ${
                    isGradeBetweenMinAndMax(i.grade) && 'font-bold'
                  }`}
                >
                  {i.grade}
                </td>
                <td
                  className="borderLeft tooltip"
                  data-tip={getInputTooltipValue(i, 'p10')}
                >
                  <InputEditable
                    key={`${i.id}_p10`}
                    item={i}
                    isGradeBetweenMinAndMax={isGradeBetweenMinAndMax(i.grade)}
                    currentValues={valueNew10}
                    dadosAlterados={dadosAlterados}
                    type="p10"
                    marketType={marketType}
                    selectedLine={selectedIntermedOptionLine.value}
                    minGradeRange={minGradeRange}
                    maxGradeRange={maxGradeRange}
                    onChange={(item, value, type) =>
                      handleValue(item, value, type)
                    }
                  />
                </td>
                <td
                  className={`${
                    isGradeBetweenMinAndMax(i.grade) ? 'font-bold' : ''
                  }`}
                >
                  {defaultValueSlope(
                    marketData,
                    index,
                    marketData.length,
                    i.p10_interpolated,
                    'p10',
                    valueNew10,
                  )}
                </td>
                <td
                  className="borderLeft tooltip"
                  data-tip={getInputTooltipValue(i, 'p25')}
                >
                  <InputEditable
                    key={`${i.id}_p25`}
                    item={i}
                    isGradeBetweenMinAndMax={isGradeBetweenMinAndMax(i.grade)}
                    currentValues={valueNew25}
                    dadosAlterados={dadosAlterados}
                    type="p25"
                    selectedLine={selectedIntermedOptionLine.value}
                    onChange={(item, value, type) =>
                      handleValue(item, value, type)
                    }
                    marketType={marketType}
                    minGradeRange={minGradeRange}
                    maxGradeRange={maxGradeRange}
                  />
                </td>
                <td
                  className={`${
                    isGradeBetweenMinAndMax(i.grade) ? 'font-bold' : ''
                  }`}
                >
                  {defaultValueSlope(
                    marketData,
                    index,
                    marketData.length,
                    i.p25_interpolated,
                    'p25',
                    valueNew25,
                  )}
                </td>
                <td
                  className="borderLeft tooltip"
                  data-tip={getInputTooltipValue(i, 'p50')}
                >
                  <InputEditable
                    key={`${i.id}_p50`}
                    item={i}
                    isGradeBetweenMinAndMax={isGradeBetweenMinAndMax(i.grade)}
                    currentValues={valueNew50}
                    dadosAlterados={dadosAlterados}
                    type="p50"
                    selectedLine={selectedIntermedOptionLine.value}
                    onChange={(item, value, type) =>
                      handleValue(item, value, type)
                    }
                    marketType={marketType}
                    minGradeRange={minGradeRange}
                    maxGradeRange={maxGradeRange}
                  />
                </td>
                <td
                  className={`${
                    isGradeBetweenMinAndMax(i.grade) ? 'font-bold' : ''
                  }`}
                >
                  {defaultValueSlope(
                    marketData,
                    index,
                    marketData.length,
                    i.p50_interpolated,
                    'p50',
                    valueNew50,
                  )}
                </td>
                <td
                  className="borderLeft tooltip"
                  data-tip={getInputTooltipValue(i, 'p75')}
                >
                  <InputEditable
                    key={`${i.id}_p75`}
                    item={i}
                    isGradeBetweenMinAndMax={isGradeBetweenMinAndMax(i.grade)}
                    currentValues={valueNew75}
                    dadosAlterados={dadosAlterados}
                    type="p75"
                    selectedLine={selectedIntermedOptionLine.value}
                    onChange={(item, value, type) =>
                      handleValue(item, value, type)
                    }
                    marketType={marketType}
                    minGradeRange={minGradeRange}
                    maxGradeRange={maxGradeRange}
                  />
                </td>
                <td
                  className={`${
                    isGradeBetweenMinAndMax(i.grade) ? 'font-bold' : ''
                  }`}
                >
                  {defaultValueSlope(
                    marketData,
                    index,
                    marketData.length,
                    i.p75_interpolated,
                    'p75',
                    valueNew75,
                  )}
                </td>
                <td
                  className="borderLeft tooltip"
                  data-tip={getInputTooltipValue(i, 'p90')}
                >
                  <InputEditable
                    key={`${i.id}_p90`}
                    item={i}
                    isGradeBetweenMinAndMax={isGradeBetweenMinAndMax(i.grade)}
                    currentValues={valueNew90}
                    dadosAlterados={dadosAlterados}
                    type="p90"
                    selectedLine={selectedIntermedOptionLine.value}
                    onChange={(item, value, type) =>
                      handleValue(item, value, type)
                    }
                    marketType={marketType}
                    minGradeRange={minGradeRange}
                    maxGradeRange={maxGradeRange}
                  />
                </td>
                <td
                  className={`${
                    isGradeBetweenMinAndMax(i.grade) ? 'font-bold' : ''
                  }`}
                >
                  {defaultValueSlope(
                    marketData,
                    index,
                    marketData.length,
                    i.p90_interpolated,
                    'p90',
                    valueNew90,
                  )}
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );
}
