import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
}

export const ContainerInput = styled.div`
  padding: 5px;
`;

export const Container = styled.div<ContainerProps>`
  padding: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-primary);
  padding: 0rem 1rem;
  color: var(--text-body);
  height: 45px;
  ${(props) =>
    props.isErrored &&
    css`
      border-color: var(--error);
    `}

  input {
    color: var(--text-title);
    flex: 1;
    background: transparent;
    &::placeholder {
      color: var(--text-body);
    }
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const Error = styled.p`
  height: 20px;
  font-size: 0.75rem;
  color: var(--error);
`;
