// import Modal from 'react-modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import './styles.css';

type ModalGenericProps = {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  full?: boolean;
  showClose?: boolean;
  children?: React.ReactNode;
  ariaHideApp?: boolean;
  styles?: React.CSSProperties | undefined;
}

const ModalGeneric = ({
  children,
  isModalOpen,
  setIsModalOpen,
  full,
  showClose,
  styles,
}: ModalGenericProps) => {
  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={closeModal}
      closeOnEsc={showClose}
      closeOnOverlayClick={showClose}
      showCloseIcon={showClose}
      classNames={{
        modal: 'customModal',
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
      styles={{
        modal: styles,
      }}
      center
      animationDuration={200}
    >
      {children}
    </Modal>
  );
};

export { ModalGeneric };
