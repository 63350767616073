const getToken = () => localStorage.getItem('@smartpay/token');

const setToken = (token: string) =>
  localStorage.setItem('@smartpay/token', token);

const deleteToken = () => localStorage.removeItem('@smartpay/token');

const getRefreshToken = () => localStorage.getItem('@smartpay/refresh_token');

const setRefreshToken = (refresh_token: string) =>
  localStorage.setItem('@smartpay/refresh_token', refresh_token);

const deleteRefreshToken = () =>
  localStorage.removeItem('@smartpay/refresh_token');

export {
  getToken,
  setToken,
  deleteToken,
  getRefreshToken,
  setRefreshToken,
  deleteRefreshToken,
};
