import { toast } from 'react-toastify';
import { ModalGeneric } from '../../../components/ModalGeneric';
import { MarketStatistic } from '../../../interfaces';
import { Button } from '../../../components/Button';

interface ConfirmProcessMarketModalProps {
  isModalOpenReprocess: boolean;
  setIsModalOpenReprocess: (v: boolean) => void;
  marketProcess: MarketStatistic | null;
  handleProccessConfirmed: (v: string) => void;
}

export function ConfirmProcessMarketModal({
  isModalOpenReprocess,
  setIsModalOpenReprocess,
  marketProcess,
  handleProccessConfirmed,
}: ConfirmProcessMarketModalProps) {
  return (
    <ModalGeneric
      isModalOpen={isModalOpenReprocess}
      setIsModalOpen={setIsModalOpenReprocess}
    >
      <div className="grid grid-cols-1 gap-4" style={{ width: '450px' }}>
        Tem certeza que deseja{' '}
        {marketProcess?.status === 'not_processed'
          ? 'processar'
          : 'reprocessar'}
        <br />
        <Button
          type="button"
          onClick={(e: any) => {
            if (!marketProcess?.id) {
              toast.error('Market not found to process!');
              return;
            }
            setIsModalOpenReprocess(false);
            handleProccessConfirmed(marketProcess.id);
          }}
        >
          Confirmar
        </Button>
        <button
          className="btn btn-ghost"
          onClick={() => setIsModalOpenReprocess(false)}
        >
          Cancelar
        </button>
      </div>
    </ModalGeneric>
  );
}
