import { useEffect, useState } from 'react';
import {
  ArrowLeft,
  ArrowRight,
  Container,
  Header,
  NoQuestions,
  Pagination,
  Select,
} from './styles';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { formatBrMoney, formatBrDecimal } from '../../shared/utils';
import { api } from '../../services/api';
import { useGlobalLoading } from '../../contexts/GlobalLoadingContext';
import { OptionSelect } from '../../interfaces';
import { AppError } from '../../error/AppError';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SiMicrosoftexcel } from 'react-icons/si';
import { toast } from 'react-toastify';
import { downloadFileFromServer } from '../../utils/removeMask';
import { ButtonPrimary, ButtonDownload } from '../DashboardUsers/styles';
import ReactLoading from 'react-loading';
import './styles.css';

type MarketProps = {
  name: string | null;
  type: string | null;
  visibility: string | null;
  retroactive_months: number | null;
  sales_commission_eligibility: string | null;
  position_level_names: any;
  contribution_level_names: any;
  monthly_workload_start: number | null;
  monthly_workload_end: number | null;
  family_names: any;
  excluded_roles: any;
  eligible_for_icp: string | null;
  eligible_for_ilp: string | null;
  state: string | null;
  gender: string | null;
  ethnicity: string | null;
  hiring_regimes: any;
  reference_month: string | null;
  reference_year: number | null;
  calculation_method: string | null;
  id: string;
};

type MarketListProps = {
  id: string | null;
  name: string | null;
  type: string | null;
  label: string | null;
  value: string | null;
};

export default function MarketIntermediate() {
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [markets, setMarkets] = useState<MarketListProps[]>([]);
  const [marketData, setMarketData] = useState<any>([]);
  const [idFilter, setIdFilter] = useState<string>('');
  const { handleSetIsLoading } = useGlobalLoading();
  const [selectedIntermedOption, setSelectedIntermedOption] =
    useState<OptionSelect>();
  const [hasExcel, setHasExcel] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPagePositions, setLastPagePositions] = useState(1);
  const [hasData, setHasData] = useState<boolean>(false);

  const { id } = useParams();

  async function listMarkets() {
    try {
      handleSetIsLoading(true);
      const { data } = await api.get('/markets/statistics/titles');
      const intermediaris = data.map((intermediari) => ({
        label: intermediari.name,
        value: intermediari.id,
      }));
      setMarkets(intermediaris);
      handleSetIsLoading(false);
      if (id) {
        setIdFilter(id);
        setSelectedIntermedOption({ label: id, value: id });
      }
    } catch (error) {
      AppError(error, `Problemas ao carregar a lista!`);
    }
  }

  async function listMarketData(currentPage) {
    try {
      setHasData(false);
      if (selectedIntermedOption) {
        setHasMore(true);
        const limit = 20;
        const { data } = await api.get(
          `/markets/statistics/intermediate/items?market_statistic_id=${selectedIntermedOption.value}&page=${currentPage}&limit=${limit}`,
        );
        const marketsList = data.data;
        if (marketData.length < data.last_page) {
          const marketsFull = marketData.concat(marketsList);
          setMarketData(marketsFull);
          setLastPagePositions(data.last_page);
          setIdFilter(selectedIntermedOption.value);
        } else {
          setHasMore(false);
        }
      }
      setHasData(false);
      handleSetIsLoading(false);
    } catch (error) {
      setHasData(false);
      AppError(error, `Problemas ao carregar mercado!`);
    }
  }

  function validValue(type, value) {
    if (value) {
      if (
        type === 'ILP' ||
        type === 'ilp' ||
        type === 'icp' ||
        type === 'ICP' ||
        type === 'media'
      ) {
        return formatBrDecimal(value);
      } else {
        return formatBrMoney(value);
      }
    } else {
      return '';
    }
  }

  function fetchDataMarket() {
    setCurrentPage((value) => value + 1);
  }

  async function handleDownload(market_statistic_id: string) {
    try {
      setHasExcel(true);
      const dataProcess = await api.get(
        `/markets/statistics/intermediate/items/download?market_statistic_id=${market_statistic_id}`,
        { responseType: 'blob' },
      );
      const fileName = dataProcess.headers['content-disposition'].split('=')[1];
      downloadFileFromServer({
        contentType: dataProcess.headers['content-type'],
        data: dataProcess.data,
        filename: fileName,
      });
      toast.success('Download da mercado intermediário realizado com sucesso!');
      setHasExcel(false);
    } catch (err) {
      toast.error('Erro ao realizar o download mercado intermediário!');
      setHasExcel(false);
    }
  }

  useEffect(() => {
    listMarketData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    listMarkets();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    setMarketData([]);
    setHasMore(false);
    handleSetIsLoading(true);
    listMarketData(currentPage);
  }, [selectedIntermedOption]);

  return (
    <Container>
      <Header>
        <h2 className="text-2xl font-bold">Mercado - QA</h2>
      </Header>
      <>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-start-1 col-end-3 ...">
            <Select
              onChange={(option) =>
                setSelectedIntermedOption(option as OptionSelect)
              }
              options={markets}
              isSearchable={false}
              maxMenuHeight={550}
              value={markets.filter(({ value }) => value === idFilter)}
              placeholder="Escolha um mercado"
            />
          </div>
          <div className="col-end-8 col-span-2 flex justify-end">
            {marketData && marketData.length > 0 ? (
              <ButtonDownload
                onClick={() => handleDownload(idFilter)}
                className="mb-5"
                disabled={!hasExcel ? false : true}
                style={{ marginTop: '5px' }}
              >
                {!hasExcel ? (
                  <>
                    <SiMicrosoftexcel
                      size={'25px'}
                      style={{ marginRight: '10px' }}
                    />
                    Exportar para XLS{' '}
                  </>
                ) : (
                  <>
                    <div style={{ paddingRight: '6px' }}>
                      <ReactLoading
                        type="spin"
                        color={'#F56A00'}
                        height={20}
                        width={20}
                      />
                    </div>
                    Aguarde exportação
                  </>
                )}
              </ButtonDownload>
            ) : null}
          </div>
        </div>
      </>
      <div className="grid grid-cols-1 gap-4 mt-6 h-32">
        <div className="card bg-base-100 shadow-xl p-4">
          <div className="overflow-x-auto">
            <InfiniteScroll
              dataLength={marketData.length}
              next={fetchDataMarket}
              height={600}
              hasMore={hasMore}
              loader={
                !marketData ? (
                  <div className="flex justify-center p-5">Carregando...</div>
                ) : (
                  ''
                )
              }
              endMessage={
                marketData.length > 0 ? (
                  <div
                    className="alert hadow-lg alert-warning alertSalvar"
                    style={{ marginTop: '20px', marginBottom: '30px' }}
                  >
                    <div>
                      <span>
                        Atenção: Não existem mais valores para carregar!!
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )
              }
            >
              {marketData.length > 0 ? (
                <>
                  <div className="div1">
                    {!hasData ? (
                      <table className="table w-full">
                        <thead>
                          <tr>
                            <th style={{ display: 'none' }}></th>
                            <th>
                              <strong>Linha</strong>
                            </th>
                            <th>
                              <strong>Grade</strong>
                            </th>
                            <th>
                              <strong>Cargo</strong>
                            </th>
                            <th>
                              <strong>Empresa</strong>
                            </th>
                            <th>
                              <strong>Nº de ocupantes do mercado</strong>
                            </th>
                            <th>
                              <strong>Nº de outliers</strong>
                            </th>
                            <th>
                              <strong>Menor valor</strong>
                            </th>
                            <th>
                              <strong>Maior valor</strong>
                            </th>
                            <th>
                              <strong>P1</strong>
                            </th>
                            <th>
                              <strong>P2</strong>
                            </th>
                            <th>
                              <strong>P3</strong>
                            </th>
                            <th>
                              <strong>P4</strong>
                            </th>
                            <th>
                              <strong>P5</strong>
                            </th>
                            <th>
                              <strong>Média</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {marketData
                            ? marketData.map((i: any, index: number) => (
                                <tr key={index}>
                                  <td style={{ display: 'none' }}></td>
                                  <td>
                                    <span className="uppercase">
                                      {i.statistic_name}
                                    </span>
                                  </td>
                                  <td>{i.grade}</td>
                                  <td>{i.role}</td>
                                  <td>{i.tenant_name}</td>
                                  <td>{i.employees_calc_count}</td>
                                  <td>{i.outliers_count}</td>
                                  <td>
                                    {i.minimum_value ? i.minimum_value : ''}
                                  </td>
                                  <td>
                                    {i.maximum_value ? i.maximum_value : ''}
                                  </td>
                                  <td>{validValue(i.statistic_name, i.p10)}</td>
                                  <td>{validValue(i.statistic_name, i.p25)}</td>
                                  <td>{validValue(i.statistic_name, i.p50)}</td>
                                  <td>{validValue(i.statistic_name, i.p75)}</td>
                                  <td>{validValue(i.statistic_name, i.p90)}</td>
                                  <td>
                                    {validValue(i.statistic_name, i.average)}
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div style={{ marginLeft: '50%', marginTop: '10%' }}>
                          <ReactLoading
                            type="spin"
                            color={'#F56A00'}
                            height={50}
                            width={50}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <NoQuestions>
                  {selectedIntermedOption
                    ? 'Item selecionado não localizado'
                    : 'Nenhum item selecionado'}
                </NoQuestions>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Container>
  );
}
