import { Stitches } from '../../../src/styles/stitches';
import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Container = Stitches.styled('div', {});

export const Header = Stitches.styled('div', {
  width: '100%',
  borderBottom: '1px solid #dddddd',
  padding: '10px',
  fontWeight: 'bold',
  marginBottom: '15px',
});

export const Select = styled(ReactSelect)`
  margin: 1rem 0;
  max-width: 400px;
  width: 100%;
`;

export const NoQuestions = Stitches.styled('div', {
  fontSize: '1.5rem',
  textAlign: 'center',
  position: 'absolute',
  top: '50%',
  left: '40%',
});

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  span {
    background-color: var(--black-light);
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    margin: 0 0.125rem;
  }
`;

export const ArrowLeft = styled.button`
  background-color: var(--primary);
  padding: 0.5rem 1rem;
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: var(--background-modal);
  }

  svg {
    font-size: 17px;
  }
`;

export const ArrowRight = styled.button`
  background-color: var(--primary);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: var(--background-modal);
  }

  svg {
    font-size: 17px;
  }
`;
