import { Stitches } from '../../../src/styles/stitches';
import { FiArrowRight } from 'react-icons/fi';
import styled from 'styled-components';
import ReactSelect from 'react-select';

export const Container = Stitches.styled('div', {
  marginTop: '50px',
  textAlign: 'right',
});
export const Form = Stitches.styled('form', {});
export const ContainerButton = Stitches.styled('div', {
  margin: '1px',
  marginLeft: '5px',
});
export const Select = styled(ReactSelect)`
  margin: 1rem 0;
  max-width: 250px;
  width: 100%;
`;
