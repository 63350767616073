import { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiEdit } from 'react-icons/fi';
import { toast } from 'react-toastify';

import {
  Container,
  Content,
  MainTitle,
  MainContent,
  ArrowLeft,
  ArrowRight,
  Pagination,
  Select,
  HeaderListPositions,
  SectionListPositions,
  ItemPosition,
  SectionFilter,
  PrimaryButton,
} from './styles';
import { AppError } from '../../error/AppError';
import { SelectPosition, OptionSelect, Position } from '../../interfaces';
import { ModalJobBond } from '../../components/ModalEditJobBond';
import { useAdmin } from '../../contexts/AdminContext';
import { api } from '../../services/api';

interface OptionSelectSubfamily extends OptionSelect {
  family_id: string;
}

const JobBond = () => {
  const { loadPositionsMatriz, positions, updatePositionMatriz, pagination } =
    useAdmin();

  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState<OptionSelect[]>([]);
  const [familiesOptions, setFamiliesOptions] = useState<OptionSelect[]>([]);

  const [selectedCompanyOption, setSelectedCompanyOption] =
    useState<OptionSelect>();
  const [selectedFamilyOption, setSelectedFamilyOption] =
    useState<OptionSelect>();
  const [allSubFamiliesOptions, setAllSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [subFamiliesOptions, setSubFamiliesOptions] =
    useState<OptionSelectSubfamily[]>();
  const [selectedSubFamilyOption, setSelectedSubFamilyOption] =
    useState<OptionSelect>();

  const [isShowModalPosition, setIsShowModalPosition] = useState(false);
  const [currentEditPosition, setCurrentEditPosition] = useState<Position>(
    {} as Position,
  );

  const loadTenants = async () => {
    try {
      const { data } = await api.get('/tenants?paginate=false');
      const companys = data.map((company) => ({
        label: company.name,
        value: company.id,
      }));

      setOptions(companys);
    } catch (error) {
      AppError(error, `Problemas ao carregar empresas!`);
    }
  };

  const selectEditPosition = (matchPosition: Position) => {
    setCurrentEditPosition(matchPosition);
    setIsShowModalPosition(true);
  };

  const loadFamilies = async () => {
    try {
      const { data } = await api.get('/smartpay/positions/subfamilies');

      const auxFamily: Array<string> = [];
      const families = data
        .map((family) => {
          const id = family.position_family.id as string;
          if (!auxFamily.includes(id)) {
            auxFamily.push(id);
            return {
              label: family.position_family.title,
              value: family.position_family.id,
            };
          }

          return null;
        })
        .filter((boolean) => boolean);
      const subFamilies: OptionSelectSubfamily[] = data.map((family) => ({
        label: family.title,
        value: family.id,
        family_id: family.position_family.id,
      }));
      setFamiliesOptions(families);
      setSubFamiliesOptions(subFamilies);
      setAllSubFamiliesOptions(subFamilies);
    } catch (error) {
      AppError(error, `Problemas ao carregar família e subfamília!`);
    }
  };

  const handleFilter = async () => {
    if (!selectedFamilyOption?.value) {
      toast.error('Escolha uma família');
      return;
    }

    const result = await loadPositionsMatriz(
      selectedCompanyOption?.value as string,
      selectedFamilyOption?.value as string,
      (selectedSubFamilyOption?.value as string) || '',
      currentPage,
    );

    if (result.length <= 0) {
      toast.error('Nenhum dado encontrado');
    }
  };

  const updateMatrizPosition = async (
    idSmartPayPosition: string,
    idCompanyPosition: string,
  ) => {
    try {
      await updatePositionMatriz({
        id: currentEditPosition.id,
        tenant_id: currentEditPosition.tenant_id,
        system_position_id: idSmartPayPosition,
        tenant_position_id: idCompanyPosition,
      });

      setSelectedCompanyOption(undefined);

      toast.success('Vínculo atualizado com sucesso!');
    } catch (error) {
      AppError(
        error,
        `Problemas ao atualizar cargo. Verifique os dados fornecidos!`,
      );
    }
  };

  const handleSetSelectedFamilyOption = (option: OptionSelect) => {
    setSelectedFamilyOption(option as OptionSelect);

    if (!option) {
      setSubFamiliesOptions(allSubFamiliesOptions);
      return;
    }

    const subFamiliesOptionsFiltered = allSubFamiliesOptions?.filter(
      (sf) => sf.family_id === option.value,
    );
    setSubFamiliesOptions(subFamiliesOptionsFiltered);
  };

  useEffect(() => {
    loadTenants();
  }, []);

  useEffect(() => {
    loadFamilies();
  }, [selectedCompanyOption]);

  return (
    <Container>
      {isShowModalPosition && (
        <ModalJobBond
          callback={updateMatrizPosition}
          closeModal={() => setIsShowModalPosition(false)}
          matchPosition={currentEditPosition}
        />
      )}
      <Content>
        <h2 className="text-2xl font-bold">Matriz de Cargos</h2>
        <div className="divider"></div>

        <MainContent>
          <Select
            onChange={(option) =>
              setSelectedCompanyOption(option as OptionSelect)
            }
            options={options}
            isSearchable={false}
            maxMenuHeight={150}
            placeholder="Escolha uma empresa"
          />

          {selectedCompanyOption?.value && (
            <>
              <SectionFilter>
                <Select
                  onChange={(option) =>
                    handleSetSelectedFamilyOption(option as OptionSelect)
                  }
                  options={familiesOptions}
                  isSearchable={true}
                  isClearable={true}
                  maxMenuHeight={150}
                  placeholder="Escolha uma Família"
                />
                <Select
                  onChange={(option) =>
                    setSelectedSubFamilyOption(option as OptionSelect)
                  }
                  options={subFamiliesOptions}
                  isSearchable={true}
                  isClearable={true}
                  maxMenuHeight={150}
                  placeholder="Escolha uma SubFamília"
                />
                <PrimaryButton onClick={handleFilter}>Buscar</PrimaryButton>
              </SectionFilter>
            </>
          )}

          {positions.length > 0 && (
            <>
              <div className="grid grid-cols-1 gap-4 mt-6">
                <div className="card bg-base-100 shadow-xl">
                  <div className="overflow-x-auto">
                    <table className="table w-full">
                      <thead>
                        <tr>
                          <td>Família</td>
                          <td>SubFamília</td>
                          <td>Cargo SmartPay</td>
                          <td>Cargo da Empresa</td>
                          <td>Empresa</td>
                          <td>Ações</td>
                        </tr>
                      </thead>
                      <tbody>
                        {positions && (
                          <>
                            {positions.map((position, index) => (
                              <tr key={index}>
                                <td>
                                  {
                                    position.system_position.position_subfamily
                                      .position_family.title
                                  }
                                </td>
                                <td>
                                  {
                                    position.system_position.position_subfamily
                                      .title
                                  }
                                </td>
                                <td>{position.system_position.title}</td>
                                <td>{position.tenant_position.title}</td>
                                <td>{position.tenant.name}</td>
                                <td>
                                  <div
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <FiEdit
                                      onClick={() => {
                                        selectEditPosition(position);
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination>
                <ArrowLeft
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FiArrowLeft />
                </ArrowLeft>
                <span>{currentPage}</span>
                <ArrowRight
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === pagination.lastPagePositionsMatriz}
                >
                  <FiArrowRight />
                </ArrowRight>
              </Pagination>
            </>
          )}
        </MainContent>
      </Content>
    </Container>
  );
};

export default JobBond;
