import { useState, useContext, useCallback } from 'react';
import { createContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';

import {
  deleteRefreshToken,
  deleteToken,
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
} from '../storage/BrowserStorage';
import {
  ConfirmPasswordAdmin,
  SignInProps,
  UserAdminProps,
} from '../interfaces';
import { api } from '../services/api';
import { useEffect } from 'react';
import axios from 'axios';

interface AuthContextProviderProps {
  children: ReactNode;
}

interface AuthContextData {
  isAuthenticated: boolean;
  signIn: (data: SignInProps) => Promise<void>;
  recoverPassword: (email: string) => Promise<void>;
  confirmPasswordAdmin: (data: ConfirmPasswordAdmin) => Promise<void>;
  signOut: () => void;
  getToken: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const signIn = async ({ email, password }: SignInProps) => {
    try {
      const req = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      const { data } = await req.post<UserAdminProps>('/auth', {
        email,
        password,
      });

      const { refresh_token, token } = data;

      setIsAuthenticated(true);
      setToken(token);
      setRefreshToken(refresh_token);

      navigate('/dashboard-users');
    } catch (error) {
      throw error;
    }
  };

  const recoverPassword = async (email: string) => {
    try {
      await api.post('/admins/password/forgot', {
        email,
      });
    } catch (error) {
      throw error;
    }
  };

  const confirmPasswordAdmin = async ({
    token,
    password,
  }: ConfirmPasswordAdmin) => {
    try {
      await api.post(`/admins/password/reset?token=${token}`, {
        password,
      });
    } catch (error) {
      throw error;
    }
  };

  const refreshTokenUser = useCallback(
    async (refreshToken: string) => {
      try {
        const { data } = await api.post('/auth/refresh', {
          token: refreshToken,
        });

        const { refresh_token, token } = data;
        setToken(token);
        setRefreshToken(refresh_token);
        setIsAuthenticated(true);
        setLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setLoading(false);
      }
    },
    [navigate],
  );

  const signOut = () => {
    deleteToken();
    deleteRefreshToken();
    setIsAuthenticated(false);
    setLoading(false);
    navigate('/');
  };

  useEffect(() => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (token && refreshToken) {
      refreshTokenUser(refreshToken);
    }
    setLoading(false);
  }, []);

  if (loading) {
    const color = '#F56A00';
    return <ReactLoading type="spin" color={color} height={67} width={75} />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        confirmPasswordAdmin,
        recoverPassword,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
