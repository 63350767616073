import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { Container, Content, Form, ContainerButton, Logo } from './styles';
import logo from '../../assets/png/logo.png';
import { useAuth } from '../../contexts/AuthContext';
import { AppError } from '../../error/AppError';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const RecoverPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const { confirmPasswordAdmin } = useAuth();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    const { password, confirmPassword } = data;

    const token = query.get('token');

    if (!token) return toast.error('Token não foi informado!');

    if (password !== confirmPassword)
      return toast.error('Senhas estão diferentes');

    try {
      await confirmPasswordAdmin({
        password,
        token,
      });
      toast.success('Senha atualizada com sucesso!');
      navigate('/');
    } catch (error) {
      AppError(error, 'Seu token não está válido');
    }
  };

  return (
    <Container>
      <Content>
        <Logo src={logo} alt="Logo SmartPay" />

        <Form onSubmit={handleSubmit(submit)}>
          <Input
            icon={showPassword ? FiEyeOff : FiEye}
            callback={() => setShowPassword(!showPassword)}
            placeholder="Senha"
            type={showPassword ? 'text' : 'password'}
            {...register('password', { required: true })}
            error={!!errors.password}
            helperText={!!errors.password ? 'Campo Obrigatório' : ''}
          />
          <Input
            icon={showPassword ? FiEyeOff : FiEye}
            callback={() => setShowConfirmPassword(!showConfirmPassword)}
            placeholder="Confirmar Senha"
            type={showPassword ? 'text' : 'password'}
            {...register('confirmPassword', { required: true })}
            error={!!errors.confirmPassword}
            helperText={!!errors.confirmPassword ? 'Campo Obrigatório' : ''}
          />
          <ContainerButton>
            <Button type="submit">Salvar</Button>
          </ContainerButton>
          <div>
            <Link to="/">Voltar para login</Link>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
