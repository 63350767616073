import { PaginationWrapper, PaginationNumber, ButtonPrimaryWrapper } from './styles';
import { ReactNode, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { LoadingSpinner } from '../Spinners/Loader';
import { useUpdateEffect } from 'react-use';
type DefaultProps = typeof PaginationWrapper.defaultProps;

const PAGINATE_LIMIT = 10;

type ButtonPrimaryDefaultProps = typeof ButtonPrimaryWrapper.defaultProps;
type CommonButtonProps = {
  label: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  isLoading?: boolean;
};
type ButtonPrimaryProps = ButtonPrimaryDefaultProps & CommonButtonProps;

type Props = DefaultProps & {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  isNextLoading?: boolean;
  isPrevLoading?: boolean;
  onNext: (limit: number) => void;
  onPrev: (limit: number) => void;
  onPage?: (page: number, limit: number) => void;
  totalLabel?: string;
};

export function Pagination({
  currentPage,
  totalPages,
  totalCount,
  isNextLoading,
  isPrevLoading,
  onNext,
  onPrev,
  onPage,
  totalLabel = 'items',
  ...props
}: Props) {
  const LIMIT = PAGINATE_LIMIT;
  const [limit, setLimit] = useState(LIMIT);

  const pages: number[] = [];
  const total = totalPages;
  // for (let i = 1; i <= (total >= 5 ? 5 : total); i++) {
  for (let i = 1; i <= total; i++) {
    pages.push(i);
  }

  function renderPages() {
    return pages.map((item) => {
      if (item >= currentPage - 2 && item <= currentPage + 2) {
        return (
          <PaginationNumber
            key={item}
            onClick={() => {
              if (onPage) {
                onPage(item, limit);
              }
            }}
            bgcolor={item == currentPage ? 'selected' : 'default'}
          >
            {item}
          </PaginationNumber>
        );
      }
    });
  }

  function renderLastPagePages() {
    return (
      <PaginationNumber
        onClick={() => {
          if (onPage) {
            onPage(total, limit);
          }
        }}
        bgcolor={currentPage == totalPages ? 'selected' : 'default'}
      >
        {totalPages}
      </PaginationNumber>
    );
  }

  function ButtonPrimary({
    label,
    startIcon = null,
    endIcon = null,
    isLoading = false,
    ...props
  }: ButtonPrimaryProps) {
    return (
      <ButtonPrimaryWrapper
        {...props}
        disabled={isLoading || props.disabled ? true : false}
      >
        <>
          {!isLoading ? (
            <>
              {startIcon} {label} {endIcon}
            </>
          ) : (
            <LoadingSpinner />
          )}
        </>
      </ButtonPrimaryWrapper>
    );
  }

  useUpdateEffect(() => {
    if (onPage) {
      onPage(1, limit);
    }
  }, [limit]);

  return (
    <PaginationWrapper {...props}>
      <div>
        <ButtonPrimary
          type="button"
          label=""
          css={{ maxWidth: 'fit-content' }}
          size="medium"
          startIcon={<FiChevronLeft />}
          disabled={currentPage === 1}
          onClick={() => onPrev(limit)}
          // isLoading={isPrevLoading}
        />
        {/* <strong>
          {currentPage} de {totalPages}
        </strong> */}

        {renderPages()}
        {totalPages > 5 ? (
          <PaginationNumber bgcolor="default" type="disabled">
            ...
          </PaginationNumber>
        ) : null}
        {totalPages > 5 ? renderLastPagePages() : null}

        <ButtonPrimary
          type="button"
          label=""
          css={{ maxWidth: 'fit-content' }}
          size="medium"
          startIcon={<FiChevronRight />}
          disabled={currentPage === totalPages}
          onClick={() => onNext(limit)}
          // isLoading={isNextLoading}
        />
        <select
          style={{
            height: '40px',
            borderRadius: '10px',
            border: '2px solid #aaaaaa',
            width: '70px',
          }}
          onChange={(event) => setLimit(Number(event.target.value))}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
      </div>

      <strong>
        Total de {totalCount} {totalLabel}
      </strong>
    </PaginationWrapper>
  );
}
