import styled from 'styled-components';

export const PasswordsErrorsContainer = styled.div`
  margin-top: 0.5rem;
  width: '100%';

  svg.success {
    * {
      color: #22d66a;
    }
  }

  svg.error {
    * {
      color: #ff0000;
    }
  }
`;
