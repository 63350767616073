import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyle from './styles/global';
import { AuthProvider } from './contexts/AuthContext';

import Routes from './routes';
import { AdminProvider } from './contexts/AdminContext';
import { GlobalLoadingContextProvider } from './contexts/GlobalLoadingContext';
import { GlobalLoading } from './components/GlobalLoading';
import { QueryClient, QueryClientProvider } from 'react-query';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: 5,
      retryDelay: 1000,
    },
    queries: {
      retry: 5,
      retryDelay: 1000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <GlobalLoadingContextProvider>
            <AuthProvider>
              <AdminProvider>
                <GlobalStyle />
                <ToastContainer autoClose={3000} />
                <GlobalLoading />
                <Routes />
              </AdminProvider>
            </AuthProvider>
          </GlobalLoadingContextProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
