import { memo, useEffect, useState } from 'react';
import { LoadingSpinner } from '../Spinners/Loader';

interface SingleCheckboxProps {
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  title?: string;
  label?: string;
  value?: boolean;
  scale?: number;
  enableLoading?: boolean;
}

export function SingleCheckboxComponent({
  onChange,
  title,
  label,
  value,
  disabled,
  scale = 14,
  enableLoading = false,
}: SingleCheckboxProps) {
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      handleIsLoading(true);
      setChecked(value);
    }
  }, [value]);

  const handleIsLoading = (value: boolean) => {
    if (enableLoading) {
      setIsLoading(value);
    }
  };

  const handleOnChange = () => {
    if (value !== undefined) {
      handleIsLoading(true);
      onChange && onChange(!checked);
    } else {
      handleIsLoading(true);
      setChecked(!checked);
    }
  };

  useEffect(() => {
    handleIsLoading(false);
  }, [checked]);

  return (
    <div className='flex'>
      <span className='mr-1'>
        {label}
      </span>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='flex items-center justify-center'>
          <input
          style={{
            width: `${scale}px`,
            height: `${scale}px`,
          }}
          className="cursor-pointer accent-primary-600"
          type="checkbox"
          onChange={handleOnChange}
          checked={checked}
          title={title}
          disabled={(enableLoading && !disabled ? isLoading : disabled)}
        />
        </div>
      )}
    </div>
  );
}

export const SingleCheckbox = memo(SingleCheckboxComponent);
