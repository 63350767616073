import { useQuery } from 'react-query';
import { api } from '../../../../services/api';
import { Company } from '../../../../interfaces';

export type GetCompanyResponse = {
  tenant: Company;
};

export function useGetCompany(tenantId: string) {
  const {
    data: company,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: `get-company-${tenantId}`,
    queryFn: async () => {
      const data = await api.get<GetCompanyResponse>(`/tenants/${tenantId}`);

      if (data) {
        return data.data.tenant;
      }

      return null;
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !!tenantId,
    staleTime: Infinity,
    retry: 2,
  });

  return {
    company,
    refetch,
    isLoading,
    isFetching,
  };
}
